import {serializable, alias, object, list, primitive} from 'serializr';
import { AttachmentModel } from '../Attachment/attachment.model';

export class FeePercent { 
	@serializable(alias('2', primitive()))
	two?: string;

	@serializable(alias('3', primitive()))
	three?: string;

	@serializable(alias('4', primitive()))
	four?: string;

	@serializable(alias('5', primitive()))
	five?: string;

	@serializable(alias('all', primitive()))
	all?: string;
}

export class InstallmentPercentage {
	@serializable(alias('installment', primitive()))
	installment?: number;

	@serializable(alias('percentage', primitive()))
	percentage?: string;
}

export class PromoConfig { 

	@serializable(alias('purchase_type', list(primitive())))
	purchaseType?: string[] = [];

	@serializable(alias('customers', list(primitive())))
	customers?: string[] = [];

	@serializable(alias('purchase_percent', primitive()))
	purchasePercent?: string;

	@serializable(alias('fee_percent', object(FeePercent)))
	feePercent: FeePercent = new FeePercent();

	@serializable(alias('installment_percentage', list(object(InstallmentPercentage))))
	installmentPercentage?: InstallmentPercentage[] = [];

}

export class Images { 

	@serializable(alias('landing_banner_ar', object(AttachmentModel)))
	landingBannerAr?: AttachmentModel;

	@serializable(alias('landing_banner_en', object(AttachmentModel)))
	landingBannerEn?: AttachmentModel;

	@serializable(alias('explainer_banner_ar', object(AttachmentModel)))
	explainerBannerAr?: AttachmentModel;

	@serializable(alias('explainer_banner_en', object(AttachmentModel)))
	explainerBannerEn?: AttachmentModel;

	@serializable(alias('small_banner_ar', object(AttachmentModel)))
	smallBannerAr?: AttachmentModel;

	@serializable(alias('small_banner_en', object(AttachmentModel)))
	smallBannerEn?: AttachmentModel;

	@serializable(alias('landing_banner_ar_id', primitive()))
	landingBannerArId?: string;

	@serializable(alias('landing_banner_en_id', primitive()))
	landingBannerEnId?: string;

	@serializable(alias('explainer_banner_ar_id', primitive()))
	explainerBannerArId?: string;

	@serializable(alias('explainer_banner_en_id', primitive()))
	explainerBannerEnId?: string;

	@serializable(alias('small_banner_ar_id', primitive()))
	smallBannerArId?: string;

	@serializable(alias('small_banner_en_id', primitive()))
	smallBannerEnId?: string;
}

export class Promotion { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('is_active', primitive()))
	isActive?: boolean;

	@serializable(alias('start_date', primitive()))
	startDate?: string;

	@serializable(alias('end_date', primitive()))
	endDate?: string;

	@serializable(alias('offer_type', primitive()))
	offerType?: string;

	@serializable(alias('discount_type', primitive()))
	discountType?: string;

	@serializable(alias('purchase_percent', primitive()))
	purchasePercent?: string;

	@serializable(alias('promo_config', object(PromoConfig)))
	promoConfig?: PromoConfig;

	@serializable(alias('images', object(Images)))
	images?: Images;

	@serializable(alias('promo_id', primitive()))
	promoId?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('phone', primitive()))
	phone?: boolean = false;

	@serializable(alias('phone_credit', primitive()))
	phoneCredit?: boolean = false;

	@serializable(alias('credit', primitive()))
	credit?: boolean = false;

	@serializable(alias('purchase_type_phone', primitive()))
	purchaseTypePhone?: boolean = false;

	@serializable(alias('purchase_type_other', primitive()))
	purchaseTypeOther?: boolean = false;

	@serializable(alias('is_processing_fee_same', primitive()))
	isProcessingFeeSame?: boolean;

	@serializable(alias('discounted_processing_fee', primitive()))
	discountedProcessingFee?: string;

}