import React, { FC } from "react";
import BadgeComponent from "../../../../../shared/components/BadgeComponent";
import "./purchaseCustomerDetailsCard.scss";
import IconComponent from "../../../../../shared/components/IconComponent";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";
import CardSkeletonComponent from "../../../../../shared/components/CardSkeletonComponent";
import { VendorTypesDefinition } from "../../../../../definitions/vendorTypesDefinition";
import { VendorTypes } from "../../../../../enums/vendorTypes";
import { Col, Row } from "antd";
import { formatDate } from "../../../../../shared/utils/formatDate";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";

interface PurchaseCustomerDetailsCardProps {
  purchase: PurchaseModel | undefined;
  loading: boolean;
}

const PurchaseCustomerDetailsCard: FC<PurchaseCustomerDetailsCardProps> = (
  props
) => {
  const { purchase, loading } = props;
  const navigate = useNavigate()

  return loading ? (
    <CardSkeletonComponent
      className="purchase-customer-details-card-skeleton"
      rows={10}
    />
  ) : (
    <div className="purchase-customer-details-card">
      <Row gutter={[12, 0]} className="customer-details details-row">
        <Col className="image__container">
          <img src={purchase?.customer?.profilePic?.s3Url} />
        </Col>
        <Col span={20} className="details__container">
          <p className="header">Customer</p>
          <h2 className="name customer" onClick={()=>navigate(
            {pathname: generatePath(AppRoutes.CUSTOMERS_LIST_VIEW, {customerId: purchase?.customer?.id?.toString()})},
            {state: {from: 'purchases'}}
            )}>
              {purchase?.customer?.fullName}
          </h2>
          <Row align="middle" gutter={[10, 0]} className="key-values">
            <Col>
              <IconComponent name="icon-info-grey" />
            </Col>
            <Col>
              <span className="value">
                {purchase?.customer?.id}
              </span>
            </Col>
          </Row>
          <Row align="middle" gutter={[10, 0]} className="key-values">
            <Col>
              <IconComponent name="icon-phone-grey" />
            </Col>
            <Col>
              <span className="value">
                {purchase?.customer?.countryCode?.isdCode} {purchase?.customer?.mobileNumber}
              </span>
            </Col>
          </Row>
          <Row align="middle" gutter={[10, 0]} className="key-values">
            <Col>
              <IconComponent name="icon-email-grey" />
            </Col>
            <Col span={21}>
              <span className="value">
                {purchase?.customer?.email}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[12, 0]} className="vendor-details details-row">
        <Col className="image__container">
          <img src={purchase?.vendor?.logo?.s3Url} />
        </Col>
        <Col span={19} className="details__container">
          <p className="header">Vendor</p>
          <h2 className="name">{purchase?.vendor?.name}</h2>
          <Row justify="space-between" gutter={[10, 0]} className="key-values">
            <Col>
              <span className="key">Mode</span>
            </Col>
            <Col>
              <span className="value">
                {purchase?.vendor?.mode === VendorTypes?.ONLINE
                  ? VendorTypesDefinition[purchase?.vendor?.mode]
                  : purchase?.vendor?.mode === VendorTypes?.INSTORE
                    ? purchase?.vendor?.instore?.name
                    : ""}
              </span>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[10, 0]} className="key-values">
            <Col>
              <span className="key">Purchase ID</span>
            </Col>
            <Col>
              <span className="value">
                {purchase?.purchaseId}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseCustomerDetailsCard;
