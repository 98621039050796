import React, { FC, SetStateAction, useEffect, useState } from "react";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../shared/components/TableComponent";
import "./vendorList.scss";
import faker, { fake } from "faker";
import { Col, Dropdown, Row } from "antd";
import { Form, Formik, FormikValues } from "formik";
import DropdownField from "../../../../shared/components/DropdownField";
import IconComponent from "../../../../shared/components/IconComponent";
import VendorForm from "./VendorForm";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import VendorService from "../../../../services/VendorService/vendor.service";
import { VendorTypes } from "../../../../enums/vendorTypes";
import { VendorTypesDefinition } from "../../../../definitions/vendorTypesDefinition";
import { tableSorter } from "../../../../shared/utils/tableSorter";
import NoVendorsImg from "../../../../assets/empty/vendors.svg";
import { BadgeTypesDefinition } from "../../../../definitions/badgeTypesDefinition";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { VendorListModel } from "../../../../models/Vendor/vendor.model";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../models/User/user.model";
import { objectHasKeys } from "../../../../shared/utils/objectHasKeys";
import { removeUndefined } from "../../../../shared/utils/removeUndefined";
import { StateProps } from "../../../../models/Meta/meta.model";
import { clearFilters } from "../../../../shared/utils/clearFilters";
interface VendorListProps {
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

interface FilterProps extends VendorListModel {
  search?: string;
}
const VendorList: FC<VendorListProps> = (props) => {
  const {setCount} = props;
  const [isFilterShown, setIsFilterShown] = useState(false);
  const { loading, fetchVendorList, vendors, paginations } = VendorService();
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = location.hash === "#list" ? stateValue?.pageNumber as number : null
  let filterOptions = location.hash === "#list" ? stateValue?.filterOptions as SortFilterModel : null
  const [filters, setFilters] = useState<FilterProps>(filterOptions ?? {});
  const [page, setPage] = useState(pageNumber ?? 1)
  const [search, setSearch] = useState("")
  const [isVendorFormShown, setIsVendorFormShown] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const showTest = LocalStorage.getItem("IS_TEST")
  const user = LocalStorage.getItem('USER') as UserModel

  useEffect(() => {
    if(objectHasKeys(filters) || location.hash === "#list"){
      const updatedFilters = objectHasKeys(filters) 
      ? { page, ...filters, ...sortOptions, showTest  }
      : { page, ...sortOptions, search, ...filters, showTest  };

      fetchVendorList(updatedFilters);
    }
  }, [filters, location.hash]);

  useEffect(() => {
    setCount(vendors?.length??0)
  }, [vendors]);

  const handleFilterSubmit = (values: FilterProps) => {
    setPage(1)
    setFilters(prev => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };
  const filterInitialValues = filterOptions ?? {
    type: undefined,
    status: undefined,
  };
  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              clearFilters({
                resetForm,
                setPage,
                handleFilterClose,
                setFilters,
                filterOptions,
                filters,
                location,
              })
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <DropdownField
                    options={[
                      {
                        value: VendorTypes.ONLINE,
                        label: VendorTypesDefinition[VendorTypes.ONLINE],
                      },
                      {
                        value: VendorTypes.INSTORE,
                        label: VendorTypesDefinition[VendorTypes.INSTORE],
                      },
                      {
                        value: VendorTypes.BOTH,
                        label: VendorTypesDefinition[VendorTypes.BOTH],
                      },
                    ]}
                    label="Type"
                    name="type"
                    placeholder="Enter"
                    onChange={(value) => setFieldValue("type", value)}
                    value={values?.type}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    label="Status"
                    options={[
                      {
                        value: BadgeTypes.APPROVED,
                        label: BadgeTypesDefinition[BadgeTypes.ACTIVE],
                      },
                      {
                        value: BadgeTypes.INACTIVE,
                        label: BadgeTypesDefinition[BadgeTypes.INACTIVE],
                      },
                      {
                        value: BadgeTypes.PENDING_APPROVAL,
                        label: BadgeTypesDefinition[BadgeTypes.PENDING_APPROVAL],
                      },
                    ]}
                    name="status"
                    placeholder="Enter"
                    onChange={(value) => setFieldValue("status", value)}
                    value={values?.status}
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );

  const handleRefresh = () => {
    fetchVendorList({showTest});
  };
  let newButton = {};
  if (user?.role !== BadgeTypes.VIEWER) {
    newButton = {
      newBtn: {
        clickHandler: () => setIsVendorFormShown(true),
        text: "Add vendor"
      }
    }
  }
  const handlePageChange = (page:number) => {
    location.state = null
    setPage(page)
    fetchVendorList({page, search, ...filters, ...sortOptions, showTest})
  }

  return (
    <div className="list-vendors">
      <VendorForm
        successHandler={() => {
          handleRefresh();
          setIsVendorFormShown(false);
        }}
        visible={isVendorFormShown}
        closeHandler={() => setIsVendorFormShown(false)}
      />
      <TableComponent
        filter={filter}
        loading={loading}
        pageNumber={pageNumber ?? page}
        paginationMeta={paginations}
        handlePageChange={handlePageChange}
        search={{
          placeholder: "Search vendor by name or id",
          onSearch: (value) => {
            setSearch(value)
            handleFilterSubmit({search: value})
          },
        }}
        onChange={() => fetchVendorList({page, search, ...filters, ...sortOptions, showTest})}
        onSort={(data)=>{
          setSortOptions(data)
        }}
        filtersSorts={{page, search, ...filters, ...sortOptions, showTest}}
        onRow={(record)=>
          navigate(
            {pathname: generatePath(AppRoutes.VENDOR_DETAILS, {
              vendorId: record?.id,
              vendorName: record?.name?.replace(/ /gi, "-"),
            })}, {state: {pageNumber: page, filterOptions: filters}}
          )
        }
        empty={{
          text: "No vendor data",
          img: NoVendorsImg,
        }}
        {...newButton}
        columns={[
          {
            title: "Vendor",
            dataIndex: "name",
            key: SortColumnTypes.VENDOR_NAME,
            sorter: true,
            render: (text, record) => (
              <span className="list-vendors-business__name cursor-pointer">
                <img src={record?.logo?.s3Url} />
                {text}
              </span>
            ),
          },
          {
            title: "Vendor ID",
            dataIndex: "id",
            key: SortColumnTypes.VENDOR_ID,
            sorter: true,
            render: (text) => text,
          },
          {
            title: "Type",
            dataIndex: "type",
            key: SortColumnTypes.VENDOR_TYPE,
            sorter: true,
            render: (text: VendorTypes) => VendorTypesDefinition[text],
          },
          {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "# Of Orders",
            dataIndex: "noOfOrders",
            key: SortColumnTypes.VENDOR_NO_OF_ORDERS,
            sorter: true,
          },
          {
            title: "Status",
            dataIndex: "status",
            key: SortColumnTypes.VENDOR_STATUS,
            render: (text) => <BadgeComponent type={text===BadgeTypes.APPROVED?BadgeTypes.ACTIVE:text} />,
            sorter: true,
          },
        ]}
        data={vendors}
      />
    </div>
  );
};

export default VendorList;
