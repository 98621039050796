import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from '../../../context/AuthContext';
import { AppRoutes } from '../../../routes/routeConstants/appRoutes';
import RestrictAccess from "../RestrictedAccess";

const RequireAuth: FC<{ children: JSX.Element, allowedRoles?: string[], user?: { role: string } }> = ({ children, allowedRoles, user }) => {
    const { authenticated } = AuthContext();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authenticated && location.pathname !== AppRoutes.LOGIN) {
            navigate(`${AppRoutes.LOGIN}?redirectUrl=${encodeURIComponent(window.location.href)}`);
        }
    }, [authenticated, location.pathname, navigate]);

    if (allowedRoles?.length && user) {
        return allowedRoles.includes(user.role) ? children : <RestrictAccess />;
    }

    return children;
};

export const isAuthenticated = (component: JSX.Element, allowedRoles?: string[], user?: { role: string }) => {
    return <RequireAuth children={component} allowedRoles={allowedRoles} user={user} />;
};

export default isAuthenticated;
