import React, { FC } from 'react'
import { Questionnaire } from '../../../../../../models/Questionnaire/questionnaire.model'
import TableComponent from '../../../../../../shared/components/TableComponent'
import "./customerQuestionnaire.scss"
import {addressQuestionId} from "../../../../../../enums/constants";
import { Divider } from 'antd';

interface CustomerQuestionnaireProps {
    data?: Questionnaire[]
}

const CustomerQuestionnaire: FC<CustomerQuestionnaireProps> = ({ data }) => {
    const isMobile = window.innerWidth < 480;

    data = data?.map(item => {
        if (item && item.question_id === addressQuestionId) {
            // Check if the answer is an array and has at least one element
            if (Array.isArray(item.answer) && item.answer[0] && typeof item.answer[0] === 'object') {
                const { city, region, neighbourhood, buildingNumber, streetAddress } = item.answer[0];

                // Return the modified item with the address fields as an array of strings
                return {
                    ...item,  // Spread the existing properties of the item
                    answer: [
                        `Building number: ${buildingNumber || ''}`,
                        `Street address: ${streetAddress || ''}`,
                        `Neighbourhood: ${neighbourhood || ''}`,
                        `Region: ${region || ''}`,
                        `City: ${city || ''}`,
                    ]
                };
            } else {
                // If answer is not as expected, return the item unchanged
                return item;
            }
        } else {
            // For items where question_id is not 7, return the item unchanged
            return item;
        }
    });
    return (
        <div className="customer-questionnaire">
            {!isMobile && 
            <TableComponent
                loading={false}
                rowClassName={() => "question-text"}
                columns={[
                    {
                        title: "Question",
                        dataIndex: "question",
                        key: "question"
                    },
                    {
                        title: "Answers",
                        dataIndex: "answer",
                        key: "answer",
                        render: (value, record) => {
                            if (record.question_id === 7) {
                                return (
                                    <div>
                                        {value?.map((line: string, index: number) => (
                                            <div className='address' key={index}>{line}</div>
                                        ))}
                                    </div>
                                );
                            } else {
                                return value?.join(", ");
                            }
                        },
                        ellipsis: true,
                    },
                ]}
                data={data}
                empty={{
                    text: "No customer questionnaire data",
                }}
            />}
            {isMobile && 
            <div className='questionnaire-container'>
                {data?.map(qna => (
                    <>
                        <div className='question'>{qna?.question}</div>
                        <div className='answer'>{qna?.answer}</div>
                        <Divider />
                    </>
                ))}
            </div>}
        </div>
    )
}

export default CustomerQuestionnaire