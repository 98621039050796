import {serializable, alias, object, list, primitive} from 'serializr';

export class EventData { 
	@serializable(alias('message', primitive()))
	message?: string;

	@serializable(alias('reasons', list(primitive())))
	reasons?: string[];

	@serializable(alias('comment', primitive()))
	comment?: string;

	@serializable(alias('verified_by', primitive()))
	verifiedBy?: string;
}

export class ApprovalRejectionHistory { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('customer_id', primitive()))
	customerId?: number;

	@serializable(alias('event_name', primitive()))
	eventName?: string;

	@serializable(alias('event_data', object(EventData)))
	eventData?: EventData;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

}