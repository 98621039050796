import React, { FC, Fragment, useState } from "react";
import CardComponent from "../../../../../../shared/components/CardComponent";
import "./approveCustomerThirdPartyDetailsCard.scss";
import { CustomerModel } from "../../../../../../models/Customer/customer.model";
import IconComponent from "../../../../../../shared/components/IconComponent";
import CustomerDetailsForm from "../../../CustomerDetailsForm/index"
import { BadgeTypes } from "../../../../../../enums/badgeTypes";
import {LocalStorage} from "../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../models/User/user.model";
import moment from "moment";
import { displayDash } from "../../../../../../shared/utils/displayDash";
import { DragOutlined } from '@ant-design/icons'
import { Divider } from "antd";
import rejectedIcon from "./../../../../../../assets/images/rejected.png"
import approvedIcon from "./../../../../../../assets/images/approved.png"
import pendingIcon from "./../../../../../../assets/images/pending.png"
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import { BadgeTypesDefinition } from "../../../../../../definitions/badgeTypesDefinition";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import { useParams } from "react-router-dom";
interface ApproveCustomerThirdPartyDetailsCardProps {
  loading: boolean;
  customer: CustomerModel | undefined;
  handleRefresh: () => void;
  detailsExpanded?: boolean;
  setDetailsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

const ApproveCustomerThirdPartyDetailsCard: FC<ApproveCustomerThirdPartyDetailsCardProps> = (props) => {
  const {loading, customer, handleRefresh, detailsExpanded, setDetailsExpanded} = props;
  const { resetKycRetry, loading: resetKycRetryLoading } = CustomerService()
  const { customerId } = useParams();
  const [editCustomerDetails, setEditCustomerDetails] = useState(false)
  const [resetModalVisible, setResetModalVisible] = useState(false)
  const isRejected = customer?.status === BadgeTypes.REJECTED
  const isApproved = customer?.status === BadgeTypes.APPROVED
  const isWaitlisted = customer?.status === BadgeTypes.WAITLISTED 
  const KYCStatus = customer?.kycStatus
  const user = LocalStorage.getItem('USER') as UserModel
  const isMobile = window.innerWidth <= 480;
  const renderDivider = isMobile && <Divider/>

  const handleSubmit = () => customerId && resetKycRetry(customerId, true).then(()=>{ setResetModalVisible(false); handleRefresh() })

  return (
    <div className={`third-party-card ${detailsExpanded && "max-height"}`}>
      <CustomerDetailsForm
        visible={editCustomerDetails}
        closeHandler={() => setEditCustomerDetails(false)} 
        data={customer}
        successHandler={ () => {
            handleRefresh()
            setEditCustomerDetails(false)
          }
        }
      />
      <ModalComponent
        type="alert"
        className="reset-retry-count-alert"
        alert={{
          title: "Are you sure you want to reset the customer KYC verification?",
          description: "This will allow the customers to retry account verification process again through their mobile application",
          buttonType: "primary"
        }}
        visible={resetModalVisible}
        closeHandler={()=>setResetModalVisible(false)}
        successProps={{
          title: "Confirm",
          clickHandler: handleSubmit,
          loading: resetKycRetryLoading
        }}
        cancelProps={{
          title: "Close",
          clickHandler: ()=>setResetModalVisible(false),
        }}
      />
      <CardComponent
        className={`approve-customer-third-party-details-card ${detailsExpanded && "max-height"}`}
        loading={loading}
        icon={!isRejected && user?.role !== BadgeTypes.VIEWER && <IconComponent name="icon-edit" onClick={() => {
          setEditCustomerDetails(true)
        }
        } />}
      >
        <div className={`approve-customer-third-party-details-card__content ${detailsExpanded && "max-height"}`}>
          {renderDivider}
          {KYCStatus && 
            <Fragment>
              <div className="approve-customer-third-party-details-card-item">
                <p className="approve-customer-third-party-details-card-item-title">Auto KYC</p>
                <p className={`kyc-value ${KYCStatus === BadgeTypes.FAILED ? "negative" : KYCStatus === BadgeTypes.KYC_PENDING ? "pending" : "positive"}`}>
                  {BadgeTypesDefinition[KYCStatus as BadgeTypes]} 
                  <img src={KYCStatus === BadgeTypes.FAILED ? rejectedIcon : KYCStatus === BadgeTypes.KYC_PENDING ? pendingIcon : approvedIcon} className="status-icon" alt="status-icon" /> 
                </p>
              </div>
              {renderDivider}
              <div className="approve-customer-third-party-details-card-item">
                <p className="approve-customer-third-party-details-card-item-title">Verification Date</p>
                <p className="kyc-value">{customer?.kycLastVerifiedAt}</p>
              </div>
              {renderDivider}
              <div className="approve-customer-third-party-details-card-item">
                <p className="approve-customer-third-party-details-card-item-title">KYC rejection reason</p>
                <p className="kyc-value text-right">{customer?.onboardingDetail?.digifiedComments}</p>
              </div>
              {renderDivider}
              {
                customer?.status === BadgeTypes.PENDING_APPROVAL &&
                <div className="approve-customer-third-party-details-card-item">
                  <p className="approve-customer-third-party-details-card-item-title"></p>
                  <p className={`kyc-value ${isMobile && "button"}`}>
                    <ButtonComponent
                      size={isMobile ? "small" : "medium"}
                      type="primary"
                      additionalTypes={"primary"}
                      className="edit-detail"
                      onClick={() => setResetModalVisible(true)}
                    >
                      Reset KYC verification
                    </ButtonComponent>
                  </p>
                </div>
              }
              {renderDivider}
              {!isMobile && <Divider/>}
            </Fragment>
          }
          {(customer?.rejectedAt && !isApproved && !isWaitlisted) && 
            <>
              <div className="approve-customer-third-party-details-card-item">
                <p className="approve-customer-third-party-details-card-item-title">Rejected By</p>
                <p className="kyc-value">{customer?.rejectedBy?.firstName} {customer?.rejectedBy?.lastName}</p>
              </div>
              {renderDivider}
              <div className="approve-customer-third-party-details-card-item">
                <p className="approve-customer-third-party-details-card-item-title">Rejected On</p>
                <p className="kyc-value">{displayDash(customer?.rejectedAt && moment(customer?.rejectedAt)?.format("DD MMM YYYY"))}</p>
              </div>
              {renderDivider}
              <div className="approve-customer-third-party-details-card-item">
                <p className="approve-customer-third-party-details-card-item-title">Rejected reason</p>
                <p className="kyc-value">
                {customer?.rejectionReasons?.map((reason, index) => (
                  <Fragment key={index}>
                    {`${reason?.label}`}
                    {index !== (customer?.rejectionReasons?.length ?? 0) - 1 && ', '}
                  </Fragment>
                ))}
                </p>
              </div>
              {renderDivider}
              <div className="approve-customer-third-party-details-card-item">
                <p className="approve-customer-third-party-details-card-item-title">Comment</p>
                <p className="kyc-value">{displayDash(customer?.rejectedReason)}</p>
              </div>
              {renderDivider}
            </>
          }
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">National ID</p>
            <p className="kyc-value">{displayDash(customer?.nationalIdNumber)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Name (Customer provided name)</p>
            <p className="kyc-value">{displayDash(customer?.fullName)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Name (Captured from ID)</p>
            <p className="kyc-value">{displayDash(customer?.nameFromId)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">DOB</p>
            <p className="kyc-value">{displayDash(customer?.dateOfBirth)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Address (Captured from ID)</p>
            <p className="kyc-value">{displayDash(customer?.address)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">City (Captured from ID)</p>
            <p className="kyc-value">{displayDash(customer?.city?.label)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Region (Captured from ID)</p>
            <p className="kyc-value">{displayDash(customer?.region?.label)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Neighbourhood (from ID) </p>
            <p className="kyc-value">{displayDash(customer?.neighbourhood?.label)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Neighborhood (from questionnaire) </p>
            <p className="kyc-value">{displayDash(customer?.neighbourhoodFromQuestionnaire?.label)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Job Title</p>
            <p className="kyc-value">{displayDash(customer?.jobTitle)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Professional Group</p>
            <p className="kyc-value">{displayDash(customer?.professionalGroup?.label)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Marital Status</p>
            <p className="kyc-value">{displayDash(customer?.maritalStatus)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Religion</p>
            <p className="kyc-value">{displayDash(customer?.religion)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Sex</p>
            <p className="kyc-value">{displayDash(customer?.gender)}</p>
          </div>
          {renderDivider}
          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">OS</p>
            <p className="kyc-value">{displayDash(customer?.os)}</p>
          </div>
          {renderDivider}

          <div className="approve-customer-third-party-details-card-item">
            <p className="approve-customer-third-party-details-card-item-title">Acquisition channel</p>
            <p className="kyc-value">{displayDash(customer?.acquisitionChannel)}</p>
          </div>
          {
            !isMobile && <DragOutlined title="expand" className="expand-details-icon" onClick={()=>setDetailsExpanded((state)=>!state)}/>
          }
        </div>
      </CardComponent>
    </div>
  );
};

export default ApproveCustomerThirdPartyDetailsCard;
