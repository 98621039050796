import React, { FC, SetStateAction, useEffect, useState } from "react";
import TableComponent from "../../../../shared/components/TableComponent";
import "./approveInstores.scss";
import faker from "faker";
import VendorInstoreDetails from "../VendorList/VendorListView/VendorInstores/VendorInstoreDetails";
import VendorService from "../../../../services/VendorService/vendor.service";
import { tableSorter } from "../../../../shared/utils/tableSorter";
import { VendorInstoreModel } from "../../../../models/Vendor/Instore/instore.model";
import NoVendorsImg from "../../../../assets/empty/vendors.svg";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";

interface ApproveInstoresProps {
  setCount: SetStateAction<any>;
}

const ApproveInstores: FC<ApproveInstoresProps> = (props) => {
  const { setCount } = props;
  const location = useLocation()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const showTest = LocalStorage.getItem("IS_TEST")
  const [isApproveInstoreDetailsShown, setIsApproveInstoreDetailsShown] =
    useState(false);
  const { fetchUnapprovedInstoreList, loading, unapprovedInstores, paginations } =
    VendorService();
  const [currentInstore, setCurrentInstore] = useState<VendorInstoreModel>();
  const navigate = useNavigate()

  const handlePageChange =(page:number) => { 
    setPage(page)
    fetchUnapprovedInstoreList({page, ...sortOptions, search, showTest})
  }

  useEffect(() => {
    location.hash === "#approve-instores" &&
    fetchUnapprovedInstoreList({showTest});
  }, [location.hash]);

  useEffect(() => {
    setCount(paginations?.totalCount);
  }, [paginations]);

  return (
    <div className="approve-instores">
      <VendorInstoreDetails
        instore={currentInstore}
        visible={isApproveInstoreDetailsShown}
        closeHandler={() => setIsApproveInstoreDetailsShown(false)}
        refreshList={() => {
          fetchUnapprovedInstoreList({showTest});
        }}
      />
      <TableComponent
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      onChange={fetchUnapprovedInstoreList}
      onSort={(data)=>setSortOptions(data)}
      filtersSorts={{page, search, showTest}}
      onRow={(record: VendorInstoreModel)=>{
        setCurrentInstore(record);
        const { vendorId, vendorName, id } = record
        navigate({ pathname: generatePath(AppRoutes.VENDOR_INSTORES_VIEW, { vendorId: vendorId?.toString(), vendorName, instoreId: id?.toString() }) }, { state: record })
      }}
      search={{
          placeholder: "Search vendor by name or id",
          onSearch: (value) => {
            setSearch(value)
            fetchUnapprovedInstoreList({ search: value , showTest});
          },
        }}
        empty={{
          text: "No instore data",
          img: NoVendorsImg,
        }}
        columns={[

          {
            title: "Vendor",
            dataIndex: "vendorName",
            key: "vendor_name",
            render: (text, record) => (
              <span className="list-vendors-business__name">
                {record?.logo ? <img src={record?.logo?.s3Url} /> : ""}

                {text}
              </span>
            ),
            sorter: true
          },
          {
            title: "Vendor ID",
            dataIndex: "vendorId",
            key: "vendor_id",
            sorter: true,
            render: (text) => text
          },
          {
            title: "Store Name",
            dataIndex: "name",
            key: "instore_name",
            sorter: true,
          },
          {
            title: "Contact Person",
            dataIndex: "contactFullName",
            key: "contactFullName",
          },
          {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
        ]}
        loading={loading}
        data={unapprovedInstores}
      />
    </div>
  );
};

export default ApproveInstores;
