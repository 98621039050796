import { Modal } from "antd";
import React, { FC, ReactComponentElement, ReactElement, useEffect, useState } from "react";
import ButtonComponent from "../ButtonComponent";
import IconComponent from "../IconComponent";
import "./modalComponent.scss";

interface ModalComponentProps {
  visible: boolean;
  title?: string | ReactElement;
  description?: string | ReactElement;
  type: "alert" | "alert-large" | "small" | "medium" | "large" | "x-large" | "no-controllers" | "details" | "no-controllers-large";
  alert?: {
    title?: string | ReactElement;
    description?: string;
    buttonType?: "affirmative" | "negative" | "primary"
  };
  successProps?: {
    title: string;
    clickHandler?: () => void;
    loading?: boolean;
    successDisabled?: boolean;
  };
  cancelProps?: {
    title: string;
    clickHandler: () => void;
  };
  closeHandler: () => void;
  className?: string;
  destroyOnClose?: boolean;
}

const ModalComponent: FC<ModalComponentProps> = (props) => {
  const {
    title,
    description,
    type,
    successProps,
    cancelProps,
    children,
    visible,
    closeHandler,
    alert,
    className,
    destroyOnClose,
  } = props;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 420)
  }, [])

  const controllers = () =>  (
    <div className="modal-component-controllers">
    {cancelProps?.title &&
    <ButtonComponent
      type="default"
      additionalTypes="secondary"
      size={type === 'alert-large' ? "fixed-v2" : "fixed"}
      onClick={cancelProps?.clickHandler}
    >
      {cancelProps.title}
    </ButtonComponent>}
    <ButtonComponent
      type={"primary"}
      htmlType={"submit"}
      size={type === 'alert-large' ? "fixed-v2" : cancelProps?.title ? "fixed" : isMobile ? "fixed-mobile" : alert?.buttonType ? "fixed-v4" :  "fixed-v3" }
      {...(alert ? { additionalTypes: alert?.buttonType ?? "negative" } : {})}
      onClick={successProps?.clickHandler}
      loading={successProps?.loading}
      disabled={successProps?.successDisabled}
    >
      {successProps?.title}
    </ButtonComponent>
  </div>)
  

  return (
    <Modal
      className={`modal-component ${type} ${className}`}
      visible={visible}
      footer={null}
      closeIcon={<IconComponent name="icon-close" />}
      onCancel={closeHandler}
      centered
      maskClosable={false}
      destroyOnClose={destroyOnClose}
    >
      {type == "no-controllers" ? (
        <div className="no-controllers-modal-component-title">{title}</div>
      ) : (type == "alert" || type == "alert-large") && alert ? (
        <div className="alert-modal-component-title">
          <h3>{alert?.title}</h3>
          <p>{alert?.description}</p>
        </div>
      ) : title ? (
        <>
        <div className="modal-component-title">{title}</div>
        {description && <div className="modal-component-description">{description}</div>}
        </>
      ) : ""}
      {children}
      {type !== "no-controllers-large" && type !== "no-controllers" && type !== "details" && (
        controllers()
      )}
    </Modal>
  );
};

export default ModalComponent;
