import React, { FC, SetStateAction, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import TableComponent, { TableFilterComponent, TableFilterDropdownComponent } from "../../../../shared/components/TableComponent";
import "./approveDocuments.scss";
import faker from "faker";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import CustomerService from "../../../../services/CustomerService/customer.service";
import NoDocumentsImg from "../../../../assets/empty/documents.svg";
import { tableSorter } from "../../../../shared/utils/tableSorter";
import { DocumentTypesDefinition } from "../../../../definitions/documentTypesDefinition";
import { DocumentTypes } from "../../../../enums/documentTypes";
import { CustomerDocumentModel } from "../../../../models/Customer/customer.model";
import moment, { Moment } from "moment";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { Form, Formik } from "formik";
import { Col, DatePicker, Row } from "antd";
import DropdownField from "../../../../shared/components/DropdownField";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { BadgeTypesDefinition } from "../../../../definitions/badgeTypesDefinition";
import { generateDateString } from "../../../../shared/utils/generateDateString";
import { RangePickerValueType } from "../../../../shared/types/rangePicker.type";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import { objectHasKeys } from "../../../../shared/utils/objectHasKeys";
import { getTimezoneISOString } from "../../../../shared/utils/getTimezoneISOString";
import { StateProps } from "../../../../models/Meta/meta.model";
import { parseDate } from "../../../../shared/utils/formatDate";
import { clearFilters } from "../../../../shared/utils/clearFilters";
interface ApproveDocumentsProps {
  setCount: React.Dispatch<React.SetStateAction<number>>
}
const { RangePicker } = DatePicker;
interface FilterProps extends CustomerDocumentModel {
  search?: string;
}

const ApproveDocuments: FC<ApproveDocumentsProps> = (props) => {
  const { setCount } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location.state as StateProps;
  const isApproveDocuments = location.hash === "#approve-documents";
  const pageNumber = isApproveDocuments ? stateValue?.page as number : null;
  let filterOptions = isApproveDocuments ? stateValue?.filterOptions as SortFilterModel : null;
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>({sortColumn: SortColumnTypes.CUSTOMER_DOCUMENT_ATTACHMENT_STATUS, sortBy: "pending"})
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const startDate = parseDate(filterOptions?.startDate)
  const endDate = parseDate(filterOptions?.endDate)
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([startDate ?? null, endDate ?? null]);
  const [page, setPage] = useState(pageNumber ?? 1)
  const { BANK_STATEMENT, PROFILE_PIC, NATIONAL_ID_BACK, NATIONAL_ID_FRONT, EMPLOYMENT_LETTER, TAX_CARD, COMMERCIAL_REGISTRATION, CREDIT_CARD_STATEMENT, SPORTS_CLUB_MEMBERSHIP, PAYSLIP, PROPERTY_CONTRACT, VEHICLE_CARD } = DocumentTypes
  const {
    fetchUnapprovedCustomerDocuments,
    loading,
    unapprovedCustomerDocuments,
    paginations
  } = CustomerService();

  const handlePageChange = (page: number) => {
    location.state = null
    setPage(page)
    fetchUnapprovedCustomerDocuments({ page, ...sortOptions, ...filters })
  }

  const filterInitialValues = filterOptions ?? {
    attachmentStatus: undefined,
    attachmentType: undefined
  };

  const handleFilterSubmit = (values: SortFilterModel) => {
    setPage(1);
    setFilters(prev => ({ ...prev, ...values }));
    handleFilterClose();
  };

  const handleFilterClose = () => setIsFilterShown(false)

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              clearFilters({
                resetForm,
                setPage,
                handleFilterClose,
                setFilters,
                setDateRange,
                filterOptions,
                filters,
                location,
              })
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="input-field">
                    <label>Document upload time Range</label>
                    <RangePicker
                      disabledDate={(date) => date.isAfter(moment())}
                      value={dateRange}
                      onChange={(values) => {
                        if (values?.length) {
                          setDateRange(values)
                          const dataString = generateDateString(
                            values as RangePickerValueType
                          )?.split(" - ");
                          setFieldValue("startDate", dataString?.[0]??null);
                          setFieldValue("endDate", dataString?.[1]??null);
                        }
                      }}
                      format={"DD/MM/YYYY"}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <DropdownField
                    options={[
                      {
                        value: DocumentTypes.BANK_STATEMENT,
                        label: DocumentTypesDefinition[DocumentTypes.BANK_STATEMENT],
                      },
                      {
                        value: DocumentTypes.EMPLOYMENT_LETTER,
                        label: DocumentTypesDefinition[DocumentTypes.EMPLOYMENT_LETTER],
                      },
                      {
                        value: DocumentTypes.PAYSLIP,
                        label: DocumentTypesDefinition[DocumentTypes.PAYSLIP],
                      },
                      {
                        value: DocumentTypes.PROOF_OF_ASSET,
                        label: DocumentTypesDefinition[DocumentTypes.PROOF_OF_ASSET],
                      },
                      {
                        value: DocumentTypes.PROOF_OF_INCOME,
                        label: DocumentTypesDefinition[DocumentTypes.PROOF_OF_INCOME],
                      },
                      {
                        value: DocumentTypes.PROPERTY_CONTRACT,
                        label: DocumentTypesDefinition[DocumentTypes.PROPERTY_CONTRACT],
                      },
                      {
                        value: DocumentTypes.SPORTS_CLUB_MEMBERSHIP,
                        label: DocumentTypesDefinition[DocumentTypes.SPORTS_CLUB_MEMBERSHIP],
                      },
                      {
                        value: DocumentTypes.UTILITY_BILL,
                        label: DocumentTypesDefinition[DocumentTypes.UTILITY_BILL],
                      },
                      {
                        value: DocumentTypes.VEHICLE_CARD,
                        label: DocumentTypesDefinition[DocumentTypes.VEHICLE_CARD],
                      },
                      {
                        value: DocumentTypes.CREDIT_CARD_STATEMENT,
                        label: DocumentTypesDefinition[DocumentTypes.CREDIT_CARD_STATEMENT],
                      }
                    ]}
                    label="Document type"
                    name="type"
                    placeholder="Enter"
                    onChange={(value) => setFieldValue("type", value)}
                    value={values?.type}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    options={[
                      {
                        value: BadgeTypes.APPROVED,
                        label: BadgeTypesDefinition[BadgeTypes.APPROVED],
                      },
                      {
                        value: BadgeTypes.REJECTED,
                        label: BadgeTypesDefinition[BadgeTypes.REJECTED],
                      },
                      {
                        value: BadgeTypes.PENDING_VERIFICATION,
                        label: BadgeTypesDefinition[BadgeTypes.PENDING_VERIFICATION],
                      }
                    ]}
                    label="Approval status"
                    name="status"
                    placeholder="Enter"
                    onChange={(value) => setFieldValue("status", value)}
                    value={values?.status}
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );

  useEffect(() => {
    if(location.hash === "#approve-documents" || objectHasKeys(filters)) {
      const updatedFilters = objectHasKeys(filters) 
      ? { page, ...filters, ...sortOptions }
      : { page, ...sortOptions, search, ...filters };

      fetchUnapprovedCustomerDocuments(updatedFilters);
    }  
  }, [location.hash, filters]);

  useEffect(() => {
    setCount(paginations?.totalCount as number);
  }, [unapprovedCustomerDocuments]);

  const handleClick = (record: CustomerDocumentModel) => {
    const { attachment, id: customerAttachmentId } = record
    navigate(generatePath(AppRoutes.FILE_VIEW, { fileId: record?.id?.toString() }), { state: { customerAttachmentId, fileUrl: attachment?.s3Url, fileName: attachment?.filename, fileType: attachment?.fileType, controllers: true, attachmentType: record?.attachmentType, customerStatus: "approved", page, filterOptions: filters, from: 'customer' } })
  };
  return (
    <div className="approve-documents">
      <TableComponent
        pageNumber={pageNumber ?? page}
        paginationMeta={paginations}
        onChange={fetchUnapprovedCustomerDocuments}
        onSort={(data) => {
          setSortOptions(data)
        }}
        filtersSorts={{ page, search, ...sortOptions, ...filters }}
        handlePageChange={handlePageChange}
        loading={loading}
        onRow={(record) => handleClick(record)}
        empty={{
          img: NoDocumentsImg,
          text: "No documents under approval",
        }}
        filter={filter}
        search={{
          placeholder: "Search customer by name or national id",
          onSearch: (value) => {
            setSearch(value)
            fetchUnapprovedCustomerDocuments({
              search: value
            });
          },
        }}
        columns={[
          {
            title: "Submitted On",
            dataIndex: "submittedOn",
            key: SortColumnTypes.SUBMITTED_ON,
            className: "cursor-pointer",
            sorter: true,
            render: (text) => <div>{moment(text)?.format("DD MMM YYYY")}</div>,
          },
          {
            title: "Document",
            dataIndex: "attachmentType",
            key: "attachmentType",
            className: "cursor-pointer",
            render: (text: typeof BANK_STATEMENT | typeof PROFILE_PIC | typeof NATIONAL_ID_BACK | typeof NATIONAL_ID_FRONT | typeof EMPLOYMENT_LETTER | typeof TAX_CARD | typeof COMMERCIAL_REGISTRATION | typeof CREDIT_CARD_STATEMENT | typeof PAYSLIP | typeof SPORTS_CLUB_MEMBERSHIP | typeof VEHICLE_CARD | typeof PROPERTY_CONTRACT, record) => (
              <div>{DocumentTypesDefinition[text]}</div>
            ),
          },
          {
            title: "Customer Name",
            dataIndex: "fullName",
            key: SortColumnTypes.CUSTOMER_NAME,
            sorter: true,
          },
          {
            title: "National ID",
            dataIndex: "nationalIdNumber",
            key: "nationalIdNumber",
          },
          {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            render: (text, record) => <span>{record?.countryCode?.isdCode ?? ""} {text}</span>
          },
          {
            title: "Approval Status",
            dataIndex: "attachmentStatus",
            sorter: true,
            key: SortColumnTypes.CUSTOMER_DOCUMENT_ATTACHMENT_STATUS,
            render: (text, record) => <BadgeComponent type={text} />
          },
        ]}
        data={unapprovedCustomerDocuments}
      />
    </div>
  );
};

export default ApproveDocuments;
