import { Formik, Form, FormikProps, FormikValues } from 'formik';
import React, { FC, useRef } from 'react'
import { CreditScoreConfig } from '../../../../../models/CreditScoreConfig/creditScoreConfig.model';
import MetaService from '../../../../../services/MetaService/meta.service';
import InputField from '../../../../../shared/components/InputField';
import ModalComponent from '../../../../../shared/components/ModalComponent';
import { approvalThresholdFormValidation } from './approvalThresholdFormValidation';

interface ApprovalthresholdformProps {
    visible: boolean;
    closeHandler: () => void;
    data?: CreditScoreConfig; 
    handleRefresh: () => void;
    type: 'Basic' | 'Phone';
 }

const ApprovalThresholdForm: FC<ApprovalthresholdformProps> = ({visible, closeHandler, data, handleRefresh, type}) => {
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const isBasic = type === 'Basic'
    const basicInitialValues = {
      approvalThreshold: data?.configJson?.thresholdCreditScore,
    };
    const phoneInitialValues = {
      approvalThreshold: data?.configJson?.phoneThresholdCreditScore,
    };
    const initialValues = isBasic ? basicInitialValues : phoneInitialValues
    const { updateRiseConfig, loading } = MetaService()
    const onSubmit = (values: FormikValues) => {

      isBasic ? updateRiseConfig({configJson: {thresholdCreditScore: Number(values?.approvalThreshold), phoneThresholdCreditScore: data?.configJson?.phoneThresholdCreditScore}}) : updateRiseConfig({configJson: {phoneThresholdCreditScore: Number(values?.approvalThreshold), thresholdCreditScore: data?.configJson?.thresholdCreditScore}})
        handleRefresh()
        resetFormAndClose()
    };
  
    const resetFormAndClose = () => {
      formRef?.current?.resetForm({ values: {} });
      closeHandler();
    };

    return (
        <Formik
        initialValues={initialValues}
        enableReinitialize
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={approvalThresholdFormValidation}
      >
        {({ handleSubmit, errors }) => (
          <ModalComponent
            visible={visible}
            type="small"
            title={`Edit ${type.toLocaleLowerCase()} purchasing status approval threshold`}
            successProps={{
              title: "Update",
              clickHandler: handleSubmit,
              loading: loading, 
            }}
            cancelProps={{
              title: "Discard",
              clickHandler: resetFormAndClose,
            }}
            closeHandler={resetFormAndClose}
          >
            <Form>
              <InputField placeholder="Enter" label={`${type} user approval threshold`} name="approvalThreshold" />
            </Form>
          </ModalComponent>
        )}
      </Formik>
    )
}

export default ApprovalThresholdForm