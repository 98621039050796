import React, { FC, useEffect, useState } from "react";
import PageView from "../../../../../shared/components/PageView";
import "./vendorListView.scss";
import { Tabs } from "antd";
import {
  generatePath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import {LocalStorage} from "../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../models/User/user.model";
import {BadgeTypes} from "../../../../../enums/badgeTypes";
import VendorForm from "../VendorForm";
import VendorService from "../../../../../services/VendorService/vendor.service";
import VendorDetails from "./VendorDetails";
import { convertJsonToFormData } from "../../../../../shared/utils/convertJsonToFormData";
import { VendorModel } from "../../../../../models/Vendor/vendor.model";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import { StateProps } from "../../../../../models/Meta/meta.model";
import VendorStorePromotions from "./VendorStorePromotions";
import VendorStorePromotionForm from "../VendorStorePromotionForm";
interface VendorListViewProps {}
const { TabPane } = Tabs;
interface VendorBlacklistAlertProps {
  vendor: VendorModel | undefined;
  visible: boolean;
  refreshList: () => void;
  closeHandler: () => void;
}

export const VendorBlacklistAlert: FC<VendorBlacklistAlertProps> = ({
  vendor,
  visible,
  refreshList,
  closeHandler,
}) => {
  const { loading, updateVendor } = VendorService();
  const handleBlacklist = () => {
    const formData = convertJsonToFormData({
      vendor: {
        vendor_details: {
          status:
          vendor?.status === BadgeTypes.APPROVED
          ? BadgeTypes.INACTIVE
          : BadgeTypes?.APPROVED,
        }
      },
    });
    if (vendor?.id)
      updateVendor(vendor?.id, formData, () => {
        closeHandler();
        refreshList();
      });
  };
  return (
    <ModalComponent
      type="alert"
      visible={visible}
      closeHandler={closeHandler}
      successProps={{
        title: vendor?.status === BadgeTypes?.APPROVED ? "Deactivate" : "Activate",
        loading: loading,
        clickHandler: () => {
          handleBlacklist();
        },
      }}
      cancelProps={{
        title: "Close",
        clickHandler: () => {
          closeHandler();
        },
      }}
      alert={{
        title: `Are you sure you want to ${
          vendor?.status === BadgeTypes?.APPROVED ? "deactivate" : "activate"
        } the vendor ?`,
        description: "",
        ...((vendor?.status === BadgeTypes?.INACTIVE || vendor?.status === BadgeTypes?.PENDING_APPROVAL)
          ? { buttonType: "affirmative" }
          : {}),
      }}
    />
  );
};

const VendorListView: FC<VendorListViewProps> = (props) => {
  const user = LocalStorage.getItem('USER') as UserModel;
  const isViewer = user?.role === BadgeTypes.VIEWER;
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = stateValue?.pageNumber;
  const filterOptions = stateValue?.filterOptions;
  const { vendorId, vendorName } = useParams();
  const [isVendorFormShown, setIsVendorFormShown] = useState(false);
  const [isPromotionFormShown, setIsPromotionFormShown] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isBlacklistAlertShown, setIsBlacklistAlertShown] = useState(false);
  const { loading, fetchVendor, vendor, fetchStorePromotionList, vendorStorePromotions } = VendorService();

  useEffect(() => {
    handleRefresh();
    fetchPromotionList()
  }, [vendorId])

  const handleRefresh = () => {
    if (vendorId) {
      fetchVendor(vendorId)
    };
  };

  const fetchPromotionList =  () => vendorId && fetchStorePromotionList(vendorId)

  const openVendorForm = () => setIsVendorFormShown(true)
  const openStorePromotionModal = () => setIsPromotionFormShown(true)
  
  return (
    <PageView
      type="back-only"
      title={{ name: vendorName?.replace(/-/gi, " ") ?? "" }}
      backRoute={`${AppRoutes.VENDORS}#list`}
      vendorFormHandler={()=>setIsVendorFormShown(true)}
      blacklistHandler={()=>setIsBlacklistAlertShown(true)}
      vendorPromotionHandler={() => setIsPromotionFormShown(true)}
      vendorStatus={vendor?.vendorDetails?.status}
      vendor={vendor?.vendorDetails}
      pageNumber={pageNumber}
      filterOptions={filterOptions}
    >
      <Tabs
        className="vendor-list-view"
        activeKey={location?.pathname?.split("/")?.[5]}
        onTabClick={(value) => {
          if (value == "details") {
            navigate(
              {pathname: generatePath(AppRoutes.VENDOR_DETAILS, { vendorId, vendorName })},
              {state: {pageNumber, filterOptions}}
            );
            return;
          }
          if (value == "store-promotions") {
            navigate(
              {pathname: generatePath(AppRoutes.VENDOR_STORE_PROMOTIONS, {
                vendorId,
                vendorName,
              })}, {state: {pageNumber, filterOptions}}
            );
            return;
          }
          if (value == "stores") {
            navigate(
              generatePath(AppRoutes.VENDOR_INSTORES, { vendorId, vendorName })
            );
            return;
          }
          if (value == "settlements") {
            navigate(
              {pathname: generatePath(AppRoutes.VENDOR_SETTLEMENTS, {
                vendorId,
                vendorName,
              })}, {state: {pageNumber, filterOptions}}
            );
            return;
          }
          if (value == "purchase-config") {
            navigate(
              {pathname: generatePath(AppRoutes.VENDOR_PURCHASE_CONFIG, {
                vendorId,
                vendorName,
              })}, {state: {pageNumber, filterOptions}}
            );
            return;
          }
        }}
      >
        <TabPane key={"details"} tab="Details" forceRender>
          <VendorDetails refresh={formSubmitted} vendor={vendor} loading={loading} refreshData={handleRefresh} openVendorForm={openVendorForm} />
        </TabPane>
          {!isViewer && <TabPane key={"store-promotions"} tab="Store Promotions">
            <VendorStorePromotions handleRefresh={fetchPromotionList} promotionData={vendorStorePromotions}  />
          </TabPane>}
        <TabPane key={"stores"} tab="Stores">
          <Outlet />
        </TabPane>
        <TabPane key={"settlements"} tab="Settlements">
          <Outlet />
        </TabPane>
        <TabPane key={"purchase-config"} tab="Purchase Config">
          <Outlet />
        </TabPane>
      </Tabs>

      <VendorForm
        successHandler={(value) => {
          handleRefresh();
          setFormSubmitted(true)
          setIsVendorFormShown(false);
          navigate(
            generatePath(AppRoutes.VENDOR_DETAILS, {
              vendorId: value?.vendorDetails?.id?.toString(),
              vendorName: value?.vendorDetails?.name?.replace(/ /gi, "-"),
            }), {replace: true}
          );
        }}
        visible={isVendorFormShown}
        data={vendor}
        closeHandler={() => setIsVendorFormShown(false)}
      />
      
      <VendorBlacklistAlert
        visible={isBlacklistAlertShown}
        closeHandler={() => {
          setIsBlacklistAlertShown(false);
        }}
        vendor={vendor?.vendorDetails}
        refreshList={()=>vendorId && fetchVendor(vendorId)}
      />
      <VendorStorePromotionForm visible={isPromotionFormShown} closeHandler={() => setIsPromotionFormShown(false)} handleRefresh={fetchPromotionList} />
    </PageView>
  );
};

export default VendorListView;
