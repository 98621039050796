import React, { useEffect, useState } from "react";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
  TableLoadingComponent,
} from "../../../../../shared/components/TableComponent";
import "./pendingSettlements.scss";
import { Form, Formik } from "formik";
import { Col, DatePicker, Row } from "antd";
import InputField from "../../../../../shared/components/InputField";
import DropdownField from "../../../../../shared/components/DropdownField";
import { PaymentcollectionService } from "../../../../../services/PaymentcollectionService/paymentcollection.service";
import NoTransactionsImg from "../../../../../assets/empty/transactions.svg";
import moment, { Moment } from "moment";
import { removeUndefined } from "../../../../../shared/utils/removeUndefined";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import SettlementForm from "./SettlementForm";
import { SortFilterModel } from "../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../shared/types/SortColumn.type";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { generateDateString } from "../../../../../shared/utils/generateDateString";
import { RangePickerValueType } from "../../../../../shared/types/rangePicker.type";
import { PaymentModeTypes } from "../../../../../enums/paymentModeTypes";
import { PaymentModeTypesDefinition } from "../../../../../definitions/paymentModeTypesDefinition";
import { PendingCollectionList } from "../../../../../models/Collection/collection.model";
import TooltipComponent from "../../../../../shared/components/TooltipComponent";
import { PartnerTypes } from "../../../../../enums/partnerTypes";
import { PartnerTypesDefinition } from "../../../../../definitions/partnerTypesDefinitions";
import { HandleSelectionProps, handleSelection } from "../../../../../shared/utils/handleSelection";
import { objectHasKeys } from "../../../../../shared/utils/objectHasKeys";
import { getTimezoneISOString } from "../../../../../shared/utils/getTimezoneISOString";
import { StateProps } from "../../../../../models/Meta/meta.model";
import { parseDate } from "../../../../../shared/utils/formatDate";
import { clearFilters } from "../../../../../shared/utils/clearFilters";

const { RangePicker } = DatePicker;

const PaymentCollectionPendingSettlements = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const stateValue = location?.state as StateProps;
  const isPending = location.hash === "#pending"
  const pageNumber = isPending ? stateValue?.pageNumber as number : null;
  let filterOptions = isPending ? stateValue?.filterOptions as SortFilterModel : null;
  const [page, setPage] = useState(pageNumber ?? 1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState<SortFilterModel>();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const startDate = parseDate(filterOptions?.startDate)
  const endDate = parseDate(filterOptions?.endDate)
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([startDate ?? null, endDate ?? null]);

  const {
    fetchPendingPaymentCollection,
    loading,
    pendingPaymentCollection,
    paginations,
    fetchAllPendingPaymentCollectionForPartner,
    dataLoading,
    pendingPaymentCollectionIds,
    markAsSettledDetails,
    markPendingSettlementsDetails
  } = PaymentcollectionService();
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const [settlementsModalVisible, setSettlementsModalVisible] =
    useState<boolean>(false);
  const [selectedSettlements, setSelectedSettlements] = useState<number[]>([]);
  const [selectedSettlementsData, setSelectedSettlementsData] = useState<
    PendingCollectionList[]
  >([]);
  const [selectAll, setSelectAll] = useState(false)
  const filterInitialValues = filterOptions ?? new SortFilterModel();
  const showTest = LocalStorage.getItem("IS_TEST");
  const user = LocalStorage.getItem("USER")
  const adminId = user?.id

  const handleCloseSetlementModal = () => setSettlementsModalVisible(false);
  const handleOpenSetlementModal = () => setSettlementsModalVisible(true);

  const handleFilterSubmit = (values: SortFilterModel) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  useEffect(() => {
    if (location.hash === "#pending" || objectHasKeys(filters)) {
      const updatedFilters = objectHasKeys(filters) 
        ? { page, ...filters, ...sortOptions, showTest  }
        : { page, ...sortOptions, search, ...filters, showTest  };
  
        fetchPendingPaymentCollection(updatedFilters);
    }
  }, [location.hash, filters]);

  useEffect(() => {
    if(pendingPaymentCollectionIds.length > 0) setSelectedSettlements(pendingPaymentCollectionIds)
  }, [pendingPaymentCollectionIds])

  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const refreshList = () => {
    setSelectedSettlements([]);
    setSelectedSettlementsData([]);
  };

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[0] !== "partnerName" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              clearFilters({
                resetForm,
                setPage,
                handleFilterClose,
                setFilters,
                setDateRange,
                filterOptions,
                filters,
                location,
              })
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputField
                    name="transactionId"
                    placeholder="Enter"
                    label="Payment ID"
                  />
                </Col>
                <Col span={12}>
                  <div className="input-field">
                    <label>{"Purchase Date Range"}</label>
                    <RangePicker
                      disabledDate={(date) => date.isAfter(moment())}
                      value={dateRange}
                      onChange={(values) => {
                        if (values?.length) {
                          setDateRange(values)
                          const dataString = generateDateString(
                            values as RangePickerValueType
                          )?.split(" - ");
                          setFieldValue("startDate", dataString?.[0]??null);
                          setFieldValue("endDate", dataString?.[1]??null);
                        }
                      }}
                      format={"DD/MM/YYYY"}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  const filterBy = ( 
    <Formik initialValues={{partnerName: filterOptions?.partnerName ?? 'All Partners'}} onSubmit={() => {}}>
      {({ values, setFieldValue }) => (          
        <DropdownField
        label=""
        options={[
          {
            value: '',
            label: 'All Partners',
          },
          {
            value: PartnerTypes.FAWRY,
            label: PartnerTypesDefinition[PartnerTypes.FAWRY],
          },
          {
            value: PartnerTypes.PAYTABS,
            label: PartnerTypesDefinition[PartnerTypes.PAYTABS],
          },
          {
            value: PartnerTypes.PAYMOB,
            label: PartnerTypesDefinition[PartnerTypes.PAYMOB],
          },
        ]}
        name="partnerName"
        placeholder="Select"
        onChange={(value) => {
          setFieldValue('partnerName', value)
          handleFilterSubmit({partnerName: value})
        }}            
        value={values?.partnerName}
        className="header-dropdown"
        />
      )}
    </Formik>)

  const selectionDetails = (
    <div className="selection-data">
      <span className="label">Transactions Selected <span className="value">{selectedSettlements.length}</span></span>
      {selectedSettlements.length > 0 && !selectAll &&
        <span className="label-select" onClick={() => {
          fetchPendingPaymentCollection({ ...sortOptions, page, ...filters, showTest });
          fetchAllPendingPaymentCollectionForPartner({partnerName: selectedSettlementsData[0]?.partnerName, showTest})
          setSelectAll(true)
        }}>Select all transactions of {selectedSettlementsData[0]?.partnerName}</span>
      }
      {selectAll && 
      <span className="label-deselect" onClick={() => {
        setSelectAll(false)
        refreshList()
        fetchPendingPaymentCollection({ ...sortOptions, page, ...filters, showTest });
      }}>Deselect All</span>
      }
    </div>
  )

  const handlePageChange = (page: number) => {
    location.state = null
    setPage(page);
    fetchPendingPaymentCollection({
      ...sortOptions,
      page,
      ...filters,
      showTest,
    });
  };

  const handleMarkAsSettled = () => {
    markPendingSettlementsDetails({pendingSettlementIds: selectedSettlements, partnerId: selectedSettlementsData[0]?.partnerId?.toString(), adminId})
    handleOpenSetlementModal()
  }

  return (
    <>
    <div className="pending-settlements">
      <TableComponent
        selectionDetails={selectedSettlements.length > 0 ? selectionDetails : <></>}
        onChange={() => fetchPendingPaymentCollection({ ...sortOptions, page, ...filters, showTest })}
        onSort={(data) => {
          setSortOptions(data);
        }}
        filtersSorts={{ page, ...filters, ...sortOptions, search, showTest }}
        rowSelection={{
          selectedRowKeys: selectedSettlements,
          type: "checkbox",
          columnTitle: <></>,
          getCheckboxProps: (record) => ({
            disabled:
              !!selectedSettlementsData?.length &&
              record?.partnerName !== selectedSettlementsData[0]?.partnerName,
            name: record.id,
          }),
          onSelect: (record, selected, selectedRows) => {
            handleSelection(
              {record, selected, selectedRows, selectedSettlements, selectedSettlementsData, setSelectedSettlements, setSelectedSettlementsData} as HandleSelectionProps<PendingCollectionList>)
          },
        }}
        newBtn={{
          text: "Mark as Settled",
          clickHandler: handleMarkAsSettled,
          disabled: !selectedSettlements?.length,
        }}
        paginationMeta={paginations}
        pageNumber={pageNumber ?? page}
        handlePageChange={handlePageChange}
        search={{
          placeholder: "Search by external purchase ID or payment ID",
          onSearch: (value) => {
            setSearch(value);
            handleFilterSubmit({ search: value });
          },
        }}
        filter={filter}
        filterBy={filterBy}
        columns={[
          {
            title: "Partner Name",
            dataIndex: "partnerName",
            key: SortColumnTypes.PENDING_PAYMENT_PARTNER_NAME,
            sorter: true,
            render: (text: PartnerTypes) => PartnerTypesDefinition[text],
          },
          {
            title: "External Purchase ID",
            dataIndex: "externalPurchaseId",
            key: "externalPurchaseId",
            render: (text, record: PendingCollectionList) => (
              <span
                onClick={() => {
                  navigate(
                    {pathname: generatePath(AppRoutes.PURCHASES_VIEW, {
                      purchaseId: record?.customerPurchaseId?.toString(),
                    })}, {state: {page, from: 'pending-payment-collection', filters}}
                  );
                }}
              >
                {text}
              </span>
            ),
          },
          {
            title: "Payment ID",
            dataIndex: "externalPaymentId",
            key: "externalPaymentId",
          },
          {
            title: "Payment Date",
            dataIndex: "createdAt",
            key: SortColumnTypes.PENDING_PAYMENT_CREATED_DATE,
            sorter: true,
            render: (text) => moment(text)?.format("DD MMM YYYY"),
          },
          {
            title: "Payment Mode",
            dataIndex: "paymentMode",
            key: "paymentMode",
            render: (text: PaymentModeTypes) =>
              PaymentModeTypesDefinition[text] ?? "-",
          },
          {
            title: "Payment Amount",
            dataIndex: "amount",
            key: SortColumnTypes.PENDING_PAYMENT_AMOUNT,
            sorter: true,
          },
          {
            title: (
              <>
                Partner Fees
                <TooltipComponent content='Payment partner fees for "Fawry" are calculated in the partner fee section' />
              </>
            ),
            dataIndex: "partnerProcessingFee",
            key: SortColumnTypes.PENDING_PAYMENT_PROCESSING_FEE,
            render: (text) => text?.toFixed(2) ?? "-",
            sorter: true,
          },
          {
            title: "VAT",
            dataIndex: "partnerProcessingFeeVat",
            key: SortColumnTypes.PENDING_PAYMENT_VAT,
            render: (text) => text?.toFixed(2) ?? "-",
            sorter: true,
          },
          {
            title: "Collection Amount",
            dataIndex: "collectionAmount",
            key: SortColumnTypes.PENDING_PAYMENT_COLLECTION_AMOUNT,
            render: (text) => text?.toFixed(2) ?? "-",
            sorter: true,
          },
        ]}
        loading={loading}
        dataLoading={dataLoading}
        empty={{
          text: "No pending settlements",
          img: NoTransactionsImg,
        }}
        data={pendingPaymentCollection}
      />
      <SettlementForm
        callback={(isTest) =>
          fetchPendingPaymentCollection({ showTest: isTest })
        }
        visible={settlementsModalVisible}
        closeHandler={handleCloseSetlementModal}
        settlementData={selectedSettlementsData}
        selectedSettlements={selectedSettlements}
        refreshList={refreshList}
        markAsSettledDetails={markAsSettledDetails}
      />
    </div>
    </>
  );
};

export default PaymentCollectionPendingSettlements;
