import { DashboardDateFilterTypes } from "../shared/types/DashboardDateFilterTypes";

export const DashboardDateFilterTypesDefinition = {
  [DashboardDateFilterTypes.TODAY]: "Today",
  [DashboardDateFilterTypes.PAST_WEEK]: "Last 7 Days",
  [DashboardDateFilterTypes.CURRENT_MONTH]: "Current Month",
  [DashboardDateFilterTypes.LAST_MONTH]: "Last Month",
  [DashboardDateFilterTypes.CURRENT_YEAR]: "Current Year",
  [DashboardDateFilterTypes.ALL_TIME]: "All Time",
  [DashboardDateFilterTypes.CUSTOM]: "Custom",
};
