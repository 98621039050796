import React, { FC } from 'react'
import { BadgeTypesDefinition } from '../../../definitions/badgeTypesDefinition';
import { BadgeTypes } from '../../../enums/badgeTypes';
import userReapply from "../../../assets/images/reapply-white.svg"
import "./badgeComponent.scss"

interface BadgeComponentProps {
    type: BadgeTypes,
    className?: string
}

const BadgeComponent: FC<BadgeComponentProps> = (props) => {
    const { type, className } = props;

    return (
        <div className={`badge-component ${type}  ${type?"border":"no-border"} ${className}`}>
            {type === BadgeTypes.REAPPLY && <img src={userReapply} alt="reapply-badge" />} {BadgeTypesDefinition[type]}
        </div>
    )
}

export default BadgeComponent;