import { Row, Col } from 'antd';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TerminalIdModel } from '../../../../../../../../models/Vendor/Instore/instore.model';
import ButtonComponent from '../../../../../../../../shared/components/ButtonComponent';
import CardComponent from '../../../../../../../../shared/components/CardComponent';
import InputField from '../../../../../../../../shared/components/InputField';
import backIcon from "../../../../../../../../assets/images/back-icon.png";
import removeIcon from "../../../../../../../../assets/images/remove-red.png";
import bluetick from "../../../../../../../../assets/images/blue-tick.png";
import cancel from "../../../../../../../../assets/images/grey-cross.png";
import { posTerminalFormValidation } from "./posTerminalFormValidation";
import "./addposterminal.scss";
import VendorService from '../../../../../../../../services/VendorService/vendor.service';
import SwitchComponent from '../../../../../../../../shared/components/SwitchComponent';
import DropdownField from '../../../../../../../../shared/components/DropdownField';
import { PosTypes } from '../../../../../../../../enums/posTypes';
import { PosTypesDefinition } from '../../../../../../../../definitions/posTypesDefinition';
import { AppRoutes } from '../../../../../../../../routes/routeConstants/appRoutes';

const AddPosTerminal: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isEdit = location?.state as boolean;
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const { instoreId, vendorId, vendorName } = useParams();
    const [initialValues, setInitialValues] = useState<TerminalIdModel>({});
    const { fetchVendorInstore, vendorInstore, updateVendorInstore, updateRisePointTerminal, error, addFawryTerminal, deleteFawryTerminal } = VendorService();

    const getInstoreData = () => fetchVendorInstore(instoreId ?? "");

    useEffect(() => {
        if (instoreId) getInstoreData();
    }, [instoreId]);

    const goBack = () => navigate({ pathname: generatePath(AppRoutes.VENDOR_INSTORES_VIEW, { vendorId, vendorName, instoreId }) }, { state: vendorInstore })

    const addFawryTerminals = (values: TerminalIdModel) => {
        const {label, terminalId} = values
        if (label && terminalId && instoreId) {
            const fawryData = {
                instoreId: Number(instoreId),
                terminalId: values?.terminalId,
                label: values?.label,
                status: "active"
            };
            addFawryTerminal(fawryData, getInstoreData);
        }
    };

    const removeFawryTerminal = (terminalData: TerminalIdModel) => {
        if(terminalData?.terminalId) deleteFawryTerminal(terminalData?.terminalId, getInstoreData);
    };

    const terminalRows = (terminals?: TerminalIdModel[], terminalType?: string) => (
        <>
            {terminals?.map((terminal: TerminalIdModel, index: number) => (
                <Row key={index} className={`input-row ${terminal?.id && "disabled"}`} align="top">
                    <Col span={5} className="pos name">
                        {terminal?.label}
                    </Col>
                    <Col span={4} className="pos terminal">
                        {terminalType === 'fawryTerminalIds' ? 'Fawry' : 'Rise point'}
                    </Col>
                    <Col offset={2} span={4} className="pos terminalId">
                        {terminal?.terminalId}
                    </Col>
                    {terminalType === 'riseTerminals' &&
                        <Col offset={2} span={1} className="switch-style">
                            <SwitchComponent
                                label=""
                                value={terminal.status === "active"}
                                checkedTitle={"Activated"}
                                unCheckedTitle={"Deactivated"}
                                onSwitch={(value) => { updateRisePointTerminal({ status: value ? "active" : "inactive", terminalId: terminal?.terminalId }, getInstoreData) }}
                            />
                        </Col>}
                    {terminalType === 'fawryTerminalIds' ?
                        <Col offset={2} span={2}>
                            <p className='remove-icon' onClick={() => removeFawryTerminal(terminal)}><img src={removeIcon} alt="minus" className='minus-icon' /></p>
                        </Col> :
                        <Col offset={2} span={2}>
                            <p className='remove-icon' onClick={() => updateRisePointTerminal({ status: "inactive", instoreId: null, terminalId: terminal?.terminalId }, getInstoreData)}><img src={removeIcon} alt="minus" className='minus-icon' /></p>
                        </Col>}
                </Row>
            ))}
        </>
    );

    const onSubmit = async (values: FormikValues) => {
        const { label, terminalId, posType } = values;

        if (label && terminalId) {
            if (posType === 'fawry') {
                addFawryTerminals(values);
            } else {
                updateRisePointTerminal({ ...values, status: "active", instoreId: Number(instoreId) }, getInstoreData);
            }
            formRef?.current?.resetForm({ values: {} });
        }

    };

    const handleIconClick = (submitForm: () => void) => (event: React.MouseEvent<HTMLParagraphElement>) => {
        event.preventDefault();
        submitForm();
    };

    return (
        <div className="addposterminal">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={onSubmit}
                innerRef={formRef}
                validationSchema={posTerminalFormValidation}
            >
                {({ values, setFieldValue, handleSubmit, submitForm, errors, touched }) => (
                    <Form>
                        <div className="header">
                            <Row>
                                <Col span={20}>
                                    <h2 className='title'>
                                        <span className="back-icon" onClick={goBack}>
                                            <img src={backIcon} alt="back" className="back-icon-image" />
                                        </span>
                                        <span className='text'>
                                            {isEdit ? "Edit store" : "Add store POS terminal ID"}
                                        </span>
                                    </h2>
                                </Col>
                                <Col span={4}>
                                    <div className="controllers">
                                        <ButtonComponent
                                            type={"primary"}
                                            size="medium"
                                            onClick={() => navigate(generatePath(AppRoutes.VENDOR_INSTORES, { vendorId, vendorName }))}
                                        >
                                            Done
                                        </ButtonComponent>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <CardComponent title='' className='terminal-section'>
                                <div className="vendor-terminal-id-container">
                                    <h3>POS Terminal ID</h3>
                                    <p>Enter the POS machine ID received from Fawry for Fawry POS type or enter device ID for rise point POS type.</p>
                                    <div className="ant-table">
                                        <div className="ant-table-container">
                                            <table>
                                                <thead className="ant-table-thead">
                                                    <tr>
                                                        <th className="ant-table-cell">LABEL</th>
                                                        <th className="ant-table-cell">POS TYPE</th>
                                                        <th className="ant-table-cell">POS ID</th>
                                                        <th className="ant-table-cell">POS STATUS</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="">
                                        <Row className={`input-row`} align="top">
                                            <Col span={5}>
                                                <InputField name={`label`} placeholder='Enter Label' />
                                            </Col>
                                            <Col span={4}>
                                                <DropdownField
                                                    label=""
                                                    placeholder="Select POS type"
                                                    name="posType"
                                                    value={values?.posType}
                                                    options={[
                                                        {
                                                            value: PosTypes.FAWRY,
                                                            label: PosTypesDefinition[PosTypes.FAWRY],
                                                        },
                                                        {
                                                            value: PosTypes.RISE_POINT,
                                                            label: PosTypesDefinition[PosTypes.RISE_POINT],
                                                        },
                                                    ]}
                                                    onChange={(value) => { setFieldValue('posType', value) }}
                                                />
                                            </Col>
                                            <Col offset={2} span={4}>
                                                <InputField name={`terminalId`} placeholder='Enter Terminal ID' />
                                            </Col>
                                            <Col offset={2} span={1}>
                                                <p className={`add-icon`} onClick={handleIconClick(submitForm)}><img src={bluetick} alt="minus" className='minus-icon' /></p>
                                            </Col>
                                            <Col span={1}>
                                                <p className='remove-icon' onClick={() => {
                                                    ['label', 'posType', 'terminalId'].forEach(field => setFieldValue(field, ''));
                                                }}><img src={cancel} alt="minus" className='minus-icon' /></p>
                                            </Col>
                                        </Row>
                                        {terminalRows(vendorInstore?.fawryTerminalIds, 'fawryTerminalIds')}
                                        {terminalRows(vendorInstore?.riseTerminals, 'riseTerminals')}
                                    </div>
                                </div>
                            </CardComponent>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddPosTerminal;
