import React, { FC, ReactElement } from 'react'
import { Tooltip } from 'antd'
import "./TooltipComponent.scss"
import { getInfoIcon } from '../../utils/getInfoIcon';

interface TooltipComponentProps {
    content: string | ReactElement;
    iconType?: "accept" | "reject";
    iconName?: string;
    onClick?: () => void;
    size?: 'large';
    text?: string;
 }

const TooltipComponent: FC<TooltipComponentProps> = (props) => {

    const { content, iconType, iconName, onClick, size, text } = props

    return (
        <>
        <Tooltip className={`${onClick ? 'refresh' : 'tooltip-component'} ${size}`} title={content}>
           {!!text ? text : <img className="info-icon" src={iconName ?? getInfoIcon(iconType)} onClick={!!onClick ? onClick : ()=>{}} alt="" />}
        </Tooltip>
        </>
    )
}

export default TooltipComponent