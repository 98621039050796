
import {serializable, alias, object, list, primitive} from 'serializr';

export class CustomerCreditHistories { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('customer_id', primitive()))
	customerId?: number;

	@serializable(alias('credit_event', primitive()))
	creditEvent?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('comments', primitive()))
	comments?: string;

	@serializable(alias('title', primitive()))
	title?: string;

}