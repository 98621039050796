import { Col, Divider, Form, Row } from 'antd'
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik'
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import { CustomerEditModel, CustomerModel } from '../../../../models/Customer/customer.model'
import CustomerService from '../../../../services/CustomerService/customer.service'
import DatePickerField from '../../../../shared/components/DatePickerField'
import DropdownField from '../../../../shared/components/DropdownField'
import InputField from '../../../../shared/components/InputField'
import ModalComponent from '../../../../shared/components/ModalComponent'
import { customerDetailsFormValidation } from './customerDetailsFormValidation'
import { GenderTypes } from '../../../../enums/genderTypes'
import { MaritalStatusTypes } from '../../../../enums/maritalStatusTypes'
import { GenderTypesDefinition } from '../../../../definitions/genderTypesDefinition'
import { MaritalStatusTypesDefinition } from '../../../../definitions/maritalStatusTypesDefinition'
import "./customerDetailsForm.scss"
import UploadComponent from '../../../../shared/components/UploadComponent'
import S3AttachmentService from '../../../../services/S3UploadService/s3upload.service'
import { DocumentTypes } from '../../../../enums/documentTypes'
import { useLocation, useNavigate } from 'react-router-dom'
import { hideBrowserSuggestions } from '../../../../shared/utils/hideBrowserSuggestions'
import { BadgeTypes } from '../../../../enums/badgeTypes'
import CheckboxComponent from '../../../../shared/components/CheckboxComponent'
import MetaService from '../../../../services/MetaService/meta.service'
import { AcquisitionTypes } from '../../../../enums/acquisitionTypes'
import { AcquisitionDefinitionTypes } from '../../../../definitions/acquisitionTypes'
import { CitiesModel, MetaModel, NeighbourhoodModel, RegionsModel } from '../../../../models/Meta/meta.model'
import ButtonComponent from '../../../../shared/components/ButtonComponent'
import addIcon from "../../../../assets/images/add.svg"
import MenuComponent from '../../../../shared/components/MenuComponent'
import { zoneRankOptions } from '../../../../enums/zoneScoreOptions'
import Notification from '../../../../shared/components/Notification'
import { NotificationTypes } from '../../../../enums/notificationTypes'
import { ReligionTypes } from '../../../../enums/religionTypes'
import { ReligionTypesDefinition } from '../../../../definitions/religionTypesDefinition'
import { mobileScreenWidth } from '../../../../enums/constants'
import Error from '../../../../shared/components/Error'
import RadioComponent from "../../../../shared/components/RadioComponent";
import ConfigurationService from "../../../../services/ConfigurationService/configuration.service";
import { HasIScore } from "../../../../enums/hasIScore";

interface CustomerDetailsFormProps {
    data?: CustomerModel;
    visible: boolean;
    closeHandler: () => void;
    successHandler?: (values?: CustomerModel) => void;
    openHandler?: () => void;
    isApprove?: boolean
    isSave?: boolean;
    cities?: CitiesModel[];
    refreshCities?: () => void;
    redirection?: 'approval' | 'rejection' | undefined;
    openApproveAlertVisibility?: () => void;
    professionalGroupList?: MetaModel[];
}

const CustomerDetailsForm: FC<CustomerDetailsFormProps> = (props) => {

    const navigate = useNavigate()
    const location = useLocation();
    const {
        visible,
        closeHandler,
        data,
        successHandler,
        isApprove,
        openHandler,
        isSave,
        professionalGroupList,
        redirection,
        openApproveAlertVisibility,
        refreshCities,
        cities
    } = props;
    const {
        updatePendingCustomerDetails,
        loading,
        manualApproveCustomer,
        error,
        fetchRegions,
        fetchNeighbourhoodList,
        regions,
        neighbourhood,
        createNeighbourhood
    } = CustomerService()
    const { fetchSuspicionReasonList, fetchRejectionReasonList } = MetaService()
    const { uploadAttachment, loading: attachmentLoading } = S3AttachmentService()
    const [initialValues, setInitialValues] = useState<CustomerEditModel>({});
    const [changedValues, setChangedValues] = useState<CustomerEditModel>({});
    const [isChanged, setIsChanged] = useState(false);
    const [city, setCity] = useState<number | undefined>(data?.city?.value);
    const [region, setRegion] = useState<number | undefined>(data?.region?.value);
    const [regionsList, setRegionsList] = useState<RegionsModel[]>([]);
    const [neighbourhoodList, setneighbourhoodList] = useState<NeighbourhoodModel[]>([]);
    const [isMobile, setIsMobile] = useState(false);
    const [dataIncomplete, setDataIncomplete] = useState(false)
    const [showAddNewNeighbourhood, setShowAddNewNeighbourhood] = useState(false)
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const queryParams = new URLSearchParams(location.search);
    const openEditModal = queryParams.get('edit');
    const isRejected = data?.status === BadgeTypes.REJECTED
    const { recalculateCreditScoreForAUser } = ConfigurationService()

    useEffect(() => {
        data && setInitialValues({
            ...data,
            profilePic: data?.profilePic?.[0],
            nationalIdBack: data?.nationalIdBack?.[0],
            nationalIdFront: data?.nationalIdFront?.[0],
            profilePicId: data?.profilePic?.[0]?.id,
            nationalIdBackId: data?.nationalIdBack?.[0]?.id,
            nationalIdFrontId: data?.nationalIdFront?.[0]?.id,
            selfiePic: data?.selfiePic?.[0],
            selfiePicId: data?.selfiePic?.[0]?.id,
            neighbourhoodId: data?.neighbourhoodId,
            professionalGroupId: data?.professionalGroup?.value
                ? Number(data.professionalGroup.value)
                : undefined,
            cityId: data?.city?.value,
            regionId: data?.region?.value,
            outOfScope: data?.outOfScope,
            iscore: data?.iscore,
            hasIScore: (data?.hasIScore === HasIScore.NO ? HasIScore.YES : data?.hasIScore) || HasIScore.YES,
            noIScoreFound: data?.noIScoreFound
        })
    }, [data, visible])

    useEffect(() => {
        fetchSuspicionReasonList()

        setIsMobile(window.innerWidth <= mobileScreenWidth)

        openEditModal && openHandler && openHandler()
    }, [])

    useEffect(() => {
        const regionsForCityId = cities?.find(cityData => cityData.value === city)?.regions as RegionsModel[];
        regionsForCityId && setRegionsList(regionsForCityId)
    }, [city, cities]);

    useEffect(() => {
        const neighbourhoodsForRegion = regionsList?.find(regionData => regionData.value === region)?.neighbourhoods
        neighbourhoodsForRegion && setneighbourhoodList(neighbourhoodsForRegion)
    }, [region, regionsList])

    const handleUpdateSuccess = () => {
        if (!data?.id) return;

        recalculateCreditScoreForAUser(String(data.id), successHandler);
        closeHandler();
    };

    const onSubmit = (
        values: FormikValues,
        errors: FormikErrors<FormikValues>,
        handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
    ) => {
        const updatedValues = { ...values, iscore: values?.noIscoreFound ? null : values?.iscore };

        updatePendingCustomerDetails({ ...updatedValues, id: data?.id }, handleUpdateSuccess);
    };

    const handleManualApproval = (values: FormikValues) => {
        manualApproveCustomer({ ...values, id: data?.id }, values?.id?.toString() ?? "", () => {
            navigate(-1)
        })
        error && openHandler && openHandler()
    }

    const handleSaveAndApprove = (values: FormikValues) => {
        const updatedValues = { ...values, iscore: values?.noIscoreFound ? null : values?.iscore }
        updatePendingCustomerDetails({ ...updatedValues, id: data?.id });
        data?.id && fetchRejectionReasonList(data?.id);
        openApproveAlertVisibility && openApproveAlertVisibility();
    }

    const resetFormAndClose = () => {
        formRef?.current?.resetForm({ values: {} });
        closeHandler()
    }

    const verifyCustomerDetails = (values: FormikValues, dirty: boolean, handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void, errors: FormikErrors<FormikValues>) => {
        setChangedValues(values)
        setIsChanged(dirty)
        closeHandler()
        const detailNotFilled = !values?.jobPlace || !values?.jobTitle || !values?.gender || !values?.maritalStatus || !values?.religion
        detailNotFilled ? setDataIncomplete(true) : (redirection === 'approval' && openApproveAlertVisibility) ? handleSaveAndApprove(values) : handleManualApproval(values)
    }

    const handleSuccess = (values: FormikValues, dirty: boolean, handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void, errors: FormikErrors<FormikValues>, isOptionSave: boolean = false) => {
        const updatedValues = {
            ...values,
            neighbourhoodId: showAddNewNeighbourhood ? null : values?.outOfScope ? null : values?.neighbourhoodId,
            neighbourhood: {}
        };
        const neighbourhoodData = {
            zoneScore: values?.zoneScore,
            label: values?.zoneKeyword,
            regionId: values?.regionId
        }
        if (showAddNewNeighbourhood) {
            if (values.zoneKeyword && values.zoneScore != undefined && !values?.outOfScope) { //Added undefined specifically because zoneScore has values from 0 - 4
                createNeighbourhood(neighbourhoodData).then((response) => {
                    const newNeighbourhoodId = response?.id;
                    const updatedWithNeighbourhood = {
                        ...updatedValues,
                        neighbourhoodId: newNeighbourhoodId
                    };
                    (isApprove && !isOptionSave)
                        ? verifyCustomerDetails(updatedWithNeighbourhood, dirty, handleSubmit, errors)
                        : onSubmit(updatedWithNeighbourhood, errors, handleSubmit);

                    setShowAddNewNeighbourhood(false);
                    refreshCities && refreshCities();
                });
            } else {
                Notification({
                    message: "Please fill Address details",
                    description: "",
                    type: NotificationTypes.ERROR,
                });
            }
        } else {
            if ((!values?.outOfScope && values?.neighbourhoodId) || (values?.outOfScope)) {
                (isApprove && !isOptionSave) ? verifyCustomerDetails(updatedValues, dirty, handleSubmit, errors) : onSubmit(updatedValues, errors, handleSubmit)
            } else {
                Notification({
                    message: "Please fill Neighbourhood details",
                    description: "",
                    type: NotificationTypes.ERROR,
                });
            }
        }
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={() => {
                }}
                innerRef={formRef}
                validateOnChange={true}
                validationSchema={customerDetailsFormValidation(showAddNewNeighbourhood)}
                validateOnBlur={true}
            >
                {({ values, setFieldValue, handleSubmit, touched, errors, dirty, validateForm, setFieldTouched }) => {
                    return (
                        <Form onScroll={hideBrowserSuggestions}>
                            <ModalComponent
                                title=""
                                visible={visible}
                                closeHandler={resetFormAndClose}
                                type="x-large"
                                successProps={{
                                    title: isSave ? "Save" : isApprove ? "Approve" : "Update",
                                    clickHandler: () => {
                                        validateForm(values).then((errors) => {
                                            if (Object.entries(errors).length) {
                                                handleSubmit()
                                            } else {
                                                handleSuccess(values, dirty, handleSubmit, errors)
                                            }
                                        })
                                    },
                                    loading: loading || attachmentLoading,
                                }}
                                cancelProps={{
                                    title: isRejected ? "Save" : "Discard",
                                    clickHandler: isRejected ? () => {
                                        validateForm(values).then((errors) => {
                                            if (Object.entries(errors).length) {
                                                handleSubmit()
                                            } else {
                                                handleSuccess(values, dirty, handleSubmit, errors, isRejected)
                                            }
                                        })
                                    } : resetFormAndClose,
                                }}
                                className="customer-form-modal"
                                destroyOnClose={true}
                            >
                                <div className="customer-details-form">
                                    <div className="customer-form-details__container">
                                        <Row gutter={16}>
                                            <Col span={10} className="customer-documents">
                                                <h3 className='customer-documents-header'>Customer Documents</h3>
                                                <Col lg={18} xs={24} sm={24}>
                                                    <UploadComponent
                                                        isUploading={false}
                                                        canRotate={true}
                                                        fileId={values?.nationalIdFrontId ?? values?.nationalIdFront?.id}
                                                        fileType={values?.nationalIdFront?.fileType}
                                                        fileName={values?.nationalIdFront?.filename}
                                                        accept={"image/png, image/jpg, image/jpeg"}
                                                        fileUrl={values?.nationalIdFront?.s3Url}
                                                        onUpload={async (file, fileUrl) => {
                                                            setFieldValue("nationalIdFront.s3Url", fileUrl);
                                                            setFieldValue("nationalIdFront.filename", file?.name);
                                                            const _commercial = await uploadAttachment(file, DocumentTypes.NATIONAL_ID_FRONT);
                                                            setFieldValue("nationalIdFrontId", _commercial);
                                                        }}
                                                        title="Upload Document"
                                                        type="document-preview"
                                                        label="National ID - Front"
                                                        error={errors?.nationalIdFrontId}
                                                    />
                                                </Col>
                                                <Col lg={18} xs={24} sm={24}>
                                                    <UploadComponent
                                                        isUploading={false}
                                                        canRotate={true}
                                                        fileId={values?.nationalIdBackId ?? values?.nationalIdBack?.id}
                                                        fileType={values?.nationalIdBack?.fileType}
                                                        fileName={values?.nationalIdBack?.filename}
                                                        accept={"image/png, image/jpg, image/jpeg"}
                                                        fileUrl={values?.nationalIdBack?.s3Url}
                                                        onUpload={async (file, fileUrl) => {
                                                            setFieldValue("nationalIdBack.s3Url", fileUrl);
                                                            setFieldValue("nationalIdBack.filename", file?.name);
                                                            const _commercial = await uploadAttachment(file, DocumentTypes.NATIONAL_ID_FRONT);
                                                            setFieldValue("nationalIdBackId", _commercial);
                                                        }}
                                                        title="Upload Document"
                                                        type="document-preview"
                                                        label="National ID - Back"
                                                        error={errors?.nationalIdBackId}
                                                    />
                                                </Col>
                                                <Col lg={18} xs={24} sm={24}>
                                                    <UploadComponent
                                                        canReupload={false}
                                                        isUploading={false}
                                                        canRotate={true}
                                                        fileId={values?.selfiePicId ?? values?.selfiePic?.id}
                                                        fileType={values?.selfiePic?.fileType}
                                                        fileName={values?.selfiePic?.filename}
                                                        accept={"image/png, image/jpg, image/jpeg"}
                                                        fileUrl={values?.selfiePic?.s3Url}
                                                        onUpload={async (file, fileUrl) => {
                                                            setFieldValue("selfiePic.s3Url", fileUrl);
                                                            setFieldValue("selfiePic.filename", file?.name);
                                                            const _commercial = await uploadAttachment(file, DocumentTypes.NATIONAL_ID_FRONT);
                                                            setFieldValue("selfiePicId", _commercial);
                                                        }}
                                                        title="Upload Document"
                                                        type="document-preview"
                                                        label="Selfie Pic"
                                                        error={errors?.selfiePicId}
                                                    />
                                                </Col>
                                            </Col>
                                            <Col span={14} className="customer-details">
                                                <div className='customer-details-header'>
                                                    <span className='label'>Customer Details</span>
                                                    <span className="modal-component-controllers">
                                                        <ButtonComponent
                                                            type="default"
                                                            additionalTypes="secondary"
                                                            size="fixed"
                                                            onClick={isRejected ? () => {
                                                                validateForm(values).then((errors) => {
                                                                    if (Object.entries(errors).length) {
                                                                        handleSubmit()
                                                                    } else {
                                                                        handleSuccess(values, dirty, handleSubmit, errors, isRejected)
                                                                    }
                                                                })
                                                            } : resetFormAndClose}
                                                        >
                                                            {isRejected ? "Save" : "Discard"}
                                                        </ButtonComponent>
                                                        <ButtonComponent
                                                            type={"primary"}
                                                            htmlType={"submit"}
                                                            size="fixed"
                                                            onClick={() => {
                                                                validateForm(values).then((errors) => {
                                                                    if (Object.entries(errors).length) {
                                                                        handleSubmit()
                                                                    } else {
                                                                        handleSuccess(values, dirty, handleSubmit, errors)
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            {isSave ? "Save" : isApprove ? "Approve" : "Update"}
                                                        </ButtonComponent>
                                                    </span>
                                                </div>

                                                <Row gutter={16} className="scrollable-section">
                                                    <Col className="filter-field" span={24}>
                                                        <div className="profile-pic-holder">
                                                            <UploadComponent
                                                                fileId={values?.profilePicId ?? values?.profilePic?.id}
                                                                fileType={values?.profilePic?.fileType}
                                                                fileName={values?.profilePic?.fileName}
                                                                label={values?.profilePic?.fileName}
                                                                accept={"image/png, image/jpg, image/jpeg"}
                                                                fileUrl={values?.profilePic?.s3Url}
                                                                type="logo-preview"
                                                                title="Profile Picture"
                                                                onUpload={async (file, fileUrl) => {
                                                                    setFieldValue("profilePic.s3Url", fileUrl);
                                                                    const _profile = await uploadAttachment(file);
                                                                    setFieldValue("profilePicId", _profile);
                                                                }}
                                                                onRemove={() => {
                                                                    setFieldValue("profilePic.s3Url", undefined);
                                                                    setFieldValue("profilePic.logoId", undefined);
                                                                }}
                                                                error={errors?.profilePicId}
                                                            />
                                                        </div>

                                                    </Col>
                                                    <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                        <InputField placeholder='Enter' label='Email' name='email' />
                                                    </Col>
                                                    <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                        <InputField placeholder='Enter' label='National ID'
                                                            name='nationalIdNumber' required />
                                                    </Col>
                                                    <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                        <InputField placeholder='Enter'
                                                            label='First name (customer provided name)'
                                                            name='firstName' required />
                                                    </Col>
                                                    <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                        <InputField placeholder='Enter'
                                                            label='Last name (customer provided name)'
                                                            name='lastName' required />
                                                    </Col>
                                                    <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                        <InputField placeholder='Enter' label='Name (Captured from ID)'
                                                            name='nameFromId' required />
                                                    </Col>
                                                    <Col xs={24} sm={24} lg={12} />
                                                    <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                        <DatePickerField
                                                            format="DD MMM YYYY"
                                                            value={values?.dateOfBirth}
                                                            onChange={(date, dateString) => setFieldValue("dateOfBirth", dateString)}
                                                            label="Date of Birth"
                                                            required
                                                            name="dateOfBirth"
                                                            placeholder="Select"
                                                        />
                                                    </Col>
                                                    <Col className="gender" xs={24} sm={24} lg={12}>
                                                        <DropdownField
                                                            label="Sex"
                                                            placeholder="Select"
                                                            name="gender"
                                                            required
                                                            value={values?.gender}
                                                            options={[
                                                                {
                                                                    value: GenderTypes.MALE,
                                                                    label: GenderTypesDefinition[GenderTypes.MALE],
                                                                },
                                                                {
                                                                    value: GenderTypes.FEMALE,
                                                                    label: GenderTypesDefinition[GenderTypes.FEMALE],
                                                                },
                                                            ]}
                                                            onChange={(value) => setFieldValue("gender", value)}
                                                        />
                                                    </Col>
                                                    <Col className="marital-status" xs={24} sm={24} lg={12}>
                                                        <DropdownField
                                                            label="Marital Status"
                                                            placeholder="Select"
                                                            name="maritalStatus"
                                                            required
                                                            value={values?.maritalStatus}
                                                            options={[
                                                                {
                                                                    value: MaritalStatusTypes.SINGLE,
                                                                    label: MaritalStatusTypesDefinition[MaritalStatusTypes.SINGLE],
                                                                },
                                                                {
                                                                    value: MaritalStatusTypes.MARRIED,
                                                                    label: MaritalStatusTypesDefinition[MaritalStatusTypes.MARRIED],
                                                                },
                                                                {
                                                                    value: MaritalStatusTypes.WIDOWED,
                                                                    label: MaritalStatusTypesDefinition[MaritalStatusTypes.WIDOWED],
                                                                },
                                                                {
                                                                    value: MaritalStatusTypes.DIVORCED,
                                                                    label: MaritalStatusTypesDefinition[MaritalStatusTypes.DIVORCED],
                                                                },
                                                            ]}
                                                            onChange={(value) => setFieldValue("maritalStatus", value)}
                                                        />
                                                    </Col>
                                                    <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                        <DropdownField
                                                            label="Religion"
                                                            placeholder="Select"
                                                            name="religion"
                                                            required
                                                            value={values?.religion}
                                                            options={[
                                                                {
                                                                    value: ReligionTypes.ISLAM,
                                                                    label: ReligionTypesDefinition[ReligionTypes.ISLAM],
                                                                },
                                                                {
                                                                    value: ReligionTypes.CHRISTIANITY,
                                                                    label: ReligionTypesDefinition[ReligionTypes.CHRISTIANITY],
                                                                },
                                                                {
                                                                    value: ReligionTypes.JUDAISM,
                                                                    label: ReligionTypesDefinition[ReligionTypes.JUDAISM],
                                                                },
                                                                {
                                                                    value: ReligionTypes.OTHER,
                                                                    label: ReligionTypesDefinition[ReligionTypes.OTHER],
                                                                }
                                                            ]}
                                                            onChange={(value) => setFieldValue("religion", value)}
                                                        />
                                                    </Col>
                                                    <Divider />
                                                    <Col span={24} className='section-header'>Professional Details</Col>
                                                    <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                        <InputField placeholder='Enter' label='Job Title'
                                                            name='jobTitle' required />
                                                    </Col>
                                                    <Col xs={24} sm={24} lg={12}>
                                                        <DropdownField
                                                            label="Professional Group"
                                                            placeholder="Select"
                                                            name="professionalGroupId"
                                                            required
                                                            value={values?.professionalGroupId}
                                                            options={professionalGroupList ?? []}
                                                            onChange={(value) => {
                                                                setFieldValue("professionalGroupId", value)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Divider />
                                                    <Col span={24} className='section-header'>Address Details</Col>
                                                    <Col className="filter-field" span={24}>
                                                        <InputField textArea={true} placeholder='Enter'
                                                            label='Address (captured from ID)' name='address'
                                                            required />
                                                    </Col>
                                                    {!values?.outOfScope && <>
                                                        <Col xs={24} sm={24} lg={12}>
                                                            <DropdownField
                                                                label="City (captured from ID)"
                                                                placeholder="Select"
                                                                name="cityId"
                                                                required
                                                                value={values?.cityId}
                                                                options={cities ?? []}
                                                                onChange={(value) => {
                                                                    setFieldValue("cityId", value);
                                                                    setRegionsList([]);
                                                                    setCity(value);
                                                                    if (value === -1) {
                                                                        setFieldValue("regionId", null);
                                                                        setFieldValue("neighbourhoodId", null);
                                                                        setFieldValue("neighbourhood", null);
                                                                    } else {
                                                                        setFieldValue("regionId", undefined);
                                                                        setFieldValue("neighbourhoodId", undefined);
                                                                        setFieldValue("neighbourhood", undefined);
                                                                    }
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs={24} sm={24} lg={12}>
                                                            <DropdownField
                                                                label="Region (captured from ID)"
                                                                placeholder="Select"
                                                                name="regionId"
                                                                required
                                                                disabled={!values?.cityId}
                                                                value={values?.regionId}
                                                                options={regionsList ?? []}
                                                                onChange={(value) => {
                                                                    setFieldValue("regionId", value);
                                                                    setRegion(value);
                                                                    setFieldValue("neighbourhoodId", undefined);
                                                                    setFieldValue("neighbourhood", undefined);
                                                                }}
                                                            />
                                                        </Col>
                                                        {!showAddNewNeighbourhood &&
                                                            <Col xs={24} sm={24} lg={10}>
                                                                <DropdownField
                                                                    label="Neighbourhood (captured from ID)"
                                                                    placeholder="Select"
                                                                    name="neighbourhoodId"
                                                                    required
                                                                    value={values?.neighbourhoodId}
                                                                    disabled={!values?.regionId}
                                                                    options={neighbourhoodList ?? []}
                                                                    onChange={(value) => {
                                                                        setFieldValue("neighbourhoodId", value);
                                                                    }}
                                                                />
                                                            </Col>}
                                                        {showAddNewNeighbourhood &&
                                                            <Col className="filter-field" xs={24} sm={24} lg={10}>
                                                                <InputField placeholder='Enter neighbourhood'
                                                                    label='Neighbourhood' name='zoneKeyword'
                                                                    required />
                                                            </Col>}
                                                        <Col xs={24} sm={24} lg={2}>
                                                            <MenuComponent
                                                                dropDownPlacement="bottomCenter"
                                                                dropdownClassName="add-neighbourhood-menu"
                                                                menu={[
                                                                    {
                                                                        title: showAddNewNeighbourhood ? 'Select neighbourhood' : `Add New Neighbourhood`,
                                                                        clickHandler: () => showAddNewNeighbourhood ? setShowAddNewNeighbourhood(false) : !showAddNewNeighbourhood ? setShowAddNewNeighbourhood(true) : "",
                                                                        icon: showAddNewNeighbourhood ? <></> :
                                                                            <img className="refund-icon"
                                                                                src={addIcon} />,
                                                                    }
                                                                ]}
                                                            />
                                                        </Col>
                                                        {showAddNewNeighbourhood &&
                                                            <Col className="neighbourhoodScore" xs={24} sm={24} lg={12}>
                                                                <DropdownField
                                                                    label="Neighbourhood Score"
                                                                    placeholder="Select"
                                                                    name="zoneScore"
                                                                    required
                                                                    value={values?.zoneScore}
                                                                    options={zoneRankOptions}
                                                                    onChange={(value) => setFieldValue("zoneScore", value)}
                                                                />
                                                            </Col>}
                                                        <Col xs={24} sm={24} lg={12} />
                                                    </>}
                                                    <Col span={24}>
                                                        <CheckboxComponent title='Others (Out of scope)'
                                                            value={values?.outOfScope}
                                                            onChecked={(value) => {
                                                                setFieldValue("outOfScope", value?.target?.checked)
                                                                setFieldValue('cityId', undefined)
                                                                setFieldValue('regionId', undefined)
                                                                setFieldValue("neighbourhoodId", undefined);
                                                                setFieldValue("neighbourhood", undefined);
                                                                if (value?.target?.checked) {
                                                                    setShowAddNewNeighbourhood(false);
                                                                }
                                                            }} />
                                                    </Col>
                                                    <Divider />
                                                    <Col span={24} className='section-header'>iscore Details</Col>
                                                    <Col xs={24} sm={24} lg={24}>
                                                        <RadioComponent
                                                            name="hasIscore"
                                                            value={values?.hasIScore}
                                                            data={[
                                                                { value: HasIScore.YES, label: "iscore was checked" },
                                                                {
                                                                    value: HasIScore.UNCHECKED,
                                                                    label: "iscore was not checked"
                                                                }
                                                            ]}
                                                            onChange={(event) => {
                                                                const selectedValue = event?.target?.value;
                                                                setFieldValue("hasIScore", selectedValue);
                                                                setFieldValue("iscore", selectedValue === HasIScore.YES ? values?.iscore : undefined);
                                                            }}
                                                            className="iscore-radio"
                                                        />
                                                    </Col>
                                                    {values?.hasIScore !== HasIScore.UNCHECKED &&
                                                        <>
                                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                                <InputField name='iscore'
                                                                    disabled={values?.noIScoreFound}
                                                                    placeholder='Enter a value between 299 - 801'
                                                                    label='iscore' hideErrorMessage={true} />
                                                                {(errors?.iscore && !values?.noIScoreFound) && (
                                                                    <Error message={errors?.iscore as string} />
                                                                )}
                                                            </Col>
                                                            {/* <Col xs={0} sm={0} lg={12}/> */}
                                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                                <InputField placeholder='Enter' label='Number of facilities '
                                                                    name='noOfFacilities' />
                                                            </Col>
                                                            <Col className="filter-field-iscore-checkbox" xs={24}
                                                                sm={24}
                                                                lg={24}>
                                                                <CheckboxComponent
                                                                    value={values?.noIScoreFound}
                                                                    title="The person is not found on iscore platform"
                                                                    name="noIScoreFound"
                                                                    onChecked={(value) => {
                                                                        setFieldValue("noIScoreFound", value?.target?.checked)
                                                                        value?.target?.checked && setFieldValue("iscore", null)
                                                                    }}
                                                                    className="iscore-checkbox" />
                                                            </Col>
                                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                                <InputField placeholder='Enter' label='Total approval amount'
                                                                    name='totalApprovalAmount' />
                                                            </Col>
                                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                                <InputField placeholder='Enter' label='Total of balances'
                                                                    name='totalOfBalances' />
                                                            </Col>
                                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                                <InputField placeholder='Enter' label='Total amount of installments'
                                                                    name='totalAmtOfInstallments ' />
                                                            </Col>
                                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                                <InputField placeholder='Enter' label='Number of fully secure facilities'
                                                                    name='numberOfFullySecureFacilities ' />
                                                            </Col>
                                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                                <InputField placeholder='Enter' label='Number of partially secured facilities'
                                                                    name='numberOfPartialSecureFacilities ' />
                                                            </Col>
                                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                                <InputField placeholder='Enter' label='Number of not secured facilities'
                                                                    name='numberOfSecuredSecureFacilities ' />
                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </ModalComponent>
                        </Form>
                    );
                }}
            </Formik>

            <ModalComponent
                type={isMobile ? 'large' : "alert-large"}
                title="Some of the customer details are empty. Are you sure you want to continue?"
                visible={dataIncomplete}
                closeHandler={() => setDataIncomplete(false)}
                successProps={{
                    title: "Approve",
                    loading: loading,
                    clickHandler: () => {
                        setDataIncomplete(false);
                        redirection === 'approval' && openApproveAlertVisibility ? handleSaveAndApprove(changedValues) : handleManualApproval(changedValues)
                    },
                }}
                className={`customer-details-confirmation-modal`}
                cancelProps={{
                    title: "Close",
                    clickHandler: () => setDataIncomplete(false),
                }}
            />
        </div>
    )
}

export default CustomerDetailsForm