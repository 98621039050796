import React, { FC, Fragment, ReactNode } from "react";
import { Field, ErrorMessage, FormikProps, Formik, useFormikContext } from "formik";
import { Input } from "antd";
import Error from "../Error";
import "./inputField.scss";
import TextArea from "antd/lib/input/TextArea";
interface InputFieldProps {
  type?: "email" | "password" | "number" | "text" | "textarea";
  name: string;
  placeholder: string;
  label?: string;
  className?: string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  addonBefore?: ReactNode;
  disabled?: boolean;
  onChange?: (value: string) => void
  textArea?: boolean;
  hideErrorMessage?: boolean
  required?: boolean
}

const InputField: FC<InputFieldProps> = (props) => {
  const { name, hideErrorMessage = false, label, className, type = "text", textArea, onChange, required, ...rest } = props;
  const { setFieldValue } = useFormikContext();
  return (
    <Fragment>
      <Field name={name}>
        {({ field, form: { touched, errors }, meta }: any) => (
          <div
            className={`input-field ${meta?.error && meta.touched && "error"
              } ${className}`}
          >
            {label ? <label>{label} {required ? "*" : "" }</label> : ""}
            {!textArea ? 
            <Input autoComplete="new-password" data-testid={name} type={type} {...rest} {...field} onChange={(e) => onChange ? onChange(e.target.value) : setFieldValue(name, e.target.value)} /> :
            <TextArea rows={3} autoComplete="new-password" data-testid={name} type={type} {...rest} {...field} onChange={(e) => onChange ? onChange(e.target.value) : setFieldValue(name, e.target.value)} style={{resize: 'none'}} />
            }
          </div>
        )}
      </Field>
        {!hideErrorMessage &&
            <ErrorMessage name={name}>
                {(message: string) =>
                    <Error
                        message={message}
                        testId={`input-error-${name}`}
                    />
                }
            </ErrorMessage>
        }
    </Fragment>
  );
};

export default InputField;
