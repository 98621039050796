import { DatePicker, Dropdown, Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { DashboardDateFilterTypesDefinition } from '../../../definitions/dashboardDateFilterTypeDefinition'
import { DashboardFilterModel } from '../../../models/Dashboard/dashboard.model'
import { DashboardDateFilterTypes } from '../../types/DashboardDateFilterTypes'
import { LocalStorage } from '../../utils/localStorageHelpers'
import IconComponent from '../IconComponent'
import "./dashboardFilterMenu.scss"

const { RangePicker } = DatePicker;

interface DashboardFilterMenuProps {
    onChange?: (values: {
        dateFilterKey: string,
        startDate: string, 
        endDate: string, 
        showTest: boolean
    })=>void
    setParams?: React.Dispatch<React.SetStateAction<DashboardFilterModel | undefined>>
 }

type RangePickerValueType = [moment.Moment, moment.Moment]

const DashboardFilterMenu: FC<DashboardFilterMenuProps> = ({onChange, setParams}) => {
    const { TODAY, PAST_WEEK, CURRENT_MONTH, LAST_MONTH, CURRENT_YEAR, CUSTOM, ALL_TIME } = DashboardDateFilterTypes
    const dateFilter = LocalStorage.getItem("DATE_FILTER_KEY")
    const [selectedFilter, setSelectedFilter] = useState<typeof TODAY | typeof PAST_WEEK | typeof CURRENT_MONTH | typeof CURRENT_YEAR | typeof ALL_TIME | typeof CUSTOM | string>(dateFilter ?? CURRENT_YEAR)
    
    const generateDateString = (values: RangePickerValueType) => values?.map((i) => i.format("DD/MM/YYYY")).join(" - ")
    const showTest = LocalStorage.getItem("IS_TEST")

    useEffect(() => {
        const startDate = selectedFilter.includes('/') ? selectedFilter.split(' - ')[0] : ""
        const endDate = selectedFilter.includes('/') ? selectedFilter.split(' - ')[1] : ""
        onChange && selectedFilter !== "Select Custom Range" && onChange({dateFilterKey: selectedFilter.includes('/') ? "custom" : selectedFilter, startDate, endDate, showTest })
        setParams && setParams({dateFilterKey: selectedFilter.includes('/') ? "custom" : selectedFilter, startDate, endDate, showTest })
    }, [selectedFilter])
    

    const menu = (
        <Menu className="menu-component-overlay" onClick={(e) => e.key !== CUSTOM && setSelectedFilter(e.key as DashboardDateFilterTypes)}>
            <Menu.Item key={TODAY}>Today</Menu.Item>
            <Menu.Item key={PAST_WEEK}>Last 7 Days</Menu.Item>
            <Menu.Item key={CURRENT_MONTH}>Current Month</Menu.Item>
            <Menu.Item key={LAST_MONTH}>Last Month</Menu.Item>
            <Menu.Item key={CURRENT_YEAR}>Current Year</Menu.Item>
            <Menu.Item key={ALL_TIME}>All Time</Menu.Item>
            <SubMenu key={CUSTOM} title="Custom Range">
                <RangePicker
                    disabledDate={(date) => date.isAfter(moment())}
                    className="dashboard-filter"
                    onChange={(values) => values?.length && setSelectedFilter(generateDateString(values as RangePickerValueType))}
                    format={'DD/MM/YYYY'} />
            </SubMenu>
        </Menu>
    )

    return (
        <div className="dashboard-filter-menu">
            <Dropdown trigger={["click"]} overlay={menu}>
                <p className="ant-dropdown-link">
                    {(selectedFilter === CURRENT_YEAR || selectedFilter === ALL_TIME || selectedFilter === CURRENT_MONTH || selectedFilter === PAST_WEEK || selectedFilter === TODAY || selectedFilter === LAST_MONTH) ? DashboardDateFilterTypesDefinition[selectedFilter as DashboardDateFilterTypes] : selectedFilter} <IconComponent name="icon-down" />
                </p>
            </Dropdown>

        </div>
    )
}

export default DashboardFilterMenu