import { Divider, Steps } from 'antd'
import moment from 'moment'
import React, { FC, Fragment, ReactElement } from 'react'
import approvedIcon from "../../../assets/images/green-tick.svg"
import rejectedIcon from "../../../assets/images/red-cross.svg"
import alignTextIcon from "../../../assets/images/TextAlignLeft.svg"
import { CustomerModel } from '../../../models/Customer/customer.model'
import { formatDate } from '../../utils/formatDate'
import './verificationDetails.scss'

interface VerificationdetailsProps {
    customer: CustomerModel;
    renderDivider: ReactElement;
    isMobile: boolean;
    isApprovedUser?: boolean;
    isRejectedUser?: boolean;
 }

const Verificationdetails: FC<VerificationdetailsProps> = ({customer, isMobile, isApprovedUser}) => {
    const verificationHistory = customer?.approvalRejectionHistory || [];

const items = verificationHistory.map((item) => {
    const { eventName, eventData, createdAt } = item;
    const isApproved = eventData?.message === 'Approved';
    const isRejectedAgain = eventData?.message === 'Rejected again';
    const statusClass = isApproved ? "approved" : "rejected";
    const icon = isApproved ? approvedIcon : rejectedIcon;

    const titleElement = (
        <div className="verify-title">
            <span className={statusClass}>{isApproved ? `Verified ` : isRejectedAgain ? `Rejected again` :  `Rejected `}</span> on {formatDate(createdAt as string)} by {eventData?.verifiedBy}
        </div>
    );

    const descriptionElement = (
        <div className="verify-container">
            <div className="verify-description">
                <span className="label">Reason</span>
                <span className="content">{eventData?.reasons?.join(', ')}</span>
            </div>
            <div className="verify-description">
                <span className="label">Comment</span>
                <span className="content">{eventData?.comment}</span>
            </div>
        </div>
    );

    return {
        title: titleElement,
        description: descriptionElement,
        icon: <img src={icon} alt="Status Icon" />,
    };
});

return (
    <Fragment>
        {verificationHistory?.length > 0 && 
        <>
        <div className="subheader">Verification history</div>
        <Steps direction="vertical" items={items} />
        <Divider /></>}
    </Fragment>
)
}

export default Verificationdetails