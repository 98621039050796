import { useFormikContext } from 'formik';
import { FC, useState } from 'react'
import { Promotion } from '../../../../../../models/Promotion/promotion.model';
import CheckboxComponent from '../../../../../../shared/components/CheckboxComponent'
import "./promotionaudience.scss"

interface PromotionAudienceComponentProps {
    iconName: string;
    title: string;
    setFieldValue: (field: string, values: unknown, shouldValidate?: boolean | undefined) => void;
    fieldName: string;
 }

const PromotionAudienceComponent: FC<PromotionAudienceComponentProps> = ({ iconName, title, setFieldValue, fieldName }) => {
    const { values } = useFormikContext();
    const other: boolean = title === 'Other';
    const phoneType: boolean = title === 'Phone';
    const {phone, phoneCredit, purchaseTypeOther=false, purchaseTypePhone=false} = values as Promotion;
    const [selected, setSelected] = useState(other ? purchaseTypeOther : purchaseTypePhone);
    const showPhoneStyle: boolean = purchaseTypePhone && phoneType && selected;
    const showOtherStyle: boolean = purchaseTypeOther && other && selected;

    return (
        <div className={`promotionaudience ${(showPhoneStyle || showOtherStyle) && 'show-customer'}`}>
            <CheckboxComponent 
                className='audience-checkbox'
                value={phoneType ? purchaseTypePhone : purchaseTypeOther}
                title="" 
                onChecked={(value) => {
                    setFieldValue(fieldName, value?.target?.checked);
                    other && setFieldValue("credit", value?.target?.checked);
                    if(phoneType && !value?.target?.checked){
                        setFieldValue("phone", value?.target?.checked);
                        setFieldValue("phoneCredit", value?.target?.checked);
                    }
                    setSelected(value?.target?.checked)
                }} 
            />
            <div className={`purchase-type ${(showPhoneStyle || showOtherStyle) && 'show-customer'}`}>
                <img src={iconName} alt="purchase-icon" className='icon' />
                <div className='audience-title'>{`${title} purchase`}</div>
            </div>
            {phoneType &&
            <div className={`applicable-customer ${purchaseTypePhone && 'show-customer'}`}>
                <div>Applicable Customers</div>
                <>
                <CheckboxComponent
                className='cutomer-checkbox'
                value={phone ?? true} 
                title="Phone only customers" 
                onChecked={(value) => {
                    setFieldValue("phone", value?.target?.checked);
                }}
                />
                <CheckboxComponent
                className='cutomer-checkbox'
                value={phoneCredit ?? false} 
                title="Credit customers" 
                onChecked={(value) => {
                    setFieldValue("phoneCredit", value?.target?.checked);
                }} 
                />
                </>
            </div>}
            {other &&
            <div className={`applicable-customer ${purchaseTypeOther && 'show-customer'}`}>
                <div>Applicable Customers</div>
                {other &&
                <CheckboxComponent
                    className='cutomer-checkbox disabled'
                    value={true} 
                    title="Credit customers"
                />}
            </div>}
        </div>
    )
}

export default PromotionAudienceComponent;