// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchase-status {
  margin-bottom: 2em;
}
.purchase-status .purchase-status-skeleton {
  min-height: 9.5em;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/views/Home/Configuration/PurchaseStatus/purchaseStatus.scss"],"names":[],"mappings":"AAAA;EAKI,kBAAA;AAHJ;AADI;EACI,iBAAA;EACA,YAAA;AAGR","sourcesContent":[".purchase-status{\n    .purchase-status-skeleton{\n        min-height: 9.5em;\n        height: 100%;\n    }\n    margin-bottom: 2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
