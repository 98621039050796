import React, { FC } from 'react'
import { Col, Divider, Row } from 'antd'
import DashboardService from '../../../../services/DasboardService/dashboard.service'
import CardSkeletonComponent from '../../../../shared/components/CardSkeletonComponent'
import DashboardFilterMenu from '../../../../shared/components/DashboardFilterMenu'
import "./northStartKpiComponent.scss"
import TooltipComponent from '../../../../shared/components/TooltipComponent'
import { Dashboard } from '../../../../models/Dashboard/dashboard.model'
import { dashboardFraction } from '../../../../shared/utils/dashboardFraction'
import { currencyString } from '../..'

const NorthStartKpiComponent: FC<{keyMetricsLoading: boolean, dashboardKeyMetrics: Dashboard}> = (props) => {
    const {keyMetricsLoading, dashboardKeyMetrics} = props

    return (
        <div className="north-start-kpi-component">
            <div className="dashboard-header">
                <h2 className='dashboard-title'>North Star KPIs</h2>
            </div>
            {
                (keyMetricsLoading || !dashboardKeyMetrics) ?
                    <CardSkeletonComponent className="north-start-kpi__skeleton" rows={14} /> :
                    <div className="content">
                        <Row>
                            <Col span={24} className="independent-kpi">
                                <Row>
                                    <Col span={3}>
                                        <div className="first">
                                            <p className="label">Total GMV <TooltipComponent content='The SUM of all approved purchases in the selected timeframe' /></p>
                                            <h3 className="value">LE {dashboardFraction(dashboardKeyMetrics?.valueOfOutstandingTransactions)}</h3>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className="second">
                                            <p className="label">Number of purchases made <TooltipComponent content='The COUNT of all approved purchases in the selected timeframe' /></p>
                                            <h3 className="value">{dashboardFraction(dashboardKeyMetrics?.numberOfAbsoluteTransactions)}</h3>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className="second">
                                            <p className="label">Average purchase value <TooltipComponent content='An AVERAGE calculated for the selected timeframe in the following way :  $ value of purchases / Absolute number of purchases' /></p>
                                            <h3 className="value">LE {dashboardFraction(dashboardKeyMetrics?.averageTransactionValue)}</h3>
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <div className="second">
                                            <p className="label"># of returns made</p>
                                            <h3 className="value">{dashboardKeyMetrics?.noOfRefunds}</h3>
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <div className="first">
                                            <p className="label"># of Vendors</p>
                                            <h3 className="value">{dashboardFraction(dashboardKeyMetrics?.numberOfLocations)}</h3>
                                        </div>
                                    </Col>
                                    <Col span={3}>
                                        <div className="first">
                                            <p className="label"># of Instores</p>
                                            <h3 className="value">{dashboardFraction(dashboardKeyMetrics?.numberOfActiveInstores?.toString())}</h3>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Divider />
                            <Col span={24} className="independent-kpi">
                                <Row>
                                    <Col span={4}>
                                        <div className="third">
                                            <p className="label"># of approved signups <TooltipComponent content='Number of approved customer in the given time frame' /></p>
                                            <h3 className="value">{dashboardFraction(dashboardKeyMetrics?.noOfCustomerSignups?.toString()) ?? 0}</h3>
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <div className="third">
                                            <p className="label"># of declined signups <TooltipComponent content='Number of declined customer in the given time frame' /></p>
                                            <h3 className="value">{dashboardFraction(dashboardKeyMetrics?.noOfCustomerDeclined?.toString()) ?? 0}</h3>
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <div className="third">
                                            <p className="label">% of approved signups <TooltipComponent content='Percentage of customers approved in the given time period ' /></p>
                                            <h3 className="value">{`${dashboardFraction(dashboardKeyMetrics?.percentOfApprovedSignups?.toString() ?? "0")}%`}</h3>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className="third">
                                            <p className="label">Unused availed credit <TooltipComponent content='Current sum of all purchasing power that is available to exercise immediately by customers (metric reflects all-time data and is not affected by time filters)' /></p>
                                            <h3 className="value">{currencyString}{dashboardFraction(dashboardKeyMetrics?.unusedAvailedCredit?.toString()) ?? 0}</h3>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <div className="bottom-content">
                                    <Col span={12} className="total-fee-realized-kpi">
                                        <Row className='total-fee-kpi-row'>
                                            <Col span={12}>
                                                <div className="total-fee-collected">
                                                    <p className='label'>Total sum of fees realized (Net Revenue) <TooltipComponent content='All fees realized by RISE on purchases listed in the selected time frame' /></p>
                                                    <h3 className='value'>LE {dashboardFraction(dashboardKeyMetrics?.totalFees)}</h3>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className="total-fee-item">
                                                    <span className="label">Consumer fees <TooltipComponent content='A SUM of realized customers fees' /></span>
                                                    <span className="value">LE {dashboardFraction(dashboardKeyMetrics?.consumerFee)}</span>
                                                </div>
                                                <div className="total-fee-item">
                                                    <span className="label">Late fees <TooltipComponent content='A SUM of all late fees realized' /></span>
                                                    <span className="value">
                                                    {(Number(dashboardKeyMetrics?.lateFee) ?? 0) > 0
                                                        ? `LE ${dashboardFraction(dashboardKeyMetrics?.lateFee)}`
                                                        : 'N/A'}
                                                    </span>
                                                </div>
                                                <div className="total-fee-item">
                                                    <span className="label">Vendor fees <TooltipComponent content='A SUM of all vendor fees realized' /></span>
                                                    <span className="value">LE {dashboardFraction(dashboardKeyMetrics?.vendorFee)}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} className="total-fee-outstanding-kpi">
                                        <Row className='total-fee-kpi-row'>
                                            <Col span={12}>
                                                <div className="total-fee-collected">
                                                    <p className='label'>Total sum of fees outstanding <TooltipComponent content='All fees outstanding on purchases listed in the selected time frame' /></p>
                                                    <h3 className='value'> LE {dashboardFraction(dashboardKeyMetrics?.totalFeesOutstanding)}</h3>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className="total-fee-item">
                                                    <span className="label">Consumer fees <TooltipComponent content='A SUM of all outstanding fees that customers are owed for purchases done.' /></span>
                                                    <span className="value">LE {dashboardFraction(dashboardKeyMetrics?.consumerFeeOutstanding) ?? 0}</span>
                                                </div>
                                                <div className="total-fee-item">
                                                    <span className="label">Late fees <TooltipComponent content='A SUM of all outstanding late fees' /></span>
                                                    <span className="value">
                                                    {(Number(dashboardKeyMetrics?.lateFeeOutstanding) ?? 0) > 0
                                                        ? `LE ${dashboardFraction(dashboardKeyMetrics?.lateFeeOutstanding)}`
                                                        : 'N/A'}
                                                    </span>
                                                </div>
                                                <div className="total-fee-item">
                                                    <span className="label">Vendor fees <TooltipComponent content='A SUM of all outstanding vendor fees' /></span>
                                                    <span className="value">LE {dashboardFraction(dashboardKeyMetrics?.vendorFeeOutstanding) ?? 0}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </div>
            }
        </div>
    )
}

export default NorthStartKpiComponent