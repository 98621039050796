import React, { FC, SetStateAction, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import TableComponent from "../../../../shared/components/TableComponent";
import CustomerService from "../../../../services/CustomerService/customer.service";
import NoCustomersImg from "../../../../assets/empty/customers.svg";
import { tableColumns } from "../tableColumns";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { CustomerModel } from "../../../../models/Customer/customer.model";
import moment from "moment";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import useLocationState from "../../../../shared/utils/useLocationState";
import { StateProps } from "../../../../models/Meta/meta.model";

interface RejectedCustomersProps {
  setCount: React.Dispatch<React.SetStateAction<number>>
}

const RejectedCustomers: FC<RejectedCustomersProps> = (props) => {
  const { setCount } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location.state as StateProps;
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const pageNumber = location.hash === "#rejected-customers" ? stateValue?.pageNumber as number : null
  const [page, setPage] = useState(pageNumber ?? 1)
  const [search, setSearch] = useState("")
  const { loading, fetchRejectedCustomersList, customers, paginations } = CustomerService();

  const handlePageChange = (page:number) => {
    location.state = null
    setPage(page)
    fetchRejectedCustomersList({page, ...sortOptions, search})
  }

  useEffect(() => {
    location.hash === "#rejected-customers" &&
    fetchRejectedCustomersList({page, ...sortOptions, search});
  }, [location?.hash]);

  useEffect(() => {
    setCount(paginations?.totalCount as number);
  }, [paginations]);

  return (
    <div className="rejected-customers">
      <TableComponent
      pageNumber={pageNumber ?? page}
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      onChange={fetchRejectedCustomersList}
      onSort={(data)=>{
        setSortOptions(data)
      }}
      filtersSorts={{page, search}}
      onRow={(record) =>
        navigate({pathname:
          generatePath(AppRoutes.REJECTED_CUSTOMERS_VIEW, {
            customerId: record?.id?.toString(),
          })},
          {state: {pageNumber: page}}
        )
      }
        search={{
          placeholder: "Search customer by name or phone number",
          onSearch: (value) => {
            setSearch(value)
            fetchRejectedCustomersList({
              search: value
            });
          },
        }}
        loading={loading}
        columns={[
          {
            title: "Customer Name",
            dataIndex: "fullName",
            key: SortColumnTypes.CUSTOMER_NAME,
            sorter: true,
            render: (text) => text,
            width: 200,
            ellipsis: true,
          },
          {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            render: (text, record) => `${record?.countryCode?.isdCode ?? ""} ${text}`
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: 250,
            ellipsis: true,
          },
          {
            title: "Purchasing Status",
            dataIndex: "plan",
            key: "plan",
            width: 250,
            sorter: true,
          },
          {
            title: "Onboarding Start Date",
            dataIndex: "onboardingStartDate",
            key: SortColumnTypes.ONBOARDING_START_DATE,
            render: (text: string) => moment(text)?.format("DD MMM YYYY"),
            sorter: true,
          },
          {
            title: "Kyc Status",
            dataIndex: "kycStatus",
            key: "kycStatus",
            render: (text) => <BadgeComponent type={(text === BadgeTypes.APPROVED ? BadgeTypes.KYC_APPROVED : text === BadgeTypes.FAILED ? BadgeTypes.KYC_FAILED : text === BadgeTypes.KYC_PENDING && BadgeTypes.KYC_PENDING) as BadgeTypes} />
          }
        ]}
        data={customers}
        empty={{
          img: NoCustomersImg,
          text: "No customer data",
        }}
      />
    </div>
  );
};

export default RejectedCustomers;
