import { NeighbourhoodModel } from './../../models/Meta/meta.model';
import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize, serializeAll } from "serializr";
import {axiosInstance} from "../../interceptor/axiosInstance";
import {
  VendorStorePromotionCreationModel,
  VendorStorePromotionModel,
} from "../../models/Vendor/StorePromotion/storePromotion.model";
import { VendorPurchaseConfigModel } from "../../models/Vendor/PurchaseConfig/purchaseConfig.model";
import { VendorListModel, VendorModel } from "../../models/Vendor/vendor.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { ConfigurationListModel, ConfigurationProcessingFeeModel, VendorDownpaymentStatusModel } from "../../models/Configuration/configuration.model";
import { DeallPurchaseConfig } from "../../models/DeallPurchaseConfig/deallPurchaseConfig.model";
import {MetaModel, OrderListMetaModel} from "../../models/Meta/meta.model";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import { CreditScoreCriterions } from "../../models/CreditScoreCriterions/creditScoreCriterions.model";
import { Questions } from "../../models/Questions/questions.model";
import { PaginationModel } from '../../models/pagination.model';
import { SortFilterModel } from '../../models/SortFilterModel/SortFilterModel.model';
import { CreditScoreConfig, InstallmentProcessingFeeList, RiseConfig } from '../../models/CreditScoreConfig/creditScoreConfig.model';
import { Promotion } from '../../models/Promotion/promotion.model';

const ConfigurationService = () => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [configrationList, setConfigrationList] = useState<ConfigurationListModel[]>([]);
  const [vendorOrdered, setVendorOrdered] = useState<OrderListMetaModel[]>([]);
  const [configurationProcessingFee, setConfigurationProcessingFee] = useState<ConfigurationProcessingFeeModel>();
  const [vendor, setVendor] = useState<VendorModel>();
  const [purchaseConfig, setPurchaseConfig] = useState<
    DeallPurchaseConfig
  >({});
  const [vendorStorePromotions, setVendorStorePromotions] = useState<
    VendorStorePromotionModel[]
  >([]);
  const [creditScore, setCreditScore] = useState<CreditScoreCriterions[]>()
  const [questions, setQuestions] = useState<Questions[]>()
  const [unapprovedNeighbourhoods, setUnapprovedNeighbourhoods] = useState<NeighbourhoodModel[]>([]);
  const [processingFeeList, setProcessingFeeList] = useState<InstallmentProcessingFeeList[]>([]);
  const [vendorDownpaymentStatus, setVendorDownpaymentStatus] = useState<VendorDownpaymentStatusModel[]>([]);
  const [downpaymentConfigMeta, setDownpaymentConfigMeta] = useState<CreditScoreConfig>();
  const [promotionsList, setPromotionsList] = useState<Promotion[]>([]);
  const [promotion, setPromotion] = useState<Promotion>();

  const [paginations, setPaginations] = useState<PaginationModel>()

  const fetchConfigurationList = () => {
    setLoading(true);
    return axiosInstance
    .get(ApiRoutes.CONFIGURATION_LIST)
    .then((response) => {
        const list = deserialize(
          ConfigurationListModel,
          response?.data?.["customer_purchase_configs"]
        ) as ConfigurationListModel[]

        setConfigrationList(list);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchConfigurationProcessingFee = () => {
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.VENDOR_PROCESSING_FEE)
      .then((response) => {

        const fee = deserialize(
          ConfigurationProcessingFeeModel,
          response?.data?.["vendor_processing_fee_config"]
        )
        setConfigurationProcessingFee(fee);

      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchVendorListWithOrder = (showTest: boolean) => {
    setLoading(true);
    return axiosInstance
        .get(ApiRoutes.VENDOR_LIST_WITH_ORDER, { params: {show_test: showTest} })
        .then((response) => {
          let vendorOrderModel: OrderListMetaModel[] = response?.data?.vendors.map(({id, name, order}: VendorModel) => ({
            id,
            label: name,
            order,
          }));
          setVendorOrdered(vendorOrderModel);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const updateVendorOrder = (vendorOrderModel: OrderListMetaModel[]) => {
    setLoading(true);
    let vendors = {
      vendors: vendorOrderModel.map(({id, order}) => ({
        id,
        order
      }))
    };
    return axiosInstance
        .put(ApiRoutes.UPDATE_VENDOR_ORDER, vendors)
        .then((response) => {
          Notification({
            description: 'Vendor Order Has Been Updated',
            type: NotificationTypes.SUCCESS,
          });
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const updateConfigurationList = (configureId:any,body:any) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CONFIGURATION_LIST_UPDATE, { configureId });
    return axiosInstance
      .put(API_URL,body)
      .then((response) => {
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const updateConfigurationProcessingFee = (body:any) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_PROCESSING_FEE);
    return axiosInstance
      .put(API_URL,body)
      .then((response) => {
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPurchaseConfig = () => {
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.PURCHASE_CONFIG)
      .then((response) => {
        const _purchaseConfig = deserialize(
          DeallPurchaseConfig,
          response?.data
        )
        setPurchaseConfig(_purchaseConfig);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePurchaseConfig = (purchaseConfig: DeallPurchaseConfig) => {
    let payload = {
      deall_purchase_config: serialize(DeallPurchaseConfig,purchaseConfig)
    }
    setLoading(true);
    return axiosInstance
      .put(ApiRoutes.PURCHASE_CONFIG, payload)
      .then((response) => {
        const _purchaseConfig = deserialize(
          DeallPurchaseConfig,
          response?.data
        )
        setPurchaseConfig(_purchaseConfig);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCreditScoreCriteria = () => {
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.CREDIT_SCORE)
      .then((response) => {
        const creditScore = deserialize(
          CreditScoreCriterions,
          response?.data?.credit_score_criteria
        ) as CreditScoreCriterions[]
        setCreditScore(creditScore)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCreditScore = (creditScoreConfig: CreditScoreCriterions[]) => {
    let payload = {
      credit_score_criteria: creditScoreConfig.map((config) =>
      serialize(CreditScoreCriterions, config))
    }
    setLoading(true);
    return axiosInstance
      .put(ApiRoutes.CREDIT_SCORE, payload)
      .then((response) => {
        const _creditScoreConfig = deserialize(
          CreditScoreCriterions,
          response?.data?.credit_score_criteria
        ) as CreditScoreCriterions[]
        setCreditScore(_creditScoreConfig);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCreditScoreQuestions = () => {
    setQuestions(questions)
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.GET_CREDIT_SCORE_QUESTIONS)
      .then((response) => {
        const questions = deserialize(
          Questions,
          response?.data?.questions
        ) as Questions[]
        setQuestions(questions)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const recalculateAllCreditScore = () => {
    setLoading(true)
    return axiosInstance
          .get(ApiRoutes.RECALCULATE_ALL_CREDIT_SCORE)
          .then(() => {
            Notification({
              description: "Credit score has been recalculated for all users!",
              type: NotificationTypes.SUCCESS,
            });
          })
          .catch((error) => {
            setError(error)
          })
          .finally(() => {
            setLoading(false)
          })
  }


  const recalculateCreditScoreForAUser = (customerId: string, onSuccess: () => void) => {
    setLoading(true)
    const API_URL = generatePath(ApiRoutes.RECALCULATE_CREDIT_SCORE_FOR_USER, {customer_id: customerId})
    return axiosInstance
          .get(API_URL)
          .then(onSuccess)
          .catch((error) => {
            setError(error)
          })
          .finally(() => {
            setLoading(false)
          })
  }

  const fetchPendingNeighbourhoods = (params?: SortFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    return axiosInstance
      .get(ApiRoutes.UNAPPROVED_NEIGHBOURHOODS, {params: _params})
      .then((response) => {
        const neighbourhoods = deserialize(
          NeighbourhoodModel,
          response?.data?.neighbourhoods
        ) as NeighbourhoodModel[];
        const _paginations = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(_paginations);
        setUnapprovedNeighbourhoods(neighbourhoods);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateNeighbourhood = (
    neighbourhoodData: NeighbourhoodModel,
    neighbourhoodId: string
    ) => {
    setLoading(true)
    const API_URL = generatePath(ApiRoutes.APPROVE_NEIGHBOURHOOD,{id: neighbourhoodId});
    return axiosInstance
    .put(API_URL, {neighbourhood: neighbourhoodData})
    .then()
    .catch((error) => {
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const fetchInstallmentProcessingFeeList = (configClass: string) => {
    setLoading(true);
    const queryParams = {
      config_class: configClass,
    };
    axiosInstance
      .get(ApiRoutes.RISE_CONFIG, { params: queryParams })
      .then((response) => {
        const meta = deserialize(InstallmentProcessingFeeList,response?.data?.config_json?.installments) as InstallmentProcessingFeeList[]
        setProcessingFeeList(meta)
      })
      .catch(error => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  };

  const updateProcessingFeeConfig = (config: InstallmentProcessingFeeList[], configClass: string) => {
    setLoading(true);
    const API_URL = ApiRoutes.RISE_CONFIG;
    const queryParams = {
      config_class: configClass,
    };
    const requestPayload = {rise_config: {config_json: {installments: config}}}

    return axiosInstance
      .put(API_URL, requestPayload, { params: queryParams })
      .then((response) => {
        const meta = deserialize(InstallmentProcessingFeeList,response?.data?.config_json?.installments) as InstallmentProcessingFeeList[]
        setProcessingFeeList(meta)
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  };

  const getDownpaymentConfig = () => {
    setLoading(true);
    const queryParams = {
      config_class: 'DownPaymentConfig',
    };

    axiosInstance
      .get(ApiRoutes.RISE_CONFIG, { params: queryParams })
      .then((response) => {
        const meta = deserialize(CreditScoreConfig,response?.data)
        setDownpaymentConfigMeta(meta)
      })
      .catch(error => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateDownpaymentConfig = (config: RiseConfig) => {
    setLoading(true);
    const API_URL = ApiRoutes.RISE_CONFIG;
    const queryParams = {
      config_class: 'DownPaymentConfig',
    };
    const serializePayload = serialize(RiseConfig, config)
    const requestPayload = {rise_config: serializePayload}

    return axiosInstance
      .put(API_URL, requestPayload, { params: queryParams })
      .then((response) => {
        const meta = deserialize(CreditScoreConfig,response?.data)
        setDownpaymentConfigMeta(meta)
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getVendorsDownpaymentStatus = (params?: SortFilterModel) => {
		setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
		return axiosInstance
			.get(ApiRoutes.VENDOR_DOWNPAYMENT_STATUS, {params: _params})
			.then((response) => {
				const vendorDownpaymentStatus = deserialize(VendorDownpaymentStatusModel, response?.data["vendors"]) as VendorDownpaymentStatusModel[];
        setVendorDownpaymentStatus(vendorDownpaymentStatus)
        const _paginations = deserialize(PaginationModel, response?.data["meta"])
        setPaginations(_paginations);
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

  const updateDownpaymentStatus = (
    data: boolean,
    vendorId: number,
    onSuccess?: () => void
    ) => {
    setLoading(true)
    const requestPayload = {
      vendor: { down_payment_enabled: data },
    };

    const API_URL = generatePath(ApiRoutes.UPDATE_VENDOR_DOWNPAYMENT_STATUS, {vendorId: vendorId.toString()})
    return axiosInstance
      .put(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(VendorDownpaymentStatusModel, response?.data?.vendors) as VendorDownpaymentStatusModel[];
        setVendorDownpaymentStatus(data);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getPromotionsList = (params?: SortFilterModel) => {
    const _params = params && serialize(SortFilterModel, params)
		setLoading(true);
		return axiosInstance
			.get(ApiRoutes.PROMOTIONS, { params: _params })
			.then((response) => {
				const promotionsList = deserialize(Promotion, response?.data["promotions"]) as unknown as Promotion[];
        setPromotionsList(promotionsList)
        const _paginations = deserialize(PaginationModel, response?.data["meta"])
        setPaginations(_paginations);
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

  const getPromotion = (
    promotionId: string
    ) => {
    setLoading(true)
    const API_URL = generatePath(ApiRoutes.PROMOTION, {promotionId})
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(Promotion, response?.data?.promotion) as Promotion;
        setPromotion(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const createPromotion = (promotion: Promotion, onSuccess: () => void) => {
    setLoading(true);
    let payload = {
      promotion: serialize(Promotion, promotion)
    }
    return axiosInstance
      .post(ApiRoutes.PROMOTIONS, payload)
      .then((response) => {
        onSuccess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePromotion = (promotionId: string, promotion: Promotion, onSuccess:() => void) => {
    const API_URL = generatePath(ApiRoutes.PROMOTION, { promotionId });
    let payload = {
      promotion: serialize(Promotion, promotion)
    }
    setLoading(true);
    return axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    configrationList,
    configurationProcessingFee,
    purchaseConfig,
    vendor,
    vendorOrdered,
    setVendorOrdered,
    vendorStorePromotions,
    fetchConfigurationList,
    fetchConfigurationProcessingFee,
    updateConfigurationList,
    updateConfigurationProcessingFee,
    fetchPurchaseConfig,
    updatePurchaseConfig,
    fetchVendorListWithOrder,
    updateVendorOrder,
    fetchCreditScoreCriteria,
    creditScore,
    updateCreditScore,
    fetchCreditScoreQuestions,
    questions,
    recalculateAllCreditScore,
    recalculateCreditScoreForAUser,
    fetchPendingNeighbourhoods,
    paginations,
    unapprovedNeighbourhoods,
    updateNeighbourhood,
    fetchInstallmentProcessingFeeList,
    updateProcessingFeeConfig,
    processingFeeList,
    getDownpaymentConfig,
    updateDownpaymentConfig,
    downpaymentConfigMeta,
    getVendorsDownpaymentStatus,
    vendorDownpaymentStatus,
    updateDownpaymentStatus,
    getPromotionsList,
    getPromotion,
    createPromotion,
    updatePromotion,
    promotionsList,
    promotion
  };
};

export default ConfigurationService;
