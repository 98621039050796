const ApiBasePaths = {
    USER_MANAGEMENT: "/user_management/admin",
    AUTH: "/user_management/admin/auth",
    CUSTOMER_MANAGEMENT: "/customer_management/admin",
    MOBILE_CUSTOMER_MANAGEMENT: "/customer_management/admin/customers",
    VENDOR_MANAGEMENT: "/vendor_management/admin",
    PURCHASE_MANAGEMENT: "/purchase_management/admin",
    SETTLEMENT_MANAGEMENT: "/settlements_management/admin",
    PAYMENT_MANAGEMENT: "/payment_management/admin",
    INSTORE_MANAGEMENT: "/instore_management/admin",
    VENDOR_STORE_PROMOTION_MANAGEMENT: "/vendor_promotions_management/admin",
    ADMIN_CONFIGURATION:"/meta/admin",
    ATTACHMENT: "/attachment_management/admin",
    NOTIFICATION_MANAGEMENT: "/notification_management/admin/notifications",
    DASHBOARD_MANAGEMENT: "/dashboard_management/admin/dashboards",
    META: "/meta",
    REPORT_MANAGEMENT: "/report_management/admin",
    CREDIT_SCORE_MANAGEMENT: "/credit_score_management/admin",
    QUESTIONNAIRE: "/questionnaire/admin",
    QUESTIONNAIRE_CUSTOMER: "/questionnaire/customers",
    TERMINAL_MANAGEMENT: "/terminal_management/genesis",
    ADDRESS: "/address/admin",
    ADMIN_PURCHASE_CONFIGURATION: "config/admin/config",
    PROMOTION_MANAGEMENT: "/promotion_management/admin/"
}
export const ApiRoutes = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    USER_LOGIN: ApiBasePaths.AUTH + "/login",
    USER_LOGOUT: ApiBasePaths.AUTH + "/logout",
    USER_VERIFY_TOKEN: ApiBasePaths.AUTH + "/mfa/verify_token",
    USER_FORGOT_PASSWORD: ApiBasePaths.AUTH + "/forgot_password",
    USER_NEW_PASSWORD: ApiBasePaths.AUTH + "/password",
    USER_RESET_PASSWORD_TOKEN_VALIDATE: ApiBasePaths.AUTH + "/validate_reset_password_token",
    CUSTOMER_LIST: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers",
    PROFESSIONAL_GROUP_LIST: ApiBasePaths.CUSTOMER_MANAGEMENT + "/professional_groups",
    UN_VERIFIED_CUSTOMERS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/un_verified_customers",
    WAITLISTED_CUSTOMERS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/waitlisted_customers",
    REJECTED_CUSTOMERS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/rejected_customers",
    INCOMPLETE_ONBOARDING_CUSTOMERS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/onboarding_customers",
    CUSTOMER_DETAILS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId",
    CUSTOMER_CONTACTS_STATISTICS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/customer_contact_statistics",
    CUSTOMER_CONTACTS_SMS_ANALYSIS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/sms_analysis",
    UPDATE_CUSTOMER_DETAILS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/update_customer_details",
    CUSTOMER_APPROVAL: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/verify",
    CUSTOMER_DOCUMENT_DETAILS: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customer_attachments/:documentId",
    CUSTOMER_PURCHASE_LIST: ApiBasePaths.PURCHASE_MANAGEMENT + "/customers/:customerId/customer_purchases",
    CUSTOMER_PAYMENT_LIST: ApiBasePaths.PAYMENT_MANAGEMENT + "/customers/:customerId/customer_payments",
    VENDOR_LIST: ApiBasePaths.VENDOR_MANAGEMENT + "/vendors",
    UNVERIFIED_VENDOR_LIST: ApiBasePaths.VENDOR_MANAGEMENT + "/un_verified_vendors",
    UNAPPROVED_VENDOR_INSTORE_LIST: ApiBasePaths.INSTORE_MANAGEMENT + "/un_approved_instores",
    VENDOR_DETAILS: ApiBasePaths.VENDOR_MANAGEMENT + "/vendors/:vendorId",
    VENDOR_STORE_PROMOTION_LIST: ApiBasePaths.VENDOR_STORE_PROMOTION_MANAGEMENT + "/vendors/:vendorId/store_promotions",
    VENDOR_STORE_PROMOTION_DETAILS: ApiBasePaths.VENDOR_STORE_PROMOTION_MANAGEMENT + "/store_promotions/:vendorStorePromotionId",
    VENDOR_PURCHASE_CONFIG_LIST: ApiBasePaths.VENDOR_MANAGEMENT + "/vendors/:vendorId/purchase_config",
    VENDOR_PURCHASE_CONFIG_DETAILS: ApiBasePaths.VENDOR_MANAGEMENT + "/purchase_config/:vendorPurchaseConfigId",
    UPDATE_VENDOR_DOWNPAYMENT_STATUS: ApiBasePaths.VENDOR_MANAGEMENT + "/vendors/:vendorId/update_vendor_downpayment_status",
    VENDOR_DOWNPAYMENT_STATUS: ApiBasePaths.VENDOR_MANAGEMENT + "/vendors_with_downpayment_status",
    VENDOR_INSTORE_LIST: ApiBasePaths.INSTORE_MANAGEMENT + "/vendors/:vendorId/instores",
    VENDOR_INSTORE_DETAILS: ApiBasePaths.INSTORE_MANAGEMENT + "/instores/:vendorInstoreId",
    VENDOR_INSTORE_UPDATE_STATUS: ApiBasePaths.INSTORE_MANAGEMENT + "/instores/:vendorInstoreId/status",
    FAWRY_POS_DEVICES_INFO: ApiBasePaths.INSTORE_MANAGEMENT + "/fawry_pos_devices",
    DELETE_FAWRY_POS_DEVICES: ApiBasePaths.INSTORE_MANAGEMENT + "/fawry_pos_devices/:fawryPosId",
    VENDOR_SETTLEMENT_LIST: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/vendors/:vendorId/settlements",
    META_BANK_LIST: ApiBasePaths.META + "/banks",
    META_BRANCH_LIST: ApiBasePaths.META + "/banks/:bankId/branches",
    META_CATEGORY_LIST: ApiBasePaths.ADMIN_CONFIGURATION + "/categories",
    META_SUB_CATEGORY_LIST: ApiBasePaths.ADMIN_CONFIGURATION + "/categories/:categoryId/sub_category",
    META_WALLET_TYPES: ApiBasePaths.META + "/wallet_types",
    META_PARTNERS: ApiBasePaths.ADMIN_CONFIGURATION + "/partners",
    META_REJECTION_REASON_LIST: ApiBasePaths.ADMIN_CONFIGURATION + "/user_rejections/:customerId",
    META_APPROVAL_REASON_LIST: ApiBasePaths.ADMIN_CONFIGURATION + "/approval_reasons",
    META_SUSPICION_REASON_LIST: ApiBasePaths.ADMIN_CONFIGURATION + "/suspicion_reasons",
    UNAPPROVED_CUSTOMER_DOCUMENT_LIST: ApiBasePaths.CUSTOMER_MANAGEMENT + "/un_approved_documents",
    UNAPPROVED_CUSTOMER_DOCUMENT: ApiBasePaths.CUSTOMER_MANAGEMENT + "/un_approved_documents/:customerAttachmentId",
    UPDATE_UNAPPROVED_DOCUMENT:ApiBasePaths.CUSTOMER_MANAGEMENT + "/update_customer_document_status/:customerAttachmentId",
    VIEW_DOCUMENT:ApiBasePaths.CUSTOMER_MANAGEMENT + "/attachment/:customerAttachmentId",
    VIEW_VENDOR_DOCUMENT:ApiBasePaths.VENDOR_MANAGEMENT + "/attachment/:vendorAttachmentId",
    PURCHASE_LIST: ApiBasePaths.PURCHASE_MANAGEMENT + "/customer_purchases",
    PURCHASE_DETAILS: ApiBasePaths.PURCHASE_MANAGEMENT + "/customer_purchases/:purchaseId",
    PENDING_SETTLEMENT_LIST: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/pending_settlements",
    PENDING_RETURNS_SETTLEMENT_LIST: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/pending_return_settlements",
    PENDING_SETTLEMENT_MARK_SETTLED: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/pending_settlements/mark_as_settled",
    PENDING_SETTLEMENT_MARK_SETTLED_DETAILS: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/pending_settlements/mark_as_settled_details",
    PENDING_SETTLEMENT_SPECIFIC_VENDOR: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/pending_settlements_for_vendor",
    CONFIGURATION_LIST: ApiBasePaths.ADMIN_CONFIGURATION + "/customer_purchase_configs",
    VENDOR_PROCESSING_FEE: ApiBasePaths.ADMIN_CONFIGURATION + "/vendor_processing_fee_config",
    VENDOR_LIST_WITH_ORDER: ApiBasePaths.VENDOR_MANAGEMENT + "/get_vendors_with_orders",
    UPDATE_VENDOR_ORDER: ApiBasePaths.VENDOR_MANAGEMENT + "/update_vendor_orders",
    DEALL_USERS: ApiBasePaths.USER_MANAGEMENT + "/deall_users",
    DEALL_USERS_UPDATE_DELETE: ApiBasePaths.USER_MANAGEMENT + "/deall_users/:userId",
    CONFIGURATION_LIST_UPDATE: ApiBasePaths.ADMIN_CONFIGURATION + "/customer_purchase_configs/:configureId",
    PAST_SETTLEMENT_LIST: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/past_settlements",
    SETTLEMENT_DETAILS: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/settlements/:settlementId",
    PAYMENT_LIST: ApiBasePaths.PAYMENT_MANAGEMENT + "/customer_payments",
    PAYMENT_VIEW: ApiBasePaths.PAYMENT_MANAGEMENT + "/payments_with_related_purchases",
    OUTSTANDING_PAYMENT_LIST: ApiBasePaths.PAYMENT_MANAGEMENT + "/outstanding_payments",
    OVERDUE_PAYMENT_LIST: ApiBasePaths.PAYMENT_MANAGEMENT + "/overdue_payments",
    META_VENDOR_CONFIGURATION: ApiBasePaths.META + "/admin/vendor_processing_fee_config",
    RISE_CONFIG: ApiBasePaths.ADMIN_CONFIGURATION + "/rise_configs",
    META_COUNTRY_CODES: ApiBasePaths.META + "/country_codes",
    PURCHASE_CONFIG: ApiBasePaths.ADMIN_CONFIGURATION + "/deall_purchase_configs",
    PENDING_PARTNER_FEES_LIST: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/partner_settlements/pending",
    PENDING_PARTNER_FEES_LIST_SPECIFIC_PARTNER: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/partner_settlements/pending/ids",
    PAST_PARTNER_FEES_LIST: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/partner_settlements",
    PENDING_PARTNERFEE_MARK_SETTLED: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/partner_settlements/mark_as_settled",
    PENDING_PARTNERFEE_MARK_SETTLED_DETAILS: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/partner_settlements/mark_as_settled_details",
    PARTNERFEE_DETAILS: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/partner_settlement/:settlementId",
    PENDING_COLLECTION_LIST: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/payment_collections/pending",
    PENDING_COLLECTION_LIST_SPECIFIC_PARTNER: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/payment_collections/pending/ids",
    PENDING_COLLECTION_SETTLEMENT_MARK_SETTLED_DETAILS: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/payment_collections/mark_as_settled_details",
    PAST_COLLECTION_LIST: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/payment_collections",
    PENDING_COLLECTION_MARK_SETTLED: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/payment_collections/mark_as_settled",
    COLLECTION_DETAILS: ApiBasePaths.SETTLEMENT_MANAGEMENT + "/payment_collection/:settlementId",

    // ATTACHMENTS
    S3_PRESIGNED_URL: ApiBasePaths.ATTACHMENT + "/presigned_url",
    S3_ATTACHMENT:   ApiBasePaths.ATTACHMENT + "/attachment",

    // NOTIFICATIONS
    NOTIFICATIONS: ApiBasePaths.NOTIFICATION_MANAGEMENT,
    NOTIFICATION: ApiBasePaths.NOTIFICATION_MANAGEMENT + "/:notificationId",

    // CURRENT USER
    ME: ApiBasePaths.USER_MANAGEMENT + "/me",
    // TOTP for admin
    GENERATE_TOTP: ApiBasePaths.USER_MANAGEMENT + "/mfa/generate_totp",
    VERIFY_TOTP: ApiBasePaths.USER_MANAGEMENT + "/mfa/verify_totp",

    UPDATE_VERIFICATION_DOC: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/update_verification_documents/:attachmentId",

    VERIFY_LOGIN_OTP: ApiBasePaths.AUTH + "/mfa/verify_login_otp",

    // VERIFY CUSTOMER
    CUSTOMER_NATIONAL_ID_VERIFY: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/verify_national_id",
    CUSTOMER_PROFILE_VERIFY: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/profile_verification",
    CUSTOMER_PURCHASE_POWER_CALCULATE: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/calculating_puchase_power",

    // RESET RETRY COUNT
    CUSTOMER_RESET_RETRY_COUNT: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/reset_onboarding_retry_limit",

    // VERIFY CUSTOMER MANUALLY
    MANUAL_VERIFY_CUSTOMER: ApiBasePaths.CUSTOMER_MANAGEMENT + "/customers/:customerId/verify_manually",

    // DASHBOARD
    DASHBOARD_KEY_METRICS: ApiBasePaths.DASHBOARD_MANAGEMENT + "/key_metrics",
    // Segment two
    DASHBOARD_TRANSACTION_VALUE: ApiBasePaths.DASHBOARD_MANAGEMENT + "/transaction_value",
    DASHBOARD_TRANSACTION_COUNT: ApiBasePaths.DASHBOARD_MANAGEMENT + "/transaction_count",
    DASHBOARD_TRANSACTION_PER_CUSTOMER: ApiBasePaths.DASHBOARD_MANAGEMENT + "/transactions_per_customer",
    
    // Segment three
    DASHBOARD_PAYMENT_KEY_METRICS: ApiBasePaths.DASHBOARD_MANAGEMENT + "/due_payments",
    DASHBOARD_PAYMENT_METHODS: ApiBasePaths.DASHBOARD_MANAGEMENT + "/payment_methods",
    DASHBOARD_PAYMENT_VALUE: ApiBasePaths.DASHBOARD_MANAGEMENT + "/payment_value",

    // Segment four
    DASHBOARD_TRANSACTIONS_PER_VENDOR: ApiBasePaths.DASHBOARD_MANAGEMENT + "/transactions_per_vendor",
    DASHBOARD_TRANSACTIONS_VALUE_PER_VENDOR: ApiBasePaths.DASHBOARD_MANAGEMENT + "/transactions_value_per_vendor",

    //Dahboard settlement widget
    DASHBOARD_VENDOR_SETTLEMENTS: ApiBasePaths.DASHBOARD_MANAGEMENT + "/vendor_settlements",
    DASHBOARD_PARTNER_FEES: ApiBasePaths.DASHBOARD_MANAGEMENT + "/partner_fees",
    DASHBOARD_PAYMENT_COLLECTION: ApiBasePaths.DASHBOARD_MANAGEMENT + "/payment_collection",

    //REFUND
    CREATE_REFUND: ApiBasePaths.PURCHASE_MANAGEMENT + "/create_refund/:purchaseId",
    EDIT_REFUND: ApiBasePaths.PURCHASE_MANAGEMENT + "/edit_refund/:purchaseId",
    VIEW_REFUND: ApiBasePaths.PURCHASE_MANAGEMENT + "/view_refund/:purchaseId",

    //IMEI NUMBER
    ADD_IMEI_NUMBER: ApiBasePaths.PURCHASE_MANAGEMENT + "/customer_purchases/:id/imei_number",

    // PURCHASING PLAN
    ACTIVE_AND_UPCOMING_PLAN: ApiBasePaths.MOBILE_CUSTOMER_MANAGEMENT + "/:customerId/active_and_upcoming_plan",
    ALL_PLAN_DETAILS: ApiBasePaths.MOBILE_CUSTOMER_MANAGEMENT + "/:customerId/all_plan_details",
    REFERRAL_REWARDS_METRICS: ApiBasePaths.MOBILE_CUSTOMER_MANAGEMENT + "/:customerId/referral_rewards_metrics",
    RECLASSIFY_PHONE_CUSTOMER: ApiBasePaths.MOBILE_CUSTOMER_MANAGEMENT + "/:customerId",
    CUSTOMER_CREDIT_HISTORIES: ApiBasePaths.MOBILE_CUSTOMER_MANAGEMENT + "/:customerId/credit_history",

    // KYC RETRY
    KYC_RETRY: ApiBasePaths.MOBILE_CUSTOMER_MANAGEMENT + "/:customerId/kyc_retry",
    KYC_VERIFICATION: ApiBasePaths.MOBILE_CUSTOMER_MANAGEMENT + "/:customerId/kyc_verification",

    //UPDATE NEIGHBOURHOOD
    UPDATE_NEIGHBOURHOOD: ApiBasePaths.MOBILE_CUSTOMER_MANAGEMENT + "/:customerId/update_customer_neighbourhood",
    
    // Reports
    GET_REPORT_TYPES: ApiBasePaths.REPORT_MANAGEMENT + '/report_names',
    CREAT_REPORT: ApiBasePaths.REPORT_MANAGEMENT + '/report',

    //Credit score rating
    CREDIT_SCORE: ApiBasePaths.CREDIT_SCORE_MANAGEMENT + '/credit_score_criteria',
    GET_CREDIT_SCORE_QUESTIONS: ApiBasePaths.QUESTIONNAIRE + '/questions',
    RECALCULATE_ALL_CREDIT_SCORE: ApiBasePaths.CREDIT_SCORE_MANAGEMENT + '/recalculate_all',
    RECALCULATE_CREDIT_SCORE_FOR_USER: ApiBasePaths.CREDIT_SCORE_MANAGEMENT + '/recalculate/:customer_id',

    // Rise point
    UPDATE_POS: ApiBasePaths.TERMINAL_MANAGEMENT + '/update_pos',

    //Address meta
    GET_CITIES: ApiBasePaths.QUESTIONNAIRE_CUSTOMER + '/all_cities_region_district',
    GET_REGIONS: ApiBasePaths.ADDRESS + '/list_region_by_city',
    GET_NEIGHBOURHOODS: ApiBasePaths.ADDRESS + '/list_neighbourhood_by_region',
    NEIGHBOURHOOD: ApiBasePaths.ADDRESS + '/neighbourhoods',
    APPROVE_NEIGHBOURHOOD: ApiBasePaths.ADDRESS + '/neighbourhoods/:id',
    UNAPPROVED_NEIGHBOURHOODS: ApiBasePaths.ADDRESS + '/neighbourhoods/unapproved',

    //Monitoring stats
    PAYMENT_KEY_METRICS: ApiBasePaths.DASHBOARD_MANAGEMENT + "/payment_metrics", 
    CARD_PAYMENT_KEY_METRICS: ApiBasePaths.DASHBOARD_MANAGEMENT + "/card_payment_metrics", 
    WALLET_PAYMENT_KEY_METRICS: ApiBasePaths.DASHBOARD_MANAGEMENT + "/wallet_payment_metrics", 

    // Late fee waive off
    LATE_FEE_WAIVE_OFF: ApiBasePaths.PURCHASE_MANAGEMENT + "/customer_purchase/waive_late_fee",

    //Configuration - Purchase config
    ADMIN_CUSTOMER_PROCESSING_FEE: ApiBasePaths.ADMIN_PURCHASE_CONFIGURATION + "/installment_processing_fees",

    // Configuration - Promotion Infra
    PROMOTIONS: ApiBasePaths.PROMOTION_MANAGEMENT + 'promotions',
    PROMOTION: ApiBasePaths.PROMOTION_MANAGEMENT + 'promotion/:promotionId',

}
