import { Row, Col } from 'antd'
import { FormikValues } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { serialize } from 'serializr'
import { BadgeTypes } from '../../../../enums/badgeTypes'
import { ConfigurationProcessingFeeModel } from '../../../../models/Configuration/configuration.model'
import { UserModel } from '../../../../models/User/user.model'
import { AppRoutes } from '../../../../routes/routeConstants/appRoutes'
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import CardComponent from '../../../../shared/components/CardComponent'
import IconComponent from '../../../../shared/components/IconComponent'
import { LocalStorage } from '../../../../shared/utils/localStorageHelpers'
import { LateFeeForm } from '../../Customers/CustomerList/CustomerListView/CustomerInfoCard'
import "./latefeeconfig.scss"

interface LatefeeconfigProps { }

const LateFeeConfig: FC<LatefeeconfigProps> = (props) => {
    const user = LocalStorage.getItem('USER') as UserModel;
    const navigate = useNavigate();
    const { updateConfigurationProcessingFee, fetchConfigurationProcessingFee, configurationProcessingFee } = ConfigurationService();
    const isAdmin = user?.role === BadgeTypes.ADMIN
    const [isLateFeeFormShown, setIsLateFeeFormShown] = useState(false);
  
    useEffect(() => {
      if(user?.role === BadgeTypes.VIEWER){
        navigate(AppRoutes.CUSTOMERS);
      }
      fetchConfigurationProcessingFee();
    }, []);

    const onSubmit = (value: FormikValues) => {
        let body = {
            "vendor_processing_fee_config": serialize(ConfigurationProcessingFeeModel, value)
        }
        updateConfigurationProcessingFee(body).then(() => {
            fetchConfigurationProcessingFee()
            setIsLateFeeFormShown(false)
        })
    }

    return (
        <div className="latefeeconfig">
            <div className='header'>Late fee</div>
            <CardComponent>
                <LateFeeForm
                    visible={isLateFeeFormShown}
                    closeHandler={() => setIsLateFeeFormShown(false)}
                    submitHandler={onSubmit}
                    data={configurationProcessingFee}
                />
                <Row className="late-fee">
                    <Col span={6}>
                        <div className="label">Late fee %</div>
                        <div className="value">
                            {configurationProcessingFee?.lateFee}%
                            {isAdmin && <IconComponent name="icon-edit-blue" onClick={() => {
                                setIsLateFeeFormShown(true)
                            }
                            }/>}
                        </div>
                    </Col>
                </Row>
            </CardComponent>
        </div>
    )
}

export default LateFeeConfig