import React, { FC, useEffect, useState } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { BadgeTypes } from '../../../../enums/badgeTypes'
import { AppRoutes } from '../../../../routes/routeConstants/appRoutes'
import BadgeComponent from '../../../../shared/components/BadgeComponent'
import ButtonComponent from '../../../../shared/components/ButtonComponent'
import TableComponent from '../../../../shared/components/TableComponent'
import PromotionForm from './PromotionForm'
import "./promotions.scss"
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import moment from 'moment'
import { PromotionTypes } from '../../../../enums/promotionTypes'
import { PromotionTypesDefinition } from '../../../../definitions/promotionTypesDefinition'
import { StateProps } from '../../../../models/Meta/meta.model'

interface PromotionsProps {
  setCount: React.Dispatch<React.SetStateAction<number>>
 }

const Promotions: FC<PromotionsProps> = ({ setCount }) => {
    const { promotionsList, loading, getPromotionsList, paginations } = ConfigurationService();
    const navigate = useNavigate();
    const location = useLocation();
    const stateValue = location?.state as StateProps;
    const pageNumber = location.hash === "#promotion-config" ? stateValue?.pageNumber as number : null
    const [showPromotionForm, setShowPromotionForm] = useState(false);
    const [page, setPage] = useState(pageNumber ?? 1)

    const action = <ButtonComponent type="primary" size="medium" onClick={() => setShowPromotionForm(true)} >New Promotion </ButtonComponent>;

    const handlePageChange = (page:number) => {
      location.state = null
      setPage(page)
      getPromotionsList({page})
    }
  

    useEffect(() => {
      location.hash === "#promotion-config" && getPromotionsList({page});
    }, [location.hash])

    useEffect(() => {
      setCount(paginations?.totalCount as number);
    }, [paginations]);

    return (
        <div className="promotions">
        <PromotionForm visible={showPromotionForm} closeHandler={() => setShowPromotionForm(false)} successHandler={() => {setShowPromotionForm(false); getPromotionsList()}} />
            <TableComponent
                title='Promotions'
                onRow={(record)=>
                    navigate(
                      {pathname: generatePath(AppRoutes.PROMOTION_VIEW, {
                        promotionId: record?.id,
                      })}, {state: {pageNumber: page}}
                    )
                  }
                action={action}
                loading={loading}
                isSettlements
                data={promotionsList}
                paginationMeta={paginations}
                handlePageChange={handlePageChange}
                columns={[
                {
                    dataIndex: "promoId",
                    key: "promoId",
                    title: "Id",
                },
                {
                    dataIndex: "title",
                    key: "title",
                    title: "Name",
                },
                {
                    dataIndex: "discountType",
                    key: "discountType",
                    title: "Type",
                    render: (text) => <span>{PromotionTypesDefinition[text as PromotionTypes]}</span>

                },
                {
                    dataIndex: ["promoConfig", "feePercent"],
                    key: "promoConfig.feePercent",
                    title: "Value",
                    render: (text, record) => {
                      const { discountType, promoConfig } = record;
                      if (discountType === 'fee_percentage') {
                        const feePercent = promoConfig?.feePercent;
                        return (
                          <span>
                            {Object.values(feePercent).filter(percentage => percentage !== undefined)?.join(', ')} %
                          </span>
                        );
                      } else {
                        return <span>{promoConfig?.purchasePercent ?? promoConfig?.feePercent?.all}%</span>;
                      }
                    }
                  },                  
                {
                    dataIndex: "startData",
                    key: "startDate",
                    title: "Time range",
                    render: (text, record) => <span>{`${moment(record?.startDate).format("DD MMM YYYY")} -  ${moment(record?.endDate).format("DD MMM YYYY")}`}</span>

                },
                {
                    dataIndex: "status",
                    key: "status",
                    title: "Status",
                    render: (text) => <BadgeComponent type={text as BadgeTypes}/>
    
                }
                ]}/>
        </div>
    )
}

export default Promotions