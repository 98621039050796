import { Tabs } from "antd";
import React, {FC, useEffect, useState} from "react";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import "./configuration.scss";
import PurchaseStatus from "./PurchaseStatus";
import VendorConfiguration from "./VendorConfiguration";
import IconImage from "./../../../assets/images/info-fill.svg";
import {UserModel} from "../../../models/User/user.model";
import {BadgeTypes} from "../../../enums/badgeTypes";
import { AuthContext } from "../../../context/AuthContext";
import CreditScoreConfiguration from "./CreditScoreConfiguration";
import ReviewNeighbourhood from "./ReviewNeighbourhood";
import CustomerProcessingFeeConfiguration from "./CustomerProcessingFee";
import DownpaymentConfiguration from "./DownpaymentConfiguration";
import VendorDownpaymentStatus from "./VendorDownpaymentStatus";
import LateFeeConfig from "./LateFeeConfig";
import TabCountComponent from "../../../shared/components/TabCountComponent";
import Promotions from "./Promotions";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";

interface ConfigurationProps { }
const { TabPane } = Tabs;
const Configuration: FC<ConfigurationProps> = (props) => {
  const user = LocalStorage.getItem('USER') as UserModel
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthenticated } = AuthContext();
  const isAdminManager = user?.role !== BadgeTypes.VIEWER 
  const [neighbourhoodCount, setNeighbourhoodCount] = useState<number>(0);
  const [promotionCount, setPromotionCount] = useState<number>(0);
  const [showTestData, setShowTestData] = useState(LocalStorage.getItem("IS_TEST"))
  const handleTabChange = (e: string) => {
    const isTest = e==="test";
    setShowTestData(isTest)
    setAuthenticated({...user, test: isTest})
    LocalStorage.setItem("IS_TEST", isTest)
  }

  useEffect(() => {
    if (!location?.hash)
      navigate({ pathname: AppRoutes.CONFIGURATION, hash: "general-config" }, { replace: true });
  }, [location]);

  return (
    <PageView
      title={{
        name: "Configuration",
        icon: <IconComponent name="icon-setting" />,
      }}
    >
      <Tabs
        className="config-list-view"
        activeKey={location.hash?.replace(/#/gi, "")}
        onTabClick={(value) => {
          navigate({ pathname: location.pathname, hash: value });
        }}
      >
        <TabPane key="general-config" tab="General config" forceRender>
          <div className="data-type">
          <Tabs className="antd-whole-tab" defaultActiveKey={LocalStorage.getItem("IS_TEST")?"test":"live"} onChange={handleTabChange}>
            <TabPane tab="Live Data" key="live" />
            <TabPane tab="Test Data" key="test" />
          </Tabs>
          <span className="info-text">
            <img className="icon-image" src={IconImage} alt="" /> The admin application displays {showTestData ? "test" : "real-time"} data
          </span>
          </div>
          {isAdminManager && <>
          <PurchaseStatus />
          <CreditScoreConfiguration /></>}
        </TabPane>
      {isAdminManager &&
      <>
        <TabPane key="purchase-config" tab="Purchase config" forceRender>
          <CustomerProcessingFeeConfiguration type="Phone" />
          <CustomerProcessingFeeConfiguration type="Credit" />
          <LateFeeConfig />
          <DownpaymentConfiguration />
          <VendorDownpaymentStatus />
        </TabPane>

        <TabPane key="vendor-config" tab="Vendor config" forceRender>
          <VendorConfiguration />
        </TabPane>

        <TabPane key="review-neighbourhoods" forceRender tab={
            <TabCountComponent
              title="Review neighbourhoods"
              count={neighbourhoodCount ?? 0}
            />
          }>
          <ReviewNeighbourhood setCount={setNeighbourhoodCount} />
        </TabPane>
        <TabPane key="promotion-config" forceRender tab={
            <TabCountComponent
              title="Promotions"
              count={promotionCount ?? 0}
            />
          }>
          <Promotions setCount={setPromotionCount} />
        </TabPane>
        </>}
      </Tabs>
    </PageView>
  );
};

export default Configuration;
