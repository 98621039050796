import { Select } from "antd";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import UserService from "../../../../../services/AuthService/auth.service";
import { LocalStorage } from "../../../../utils/localStorageHelpers";
import InputField from "../../../InputField";
import ModalComponent from "../../../ModalComponent";
import "./profileEditForm.scss";
import { profileEditFormValidation } from "./profileEditFormValidation";
import { generateCodeOptions, getCountryIsdCode } from "../../../../utils/generateCountryCodeOptions";
import MetaService from "../../../../../services/MetaService/meta.service";
import { useLocation, useNavigate } from "react-router-dom";

interface ProfileEditFormProps {
  visible: boolean;
  closeHandler: () => void;
}

const ProfileEditForm: FC<ProfileEditFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {editProfile, loading, error} = UserService(navigate, location);
  const { getCountryCodesMeta, countryCodesMeta } = MetaService();
  const { visible, closeHandler } = props;
  const admin = AuthContext()
  const [initialValues, setInitialValues] = useState<any>(null);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const onSubmit = (values:FormikValues) => {
    editProfile(values,()=>{
      closeHandler()
    })
  };
  const resetFormAndClose = () =>{
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }
  const user = admin?.user ?? LocalStorage.getItem('USER')
  useEffect(()=>{
    visible && getCountryCodesMeta()
  }, [visible])
  useEffect(() => {
    setInitialValues({...user})
  }, [user, visible])
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={profileEditFormValidation}
    >
      {({handleSubmit, setFieldValue, values}) => (
        <ModalComponent
          type="small"
          visible={visible}
          closeHandler={resetFormAndClose}
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          title={"Edit Profile"}
        >
          <div className="profile-edit-form">
            <Form>
              <InputField
                name="firstName"
                label="First Name"
                placeholder="Enter"
              />
              <InputField
                name="lastName"
                label="Last Name"
                placeholder="Enter"
              />
              <InputField name="email" label="Email" placeholder="Enter" />
              <InputField
                  addonBefore={<Select
                    value={values?.countryCodeId ?? ''}
                    onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                      if(typeof value !== "string"){
                        setFieldValue("countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                        setFieldValue("countryCodeId", value)
                      }
                      setFieldValue("mobileNumber", undefined)
                    }}
                    options={generateCodeOptions(countryCodesMeta)}
                    />
                  }
                  name="mobileNumber"
                  label="Phone Number"
                  placeholder="Enter" />
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default ProfileEditForm;
