// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/login_gradient.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth .landing-image__container {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-position: 50%;
  background-size: cover;
  height: 100vh;
  padding: 40px;
  overflow: hidden;
}
.auth .landing-image__container .logo {
  width: 244px;
  height: 64px;
}
.auth .landing-image__container .content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth .landing-image__container .content .bird {
  width: 394px;
  height: 341px;
}
.auth .landing-image__container .content .register {
  width: 524px;
  height: 499px;
}
.auth h2 {
  font-weight: bold;
  margin-bottom: 16px;
}
.auth .auth-bg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.auth .auth-form__container {
  padding: 15% 15% 0;
  margin-top: 80px;
}
.auth .auth-form__container .input-field {
  margin-top: 32px;
}
.auth .auth-form__container .auth-form__btn {
  margin-top: 40px;
  height: 50px !important;
}

@media screen and (max-width: 1024px) {
  .auth {
    overflow-y: visible;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/Auth/auth.scss"],"names":[],"mappings":"AACI;EACI,6DAAA;EACA,wBAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;AAAR;AACQ;EACE,YAAA;EACA,YAAA;AACV;AACQ;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACV;AAAU;EACE,YAAA;EACA,aAAA;AAEZ;AAAU;EACE,YAAA;EACA,aAAA;AAEZ;AAEI;EACI,iBAAA;EACA,mBAAA;AAAR;AAEI;EACI,YAAA;EACA,WAAA;EACA,iBAAA;AAAR;AAEI;EACI,kBAAA;EACA,gBAAA;AAAR;AACQ;EACI,gBAAA;AACZ;AACQ;EACI,gBAAA;EACA,uBAAA;AACZ;;AAIA;EACI;IACI,mBAAA;EADN;AACF","sourcesContent":[".auth {\n    .landing-image__container {\n        background: url(\"./../../assets/images/login_gradient.png\") no-repeat;\n        background-position: 50%;\n        background-size: cover;\n        height: 100vh;\n        padding: 40px;\n        overflow: hidden;\n        .logo{\n          width: 244px;\n          height: 64px;\n        }\n        .content {\n          height: 100%;\n          display: flex;\n          align-items: center;\n          justify-content: center;\n          .bird {\n            width: 394px;\n            height: 341px;\n          }\n          .register{\n            width: 524px;\n            height: 499px;\n          }\n        }\n      }\n    h2 {\n        font-weight: bold;\n        margin-bottom: 16px;\n    }\n    .auth-bg {\n        height: 100%;\n        width: 100%;\n        object-fit: cover;\n    }\n    .auth-form__container {\n        padding: 15% 15% 0;\n        margin-top: 80px;\n        .input-field {\n            margin-top: 32px;\n        }\n        .auth-form__btn {\n            margin-top: 40px;\n            height: 50px !important;\n        }\n    }\n}\n\n@media screen and (max-width: 1024px) {\n    .auth {\n        overflow-y: visible;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
