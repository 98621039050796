
import { serializable, alias, object, list, primitive } from 'serializr';
import { SortColumnTypes } from '../../shared/types/SortColumn.type';
import { getTimezoneISOString } from '../../shared/utils/getTimezoneISOString';
import { PurchaseListFilterModel } from '../Purchase/purchase.model';

export class SortFilterModel {

	@serializable(alias('search', primitive()))
	search?: string;

	@serializable(alias('page', primitive()))
	page?: number;

	@serializable(alias('status', primitive()))
	status?: string;
	
	@serializable(alias('attachment_status', primitive()))
	attachmentStatus?: string;

	@serializable(alias('sort_column', primitive()))
	sortColumn?: SortColumnTypes;

	@serializable(alias('sort_by', primitive()))
	sortBy?: "asc" | "desc" | "pending" |"";

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('role', primitive()))
	role?: string;

	@serializable(alias('type', primitive()))
	type?: string;

	@serializable(alias('show_test', primitive()))
	showTest?: boolean;

	@serializable(alias('pos_type', primitive()))
	posType?: string;

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('start_date', primitive()))
	startDate?: string;

	@serializable(alias('end_date', primitive()))
	endDate?: string;

	@serializable(alias('start_timestamp', primitive()))
	startTimeStamp?: string;

	@serializable(alias('end_timestamp', primitive()))
	endTimeStamp?: string;

	@serializable(alias('purchase_start_timestamp', primitive()))
	purchaseStartTimeStamp?: string;

	@serializable(alias('purchase_end_timestamp', primitive()))
	purchaseEndTimeStamp?: string;
	
	@serializable(alias('purchase_start_date', primitive()))
	purchaseStartDate?: string;

	@serializable(alias('purchase_end_date', primitive()))
	purchaseEndDate?: string;

	@serializable(alias('transaction_type', primitive()))
	transactionType?: string;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('payment_id', primitive()))
	paymentId?: string;

	@serializable(alias('vendor_id', primitive()))
	vendorId?: number;

	@serializable(alias('collection_settlement_id', primitive()))
	collectionSettlementId?: string;

	@serializable(alias('pagination', primitive()))
    pagination?: boolean;

	@serializable(alias('ids', list(primitive())))
	pendingSettlementIds?: number[];

	@serializable(alias('partner_id', primitive()))
	partnerId?: string;

	@serializable(alias('admin_id', primitive()))
	adminId?: string;
	
	@serializable(alias('customer_attachment_id', primitive()))
	customerAttachmentId?: string;

	@serializable(alias("vendor_name", primitive()))
	vendorName?: string;

	@serializable(alias("created_at", primitive()))
	createdAt?: string;

	@serializable(alias("customer_name", primitive()))
	customerName?: string;

	@serializable(alias("plan", primitive()))
	plan?: string;

	@serializable(alias("customer_payment_status", primitive()))
	customerPaymentStatus?: string;

	@serializable(alias("purchase_status", primitive()))
	purchaseStatus?: string;

	@serializable(alias("national_id", primitive()))
	nationalId?: number;

	@serializable(alias("imei_number", primitive()))
	imeiNumber?: number;

	constructor(data?: SortFilterModel){
		if(data?.startDate) this.startTimeStamp = getTimezoneISOString(data?.startDate, true)
		if(data?.endDate) this.endTimeStamp = getTimezoneISOString(data?.endDate, false)
		if(data?.purchaseStartDate) this.purchaseStartTimeStamp = getTimezoneISOString(data?.purchaseStartDate, true)
		if(data?.purchaseEndDate) this.purchaseEndTimeStamp = getTimezoneISOString(data?.purchaseEndDate, false)
	}

	@serializable(alias('kyc_status', primitive()))
	kycStatus?: string;
}