import React, {FC, Fragment, useEffect, useState} from "react";
import {List} from "antd";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    VerticalAlignBottomOutlined,
    VerticalAlignTopOutlined
} from "@ant-design/icons";
import ButtonComponent from "../ButtonComponent";
import "./reorderListComponent.scss";
import {OrderListMetaModel} from "../../../models/Meta/meta.model";

interface ReorderListProps {
    items: OrderListMetaModel[];
    label?: string;
    className?: string;
    onReorder: (updatedItems: OrderListMetaModel[]) => void;
}

const ReorderListComponent: FC<ReorderListProps> = (props) => {
    const {items, label, className, onReorder} = props;
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    const handleMoveUp = () => {
        if (selectedItems.length > 0) {
            const updatedItems = [...items];

            // Sort selected items in descending order to avoid index issues during reordering
            const sortedSelectedItems = selectedItems.slice().sort();

            sortedSelectedItems.forEach((index) => {
                if (index > 0) {
                    // Swap the order values without using an extra variable
                    updatedItems[index].order = updatedItems[index].order + updatedItems[index - 1].order;
                    updatedItems[index - 1].order = updatedItems[index].order - updatedItems[index - 1].order;
                    updatedItems[index].order = updatedItems[index].order - updatedItems[index - 1].order;
                    [updatedItems[index - 1], updatedItems[index]] = [
                        updatedItems[index],
                        updatedItems[index - 1]
                    ];
                }
            });

            onReorder(updatedItems);
            setSelectedItems(sortedSelectedItems.map((index) => index - 1));
        }
    };

    const handleMoveDown = () => {
        if (selectedItems.length > 0) {
            const updatedItems = [...items];

            // Sort selected items in ascending order to avoid index issues during reordering
            const sortedSelectedItems = selectedItems.slice().sort((a, b) => b - a);

            sortedSelectedItems.forEach((index) => {
                if (index < items.length - 1) {
                    // Swap the order values without using an extra variable
                    updatedItems[index].order = updatedItems[index].order + updatedItems[index + 1].order;
                    updatedItems[index + 1].order = updatedItems[index].order - updatedItems[index + 1].order;
                    updatedItems[index].order = updatedItems[index].order - updatedItems[index + 1].order;
                    [updatedItems[index], updatedItems[index + 1]] = [
                        updatedItems[index + 1],
                        updatedItems[index]
                    ];
                }
            });

            onReorder(updatedItems);
            setSelectedItems(sortedSelectedItems.map((index) => index + 1));
        }
    };

    const handleMoveToTop = () => {
        if (selectedItems.length > 0) {
            const updatedItems = [...items];

            // Sort selected items in descending order
            const sortedSelectedItems = selectedItems.slice().sort((a, b) => b - a);

            sortedSelectedItems.forEach((index, i) => {
                const [movedItem] = updatedItems.splice(index, 1);
                updatedItems.unshift(movedItem);
            });
            // Update order numbers after moving to the top
            updatedItems.forEach((item, i) => {
                item.order = i + 1;
            });

            onReorder(updatedItems);
            setSelectedItems(Array.from({length: sortedSelectedItems.length}, (_, index) => index));
        }
    };

    const handleMoveToBottom = () => {
        if (selectedItems.length > 0) {
            const updatedItems = [...items];

            // Sort selected items in ascending order
            const sortedSelectedItems = selectedItems.slice().sort((a, b) => a - b);


            sortedSelectedItems.forEach((index, i) => {
                const [movedItem] = updatedItems.splice(index - i, 1);
                updatedItems.push(movedItem);
            });
            // Update order numbers after moving to the bottom
            updatedItems.forEach((item, i) => {
                item.order = i + 1;
            });

            onReorder(updatedItems);
            const startNumber = updatedItems.length - sortedSelectedItems.length;
            setSelectedItems(Array.from({ length: sortedSelectedItems.length }, (_, index) => startNumber + index));
        }
    };

    const handleSelectItem = (index: number) => {
        if (selectedItems.includes(index)) {
            setSelectedItems(selectedItems.filter((item) => item !== index));
        } else {
            setSelectedItems([...selectedItems, index]);
        }
    };

    useEffect(() => {
        // If the number of items changes, update selected items to match the new indices
        setSelectedItems(selectedItems.map((index) => Math.min(items.length - 1, index)));
    }, [items.length]);

    return (
        <Fragment>
            <div className="lst-container">
                <div className="list">
                    <List
                        dataSource={items}
                        renderItem={(item, index) => (
                            <List.Item
                                className={`list-item ${selectedItems.includes(index) ? "selected-item" : ""}`}
                                onClick={() => handleSelectItem(index)}>
                                <div>{item.label}</div>
                            </List.Item>
                        )}
                    />
                </div>
                <div className="lst-btns-container">
                    <ButtonComponent
                        type="primary"
                        className="lst-btn"
                        size="icon-only"
                        icon={<VerticalAlignTopOutlined/>}
                        onClick={handleMoveToTop}
                    />
                    <ButtonComponent
                        type="primary"
                        className="lst-btn"
                        size="icon-only"
                        icon={<ArrowUpOutlined/>}
                        onClick={handleMoveUp}
                    />
                    <ButtonComponent
                        type="primary"
                        className="lst-btn"
                        size="icon-only"
                        icon={<ArrowDownOutlined/>}
                        onClick={handleMoveDown}
                    />
                    <ButtonComponent
                        type="primary"
                        className="lst-btn"
                        size="icon-only"
                        icon={<VerticalAlignBottomOutlined/>}
                        onClick={handleMoveToBottom}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default ReorderListComponent;
