import { Moment } from "moment";
import { Location } from "react-router-dom";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";

interface ClearFiltersParams {
  resetForm: (params: { values: Record<string, unknown> }) => void;
  setPage: (page: number) => void;
  handleFilterClose: () => void;
  setFilters: (callback: () => Record<string, unknown>) => void;
  filterOptions: SortFilterModel | null | undefined;
  filters: SortFilterModel | null | undefined;
  location: Location;
  setDateRange?: (dateRange: [Moment | null, Moment | null]) => void
}

export const clearFilters = ({
  resetForm,
  setPage,
  handleFilterClose,
  setFilters,
  filterOptions,
  filters,
  location,
  setDateRange,
}: ClearFiltersParams): void => {
  (location as Location).state = null;
  filterOptions = null;

  setPage(1);
  handleFilterClose();
  setDateRange && setDateRange([null, null]);
  setFilters(() => ({
    ...filterOptions,
    search: filters?.search,
  }));
  resetForm({ values: {} });
};
