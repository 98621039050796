import { FormikProps, FormikValues, Formik, Form } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react'
import { CreditScoreConfig } from '../../../../models/CreditScoreConfig/creditScoreConfig.model';
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service';
import CardComponent from '../../../../shared/components/CardComponent'
import IconComponent from '../../../../shared/components/IconComponent'
import InputField from '../../../../shared/components/InputField';
import ModalComponent from '../../../../shared/components/ModalComponent';
import { downpaymentFormValidation } from './downpaymentFormValidation';
import "./downpaymentconfiguration.scss"

interface DownpaymentConfigurationProps { }

interface DownpaymentConfigFormProps {
    visible: boolean;
    closeHandler: () => void;
    data?: CreditScoreConfig; 
    handleRefresh: () => void;
    type: 'Minimum' | 'Maximum';
 }

const DownpaymentConfigForm: FC<DownpaymentConfigFormProps> = ({visible, closeHandler, data, handleRefresh, type}) => {
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const isMinimum = type === 'Minimum'
    const minDownPaymentPercent = data?.configJson?.minDownPaymentPercent;
    const maxDownPaymentPercent = data?.configJson?.maxDownPaymentPercent;
    const minimumInitialValues = {
      downpayment: minDownPaymentPercent,
    };
    const maximumInitialValues = {
        downpayment: maxDownPaymentPercent,
    };
    const initialValues = isMinimum ? minimumInitialValues : maximumInitialValues
    const { updateDownpaymentConfig, loading } = ConfigurationService();
    const onSubmit = (values: FormikValues) => {

      isMinimum ? updateDownpaymentConfig({configJson: {minDownPaymentPercent: Number(values?.downpayment), maxDownPaymentPercent: maxDownPaymentPercent ?? 0}}) : updateDownpaymentConfig({configJson: {maxDownPaymentPercent: Number(values?.downpayment), minDownPaymentPercent: minDownPaymentPercent ?? 0}})
        handleRefresh()
        resetFormAndClose()
    };
  
    const resetFormAndClose = () => {
      formRef?.current?.resetForm({ values: {} });
      closeHandler();
    };

    return (
        <Formik
        initialValues={initialValues}
        enableReinitialize
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={downpaymentFormValidation(type, maxDownPaymentPercent, minDownPaymentPercent)}
      >
        {({ handleSubmit, errors }) => (
          <ModalComponent
            visible={visible}
            type="small"
            title={`Edit ${type.toLocaleLowerCase()} down payment`}
            successProps={{
              title: "Update",
              clickHandler: handleSubmit,
              loading: loading, 
            }}
            cancelProps={{
              title: "Discard",
              clickHandler: resetFormAndClose,
            }}
            closeHandler={resetFormAndClose}
          >
            <Form>
              <InputField placeholder="Enter" label={`${type} down payment`} name="downpayment" />
            </Form>
          </ModalComponent>
        )}
      </Formik>
    )
}

const DownpaymentConfiguration: FC<DownpaymentConfigurationProps> = (props) => {

    const { getDownpaymentConfig, updateDownpaymentConfig, downpaymentConfigMeta, loading } = ConfigurationService();
    const [openMinDownpaymentForm, setOpenMinDownpaymentForm] = useState(false);
    const [openMaxDownpaymentForm, setOpenMaxDownpaymentForm] = useState(false);

    useEffect(() => {
        getDownpaymentConfig();
    }, [])

    return (
        <div className="downpaymentconfiguration">
            <DownpaymentConfigForm visible={openMinDownpaymentForm} closeHandler={() => setOpenMinDownpaymentForm(false)} data={downpaymentConfigMeta} handleRefresh={getDownpaymentConfig} type='Minimum' />
            <DownpaymentConfigForm visible={openMaxDownpaymentForm} closeHandler={() => setOpenMaxDownpaymentForm(false)} data={downpaymentConfigMeta} handleRefresh={getDownpaymentConfig} type='Maximum' />
            <div className='header'>Down payment</div>
            <CardComponent className='downpayment-card'>
                <div className='minimum-threshold'>
                    <div className='label'>Minimum down payment %</div>
                    <div className='value'>{downpaymentConfigMeta?.configJson?.minDownPaymentPercent ?? 0}% <IconComponent name="icon-edit-blue" onClick={() => setOpenMinDownpaymentForm(true)} />
                    </div>
                </div> 
                <div className='maximum-threshold'>
                    <div className='label'>Maximum down payment %</div>
                    <div className='value'>{downpaymentConfigMeta?.configJson?.maxDownPaymentPercent ?? 0}% <IconComponent name="icon-edit-blue" onClick={() => setOpenMaxDownpaymentForm(true)} />
                    </div>
                </div> 
            </CardComponent>
        </div>
    )
}

export default DownpaymentConfiguration