import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import UserService from "../../../services/AuthService/auth.service";
import "./otpForm.scss";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import {otpValidationSchema} from "./OtpValidation";
import {VerifyTokenRequestModel} from "../../../models/Auth/auth.model";
import {LocalStorage} from "../../../shared/utils/localStorageHelpers";
import { useLocation, useNavigate } from "react-router-dom";

interface otp {
  code: string;
}

const initialValue = {
  otp: "",
};
interface OtpFormProps {
  submitHandler?: Function;
}

const OtpForm = ({ submitHandler }: OtpFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, verifyToken } = UserService(navigate, location);
  const message: string = location.state as string;
  const onSubmit = (user: OtpFormProps) => {
    const email = LocalStorage.getItem("USER")?.email;
    const payload: VerifyTokenRequestModel = { ...user, email };
    verifyToken(payload);
    submitHandler && submitHandler(user);
  };

  return (
    <div className="login-form">
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={otpValidationSchema}
      >
        {({ isValid, values }) => (
          <Form>
            <InputField
              type="text"
              name="code"
              placeholder="Code"
              label={message}
            />

            <ButtonComponent
              type="primary"
              htmlType="submit"
              className="auth-form__btn"
              size="wide"
              loading={loading}
            >
              Verify
            </ButtonComponent>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OtpForm;
