import { useLocation } from 'react-router-dom';
import { StateProps } from '../../models/Meta/meta.model';

const useLocationState = () => {
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = stateValue?.pageNumber ?? 1;
  const filterOptions = stateValue?.filterOptions ?? null;
  const pageFrom = stateValue?.from ?? null;

  return { pageNumber, filterOptions, pageFrom };
};

export default useLocationState;
