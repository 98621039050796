import { Row, Col } from 'antd'
import { FormikProps, FormikValues, Formik, Form } from 'formik'
import React, { FC, useEffect, useRef, useState } from 'react'
import { serialize } from 'serializr'
import { zoneRankOptions } from '../../../../enums/zoneScoreOptions'
import { NeighbourhoodModel, RegionsModel } from '../../../../models/Meta/meta.model'
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import CustomerService from '../../../../services/CustomerService/customer.service'
import DropdownField from '../../../../shared/components/DropdownField'
import InputField from '../../../../shared/components/InputField'
import ModalComponent from '../../../../shared/components/ModalComponent'
import TableComponent from '../../../../shared/components/TableComponent'
import { reviewNeighbourhoodSchema } from './reviewNeighbourhoodSchema'
import "./reviewNeighbourhood.scss"
import TabCountComponent from '../../../../shared/components/TabCountComponent'
import { ConfigurationNeighbourhoodModel } from '../../../../models/Configuration/configuration.model'

interface ReviewNeighbourhoodProps {}

interface ReviewNeighbourhoodFormProps
{
    visible: boolean;
    closeHandler: () => void;
    data?: NeighbourhoodModel;
    callback: Function;
  }

const ReviewNeighbourhoodForm: FC<
ReviewNeighbourhoodFormProps
> = (props) => {
  const { visible, closeHandler, data, callback } = props;
  const [initialValues, setInitialValues] = useState<NeighbourhoodModel>({});
  const [city, setCity] = useState<number>();
  const [region, setRegion] = useState<number>();
  const [regionsList, setRegionsList] = useState<RegionsModel[]>([]);
  const { loading, updateNeighbourhood } = ConfigurationService()
  const { fetchCities, cities, fetchRegions, regions } = CustomerService()
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const cityId = data?.city?.value
  const regionsForCityId = cities?.find(city => city.value === cityId)?.regions;

  const resetFormAndClose = () =>{
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }

  useEffect(() => {
    if (data) {
      setInitialValues({
        value: data.value,
        cityId: data?.city?.value,
        regionId: data?.region?.value,
        label: data?.label,
        zoneScore: data?.zoneScore
      })
    }
  }, [data, visible])

  useEffect(() => {
    fetchCities()
  }, [])

  useEffect(() => {
    const getRegions = async () => {
      if (city) {
        const fetchedRegions: RegionsModel[] = await fetchRegions(city.toString());
        setRegionsList(fetchedRegions);
      }
    };
  
    getRegions();
}, [city]);

  useEffect(() => {
    regionsForCityId && setRegionsList(regionsForCityId)
  }, [regionsForCityId])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={() => {}}
      validationSchema={reviewNeighbourhoodSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, validateForm }) => (
        <ModalComponent
          visible={visible}
          closeHandler={resetFormAndClose}
          type="small"
          title="Review neighbourhood"
          successProps={{
            title: "Update",
            clickHandler: () => {
                validateForm(values).then((errors) => {
                  if (Object.entries(errors).length) {
                    handleSubmit()
                  } else {
                    let body = serialize(NeighbourhoodModel,{...values, approved: true})
                    updateNeighbourhood(body, values.value).then(()=>{
                      closeHandler()
                      callback()
                    })
                  }
                })
            },
            loading: loading
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          className="purchase-config-form-modal"
        >
          <Form>
            <div className="purchase-config-form">
              <Row gutter={16}>
                <Col span={24}>
                    <DropdownField
                        label="City"
                        placeholder="Select"
                        name="cityId"
                        required
                        value={values?.cityId}
                        options={cities ?? []}
                        onChange={(value) => {
                            setFieldValue("cityId", value);
                            setCity(value);
                            setFieldValue("regionId",undefined);
                        }}
                    />
                </Col>
                <Col span={24}>
                    <DropdownField
                        label="Region"
                        placeholder="Select"
                        name="regionId"
                        required
                        disabled={!values?.cityId}
                        value={values?.regionId}
                        options={regionsList ?? []}
                        onChange={(value) => {
                            setFieldValue("regionId", value);
                            setRegion(value);
                        }}
                    />
                </Col>
                <Col span={24}>
                    <InputField placeholder='Enter neighbourhood' label='Neighbourhood' name='label' required />
                </Col>
                <Col span={24}>
                    <DropdownField
                    label="Neighbourhood Score"
                    placeholder="Select"
                    name="zoneScore"
                    required
                    value={values?.zoneScore}
                    options={zoneRankOptions}
                    onChange={(value) => setFieldValue("zoneScore", value)}
                    />
                </Col>
              </Row>
            </div>
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

const ReviewNeighbourhood: FC<ReviewNeighbourhoodProps> = (props) => {

    const { } = props
    const { fetchPendingNeighbourhoods, unapprovedNeighbourhoods, loading, paginations } = ConfigurationService()
    const [isNeighbourhoodReviewShown, setIsNeighbourhoodReviewShown] = useState(false)
    const [selectedVal, setSelectedVal] = useState()
    const [page, setPage] = useState(1)

    useEffect(() => {
        fetchPendingNeighbourhoods()
    }, [])

    const handlePageChange = (page:number) => {
      setPage(page)
      fetchPendingNeighbourhoods({page})
    }

    return (
        <div className="reviewneighbourhood">
            <ReviewNeighbourhoodForm
                visible={isNeighbourhoodReviewShown}
                closeHandler={() => setIsNeighbourhoodReviewShown(false)}
                data={selectedVal}
                callback={()=>fetchPendingNeighbourhoods()}
            />
            <div className="label"><TabCountComponent title='Review neighbourhoods' count={paginations?.totalCount ?? 0}  /></div>
            <TableComponent
            loading={loading}
                data={unapprovedNeighbourhoods}
                paginationMeta={paginations}
                handlePageChange={handlePageChange}
                pageNumber={page}
                columns={[
                {
                    dataIndex: "city",
                    key: "city",
                    title: "City",
                    width: "14%",
                    render: (record: NeighbourhoodModel) => <div>{record?.label}</div>
                },
                {
                    dataIndex: "region",
                    key: "region",
                    title: "Region",
                    width: "14%",
                    className: "text-right",
                    render: (record: NeighbourhoodModel) => <div>{record?.label}</div>
                },
                {
                    dataIndex: "label",
                    key: "label",
                    title: "Neighbourhood",
                    width: "30%"

                },
                {
                    dataIndex: "zoneScore",
                    key: "zoneScore",
                    title: "Score",
                    width: "12%",
                    className: "text-center"
                },
                {
                    title: "",
                    key: "action",
                    width: "25%",
                    render: (val) => (
                    <div className="neighbourhood-review-action"                
                        onClick={() => {
                        setSelectedVal(val)
                        setIsNeighbourhoodReviewShown(true);
                      }}>
                        Review
                    </div>
                    ),
                },
                ]}
            />
        </div>
    )
}

export default ReviewNeighbourhood