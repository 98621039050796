import { Tabs } from "antd";
import React, { FC, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import IconComponent from "../../../../shared/components/IconComponent";
import PageView from "../../../../shared/components/PageView";
import PastSettlements from "./PastSettlements";
import PendingSettlements from "./PendingSettlements";
import SettlementInfo from "./SettlementInfo";
import "./vendorSettlements.scss";
import useWindowWidth from "../../../../shared/utils/useWindowWidth";
import Viewondesktop from "../../../../shared/components/Viewondesktop";
import { mobileScreenWidth } from "../../../../enums/constants";
const { TabPane } = Tabs;

interface VendorSettlementsProps {}

const VendorSettlements: FC<VendorSettlementsProps> = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.hash?.replace(/#/gi, "")
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= mobileScreenWidth;

  useEffect(() => {
    if (!location?.hash)
      navigate(
        { pathname: AppRoutes.SETTLEMENTS, hash: "pending" },
        { replace: true }
      );
  }, [location]);

  return (
    <PageView
      title={{
        name: "Vendor Settlements",
        icon: <IconComponent name="icon-settlements" />,
        info: path === "pending" && <SettlementInfo/>
      }}
    >
      {isMobile ? <Viewondesktop /> :
      <Tabs
        activeKey={path}
        onTabClick={(value) => {
          navigate({ pathname: location.pathname, hash: value });
        }}
        className="settlements"
      >
        <TabPane key="pending" tab="Pending">
          <PendingSettlements/>
        </TabPane>
        <TabPane key="past-settlements" tab="Past Settlements">
          <PastSettlements/>
        </TabPane>
      </Tabs>
      }
    </PageView>
  );
};

export {VendorSettlements as VendorsSettlements};
