import React, {FC, useEffect, useState} from 'react'
import "./customeradditionalinfo.scss"
import {useParams} from 'react-router-dom'
import TableComponent from "../../../../../../shared/components/TableComponent";
import CustomerContactsStatistics from "../CustomerContactsStatistics";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import {Button, Divider} from "antd";
import EmptyStateComponent from '../../../../../../shared/components/EmptyStateComponent';
import { CustomerSmsAnalysis } from '../../../../../../models/Customer/customer.model';
import { mobileScreenWidth } from '../../../../../../enums/constants';

interface CustomerAdditionalInfo {
    isMobileContactView?: boolean;
    isMobileSMSView?: boolean;
}

const CustomerAdditionalInfo: FC<CustomerAdditionalInfo> = ({ isMobileContactView, isMobileSMSView }) => {

    const isMobile = window.innerWidth < mobileScreenWidth;

    const {
        customerContactStat,
        loading,
        fetchCustomerContactsStatistics,
        customerSmsAnalysis,
        fetchCustomerSmsAnalysis
    } = CustomerService();
    const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

    const {customerId} = useParams();

    const smsPermissionNotProvided = <EmptyStateComponent header='SMS permission not providedd' description='No data to present as SMS permission is not provided by the customer' title='SMS data analysis'/>;
    const contactPermissionNotProvided = <EmptyStateComponent header='Contact permission not provided' description='No data to present as contact permission is not provided by the customer' title='Contact data analysis'/>;


    useEffect(() => {
        if (customerId) {
            fetchCustomerContactsStatistics(customerId);
            fetchCustomerSmsAnalysis(customerId);
        }
    }, [])
    const toggleEllipsis = (record: CustomerSmsAnalysis) => {
        setExpandedRows(prevState => ({
            ...prevState,
            [record.id]: !prevState[record.id]
        }));
    }

    const showCustomerStatics = !!customerContactStat?.Approved || !!customerContactStat?.['Related Contacts With Late Payment'] || !!customerContactStat?.['Related Contacts With Overdue']
    return (
        <div className="customer-additional-info">
            {!isMobile &&
            <>
                {showCustomerStatics 
                ? <CustomerContactsStatistics data={customerContactStat}/> 
                : contactPermissionNotProvided }
                {customerSmsAnalysis ? <TableComponent
                    loading={false}
                    rowClassName={() => "question-text"}
                    columns={[
                        {
                            title: "Parameter",
                            dataIndex: "parameter",
                            key: "parameter"
                        },
                        {
                            title: "Answer",
                            dataIndex: "answer",
                            key: "answer",
                            ellipsis: true,
                        },
                        {
                            title: "Elaboration",
                            dataIndex: "justification",
                            key: "justification",
                            render: (text, record) => {
                                const isEllipsisActive = !expandedRows[record.id];

                                return (
                                    <div>
                                        <span className={isEllipsisActive ? 'ellipsis' : ''}>{text}</span>
                                        {text.length > 20 && (
                                            <Button
                                                onClick={() => toggleEllipsis(record as CustomerSmsAnalysis)}
                                                type="link"
                                                style={{padding: 0, color: 'blue'}}
                                            >
                                                {isEllipsisActive ? 'View More' : 'View Less'}
                                            </Button>
                                        )}
                                    </div>
                                );
                            }
                        }
                    ]}
                    data={customerSmsAnalysis}
                /> : 
                smsPermissionNotProvided}
            </>}

            {(isMobile && isMobileContactView) && 
                (showCustomerStatics 
                ? <CustomerContactsStatistics data={customerContactStat}/> 
                : contactPermissionNotProvided) }
            {(isMobile && isMobileSMSView) && 
            (customerSmsAnalysis 
            ? 
            <div className='questionnaire-container'>
            {customerSmsAnalysis?.map((sms: CustomerSmsAnalysis) => (
                <>
                    <div className='question'>{sms?.parameter}</div>
                    <div className='answer'>{sms?.answer}</div>
                    <div>
                        <span className={!expandedRows[sms?.id] ? 'ellipsis' : ''}>{sms?.justification}</span>
                        {(sms?.justification?.length ?? 0) > 20 && (
                            <Button
                                onClick={() => toggleEllipsis(sms as CustomerSmsAnalysis)}
                                type="link"
                                style={{padding: 0, color: 'blue'}}
                            >
                                {!expandedRows[sms?.id] ? 'View More' : 'View Less'}
                            </Button>
                        )}
                    </div>
                    <Divider />
                </>
            ))}
            </div> 
            : smsPermissionNotProvided) }
        </div>
    )
}

export default CustomerAdditionalInfo