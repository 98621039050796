import { SortColumnTypes } from "../shared/types/SortColumn.type";

export const SortColumnTypeDefinitions = {
    [SortColumnTypes.CUSTOMER_NAME]: "customer_name",
    [SortColumnTypes.TOTAL_AMOUNT]: "total_amount",
    [SortColumnTypes.PURCHASE_STATUS]: "purchase_status",
    [SortColumnTypes.CUSTOMER_PAYMENT_STATUS]: "customer_payment_status",
    [SortColumnTypes.PURCHASE_ID]: "purchase_id",
    [SortColumnTypes.PURCHASE_DATE]: "purchase_date",
    [SortColumnTypes.VENDOR_NAME]: "vendor_name",
    [SortColumnTypes.VENDOR_ID]: "vendor_id",
    [SortColumnTypes.VENDOR_TYPE]: "vendor_type",
    [SortColumnTypes.VENDOR_STATUS]: "vendor_status",
    [SortColumnTypes.PAYMENT_DATE]: "payment_date",
    [SortColumnTypes.PAYMENT_AMOUNT]: "payment_amount",
    [SortColumnTypes.DEALL_SCORE]: "deall_score",
    [SortColumnTypes.PLAN]: "plan",
    [SortColumnTypes.MAX_PURCHASE_POWER]: "max_purchase_power",
    [SortColumnTypes.PROCESSING_FEE]: "processing_fee",
    [SortColumnTypes.CUSTOMER_STATUS]: "customer_status",
    [SortColumnTypes.ACTIVE]: "active",
    [SortColumnTypes.USER_ID]: "user_id",
    [SortColumnTypes.USER_NAME]: "user_name",
    [SortColumnTypes.USER_ROLE]: "user_role",
    [SortColumnTypes.USER_STATUS]: "user_status",
    [SortColumnTypes.ONBOARDING_START_DATE]: "onboarding_start_date",
    [SortColumnTypes.SUBMITTED_ON]: "submitted_on",
    [SortColumnTypes.ASCEND]: "asc",
    [SortColumnTypes.DESCEND]: "desc",
    [SortColumnTypes.SETTLED_AT]: "settled_at",
    [SortColumnTypes.CREATED_AT]: "created_at",
    [SortColumnTypes.NUMBER_OF_ORDERS]: "no_of_orders",
    [SortColumnTypes.MINIMUM_DUE]: "min_amount",
    [SortColumnTypes.DUE_DATE]: "due_date",
    [SortColumnTypes.SETTLEMENT_NO_OF_ORDERS]: "settlement_no_of_orders",
    [SortColumnTypes.VENDOR_NO_OF_ORDERS]: "vendor_no_of_orders",
    [SortColumnTypes.SETTLEMENT_AMOUNT]: "settlement_amount",
    [SortColumnTypes.EXTERNAL_PAYMENT_ID]: "external_payment_id",
    [SortColumnTypes.INSTORE_NAME]: "instore_name",
    [SortColumnTypes.INSTORE_NO_OF_ORDERS]: "instore_no_of_orders",
    [SortColumnTypes.VENDOR_SETTLEMENT_NO_OF_ORDERS]: "vendor_settlement_no_of_orders",
    [SortColumnTypes.VENDOR_SETTLEMENT_SETTLED_AT]: "vendor_settlement_settled_at",
    [SortColumnTypes.PARTNER_NAME]: "partner_name",
    [SortColumnTypes.PARTNER_TRANSACTION_DATE]: "partner_transaction_date",
    [SortColumnTypes.PARTNER_TRANSACTION_AMOUNT]: "partner_transaction_amount",
    [SortColumnTypes.PARTNER_FEES]: "partner_fees",
    [SortColumnTypes.PARTNER_VAT]: "partner_vat",
    [SortColumnTypes.PARTNER_TOTAL_FEES]: "partner_total_fees",
    [SortColumnTypes.PAST_TRANSACTION_DATE]: "past_transaction_date",
    [SortColumnTypes.PAST_TRANSACTION_AMOUNT]: "past_transaction_amount",
    [SortColumnTypes.VENDOR_PROCESSING_FEE]: "vendor_processing_fee",
    [SortColumnTypes.VAT]: "vat",
    [SortColumnTypes.VENDOR_SETTLEMENT_AMOUNT]: "vendor_settlement_amount",
    [SortColumnTypes.ONBOARDED_DATE]: "onboarded_date",
    [SortColumnTypes.PENDING_PARTNER_NAME]: "pending_partner_fees_partner_name",
    [SortColumnTypes.PENDING_PARTNER_TRANSACTION_DATE]: "pending_partner_fees_transaction_date",
    [SortColumnTypes.PENDING_PARTNER_TRANSACTION_AMOUNT]: "pending_partner_fees_transaction_amount",
    [SortColumnTypes.PENDING_PARTNER_PROCESSING_FEE]: "pending_partner_fees_partner_processing_fee",
    [SortColumnTypes.PENDING_PARTNER_VAT]: "pending_partner_fees_partner_processing_fee_vat",
    [SortColumnTypes.PENDING_PARTNER_SETTLEMENT_AMOUNT]: "pending_partner_fees_settlement_amount",
    [SortColumnTypes.PAST_PARTNER_NAME]: "past_partner_fees_partner_name",
    [SortColumnTypes.PAST_PARTNER_SETTLED_DATE]: "past_partner_fees_settled_date",
    [SortColumnTypes.PAST_PARTNER_ORDERS]: "past_partner_fees_no_of_orders",
    [SortColumnTypes.PAST_PARTNER_TOTAL_AMOUNT]: "past_partner_fees_total_amount",
    [SortColumnTypes.PENDING_PAYMENT_PARTNER_NAME]: "pending_payment_collection_partner_name",
    [SortColumnTypes.PENDING_PAYMENT_CREATED_DATE]: "pending_payment_collection_created_at",
    [SortColumnTypes.PENDING_PAYMENT_AMOUNT]: "pending_payment_collection_amount",
    [SortColumnTypes.PENDING_PAYMENT_PROCESSING_FEE]: "pending_payment_collection_partner_processing_fee",
    [SortColumnTypes.PENDING_PAYMENT_VAT]: "pending_payment_collection_partner_processing_fee_vat",
    [SortColumnTypes.PENDING_PAYMENT_COLLECTION_AMOUNT]: "pending_payment_collection_collection_amount",
    [SortColumnTypes.PAST_PAYMENT_PARTNER_NAME]: "past_payment_collection_partner_name",
    [SortColumnTypes.PAST_PAYMENT_SETTLED_DATE]: "past_payment_collection_settled_date",
    [SortColumnTypes.PAST_PAYMENT_ORDERS]: "past_payment_collection_no_of_orders",
    [SortColumnTypes.PAST_PAYMENT_COLLECTION_AMOUNT]: "past_payment_collection_collection_amount",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_NAME]: "pending_vendor_settlements_vendor_name",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_PURCHASE_DATE]: "pending_vendor_settlements_purchase_date",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_PURCHASE_AMOUNT]: "pending_vendor_settlements_purchase_amount",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_FEE]: "pending_vendor_settlements_vendor_fee",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_FEE_VAT]: "pending_vendor_settlements_vendor_fee_vat",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_SETTLEMENT_AMOUNT]: "pending_vendor_settlements_vendor_settlement_amount",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_REFUND_VENDOR_SETTLEMENT_AMOUNT]: "pending_vendor_settlements_refund_vendor_settlement_amount",
    [SortColumnTypes.PAST_VENDOR_SETTLEMENTS_VENDOR_ID]: "past_vendor_settlements_vendor_id",
    [SortColumnTypes.PAST_VENDOR_SETTLEMENTS_VENDOR_NAME]: "past_vendor_settlements_vendor_name",
    [SortColumnTypes.PAST_VENDOR_SETTLEMENTS_SETTLED_DATE]: "past_vendor_settlements_settled_date",
    [SortColumnTypes.PAST_VENDOR_SETTLEMENTS_NO_OF_ORDERS]: "past_vendor_settlements_no_of_orders",
    [SortColumnTypes.PAST_VENDOR_SETTLEMENTS_VENDOR_SETTLEMENT_AMOUNT]: "past_vendor_settlements_vendor_settlement_amount",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_REFUND_AMOUNT]: "pending_vendor_settlements_refund_amount",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_REFUND_RECEIPT_ID]: "pending_vendor_settlements_refund_receipt_id",
    [SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_RETURNED_DATE]: "pending_vendor_settlements_returned_date",
    [SortColumnTypes.CUSTOMER_DOCUMENT_ATTACHMENT_STATUS]: "attachment_status"
}