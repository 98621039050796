import React, { FC, SetStateAction, useEffect, useState } from "react";
import TableComponent, { TableFilterComponent, TableFilterDropdownComponent } from "../../../../shared/components/TableComponent";
import "./customerList.scss";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import CustomerService from "../../../../services/CustomerService/customer.service";
import NoCustomersImg from "../../../../assets/empty/customers.svg";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import moment from "moment";
import { StateProps } from "../../../../models/Meta/meta.model";
import { Row, Col } from "antd";
import { Formik, Form } from "formik";
import { BadgeTypesDefinition } from "../../../../definitions/badgeTypesDefinition";
import DropdownField from "../../../../shared/components/DropdownField";
import { objectHasKeys } from "../../../../shared/utils/objectHasKeys";
import { PurchaseTypes } from "../../../../enums/purchaseTypes";
import { PurchaseTypesDefinition } from "../../../../definitions/purchaseTypesDefinition";

interface CustomerListProps {
  setCount: React.Dispatch<React.SetStateAction<number>>
}

const CustomerList: FC<CustomerListProps> = (props) => {
  const { setCount } = props
  const { loading, customers, fetchCustomersList, paginations, } = CustomerService();
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = location.hash === "#list" ? stateValue?.pageNumber as number : null
  let filterOptions = location.hash === "#list" ? stateValue?.filterOptions as SortFilterModel : null;
  const [page, setPage] = useState(pageNumber ?? 1)
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()

  const filterInitialValues = filterOptions ?? {
    status: undefined
  };

  const handleFilterSubmit = (values: SortFilterModel) => {
    setFilters(prev => ({ ...prev, ...values }));
    handleFilterClose();
  };

  const handleFilterClose = () => setIsFilterShown(false)

  const handlePageChange = (page:number) => {
    location.state = null
    setPage(page)
    fetchCustomersList({page,...sortOptions, search, ...filters})
  }

  useEffect(() => {
    location.hash === "#list" &&
    fetchCustomersList({page,...sortOptions, search, ...filters});
  }, [location.hash]);

  useEffect(() => {
    objectHasKeys(filters) &&
    fetchCustomersList({page:1, ...filters, ...sortOptions});
  }, [filters]);

  useEffect(() => {
    setCount(paginations?.totalCount as number);
  }, [paginations]);

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              location.state = null;
              filterOptions = null;
              setPage(1);
              handleFilterClose();
              setFilters(() => ({ ...filterOptions, search: filters?.search }));
              resetForm({values: {}});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <DropdownField
                    options={[
                      {
                        value: PurchaseTypes.BASIC,
                        label: PurchaseTypesDefinition[PurchaseTypes.BASIC],
                      },
                      {
                        value: PurchaseTypes.SILVER,
                        label: PurchaseTypesDefinition[PurchaseTypes.SILVER],
                      },
                      {
                        value: PurchaseTypes.GOLD,
                        label: PurchaseTypesDefinition[PurchaseTypes.GOLD],
                      },
                      {
                        value: PurchaseTypes.PHONE,
                        label: PurchaseTypesDefinition[PurchaseTypes.PHONE],
                      },
                    ]}
                    label="Purchase status"
                    name="purchaseStatus"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("purchaseStatus", value)}
                    value={values?.purchaseStatus}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    options={[
                      {
                        value: BadgeTypes.APPROVED,
                        label: BadgeTypesDefinition[BadgeTypes.ACTIVE],
                      },
                      {
                        value: BadgeTypes.INACTIVE,
                        label: BadgeTypesDefinition[BadgeTypes.INACTIVE],
                      }
                    ]}
                    label="Status"
                    name="status"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("status", value)}
                    value={values?.status}
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );

  return (
    <div className="customer-list">
      <TableComponent
      pageNumber={pageNumber ?? page}
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      onChange={fetchCustomersList}
      onSort={(data)=>{
        setSortOptions(data)
      }}
      filter={filter}
      filtersSorts={{page, search}}
      onRow={(record)=>
        navigate({
          pathname: generatePath(AppRoutes.CUSTOMERS_LIST_VIEW, {
            customerId: record?.id,
          }),
        }, 
        {state: {pageNumber: page, filterOptions: filters}}
        )
      }
        search={{
          placeholder: "Search customer by name or national id or phone number",
          onSearch: (value) => {
            setSearch(value)
            fetchCustomersList({
              search: value
            });
          },
        }}
        loading={loading}
        columns={[
          {
            title: "Customer Name",
            dataIndex: "fullName",
            key: SortColumnTypes.CUSTOMER_NAME,
            sorter: true,
            render: (text) => text
          },
          {
            title: "Name from Id",
            dataIndex: "nameFromId",
            width: 130,
            render: (text) => text
          },
          {
            title: "National ID",
            dataIndex: "nationalId",
            key: "nationalId",
            width: 80,
          },
          {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            render: (text, record) => <span>{record?.countryCode?.isdCode ?? ""} {text}</span>
          },
          {
            title: "Purchasing Status",
            dataIndex: "plan",
            key: SortColumnTypes.PLAN,
            sorter: true,
          },
          {
            title: "Purchasing Power",
            dataIndex: "maxPurchasePower",
            key: SortColumnTypes.MAX_PURCHASE_POWER,
            sorter: true,
            render: (text, record) => (
              <span className="vendor__name">
                LE &nbsp;
                {text}
              </span>
            ),
          },
          {
            title: "Processing Fee",
            dataIndex: "processingFee",
            key: SortColumnTypes.PROCESSING_FEE,
            sorter: true,
            render: (text, record) => <span>{text}%</span>
          },
          {
            title: "Status",
            dataIndex: "status",
            key: SortColumnTypes.CUSTOMER_STATUS,
            render: (text) => <BadgeComponent type={text===BadgeTypes.APPROVED?BadgeTypes.ACTIVE:BadgeTypes.INACTIVE} />,
            sorter: true,
          },
          {
            title: "Approval date",
            dataIndex: "onboardedDate",
            key: SortColumnTypes.ONBOARDED_DATE,
            render: (text, record) => <span>{moment(record?.onboardedDate).format("DD MMM YYYY hh:mm a")}</span>,
            sorter: true,
            width: 80,
          },
          {
            title: "Kyc Status",
            dataIndex: "kycStatus",
            key: "kycStatus",
            width: 130,
            render: (text) => <BadgeComponent type={(text === BadgeTypes.APPROVED ? BadgeTypes.KYC_APPROVED : text === BadgeTypes.FAILED ? BadgeTypes.KYC_FAILED : text === BadgeTypes.KYC_PENDING && BadgeTypes.KYC_PENDING) as BadgeTypes} />
          }
        ]}
        data={customers}
        empty={{
          img: NoCustomersImg,
          text: "No customer data",
        }}
      />
    </div>
  );
};

export default CustomerList;
