import React, { FC, Fragment, useRef, useState } from "react";
import "./customerInfoCard.scss";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import SectionCardComponent from "../../../../../../shared/components/SectionCardComponent";
import { Col, Divider, Row } from "antd";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import InputField from "../../../../../../shared/components/InputField";
import { CustomerEditModel, CustomerModel } from "../../../../../../models/Customer/customer.model";
import { currencyString } from "../../../..";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import { customerInfoCardValidation } from "./customerInfoCardValidation";
import { customerLateFeeValidation } from "./customerLateFeeValidation";
import { customerPurchasingPowerValdiation } from "./customerPurchasingPowerValidation";
import IconComponent from "../../../../../../shared/components/IconComponent";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { BadgeTypes } from "../../../../../../enums/badgeTypes";
import { mobileScreenWidth } from "../../../../../../enums/constants";
import { useParams } from "react-router-dom";
import { Kycdetails, ManualKycDetails } from "../../../../../../shared/components/Kycdetails";
import Verificationdetails from "../../../../../../shared/components/Verificationdetails";
import Customerdetails from "../../../../../../shared/components/Customerdetails";
import DropdownField from "../../../../../../shared/components/DropdownField";
import { ProcessingFeeReasonType } from "../../../../../../enums/processFeeReasonTypes";
import { ProcessingFeeReasonDefinition } from "../../../../../../definitions/processFeeReasonDefinition";

interface CustomerInfoCardProps {
  customer: CustomerModel | undefined;
  isFetching: boolean;
  refreshList: () => void;
  detailsExpanded?: boolean;
  setDetailsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}
interface CustomerInfoCardFormProps {
  visible: boolean;
  closeHandler: () => void;
  data?: CustomerModel;
  loading?: boolean;
  submitHandler: (values: FormikValues) => void;
  type?: 'customer' | undefined ;
}
export const PurchasingPowerForm: FC<CustomerInfoCardFormProps> = ({
  visible,
  closeHandler,
  data,
  submitHandler,
  loading
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const onSubmit = (values: FormikValues) => {
    submitHandler(values);
  };
  const initialValues = {
    maxPurchasePower: data?.maxPurchasePower,
  };
  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler();
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={customerPurchasingPowerValdiation}
    >
      {({ handleSubmit }) => (
        <ModalComponent
          visible={visible}
          type="small"
          title="Edit Purchasing Power"
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading,
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          closeHandler={resetFormAndClose}
        >
          <Form>
            <InputField
              placeholder="Enter"
              label="Purchasing Power"
              name="maxPurchasePower"
              prefix={<span>LE</span>}
            />
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

export const ProcessingFeeForm: FC<CustomerInfoCardFormProps> = ({
  visible,
  closeHandler,
  data,
  loading,
  submitHandler,
  type
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const initialValues = {
    processingFee: undefined,
    processingFeeComment: undefined
  };
  const onSubmit = (values: FormikValues) => {
    submitHandler(values);
    resetFormAndClose()
  };
  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={customerInfoCardValidation(type)}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <ModalComponent
          visible={visible}
          type="small"
          title="Edit Processing Fee %"
          description={type === 'customer' ? "The new processing fee % will be applied for all the installments" : ''}
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading,
          }}
          cancelProps={{
            title: "Close",
            clickHandler: resetFormAndClose,
          }}
          closeHandler={resetFormAndClose}
        >
          <Form>
            <InputField
              placeholder="Enter"
              label="Processing Fee"
              name="processingFee"
              required
            />
            {type === 'customer' &&
            <DropdownField
              label="Reason"
              placeholder="Select reason"
              required
              name="processingFeeComment"
              value={values?.processingFeeComment}
              options={[
                {
                  value: ProcessingFeeReasonType.CORPORATE_DEAL,
                  label: ProcessingFeeReasonDefinition[ProcessingFeeReasonType.CORPORATE_DEAL],
                },
                {
                  value: ProcessingFeeReasonType.RISE_EMPLOYEE,
                  label: ProcessingFeeReasonDefinition[ProcessingFeeReasonType.RISE_EMPLOYEE],
                },
              ]}
              onChange={(value) => setFieldValue("processingFeeComment", value)}
            />}
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

export const LateFeeForm: FC<CustomerInfoCardFormProps> = ({
  visible,
  closeHandler,
  data,
  loading,
  submitHandler,
}) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const initialValues = {
    lateFee: data?.lateFee,
  };
  const onSubmit = (values: FormikValues) => {
    submitHandler(values);
  };

  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={customerLateFeeValidation}
    >
      {({ handleSubmit, errors }) => (
        <ModalComponent
          visible={visible}
          type="small"
          title="Edit late fee"
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading,
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          closeHandler={resetFormAndClose}
        >
          <Form>
            <InputField placeholder="Enter" label="Late fee" name="lateFee" />
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

const CustomerInfoCard: FC<CustomerInfoCardProps> = (props) => {
  const { isFetching, customer, refreshList, detailsExpanded, setDetailsExpanded } = props;
  const user = LocalStorage.getItem('USER')
  const isAdminManager = user?.role !== BadgeTypes.VIEWER;
  const isMobile = window.innerWidth <= mobileScreenWidth;
  const renderDivider = isMobile ? <Divider/> : <></>;
  const [isPurchasingPowerFormShown, setIsPurchasingPowerFormShown] =
    useState(false);
  const [isProcessingFeeFormShown, setIsProcessingFeeFormShown] =
    useState(false);
  const [resetModalVisible, setResetModalVisible] = useState(false)

  const { updateCustomerDetails, loading, resetKycRetry, loading: resetKycRetryLoading, kycVerification } = CustomerService();
  const { customerId } = useParams();

  const KYCStatus = customer?.kycStatus;
  const isRejected = customer?.status === BadgeTypes.REJECTED;
  const isApprovedUser = customer?.status === BadgeTypes.APPROVED;
  const isPendingUser = customer?.status === BadgeTypes.PENDING_APPROVAL
  const isUserWaitlisted = customer?.status === BadgeTypes.WAITLISTED

  const handleUpdate = (data: CustomerEditModel) => {
    updateCustomerDetails({ ...data, id: customer?.id }, () => {
      setIsPurchasingPowerFormShown(false);
      setIsProcessingFeeFormShown(false);
      refreshList();
    });
  };

  const handleSubmit = () => customerId && resetKycRetry(customerId, true).then(()=>{ setResetModalVisible(false); refreshList() })

  const verificationDetailElement = <Verificationdetails isMobile={isMobile} renderDivider={renderDivider} customer={customer!} isApprovedUser={isApprovedUser} isRejectedUser={isRejected} />
  const customerDetailsElement = <Customerdetails isMobile={isMobile} detailsExpanded={() => setDetailsExpanded((state)=>!state)} customer={customer!} renderDivider={renderDivider} isApprovedUser={isApprovedUser} />
  const isApprovedWaitlist = [customerDetailsElement, verificationDetailElement]
  const otherStatusUsers = [verificationDetailElement, customerDetailsElement]

  return (
    <Fragment>
      <PurchasingPowerForm
        visible={isPurchasingPowerFormShown}
        data={customer}
        closeHandler={() => setIsPurchasingPowerFormShown(false)}
        loading={loading}
        submitHandler={(values) => handleUpdate(values)}
      />

      <ModalComponent
        type="alert"
        className="reset-retry-count-alert"
        alert={{
          title: "Are you sure you want to reset the customer KYC verification?",
          description: "This will allow the customers to retry account verification process again through their mobile application",
          buttonType: "primary"
        }}
        visible={resetModalVisible}
        closeHandler={()=>setResetModalVisible(false)}
        successProps={{
          title: "Confirm",
          clickHandler: handleSubmit,
          loading: resetKycRetryLoading
        }}
        cancelProps={{
          title: "Close",
          clickHandler: ()=>setResetModalVisible(false),
        }}
      />

      <SectionCardComponent
        loading={isFetching}
        className={`customer-info-card ${detailsExpanded && "max-height"}`}
        section={
          <Row className="customer-info-card-section" align="middle">
            <Col span={8}>
              <div className="deall-score">
                <p>Rise Score</p>
                <h2>{customer?.deallScore ?? 0}</h2>
              </div>
            </Col>
            <Col span={8}>
              <div className="purchasing-power">
                <p>Purchasing Power</p>
                <h2>
                  {currencyString}
                  {customer?.maxPurchasePower ?? 0}
                  {isAdminManager && customer?.status !== BadgeTypes.WAITLISTED && 
                      <IconComponent
                        onClick={() => setIsPurchasingPowerFormShown(true)}
                        name="icon-edit"
                      />
                    }
                </h2>
              </div>
            </Col>
            <Col span={8}>
              <div className="processing-fee">
                <p>Processing Fee %</p>
                <h2>
                  {customer?.processingFee ?? 0}%
                  {isAdminManager &&
                    <IconComponent
                        onClick={() => setIsProcessingFeeFormShown(true)}
                        name="icon-edit"
                      />
                  }
                </h2>
              </div>
            </Col>
          </Row>
        }
        content={
          <div className={`customer-info-card-content ${detailsExpanded && "max-height"}`}>
            {KYCStatus && <Kycdetails customer={customer!} isMobile={isMobile} KYCStatus={KYCStatus} resetModal={() => setResetModalVisible(true)} />}
            {customer?.allowManualKyc && <ManualKycDetails isMobile={isMobile} kycVerification={() => customer?.id && kycVerification(customer?.id?.toString()).then(() => refreshList())} />}
            {(isUserWaitlisted || isApprovedUser) && isApprovedWaitlist.map(element => element)}
            {(isPendingUser || isRejected) && otherStatusUsers.map(element => element)}
          </div>
        }
      />
    </Fragment>
  );
};

export default CustomerInfoCard;
