import React, { FC, ReactNode } from "react";
import { Col, Row } from "antd";
import { generatePath, To, useLocation, useNavigate } from "react-router-dom";
import IconComponent from "../IconComponent";
import Notifications from "./Notifications";
import "./pageView.scss";
import ProfileDetails from "./ProfileDetails";
import backIcon from "./../../../assets/images/back-icon.png"
import ButtonComponent from "../ButtonComponent";
import MenuComponent from "../MenuComponent";
import { LocalStorage } from "../../utils/localStorageHelpers";
import { UserModel } from "../../../models/User/user.model";
import { BadgeTypes } from "../../../enums/badgeTypes";
import { VendorModel } from "../../../models/Vendor/vendor.model";
import { CustomerModel } from "../../../models/Customer/customer.model";
import ApproveCustomersControllers from "../../../views/Home/Customers/ApproveCustomers/ApproveCustomersView/ApproveCustomersControllers";
import BadgeComponent from "../BadgeComponent";
import { BadgeTypesDefinition } from "../../../definitions/badgeTypesDefinition";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { SortFilterModel } from "../../../models/SortFilterModel/SortFilterModel.model";

interface PageViewProps {
  title?: {
    icon?: ReactNode;
    name: string;
    span?: string;
    info?: ReactNode
  };
  type?: "default" | "back-only" | "back" | 'dashboard';
  backRoute?: string;
  createRefundComponent?: JSX.Element;
  showPurchaseMenu?: JSX.Element;
  vendorFormHandler?: () => void
  blacklistHandler?: () => void
  vendorStatus?: BadgeTypes
  vendor?: VendorModel
  customer?: CustomerModel
  handleRefresh?: () => void
  className?: string
  pageNumber?: number;
  filterOptions?: SortFilterModel | null;
}

const PageView: FC<PageViewProps> = (props) => {
  const { title, type = "default", children, backRoute, className, createRefundComponent, vendorFormHandler, blacklistHandler, vendorStatus, vendor, customer, handleRefresh, pageNumber, showPurchaseMenu, filterOptions } = props;
  const navigate = useNavigate();
  const location = useLocation()
  const user = LocalStorage.getItem('USER') as UserModel;
  const isAdminManager = user?.role !== BadgeTypes.VIEWER
  const isApproved = customer?.status === BadgeTypes.APPROVED
  const isPendingApproval = customer?.status === BadgeTypes.PENDING_APPROVAL
  const isWaitlisted = customer?.status === BadgeTypes.WAITLISTED
  const isMobile = window.innerWidth < 480
  const pendingApprovalMobile = isMobile && className==="pending-approval-view"
  const isKycPending = customer?.kycStatus === BadgeTypes.KYC_PENDING
  const isDetailsAvailable = customer?.detailsAvailable

  return (
    <div className={`page-view ${className}`}>
      <div className="page-view__header">
        <Row className="page-view-header-title__container" justify="space-between">
          <Col lg={14} xs={pendingApprovalMobile ? 24 : 16} sm={16}>
            <div className="title-content">
              {(type === "back" || type === "back-only") && (
                <span className="back-icon" onClick={() => {
                  ((pageNumber || filterOptions) && backRoute) ? navigate({pathname: generatePath(backRoute!)}, {state: { pageNumber, filterOptions}}) : navigate(-1 as To)
                }}>
                  <img src={backIcon} alt="back" className="back-icon-image" />
                </span>
              )}
              {vendor && <img src={vendor?.logo?.s3Url} alt="" className="vendor-logo"/>}
              {customer && <img src={customer?.selfiePic?.[0]?.s3Url ?? customer?.profilePic?.[0]?.s3Url} alt="" className="vendor-logo"/>}
            <div className="title">
              <p className="main">
                {title?.icon} {title?.name} {!isApproved && <BadgeComponent className='fill' type={customer?.status === BadgeTypes.REJECTED ? BadgeTypes.REJECTED : customer?.status! as BadgeTypes} />} {title?.span && <span className="title-span">{title?.span}</span>} {title?.info} 
                {(isMobile && isPendingApproval && !isKycPending && isDetailsAvailable) ? <IconComponent name="icon-edit-blue" onClick={() => navigate(generatePath(AppRoutes.APPROVE_CUSTOMERS_EDIT_VIEW, { customerId: customer?.id?.toString() }), { state: { isApprove: true, customer, isSave: true } })} /> : <></>}
              </p>
              {
                vendor &&
                <div className="sub">
                  <div className="phone">
                    <IconComponent name="icon-phone"/>
                    {vendor?.countryCode?.isdCode} {vendor?.mobileNumber}
                  </div>
                  <div className="mail">
                    <IconComponent name="icon-email"/>
                    {vendor?.email}
                  </div>
                </div>
              }
              {
                customer &&
                <div className="sub">
                  <div className="phone">
                    <IconComponent name="icon-phone"/>
                    {customer?.countryCode?.isdCode} {customer?.mobileNumber}
                  </div>
                  <div className="mail">
                    <IconComponent name="icon-email"/>
                    {customer?.email}
                  </div>
                </div>
              }
            </div>
            </div>
          </Col>
          <Col lg={10} xs={pendingApprovalMobile ? 1 : 8} sm={8}>
            {type !== "back-only" && (
              <div className="page-view-header-title__right">
                <Notifications />
                <ProfileDetails />
              </div>
            )}
            {isAdminManager && location?.pathname?.split("/")[5] === "details" && vendorFormHandler && (
              <Row justify="end" align="middle">
                <ButtonComponent
                  type="primary"
                  size="medium"
                  icon={<IconComponent name="icon-edit-white" />}
                  onClick={vendorFormHandler}
                  className="vendor-form-edit"
                >
                  <span className="text">
                    Edit Details
                  </span>
                </ButtonComponent>
                <MenuComponent
                  dropdownClassName="vendor-details-card-menu"
                  menu={[
                    {
                      title: `${vendorStatus === BadgeTypes.INACTIVE ? "Activate" : "Deactivate"} Vendor`,
                      clickHandler: blacklistHandler,
                      icon: <IconComponent name="icon-blacklist" />,
                    },
                  ]}
                />
              </Row>
            )}
            {customer && (!isWaitlisted) && <ApproveCustomersControllers customer={customer} handleRefresh={handleRefresh!} />}
            {showPurchaseMenu && showPurchaseMenu}
          </Col>
        </Row>
      </div>
      <div className="page-view-content">{children}</div>
    </div>
  );
};

export default PageView;
