import React, { FC } from "react";
import Lottie from "react-lottie";
import "./lottiePlayerComponent.scss";

interface LottiePlayerComponentProps {
  data: any;
  width: number;
  height: number;
  className?: string;
}

const LottiePlayerComponent: FC<LottiePlayerComponentProps> = (props) => {
  const { data, width, height, className } = props;

  return (
    <div className={`lottie-player-component ${className}`}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: data,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={height}
        width={width}
      />
    </div>
  );
};

export default LottiePlayerComponent;
