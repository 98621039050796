import React, { FC, useEffect, useRef, useState, useMemo, useCallback } from 'react'
import IconComponent from '../../../shared/components/IconComponent';
import PageView from '../../../shared/components/PageView'
import "./dashboard.scss"
import { useNavigate } from 'react-router-dom';
import NorthStartKpiComponent from './NorthStartKpiComponent';
import LineChartComponent from '../../../shared/components/LineChartComponent';
import { Col, Divider, Row } from 'antd';
import DoughnutComponent from '../../../shared/components/DoughnutComponent';
import { paymentLogsConfig } from './paymentLogsConfig';
import { numberOfTransactionsConfig } from './numberOfTransactionsConfig';
import PaymentKpiComponent from './PaymentKpiComponent';
import { allPaymentConfig } from './allPaymentConfig';
import BarChartComponent from '../../../shared/components/BarChartComponent';
import { paymentMethodConfig } from './paymentMethodConfig';
import { vendorTransactionsNumberConfig } from './vendorTransactionsNumberConfig';
import { vendorTransactionsValueConfig } from './vendorTransactionsValueConfig';
import DashboardService, { graphDataType } from '../../../services/DasboardService/dashboard.service';
import { LOCAL_STORAGE_KEYS, LocalStorage } from "../../../shared/utils/localStorageHelpers";
import { UserModel } from "../../../models/User/user.model";
import { BadgeTypes } from "../../../enums/badgeTypes";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import SettlementComponent from './SettlementComponent/Settlementcomponent';
import Viewondesktop from '../../../shared/components/Viewondesktop';
import useWindowWidth from '../../../shared/utils/useWindowWidth';
import DashboardFilterMenu from '../../../shared/components/DashboardFilterMenu';
import { Dashboard as DashboardModel, DashboardFilterModel } from '../../../models/Dashboard/dashboard.model';
import { DashboardDateFilterTypes } from '../../../shared/types/DashboardDateFilterTypes';
import ReportForm from "../Reports/ReportForm";
import folderIcon from "../../../assets/images/folder.png"
import { mobileScreenWidth } from '../../../enums/constants';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const showTest = LocalStorage.getItem('IS_TEST')
  const dateFilter = LocalStorage.getItem("DATE_FILTER_KEY")
  const startDate = LocalStorage.getItem("START_DATE")
  const endDate = LocalStorage.getItem("END_DATE")
  const [params, setParams] = useState<DashboardFilterModel | undefined>({ dateFilterKey: dateFilter ?? DashboardDateFilterTypes["CURRENT_YEAR"], showTest })
  const user = LocalStorage.getItem('USER') as UserModel
  const windowWidth = useWindowWidth();
  const [isDasboardNorthStartLoading, setIsDasboardNorthStartLoading] = useState(false)
  const [isDasboardPurchasesLoading, setIsDasboardPurchasesLoading] = useState(false)
  const [isDasboardPaymentsLoading, setIsDasboardPaymentsLoading] = useState(false)
  const [isReportFormShown, setIsReportFormShown] = useState(false);

  const dashboardPurchasesRef = useRef<HTMLDivElement>(null);
  const dashboardPaymentsRef = useRef<HTMLDivElement>(null);

  const [cachedKeyMetrics, SetCachedKeyMetrics] = useState()
  const [cachedTransactionValues, SetCachedTransactionValues] = useState()
  const [cachedTransactionCount, setCachedTransactionCount] = useState()
  const [cachedTransactionPerVendor, setCachedTransactionPerVendor] = useState()
  const [cachedTransactionValue, setCachedTransactionValue] = useState()
  const [cachedPaymentMethods, setCachedPaymentMethods] = useState()
  const [cachedPayments, setCachedPayments] = useState()
  const [cachedPaymentsKeyMetrics, setCachedPaymentsKeyMetrics] = useState()
  const [apiDataFetched, setApiDataFetched] = useState<boolean>(false)

  const isMobile = windowWidth <= mobileScreenWidth;
  if (user?.role === BadgeTypes.VIEWER) {
    navigate(AppRoutes.CUSTOMERS);
  }
  const {
    loading,
    fetchDashboardKeyMetrics,
    dashboardKeyMetrics,
    fetchDashboardTransactionValues,
    dashboardTransactionValues,
    fetchDashboardTransactionCounts,
    dashboardTransactionCounts,
    fetchDashboardTransactionPerVendor,
    dashboardTransactionPerVendor,
    fetchDashboardTransactionValuePerVendor,
    dashboardTransactionValuePerVendor,
    fetchDashboardPaymentMethods,
    dashboardPaymentMethods,
    fetchDashboardPayments,
    dashboardPayments,
    fetchDashboardPaymentKeyMetrics,
    dashboardPaymentsKeyMetrics} = DashboardService()

  const fetchDashboardNorthStarData = async (values?: DashboardFilterModel) => {
    setIsDasboardNorthStartLoading(true)
    await fetchDashboardKeyMetrics(values)
  }

  const fetchDashboardPurchasesData = async (values?: DashboardFilterModel) => {
    setIsDasboardPurchasesLoading(true)
    await Promise.all(
      [fetchDashboardTransactionValues(values),
      fetchDashboardTransactionPerVendor(values),
      fetchDashboardTransactionValuePerVendor(values),
      fetchDashboardTransactionCounts(values)]
    )
  }

  const fetchDashboardPaymentsData = async (values?: DashboardFilterModel) => {
    setIsDasboardPaymentsLoading(true)
    await Promise.all(
      [fetchDashboardPaymentMethods(values),
      fetchDashboardPayments(values),
      fetchDashboardPaymentKeyMetrics(values)]
    )
  }

  const fetchAndCacheData = async (params: DashboardFilterModel | undefined) => {
      setApiDataFetched(false);
      await fetchDashboardNorthStarData(params);
      await fetchDashboardPurchasesData(params);
      await fetchDashboardPaymentsData(params);
      setApiDataFetched(true);
  };

  const dataMapping = {
    "DASHBOARD_KEY_METRICS": dashboardKeyMetrics,
    "DASHBOARD_TRANSACTION_VALUES": dashboardTransactionValues,
    "DASHBOARD_TRANSACTION_COUNTS": dashboardTransactionCounts,
    "DASHBOARD_TRANSACTION_PER_VENDOR": dashboardTransactionPerVendor,
    "DASHBOARD_TRANSACTION_VALUE_PER_VENDOR": dashboardTransactionValuePerVendor,
    "DASHBOARD_PAYMENT_METHODS": dashboardPaymentMethods,
    "DASHBOARD_PAYMENTS": dashboardPayments,
    "DASHBOARD_PAYMENTS_KEY_METRICS": dashboardPaymentsKeyMetrics,
  };

  const stateSetters = {
    "DASHBOARD_KEY_METRICS": SetCachedKeyMetrics,
    "DASHBOARD_TRANSACTION_VALUES": SetCachedTransactionValues,
    "DASHBOARD_TRANSACTION_COUNTS": setCachedTransactionCount,
    "DASHBOARD_TRANSACTION_PER_VENDOR": setCachedTransactionPerVendor,
    "DASHBOARD_TRANSACTION_VALUE_PER_VENDOR": setCachedTransactionValue,
    "DASHBOARD_PAYMENT_METHODS": setCachedPaymentMethods,
    "DASHBOARD_PAYMENTS": setCachedPayments,
    "DASHBOARD_PAYMENTS_KEY_METRICS": setCachedPaymentsKeyMetrics,
  };

  const cacheData = () => {
    Object.entries(dataMapping).forEach(([key, value]) => {
      LocalStorage.setItem(key as LOCAL_STORAGE_KEYS, value);
    });
  }

  const setLoadingFalse = () => {
    setIsDasboardNorthStartLoading(false);
    setIsDasboardPurchasesLoading(false);
    setIsDasboardPaymentsLoading(false);
  }

  const getCachedData = () => {  
    const isCacheComplete = Object.entries(stateSetters).every(([key, setState]) => {
      const cachedValue = LocalStorage.getItem(key as LOCAL_STORAGE_KEYS);
      if (cachedValue) {
        setState(cachedValue);
        return true;
      }
      return false;
    });
    if (!isCacheComplete) {
      fetchAndCacheData(params);
    } else{
      setLoadingFalse();
    }
  }

  useEffect(() => {
    getCachedData();
  }, []);

  const allDataPresent = useMemo(() => {
    return [
      dashboardKeyMetrics, dashboardTransactionValues, dashboardTransactionCounts,
      dashboardTransactionPerVendor, dashboardTransactionValuePerVendor,
      dashboardPaymentMethods, dashboardPayments, dashboardPaymentsKeyMetrics
    ].every(Boolean);
  }, [
    dashboardKeyMetrics, dashboardTransactionValues, dashboardTransactionCounts,
    dashboardTransactionPerVendor, dashboardTransactionValuePerVendor,
    dashboardPaymentMethods, dashboardPayments, dashboardPaymentsKeyMetrics
  ]);

  useEffect(() => {
    if(allDataPresent && apiDataFetched){
      cacheData();
      getCachedData();
    }
  }, [allDataPresent, apiDataFetched])

  return (
    <PageView type='dashboard' title={{
      name: "Dashboard",
      icon: <IconComponent name="icon-dashboard" />,
    }}
    lastUpdated=""
    onRefresh={() => fetchAndCacheData({...params, forceUpdate: true})}
    >
      {isMobile ? <Viewondesktop /> :
        <div className="dashboard-view">
          <ReportForm
            successHandler={() => {
              setIsReportFormShown(false);
            }}
            visible={isReportFormShown}
            closeHandler={() => setIsReportFormShown(false)} />
          <div className="header">
            <Divider />
            <div className='date-range-container'>
              <div className='date'><IconComponent name="icon-calender" />
                <h3> Date Range: </h3> &nbsp;
                <DashboardFilterMenu setParams={setParams} onChange={(values) => {
                  // Retain date filter in local storage
                  if(values?.dateFilterKey) {
                    LocalStorage.setItem("DATE_FILTER_KEY", values?.dateFilterKey);
                    LocalStorage.setItem("START_DATE", values?.startDate)
                    LocalStorage.setItem("END_DATE", values?.endDate)
                  }
                  values?.dateFilterKey !== 'custom' && dateFilter != null && values?.dateFilterKey !== dateFilter && fetchAndCacheData(values) //to avoid re-etching data when we switch between modules
                  values?.dateFilterKey === 'custom' && values?.startDate !== '' && values?.endDate !== '' &&  (values?.startDate !== startDate || values?.endDate !== endDate) && fetchAndCacheData(values) //because custom has date range and behaves differently
                }} />
              </div>
              <div className='report' onClick={() => setIsReportFormShown(true)}><img src={folderIcon} className='icon-folder' /> <span className='label'>Create Report</span></div>
            </div>
            <Divider />
          </div>
          <Row gutter={[24, 40]}>
            <Col span={24}>
              <NorthStartKpiComponent keyMetricsLoading={isDasboardNorthStartLoading} dashboardKeyMetrics={cachedKeyMetrics! as DashboardModel} />
            </Col>
            <Col span={24}>
              <div className="dashboard-header purchases">
                <h2 className='dashboard-title'>Purchases</h2>
              </div>
            </Col>
            <Col span={12} id="purchase-ref" ref={dashboardPurchasesRef}>
              <LineChartComponent data={cachedTransactionValues} loading={isDasboardPurchasesLoading} classname="small" options={paymentLogsConfig} title="LE value of purchases" />
            </Col>
            <Col span={12}>
              <LineChartComponent onChange={fetchDashboardTransactionCounts} data={cachedTransactionCount} loading={isDasboardPurchasesLoading} classname="small" options={numberOfTransactionsConfig} title="Number of purchases" />
            </Col>
            <Col span={12}>
              <BarChartComponent onChange={fetchDashboardTransactionPerVendor} data={cachedTransactionPerVendor} loading={isDasboardPurchasesLoading} options={vendorTransactionsNumberConfig} title='# of purchases per vendor' />
            </Col>
            <Col span={12}>
              <BarChartComponent onChange={fetchDashboardTransactionValuePerVendor} data={cachedTransactionValue} loading={isDasboardPurchasesLoading} options={vendorTransactionsValueConfig} title='Value of purchases per vendor' />
            </Col>
            <Col span={24} id="payment-ref" ref={dashboardPaymentsRef}>
              <PaymentKpiComponent paymentKeyMetricsLoading={isDasboardPaymentsLoading} dashboardPaymentsKeyMetrics={cachedPaymentsKeyMetrics! as DashboardModel} />
            </Col>
            <Col span={12}>
              <BarChartComponent onChange={fetchDashboardPaymentMethods} data={cachedPaymentMethods} loading={isDasboardPaymentsLoading} options={paymentMethodConfig} title='Payment Methods' />
            </Col>
            <Col span={12}>
              <LineChartComponent onChange={fetchDashboardPayments} data={cachedPayments} loading={isDasboardPaymentsLoading} classname="small" backgroundColor='rgba(255, 114, 158, .2)' borderColor='#FF729E' title='LE value of payments' options={allPaymentConfig} />
            </Col>
            {/* TODO: Need to be removed as part of DL-5516 */}
            {/* <Divider />
            <Col span={24} id="settlement-ref" ref={dashboardSettlementsRef}>
              <SettlementComponent setIsDasboardSettlementsLoading={setIsDasboardSettlementsLoading} isDasboardSettlementsLoading={isDasboardSettlementsLoading} isDasboardSettlementsVisible={isDasboardSettlementsVisible} params={params as DashboardFilterModel} />
            </Col> */}
          </Row>
        </div>
      }
    </PageView>
  )
}

export default Dashboard