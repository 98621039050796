export type LOCAL_STORAGE_KEYS = 
"ACCESS_TOKEN" | 
"REFRESH_TOKEN" | 
"USER" | 
"IS_TEST" | 
"MOBILE_DEVICE" | 
"DASHBOARD_KEY_METRICS" | 
"DASHBOARD_TRANSACTION_VALUES" | 
"DASHBOARD_TRANSACTION_COUNTS" |
"DASHBOARD_TRANSACTION_PER_VENDOR" |
"DASHBOARD_TRANSACTION_VALUE_PER_VENDOR" |
"DASHBOARD_PAYMENT_METHODS" |
"DASHBOARD_PAYMENTS" |
"DASHBOARD_PAYMENTS_KEY_METRICS"|
"DATE_FILTER_KEY" |
"START_DATE" |
"END_DATE" |
"LAST_UPDATED";

export class LocalStorage {
    static setItem(key: LOCAL_STORAGE_KEYS, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static getItem(key: LOCAL_STORAGE_KEYS)  {
        const data = localStorage.getItem(key);
        if(data && data !== "undefined") {
            return JSON.parse(data);
        }
        return null
    }
    static clearItem(key: LOCAL_STORAGE_KEYS) {
        localStorage.removeItem(key);
    }

    static clearAllDashboardData() {
        ["USER", "ACCESS_TOKEN", "REFRESH_TOKEN", "IS_TEST", "MOBILE_DEVICE"].forEach(key => 
            this.clearItem(key as LOCAL_STORAGE_KEYS)
        );
    }
}