import moment from "moment"

export const formatDate = (date: string) => moment(date).format("DD MMM YYYY, HH:mm")

export const formatDateTime = (date: string) => moment(date).format("YYYY-MM-DD HH:mm:ss")

export const formatDateOnly = (date: string) => moment(date).format("DD MMM YYYY")

export const formatDateAndYear = (date: string) => moment(date).format("DD MMM, YY")

export const getDate = (data: string) => moment(data.split("-")[1], 'DD MMM YYYY')

export const parseDate = (dateStr?: string) => dateStr ? moment(dateStr, "DD/MM/YYYY") : null;

export const parseIsoDate = (dateStr?: string) => dateStr ? moment(dateStr) : null;