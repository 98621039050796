import React, { FC, useState, useEffect } from 'react';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Libraries
} from '@react-google-maps/api';
import MapsAutocompleteComponent from "../MapsAutocompleteComponent";
import './mapComponent.scss'

interface Position {
  lat: number;
  lng: number;
  address?: string;
}

interface MapComponentProps {
  position?: Position;
  onChange?: (position: Position) => void;
  editable?: boolean;
  className?: string;
  label?: string;
  error?: string;
}

const libraries: Libraries = ['places'];

const MapComponent: FC<MapComponentProps> = ({
  position,
  onChange,
  editable = true,
  className,
  label,
  error
}) => {
  const [currentLocation, setCurrentLocation] = useState<Position>({
    lat: 0,
    lng: 0,
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        setCurrentLocation({ lat, lng });
      }
    );
  }, []);

  const handleMarkerDragEnd = (event: google.maps.MapMouseEvent) => {
    if (event.latLng && onChange) {
      onChange({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    }
  };

  const mapOptions: google.maps.MapOptions = {
    fullscreenControl: editable,
    draggable: editable,
    zoom: 10
  };

  return (
    <div className={`map-component ${className}`}>
      {label && <label>{label}</label>}
      
      {editable && (
        <MapsAutocompleteComponent
          onSearch={(position) => {
            onChange && onChange(position);
          }}
          address={position?.address ?? ""}
          error={error}
        />
      )}

      {isLoaded ? (
        <div className="map-container">
          <GoogleMap
            mapContainerClassName="map"
            center={position ?? currentLocation}
            options={mapOptions}
          >
            <Marker
              position={position ?? currentLocation}
              draggable={editable}
              onDragEnd={handleMarkerDragEnd}
            />
          </GoogleMap>
        </div>
      ) : (
        <div className="map-loading" />
      )}
    </div>
  );
};

export default MapComponent;