import { Row, Col } from 'antd';
import { FormikProps, FormikValues, Formik, Form } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react'
import { serialize } from 'serializr';
import { InstallmentProcessingFeeList } from '../../../../models/CreditScoreConfig/creditScoreConfig.model';
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service';
import IconComponent from '../../../../shared/components/IconComponent';
import InputField from '../../../../shared/components/InputField';
import ModalComponent from '../../../../shared/components/ModalComponent';
import TableComponent from '../../../../shared/components/TableComponent';
import { processingFeeSchema } from '../CustomerPurchaseConfiguration/configurationSchema';

interface ProcessingFeeConfigurationFormProps {
  visible: boolean;
  closeHandler: () => void;
  data?: InstallmentProcessingFeeList;
  installmentList: InstallmentProcessingFeeList[];
  callback: Function;
}

const CustomerProcessingFeeConfigurationForm: FC<ProcessingFeeConfigurationFormProps> = ({ visible, closeHandler, data, callback, installmentList }) => {
  const [initialValues, setInitialValues] = useState<any>({});
  const { loading, updateProcessingFeeConfig } = ConfigurationService()
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const onSubmit = (values: FormikValues) => {
    let updatedValues = [...installmentList, {processingFee: Number(values?.processingFee), numberOfInstallments: Number(values?.numberOfInstallments)}]
    let body = updatedValues.map((value) => serialize(InstallmentProcessingFeeList, value))
    updateProcessingFeeConfig(body).then(()=>{
      closeHandler()
      callback()
    })
  };

  const resetFormAndClose = () =>{
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }

  useEffect(() => {
    if (data) {
      setInitialValues({
        processingFee: data.processingFee,
        numberOfInstallments: data.numberOfInstallments
      })
    }
  }, [data, visible])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={processingFeeSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <ModalComponent
          visible={visible}
          closeHandler={resetFormAndClose}
          type="small"
          title="Edit Processing Fee %"
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          className="purchase-config-form-modal"
        >
          <Form>
            <div className="purchase-config-form">
              <Row gutter={16}>
              <Col span={24}>
                  <InputField
                    label="# of installments"
                    name="numberOfInstallments"
                    disabled
                    placeholder="Enter"
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    label="Processing fee %"
                    name="processingFee"
                    placeholder="Enter"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

const CustomerProcessingFeeConfiguration: FC = (
  props
) => {
  const [selectedVal, setSelectedVal] = useState<InstallmentProcessingFeeList>({})
  const { loading, fetchInstallmentProcessingFeeList, processingFeeList } = ConfigurationService()
  useEffect(() => {
    fetchInstallmentProcessingFeeList()
  },[])

  const [isProcessingFeeConfigFormShown, setIsProcessingFeeConfigFormShown] = useState(false);

  return (
    <div className="customer-purchase-configuration">
      <CustomerProcessingFeeConfigurationForm
        visible={isProcessingFeeConfigFormShown}
        closeHandler={() => setIsProcessingFeeConfigFormShown(false)}
        data={selectedVal!}
        callback={()=>fetchInstallmentProcessingFeeList()}
        installmentList={processingFeeList?.filter(list => list?.numberOfInstallments !== selectedVal?.numberOfInstallments)}
      />
      <div className="label">Customer processing fee</div>
      <TableComponent
       loading={loading}
        data={processingFeeList}
        columns={[
          {
            dataIndex: "numberOfInstallments",
            key: "numberOfInstallments",
            title: "# of installments",
            width: "12%",
          },
          {
            dataIndex: "processingFee",
            key: "processingFee",
            title: "Processing fee %",
            render: (text) => <div>{text + "%"}</div>,
            width: "30%"

          },
          {
            title: "",
            key: "action",
            width: "25%",
            render: (val) => (
              <div className="purchase-config-action">
                <IconComponent
                  name="icon-edit"
                  onClick={() => {
                    setSelectedVal(val)
                    setIsProcessingFeeConfigFormShown(true);
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default CustomerProcessingFeeConfiguration;