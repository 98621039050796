import { Form, Formik, FormikValues } from "formik";
import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ForgotPasswordRequestModel } from "../../../models/Auth/auth.model";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import UserService from "../../../services/AuthService/auth.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
import NewPasswordForm from "../NewPasswordForm";
import PasswordResetLinkSent from "../PasswordResetLinkSent";
import "./forgotPasswordForm.scss";
import { forgotPasswordValidationSchema } from "./ForgotPasswordValidation";

interface ForgotPasswordFormProps {}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, sendPasswordResetLinkToUser } = UserService(navigate, location);
  const onSubmit = (values: FormikValues) => {
    const payload: ForgotPasswordRequestModel = {
      email: values?.email,
      redirectUrl:
        window.location.origin +  AppRoutes.NEW_PASSWORD,
    };
    sendPasswordResetLinkToUser(payload, () => {
      setStep(1);
    });
  };
  const initialValue = {
    email: "",
  };
  const [step, setStep] = useState<number>(0);
  return step ? (
    <PasswordResetLinkSent />
  ) : (
    <div className="forgot-password-form">
      <h2>Forgot password</h2>
      <h4>Enter email to recover password</h4>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={forgotPasswordValidationSchema}
      >
        {({ handleSubmit }) => (
          <Form>
            <InputField
              type="email"
              name="email"
              placeholder="Enter email"
              label="Email"
              className="forgot-password-form__input"
            />
            <ButtonComponent
              type="primary"
              htmlType="submit"
              className="auth-form__btn"
              size="wide"
              loading={loading}
            >
              Proceed
            </ButtonComponent>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
