import moment from "moment";
import * as Yup from "yup";
import {HasIScore} from "../../../../enums/hasIScore";

const requiredSchema = Yup.string().required("Comment is required");
const notRequiredSchema = Yup.string().notRequired();

export const customerDetailsFormValidation = (showAddNewNeighbourhood: boolean) => Yup.object().shape({
	firstName: Yup.string().required('First Name is required').nullable(),
	lastName: Yup.string().required('Last Name is required').nullable(),
  nameFromId: Yup.string().required('Name from id is required').nullable(),
	nationalIdNumber: Yup.string().required('National ID is required').nullable(),
  dateOfBirth: Yup.date().nullable().required('Date of Birth is required'),
	address: Yup.string().required('Address is required').nullable(),
	gender: Yup.string().required('Sex is required').nullable(),
  maritalStatus: Yup.string().required('Marital status is required').nullable(),
  jobTitle: Yup.string().required('Job title is required').nullable(),
  professionalGroupId: Yup.string().required('Professional group is required').nullable(),
	nationalIdFrontId: Yup.string().required('National id front is required'),
	nationalIdBackId: Yup.string().required('National id back is required'),
  religion: Yup.string().required('Religion is required').nullable(),
  iscore: Yup.number()
  .nullable()
  .typeError('iscore must be a number')
      .when(['hasIScore', 'noIScoreFound'], {
        is: (hasIScore?: string, noIScoreFound?: boolean) =>
            hasIScore === HasIScore.YES && !noIScoreFound,
    then: Yup.number()
      .nullable()
      .required('Please submit an iscore or indicate that iscore does not exist for the customer')
      .integer('iscore must be a whole number')
      .min(299, 'iscore must be at least 299')
      .max(801, 'iscore must not exceed 801')
      .test(
        'iscore-format',
        'iscore must contain only numbers',
        (value) => !value || /^[0-9]+$/.test(String(value))
      ),
    otherwise: Yup.number()
      .nullable()
      .notRequired()
      .integer('iscore must be a whole number')
      .min(299, 'iscore must be at least 299')
      .max(801, 'iscore must not exceed 801')
      .test(
        'iscore-format',
        'iscore must contain only numbers',
        (value) => !value || /^[0-9]+$/.test(String(value))
      ),
  }),
  cityId: Yup.string()
  .nullable()
  .when('outOfScope', {
    is: false,
    then: Yup.string().required('City is required'),
    otherwise: Yup.string().nullable(),
  }),
  regionId: Yup.string()
    .nullable()
    .when('outOfScope', {
      is: false,
      then: Yup.string().required('Region is required'),
      otherwise: Yup.string().nullable(),
    }),
  
  zoneKeyword: Yup.string().when('showAddNewNeighbourhood', {
    is: true,
    then: Yup.string().required('Neighbourhood is required'),
    otherwise: Yup.string().nullable()
  }),
  
  zoneScore: Yup.string().when('showAddNewNeighbourhood', {
    is: true,
    then: Yup.string().required('Neighbourhood score is required'),
    otherwise: Yup.string().nullable()
  }),
});

export const approvalFormValidationSchema = Yup.object().shape({
  approvalComment: Yup.string()
    .required('Approval Comment is required'),
  
  approvalReasonId: Yup.array()
    .min(1, 'At least one reason is required')
    .required('Approval Reason is required'),
});
