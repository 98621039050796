import { Col, Row } from "antd";
import { FC, useState } from "react";
import "./vendorStorePromotions.scss";
import IconComponent from "../../../../../../shared/components/IconComponent";
import VendorService from "../../../../../../services/VendorService/vendor.service";
import {
  VendorStorePromotionModel,
} from "../../../../../../models/Vendor/StorePromotion/storePromotion.model";
import SpinnerComponent from "../../../../../../shared/components/SpinnerComponent";
import EmptyStateComponent from "../../../../../../shared/components/EmptyStateComponent";
interface VendorStorePromotionsProps {
  promotionData: VendorStorePromotionModel[];
  handleRefresh: () => void;
}
const VendorStorePromotions: FC<VendorStorePromotionsProps> = ({promotionData, handleRefresh}) => {
  const {
    loading,
    deleteVendorStorePromotion,
  } = VendorService();
  const [currentPromotion, setCurrentPromotion] = useState<VendorStorePromotionModel>();

  const deleteHandler = async (vendorStorePromotionId: number) => {
    if (vendorStorePromotionId){
      await deleteVendorStorePromotion(vendorStorePromotionId, () =>
        {
          handleRefresh();
        }
      ).then(() => setCurrentPromotion(undefined))
    }
  };

  return (
    <>
      {promotionData?.length > 0 ?
      promotionData?.map((value, index) => {
        return (
          <Row className="vendor-store-promotions" gutter={[24, 24]}>
          <Col span={8}>
            <div
              className={`vendor-store-promotion__container`}
            >
              <div className={`asset-header ${index === 0 && 'title'}`}>English asset</div>
              <img
                className="vendor-store-promotion-img"
                src={value?.attachment?.s3Url}
                alt=""
              />
            </div>
          </Col>
          <Col span={8}>
            <div
              className={`vendor-store-promotion__container`}
            >
              <div className={`asset-header ${index === 0 && 'title'}`}>Arabic asset (الأصول العربية)</div>
              <img
                className="vendor-store-promotion-img"
                src={value?.attachmentAr?.s3Url}
                alt=""
              />
            </div>
          </Col>
          <Col span={8}>
          <div
                className="vendor-store-promotion-close"
                onClick={() => {
                  if(currentPromotion?.id) return;
                  setCurrentPromotion(value);
                  if (value?.id) deleteHandler(value?.id);
                }}
              >
              
              {currentPromotion?.id === value?.id ? <SpinnerComponent /> :<IconComponent name="icon-trash-red" />}
          </div>
          </Col>
          </Row>
        );
      })
      : <EmptyStateComponent description="No store promotions available" classname="no-border" ></EmptyStateComponent>
    }
    </>
  );
};
export default VendorStorePromotions;
