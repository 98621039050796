import { Button } from "antd";
import { ButtonType } from "antd/lib/button";
import { ButtonHTMLType } from "antd/lib/button/button";
import React, { FC } from "react";
import "./buttonComponent.scss";

interface ButtonComponentProps {
  className?: string;
  type?: ButtonType;
  additionalTypes?: "secondary" | "affirmative" | "negative" | "primary" | "rise-secondary";
  size?: "large" | "medium" | "fixed" | "fixed-v2" | "fixed-v3" | "fixed-v4" | "fixed-mobile" | "wide" | "small" | "icon-only";
  htmlType?: ButtonHTMLType | 'reset' | 'submit';
  onClick?: () => void;
  icon?: any;
  disabled?: boolean;
  loading?: boolean;
  hidden?: boolean
  buttonRef?: React.RefObject<HTMLButtonElement>
}

const ButtonComponent: FC<ButtonComponentProps> = (props) => {
  const { className, type = "default", children, htmlType = "button", onClick, size, buttonRef, hidden, icon, additionalTypes, ...rest } = props;

  return (
    <div className={`button-component ${size} ${className} ${additionalTypes}`}>
      <Button type={type} htmlType={htmlType} hidden={hidden} ref={buttonRef} onClick={onClick} shape={(!children && icon) ?  "circle" : undefined} icon={icon} {...rest}>{children}</Button>
    </div>
  );
};

export default ButtonComponent;
