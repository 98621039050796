import React, { FC, useEffect } from 'react'
import "./paymentview.scss"
import { Row, Col } from 'antd'
import moment from 'moment'
import { currencyString } from '../../..'
import CardSkeletonComponent from '../../../../../shared/components/CardSkeletonComponent'
import PageView from '../../../../../shared/components/PageView'
import TableComponent from '../../../../../shared/components/TableComponent'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../../../../../routes/routeConstants/appRoutes'
import IconComponent from '../../../../../shared/components/IconComponent'
import PaymentService from '../../../../../services/PaymentService/payment.service'
import { PurchasesPayment } from '../../../../../models/PurchasesPayment/purchasesPayment.model'
import { PaymentModeTypesDefinition } from '../../../../../definitions/paymentModeTypesDefinition'
import { PaymentModeTypes } from '../../../../../enums/paymentModeTypes'
import { StateProps } from '../../../../../models/Meta/meta.model'
import useLocationState from '../../../../../shared/utils/useLocationState'

const PaymentView: FC = () => {

    const navigate = useNavigate()
    const { pageNumber, filterOptions } = useLocationState();

    const { fetchPaymentDetails, loading, payments } = PaymentService()
    const { transactionId } = useParams()

    useEffect(() => {
      transactionId && fetchPaymentDetails(transactionId)
    }, [transactionId])

    return (
        <PageView type="back-only" backRoute={`${AppRoutes.PAYMENTS}#payment-logs`} pageNumber={pageNumber} filterOptions={filterOptions}>
        <div className="paymentview">
          {loading ? (
            <Row>
              <Col span={10}>
                <CardSkeletonComponent
                  className="payments-details__container-skeleton"
                  rows={4}
                />
              </Col>
              <Col span={14}>
                <CardSkeletonComponent
                  className="payments-details__container-skeleton"
                  rows={4}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="payments-details__container" span={10}>
                <div className="customer-details-card__container">
                  <div className="item">
                    <div className="label">External payment ID</div>
                    <div className="value">{payments?.payment?.externalPaymentId ?? "-"}</div>
                  </div>
                  <div className="item">
                    <div className="label">Payment date</div>
                    <div className="value">{moment(payments?.payment?.createdAt)?.format("DD MMM YYYY, HH:mm") ?? "-"}</div>
                  </div>
                  <div className="item">
                    <div className="label">Payment mode</div>
                    <div className="value">{payments?.payment?.paymentMode ? PaymentModeTypesDefinition[payments.payment.paymentMode as PaymentModeTypes] : "-"}</div>
                  </div>
                  <div className="item">
                    <div className="label">Total payment amount</div>
                    <div className="value">{currencyString}{payments?.payment?.amount ?? 0}</div>
                  </div>
                </div>
              </Col>
              <Col span={8} className='payments-details__container'>
              <Row gutter={[12, 0]}>
                <Col className="image__container">
                  <img src={payments?.customer?.selfiePic?.[0]?.s3Url ?? payments?.customer?.profilePic?.[0]?.s3Url} alt="pic" />
                </Col>
                <Col span={20} className="details__container">
                  <p className="header">Customer</p>
                  <h2 className="name customer" 
                      onClick={()=>navigate({pathname: generatePath(AppRoutes.CUSTOMERS_LIST_VIEW, {customerId: payments?.customer?.id?.toString()})}, {state: {from: 'payments'}})}>
                        {payments?.customer?.fullName}
                  </h2>
                  <Row align="middle" gutter={[10, 0]} className="key-values">
                    <Col>
                      <IconComponent name="icon-info-grey" />
                    </Col>
                    <Col>
                      <span className="value">
                      {payments?.customer?.nationalIdNumber}
                      </span>
                    </Col>
                  </Row>
                  <Row align="middle" gutter={[10, 0]} className="key-values">
                    <Col>
                      <IconComponent name="icon-phone-grey" />
                    </Col>
                    <Col>
                      <span className="value">
                      {payments?.customer?.countryCode?.isdCode}{payments?.customer?.mobileNumber}
                      </span>
                    </Col>
                  </Row>
                  <Row align="middle" gutter={[10, 0]} className="key-values">
                    <Col>
                      <IconComponent name="icon-email-grey" />
                    </Col>
                    <Col span={21}>
                      <span className="value">
                      {payments?.customer?.email}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              </Col>
            </Row>
          )}
          <div className="settlements-summary-list__container">
            <div className="title">Purchases paid</div>
            <TableComponent
              loading={loading}
              columns={[
                {
                  dataIndex: "purchase.purchaseId",
                  key: "purchaseId",
                  title: "Purchase ID",
                  render: (text, record: PurchasesPayment) => <span>{record?.purchasePayment?.purchaseId ?? "-"}</span>
                },
                {
                  dataIndex: "purchase.createdAt",
                  key: "createdAt",
                  title: "Purchase Date",
                  render: (text, record: PurchasesPayment) => <span>{moment(record?.purchasePayment?.createdAt)?.format("DD MMM YYYY")}</span>,
                },
                {
                  title: "Purchase Amount",
                  dataIndex: "purchase.totalAmount",
                  render: (text, record: PurchasesPayment) => <span>{record?.purchasePayment?.totalAmount ?? "-"}</span>
                },
                {
                  title: "Installment paid",
                  dataIndex: "installment.installmentNo",
                  render: (text, record: PurchasesPayment) => <span>{record?.installment?.installmentNo ?? "-"}</span>
                },
                {
                  title: "Paid Amount",
                  dataIndex: "installment.paidAmount",
                  render: (text, record: PurchasesPayment) => <div>{currencyString} {record?.installment?.paidAmount}</div>
                },
              ]}
              data={payments?.purchases}
            />
          </div>
        </div>
      </PageView>
    )
}

export default PaymentView