import React from "react";
import AppRoutes from "./routes";
import { AuthProvider } from "./context/AuthContext";
import "antd/dist/antd.css";
import { BrowserRouter as Router } from "react-router-dom";
import { appsignal } from "./appsignal";
import { ErrorBoundary } from "@appsignal/react";
 
const FallbackComponent = () => (
  <div>Uh oh! There was an error :(</div>
);

const App = () => {
  return (
    <ErrorBoundary
      instance={appsignal}
      tags={{ tag: "value" }} 
      fallback={() => <FallbackComponent />}
    >
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;
