import {Col, Collapse, Row, Tabs} from "antd";
import React, { FC, useEffect, useState } from "react";
import PageView from "../../../../../shared/components/PageView";
import "./customerListView.scss";
import { CustomerPurchases } from "./CustomerPurchases";
import { CustomerPayments } from "./CustomerPayments";
import CustomerDetailsCard from "./CustomerDetailsCard";
import CustomerInfoCard from "./CustomerInfoCard";
import DocumentsCard from "../../../../../shared/components/DocumentsCard";
import CustomerService from "../../../../../services/CustomerService/customer.service";
import { useLocation, useParams } from "react-router-dom";
import { CustomerModel } from "../../../../../models/Customer/customer.model";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import CustomerQuestionnaire from "./CustomerQuestionnaire";
import CustomerReferralRewards from "./CustomerReferralRewards";
import CustomerAdditionalInfo from "./CustomerAdditionalInfo";
import CustomerAssets from "./CustomerAssets";
import useLocationState from "../../../../../shared/utils/useLocationState";

const { TabPane } = Tabs;
const { Panel } = Collapse;
interface CustomerListViewProps {}

const CustomerListView: FC<CustomerListViewProps> = (props) => {
  const { customer, loading, fetchCustomerDetails } = CustomerService();
  const { customerId } = useParams();
  const location = useLocation();
  const { pageNumber, filterOptions } = useLocationState();
  const [detailsExpanded, setDetailsExpanded] = useState(false)
    const [isMobile, setIsMobile] = useState(false);

    const handleRefresh = () => {
        if (customerId) {
            fetchCustomerDetails(customerId);
        }
    }
    useEffect(() => {
        handleRefresh();
        setIsMobile(window.innerWidth <= 420);
    }, []);

  return (
    <PageView type="back-only" backRoute={`${AppRoutes.CUSTOMERS}#list`} title={{ name: customer?.nameFromId ?? "" }} customer={customer as CustomerModel} handleRefresh={handleRefresh} pageNumber={pageNumber} filterOptions={filterOptions}>
      <div className="customer-list-view">
        <Row gutter={24}>
          <Col xs={24} sm={6} md={6} lg={6}>
            <CustomerDetailsCard detailsExpanded={detailsExpanded} customer={customer} isFetching={loading} refreshList={handleRefresh}/>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <CustomerInfoCard detailsExpanded={detailsExpanded} setDetailsExpanded={setDetailsExpanded} customer={customer} isFetching={loading} refreshList={handleRefresh}/>
          </Col>
          <Col xs={24} sm={6} md={6} lg={6}>
            <DocumentsCard 
              detailsExpanded={detailsExpanded}
              customer={customer}
              data={[
                {
                  label: "PROOF OF ASSET",
                  document: customer?.proofOfAsset
                },
                {
                  label: "PROOF OF INCOME",
                  document: customer?.proofOfIncome
                },
                {
                  label: "SELFIE PIC",
                  document: (customer?.selfiePic?.length! > 0) ? customer?.selfiePic : customer?.profilePic
                },
                {
                  label: "NATIONAL ID - FRONT",
                  document: customer?.nationalIdFront
                },
                {
                  label: "NATIONAL ID - BACK",
                  document: customer?.nationalIdBack
                },
                {
                  label: "BANK STATEMENT",
                  document: customer?.bankStatement
                },
                {
                  label: "EMPLOYMENT LETTER",
                  document: customer?.employmentLetter
                },
                {
                  label: "VEHICLE CARD",
                  document: customer?.vehicleCard
                },
                {
                  label: "PROPERTY CONTRACT",
                  document: customer?.propertyContract
                },
                {
                  label: "CREDIT CARD STATEMENT",
                  document: customer?.creditCardStatement
                },
                {
                  label: "SPORTS CLUB MEMBERSHIP",
                  document: customer?.sportsClubMembership
                },
                {
                  label: "PAYSLIP",
                  document: customer?.paySlip
                },
                {
                  label: "PAYSLIP / BANK STATEMENT",
                  document: customer?.paySlipBankStatement
                },
                {
                  label: "EMPLOYMENT LETTER / MEDICAL CARD",
                  document: customer?.employmentLetterMedicalCard
                },
                {
                  label: "MEDICAL CARD",
                  document: customer?.medicalCard
                },
                {
                  label: "STUDENT CARD",
                  document: customer?.studentCard
                },
              ]}
              loading={loading}
            />
          </Col>
        </Row>
          {!isMobile &&
              <Tabs className="customer-list-view-tabs">
                  <TabPane tab="Purchases" key={"purchases"}>
                      <CustomerPurchases/>
                  </TabPane>
                  <TabPane tab="Payments" key={"payments"}>
                      <CustomerPayments/>
                  </TabPane>
                  <TabPane tab="Assets" key={"assets"}>
                      <CustomerAssets customerId={customerId} />
                  </TabPane>
                  <TabPane tab="Questionnaire" key={"questionnaire"}>
                      <CustomerQuestionnaire data={customer?.questionnaire}/>
                  </TabPane>
                  <TabPane tab="Referral & Rewards" key={"referral-rewards"}>
                      <CustomerReferralRewards isPhoneUser={customer?.isPhoneOnlyUser}/>
                  </TabPane>
                  <TabPane tab="Additional Information" key={"additional-information"}>
                      <CustomerAdditionalInfo />
                  </TabPane>
              </Tabs>
          }
          {isMobile &&
              <Collapse
                  accordion
                  expandIconPosition="right" // This sets the arrow on the right side
                  className="accordion-panel-container"
              >
                  <Panel header="Purchases" key="purchases" className="accordion-panel">
                      <CustomerPurchases/>
                  </Panel>
                  <Panel header="Payments" key="payments" className="accordion-panel">
                      <CustomerPayments/>
                  </Panel>
                  <Panel header="Questionnaire" key="questionnaire" className="accordion-panel">
                      <CustomerQuestionnaire data={customer?.questionnaire}/>
                  </Panel>
                  <Panel header="Referral & Rewards" key="referral-rewards" className="accordion-panel">
                      <CustomerReferralRewards/>
                  </Panel>
                  <Panel header="Additional Information" key="additional-information" className="accordion-panel">
                      <CustomerAdditionalInfo/>
                  </Panel>
              </Collapse>
          }
      </div>
    </PageView>
  );
};

export default CustomerListView;
