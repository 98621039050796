import React, { FC } from "react";
import CardComponent from "../../../../../shared/components/CardComponent";
import "./purchaseSummaryCard.scss";
import { Divider } from "antd";
import { currencyString } from "../../..";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";
import CardSkeletonComponent from "../../../../../shared/components/CardSkeletonComponent";
import TooltipComponent from "../../../../../shared/components/TooltipComponent";
import { convertToTwoDecimals } from "../../../../../shared/utils/convertToTwoDecimals";
import { CustomerFee } from "../../../../../enums/customerFee";
import { calculatePurchaseTotal } from "../../../../../shared/utils/calculatePurchaseTotal";

interface PurchaseSummaryCardProps {
  purchase: PurchaseModel | undefined;
  loading: boolean;
}

const PurchaseSummaryCard: FC<PurchaseSummaryCardProps> = (props) => {
  const { purchase, loading } = props;
  const vendorSettlementAmount = Number(purchase?.vendor?.vendorSettlementAmount) && convertToTwoDecimals(Number(purchase?.vendor?.vendorSettlementAmount))
  const vendorProcessingFee = Number(purchase?.vendor?.vendorProcessingFee) && convertToTwoDecimals(Number(purchase?.vendor?.vendorProcessingFee))
  const vat = Number(purchase?.vendor?.vat) && convertToTwoDecimals(Number(purchase?.vendor?.vat))
  const purchaseAmount = (vendorSettlementAmount ?? 0) + (vendorProcessingFee ?? 0) + (vat ?? 0)
  const purchaseTotal = calculatePurchaseTotal(Number(purchase?.totalAmount), Number(purchase?.customerProcessingFee), Number(purchase?.promotionDiscount), Number(purchase?.cashDiscount), Number(purchase?.totalLateFee))

  return loading ? (
    <CardSkeletonComponent className="purchase-summary-card-skeleton" rows={10} />
  ) : (
    <CardComponent
      title=""
      className="purchase-summary-card"
    >
      <div className="purchase-summary-list__container">
        <div className="purchase-summary-sub-total__container">
          <div className="label">
            <h3>
              Purchase Amount
            </h3>
            <h5>
              (Vendor settlement fee + Rise vendor fee + 14% VAT on Rise vendor fee)
            </h5>
          </div>
          <div className="value">
            <h3>
              {currencyString}
              {convertToTwoDecimals(purchaseAmount)}
            </h3>

            <h5>
              ({convertToTwoDecimals(vendorSettlementAmount ?? 0)} + {convertToTwoDecimals(vendorProcessingFee ?? 0)} + {convertToTwoDecimals(vat ?? 0)})
            </h5>

          </div>
        </div>
        <div className="purchase-summary-sub-total__container">
          <div className="label">
            <h3>
              Rise Customer Fee
            </h3>
            <h5>
              (Customer processing fee + 14% VAT on customer processing fee)
            </h5>
          </div>
          <div className="value">
            <h3>
              {currencyString}

              {convertToTwoDecimals(purchase?.customerProcessingFee ?? 0.00)}
            </h3>
            <h5>
              ({purchase?.customerProcessingFeeBeforeVat} + {purchase?.customerFeeVat})
            </h5>
          </div>
        </div>
        {
          !!purchase?.totalLateFee &&
          <div className="purchase-summary-sub-total__container">
            <div className="label">
              <h3>
                Total late Fees
              </h3>
            </div>
            <div className="value">
              <h3>
                {currencyString}

                {convertToTwoDecimals(purchase?.totalLateFee ?? 0.00)}
              </h3>
            </div>
          </div>
        }
        {
          !!purchase?.cashDiscount &&
          <div className="purchase-summary-sub-total__container">
            <div className="label">
              <h3>
                Referral Discount
              </h3>
            </div>
            <div className="value">
              <h3 className="discount">
                {"- "}
                {currencyString}
                {convertToTwoDecimals(purchase?.cashDiscount)}
              </h3>
            </div>
          </div>
        }
        {
          !!purchase?.promotionDiscount &&
          <div className="purchase-summary-sub-total__container">
            <div className="label">
              <h3>
                Promotion Discount
              </h3>
            </div>
            <div className="value">
              <h3 className="discount">
                {"- "}
                {currencyString}
                {convertToTwoDecimals(purchase?.promotionDiscount)}
              </h3>
            </div>
          </div>
        }
      </div>
      <Divider dashed />
      <div className="purchase-summary-total__container">
        <div className="label">Purchase total <TooltipComponent content='Purchase total = Purchase amount + Rise customer fee' /></div>
        <div className="value">
          {currencyString}
          {convertToTwoDecimals(purchaseTotal)}
        </div>
      </div>
    </CardComponent>
  );
};

export default PurchaseSummaryCard;
