import { Col, Row } from 'antd'
import React, { FC, Fragment, useEffect, useState } from 'react'
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import CardSkeletonComponent from '../../../../shared/components/CardSkeletonComponent'
import ModalComponent from '../../../../shared/components/ModalComponent'
import MonthlyLoanLimitCard from './MonthlyLoanLimitCard'
import PurchaseStatusCard from './PurchaseStatusCard'
import "./purchaseStatus.scss"
import {LocalStorage} from "../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../models/User/user.model";
import {BadgeTypes} from "../../../../enums/badgeTypes";


const PurchaseStatus: FC = () => {
    const { fetchPurchaseConfig, purchaseConfig, updatePurchaseConfig, loading } = ConfigurationService()
    const [purchaseStatusPromptVisible, setPurchaseStatusPromptVisible] = useState(false)
    const [currentPurchaseStatus, setCurrentPurchaseStatus] = useState(false)
    const purchaseStatus = purchaseConfig?.purchaseStatus
    const user = LocalStorage.getItem('USER') as UserModel

    const closePurchaseStatusPrompt = () => setPurchaseStatusPromptVisible(false)

    useEffect(() => {
        fetchPurchaseConfig()
    }, [])

    return (
        <div className="purchase-status">
            <div className="label">Rise Purchases Status</div>
            <Fragment>
                <ModalComponent
                    type="alert"
                    visible={purchaseStatusPromptVisible}
                    closeHandler={closePurchaseStatusPrompt}
                    successProps={{
                        title: purchaseStatus ? "Deactivate" : "Activate",
                        loading: false,
                        clickHandler: () => {
                            updatePurchaseConfig({ purchaseStatus: currentPurchaseStatus }).then(() => {
                                setPurchaseStatusPromptVisible(false)
                                fetchPurchaseConfig()
                            })
                        }
                    }}
                    cancelProps={{
                        title: "Close",
                        clickHandler: () => closePurchaseStatusPrompt()
                    }}
                    alert={{
                        title: `Are you sure you want to ${purchaseStatus ? "deactivate" : "activate"} Rise Purchase status?`,
                        description: "",
                        buttonType: purchaseStatus ? "negative" : "affirmative"
                    }}
                />
                <Row className='purchase-status__container' gutter={[24, 24]}>
                    {
                        loading ?
                            <Fragment>
                                <Col span={6}>
                                    <CardSkeletonComponent className='purchase-status-skeleton' rows={2} />
                                </Col>
                                <Col span={18}>
                                    <Row gutter={[24, 24]}>
                                        <Col span={8}>
                                            <CardSkeletonComponent className='purchase-status-skeleton' rows={2} />
                                        </Col>
                                        <Col span={14}>
                                            <CardSkeletonComponent className='purchase-status-skeleton' rows={2} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Fragment> :
                            <Fragment>
                                {user?.role !== BadgeTypes.MANAGER &&
                                    <Col span={6}>
                                        <PurchaseStatusCard purchaseConfig={purchaseConfig} onStatusChange={(value: boolean) => {
                                            setPurchaseStatusPromptVisible(state => !state)
                                            setCurrentPurchaseStatus(value)
                                        }} status={purchaseStatusPromptVisible} />
                                    </Col>
                                }
                                <Col span={18}>
                                    <MonthlyLoanLimitCard purchaseConfig={purchaseConfig} refreshHandler={fetchPurchaseConfig} />
                                </Col>
                            </Fragment>
                    }
                </Row>
            </Fragment>
        </div>
    )
}

export default PurchaseStatus