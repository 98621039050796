import React, { useEffect, useState } from "react";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../../shared/components/TableComponent";
import "./pendingSettlements.scss";
import { Form, Formik } from "formik";
import { Col, DatePicker, Row } from "antd";
import InputField from "../../../../../shared/components/InputField";
import DropdownField from "../../../../../shared/components/DropdownField";
import PartnerfeeService from "../../../../../services/PartnerfeeService/partnerfee.service";
import NoTransactionsImg from "../../../../../assets/empty/transactions.svg";
import moment, { Moment } from "moment";
import { removeUndefined } from "../../../../../shared/utils/removeUndefined";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import PartnerFeeSettlementForm from "./PartnerFeeSettlementForm";
import { SortFilterModel } from "../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../shared/types/SortColumn.type";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { PartnerTypes } from "../../../../../enums/partnerTypes";
import { PartnerTypesDefinition } from "../../../../../definitions/partnerTypesDefinitions";
import { TransactionTypes } from "../../../../../enums/transactionTypes";
import { TransactionTypesDefinition } from "../../../../../definitions/transactionTypesDefinition";
import { PendingPartnerFees } from "../../../../../models/PartnerFees/partnerFees.model";
import IconComponent from "../../../../../shared/components/IconComponent";
import { PaymentModeTypesDefinition } from "../../../../../definitions/paymentModeTypesDefinition";
import { PaymentModeTypes } from "../../../../../enums/paymentModeTypes";
import MetaService from "../../../../../services/MetaService/meta.service";
import { HandleSelectionProps, handleSelection } from "../../../../../shared/utils/handleSelection";
import { TableLoadingComponent } from "../../../../../shared/components/TableComponent";
import { objectHasKeys } from "../../../../../shared/utils/objectHasKeys";
import { getTimezoneISOString } from "../../../../../shared/utils/getTimezoneISOString";
import { StateProps } from "../../../../../models/Meta/meta.model";
import { parseDate } from "../../../../../shared/utils/formatDate";
import { clearFilters } from "../../../../../shared/utils/clearFilters";

const { RangePicker } = DatePicker;

type RangePickerValueType = [moment.Moment, moment.Moment];

const PendingPartnerFeeSettlements = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const stateValue = location?.state as StateProps;
  const isPending = location.hash === "#pending"
  const pageNumber = isPending ? stateValue?.pageNumber as number : null;
  let filterOptions = isPending ? stateValue?.filterOptions as SortFilterModel : null;
  const [page, setPage] = useState(pageNumber ?? 1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState<SortFilterModel>(filterOptions ?? {});
  const [isFilterShown, setIsFilterShown] = useState(false);

  const {
    fetchPendingPartnerFeeList,
    pendingPartnerFees,
    loading,
    paginations,
    fetchAllPendingSettlementForPartner,
    dataLoading,
    pendingPartnerFeeIds,
    markAsSettledDetails,
    markPendingSettlementsDetails
  } = PartnerfeeService();
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const [settlementsModalVisible, setSettlementsModalVisible] =
    useState<boolean>(false);
  const [selectedSettlements, setSelectedSettlements] = useState<number[]>(
    []
  );
  const [selectedSettlementsData, setSelectedSettlementsData] = useState<
    PendingPartnerFees[]
  >([]);
  const [selectAll, setSelectAll] = useState(false)
  const startDate = parseDate(filterOptions?.startDate)
  const endDate = parseDate(filterOptions?.endDate)
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([startDate ?? null, endDate ?? null]);

  const { fetchPartnersMeta, partnersMeta } = MetaService() 
  const filterInitialValues = filterOptions ?? new SortFilterModel();
  const showTest = LocalStorage.getItem("IS_TEST");
  const user = LocalStorage.getItem("USER")
  const adminId = user?.id

  const generateDateString = (values: RangePickerValueType) =>
    values?.map((i) => i.format("DD/MM/YYYY")).join(" - ");

  const handleCloseSetlementModal = () => setSettlementsModalVisible(false);
  const handleOpenSetlementModal = () => setSettlementsModalVisible(true);

  const handleFilterSubmit = (values: SortFilterModel) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  useEffect(() => {
    if (location.hash === "#pending" || objectHasKeys(filters)) {
      const updatedFilters = objectHasKeys(filters) 
      ? { page, ...filters, ...sortOptions, showTest  }
      : { page, ...sortOptions, search, ...filters, showTest  };

      fetchPendingPartnerFeeList(updatedFilters);
    }
  }, [location.hash, filters]);

  useEffect(() => {
    if(pendingPartnerFeeIds.length > 0) setSelectedSettlements(pendingPartnerFeeIds)
  }, [pendingPartnerFeeIds])

  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const refreshList = () => {
    setSelectedSettlements([]);
    setSelectedSettlementsData([]);
  };

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[0] !== "partnerName" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              setSortOptions({} as SortFilterModel);
              clearFilters({
                resetForm,
                setPage,
                handleFilterClose,
                setFilters,
                setDateRange,
                filterOptions,
                filters,
                location,
              })
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <DropdownField
                    label="Transaction Type"
                    options={[
                      {
                        value: TransactionTypes.PURCHASE,
                        label:
                          TransactionTypesDefinition[TransactionTypes.PURCHASE],
                      },
                      {
                        value: TransactionTypes.PAYMENT,
                        label:
                          TransactionTypesDefinition[TransactionTypes.PAYMENT],
                      },
                      {
                        value: TransactionTypes.RETURN, //Value will be passed as return since in BE it is handled as return
                        label:
                          TransactionTypesDefinition[TransactionTypes.REFUND],
                      },
                      {
                        value: TransactionTypes.CASH_OUT,
                        label:
                          TransactionTypesDefinition[TransactionTypes.CASH_OUT],
                      },
                    ]}
                    name="transactionType"
                    placeholder="Enter"
                    onChange={(value) =>
                      setFieldValue("transactionType", value)
                    }
                    value={values?.transactionType}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="transactionId"
                    placeholder="Enter"
                    label="Transaction ID"
                  />
                </Col>
                <Col span={12}>
                  <div className="input-field">
                    <label>{"Transaction Date Range"}</label>
                    <RangePicker
                      disabledDate={(date) => date.isAfter(moment())}
                      value={dateRange}
                      onChange={(values) => {
                        if (values?.length) {
                          setDateRange(values)
                          const dataString = generateDateString(
                            values as RangePickerValueType
                          )?.split(" - ");
                          setFieldValue("startDate", dataString?.[0]??null);
                          setFieldValue("endDate", dataString?.[1]??null);
                        }
                      }}
                      format={"DD/MM/YYYY"}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );

  const filterBy = ( 
    <Formik initialValues={{partnerName: filterOptions?.partnerName ?? 'All Partners'}} onSubmit={() => {}}>
      {({ values, setFieldValue }) => (          
        <DropdownField
        label=""
        options={[
          {
            value: '',
            label: 'All Partners',
          },
          {
            value: PartnerTypes.FAWRY,
            label: PartnerTypesDefinition[PartnerTypes.FAWRY],
          },
          {
            value: PartnerTypes.PAYTABS,
            label: PartnerTypesDefinition[PartnerTypes.PAYTABS],
          },
          {
            value: PartnerTypes.PAYMOB,
            label: PartnerTypesDefinition[PartnerTypes.PAYMOB],
          },
        ]}
        name="partnerName"
        placeholder="Select"
        onChange={(value) => {
          setFieldValue('partnerName', value)
          handleFilterSubmit({partnerName: value})
        }}            
        value={values?.partnerName}
        className="header-dropdown"
        />
      )}
    </Formik>)

const selectionDetails = (
  <div className="selection-data">
    <span className="label">Transactions Selected <span className="value">{selectedSettlements.length}</span></span>
    {selectedSettlements.length > 0 && !selectAll &&
      <span className="label-select" onClick={() => {
        fetchPendingPartnerFeeList({ ...sortOptions, page, ...filters, showTest });
        fetchAllPendingSettlementForPartner({partnerName: selectedSettlementsData[0]?.partnerName, showTest})
        setSelectAll(true)
      }}>Select all transactions of {selectedSettlementsData[0]?.partnerName}</span>
    }
    {selectAll && 
    <span className="label-deselect" onClick={() => {
      setSelectAll(false)
      refreshList()
      fetchPendingPartnerFeeList({ ...sortOptions, page, ...filters, showTest });
    }}>Deselect All</span>
    }
  </div>
)

  const handlePageChange = (page: number) => {
    location.state = null;
    setPage(page);
    fetchPendingPartnerFeeList({ ...sortOptions, page, ...filters, showTest });
  };

  const handleMarkAsSettled = () => {
    markPendingSettlementsDetails({pendingSettlementIds: selectedSettlements, partnerId: selectedSettlementsData[0]?.partnerId?.toString(), adminId})
    handleOpenSetlementModal()
  }

  return (
    <div className="pending-settlements">
      <TableComponent
        selectionDetails={selectedSettlements.length > 0 ? selectionDetails : <></>}
        onChange={() => fetchPendingPartnerFeeList({ ...sortOptions, page, ...filters, showTest })}
        onSort={(data) => {
          setSortOptions(data);
        }}
        filtersSorts={{ page, ...filters, ...sortOptions, search, showTest }}
        rowSelection={{
          selectedRowKeys: selectedSettlements,
          type: "checkbox",
          columnTitle: <></>,
          getCheckboxProps: (record) => ({
            disabled:
              !!selectedSettlementsData?.length &&
              record?.partnerName !== selectedSettlementsData[0]?.partnerName,
            name: record.id,
          }),
          onSelect: (record, selected, selectedRows) => {
            handleSelection(
              {record, selected, selectedRows, selectedSettlements, selectedSettlementsData, setSelectedSettlements, setSelectedSettlementsData} as HandleSelectionProps<PendingPartnerFees>)
          },
        }}
        newBtn={{
          text: "Mark as Settled",
          clickHandler: handleMarkAsSettled,
          disabled: !selectedSettlements?.length,
        }}
        paginationMeta={paginations}
        pageNumber={pageNumber ?? page}
        handlePageChange={handlePageChange}
        search={{
          placeholder: "Search by external purchase ID or payment ID",
          onSearch: (value) => {
            setSearch(value);
            handleFilterSubmit({ search: value });
          },
        }}
        filter={filter}
        filterBy={filterBy}
        columns={[
          {
            title: "Partner Name",
            key: SortColumnTypes.PENDING_PARTNER_NAME,
            dataIndex: "partnerName",
            sorter: true,
            render: (text: PartnerTypes) => PartnerTypesDefinition[text],
          },
          {
            title: "External Purchase id",
            dataIndex: "externalPurchaseId",
            render: (text, record: PendingPartnerFees) => (
              <span
                onClick={() => {
                  navigate(
                    {pathname: generatePath(AppRoutes.PURCHASES_VIEW, {
                      purchaseId: record?.customerPurchaseId,
                    })}, {state: {page, from: 'partner-pending', filters}}
                  );
                }}
              >
                {text}
              </span>
            ),
          },
          {
            title: "Transaction Type",
            dataIndex: "transactionType",
            render: (text: TransactionTypes) => (
              <span>
                <IconComponent
                  name={
                    text === "purchase" ? "icon-purchases" : "icon-payments"
                  }
                />{" "}
                {text === TransactionTypes.RETURN
                  ? TransactionTypesDefinition[TransactionTypes.REFUND]
                  : text === TransactionTypes.CASH_OUT
                  ? TransactionTypesDefinition[TransactionTypes.CASH_OUT]
                  : TransactionTypesDefinition[text]}
              </span>
            ),
          },
          {
            title: "Transaction Date",
            dataIndex: "transactionDate",
            key: SortColumnTypes.PENDING_PARTNER_TRANSACTION_DATE,
            render: (text) => moment(text)?.format("DD MMM YYYY"),
            sorter: true,
          },
          {
            title: "Payment ID",
            dataIndex: "paymentId",
            render: (text) => text ?? "-",
          },
          {
            title: "Payment Mode",
            dataIndex: "paymentMode",
            key: "paymentMode",
            render: (text: PaymentModeTypes) =>
              PaymentModeTypesDefinition[text] ?? "-",
          },
          {
            title: "Transaction Amount",
            dataIndex: "transactionAmount",
            key: SortColumnTypes.PENDING_PARTNER_TRANSACTION_AMOUNT,
            sorter: true,
          },
          {
            title: "Partner Fees",
            dataIndex: "partnerProcessingFee",
            key: SortColumnTypes.PENDING_PARTNER_PROCESSING_FEE,
            render: (text) => text?.toFixed(2) ?? "-",
            sorter: true,
          },
          {
            title: "VAT",
            dataIndex: "partnerProcessingFeeVat",
            key: SortColumnTypes.PENDING_PARTNER_VAT,
            render: (text) => text?.toFixed(2) ?? "-",
            sorter: true,
          },
          {
            title: "Settlement Amount",
            dataIndex: "settlementAmount",
            key: SortColumnTypes.PENDING_PARTNER_SETTLEMENT_AMOUNT,
            render: (text) => text?.toFixed(2) ?? "-",
            sorter: true,
          },
        ]}
        loading={loading}
        dataLoading={dataLoading}
        empty={{
          text: "No pending partner fee settlements",
          img: NoTransactionsImg,
        }}
        data={pendingPartnerFees}
      />
      <PartnerFeeSettlementForm
        callback={(isTest) => fetchPendingPartnerFeeList({ showTest: isTest })}
        visible={settlementsModalVisible}
        closeHandler={handleCloseSetlementModal}
        settlementData={selectedSettlementsData}
        selectedSettlements={selectedSettlements}
        refreshList={refreshList}
        markAsSettledDetails={markAsSettledDetails}
      />
    </div>
  );
};

export default PendingPartnerFeeSettlements;
