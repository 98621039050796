import { Col, Row } from "antd"
import ModalComponent from "../../../../../../../shared/components/ModalComponent"
import currency from "../../../../../../../assets/images/currency.svg"
import profile from "../../../../../../../assets/images/profile.svg"
import { FC, Fragment, useEffect } from "react"
import { CustomerCreditHistories } from "../../../../../../../models/CustomerCreditHistories/customerCreditHistories.model"
import { formatDateAndYear } from "../../../../../../../shared/utils/formatDate"
import PurchasingStatusService from "../../../../../../../services/PurchasingStatusService/purchasingStatus.service"
import { useParams } from "react-router-dom"
import { currencyString } from "../../../../.."
import "./creditGrowthHistory.scss"

interface CreditGrowthHistoryProps{
    visible: boolean;
    closeHandler: () => void;
}

export const CreditGrowthHistory: FC<CreditGrowthHistoryProps> = ({ visible, closeHandler }) => {
    const { fetchCreditHistories, creditHistories } = PurchasingStatusService();
    const {customerId} = useParams();

    useEffect(() => {
        customerId && fetchCreditHistories(customerId)
    }, [customerId])

    return (
    <ModalComponent
        visible={visible}
        className="credit-growth-modal"
        type="no-controllers-medium"
        closeHandler={closeHandler}
    >
    <div className="credit-container">
        <div className='growth-label'>Credit growth history</div>
        
        <div className="rewards-container">
        {creditHistories?.map((credits: CustomerCreditHistories, index) => (
            <div className='rewards-item-container' key={index}>
                <Row className='rewards-item'>
                    <Col span={3}>
                        <div className="icon-wrapper">
                            <img className="icon-style-reward-redeemed" src={credits?.creditEvent === "account_creation" ? profile : currency} alt='reward' />
                        </div>
                    </Col>
                    <Col span={15}>
                        <div className='title'>
                            {credits?.title}
                        </div>
                        <div className='subtitle'>{credits?.comments}</div>
                    </Col>
                    <Col span={5}>
                        <div className={`value ${credits?.creditEvent === 'return' ? 'outbound' : 'inbound'}`}>
                            {credits?.creditEvent === 'return' ? '-' : '+'} {currencyString}{credits?.amount}
                        </div>
                        <div className='value-date'>{formatDateAndYear(credits?.createdAt as string)}</div>
                    </Col>
                </Row>
            </div>
        ))}
        </div>
    </div> 
    </ModalComponent>
    )
}