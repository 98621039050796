import React, { FC, Fragment, useEffect, useState } from "react";
import PageView from "../../../../shared/components/PageView";
import "./purchasesView.scss";
import { Col, Row } from "antd";
import PurchaseCustomerDetailsCard from "./PurchaseCustomerDetailsCard";
import PurchaseSummaryCard from "./PurchaseSummaryCard";
import PurchaseOutstandingPaymentsCard from "./PurchaseOutstandingPaymentsCard";
import PurchasePaymentLogsCard from "./PurchasePaymentLogsCard";
import PurchaseReturnSummaryCard from "./PurchaseReturnSummaryCard";
import PurchaseService from "../../../../services/PurchaseService/purchase.service";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import PurchaseOverduePaymentsCard from "./PurchaseOverduePaymentsCard";
import CreateRefundForm from "./CreateRefundForm";
import refundIcon from "./../../../../assets/images/refund icon.svg";
import IconComponent from "../../../../shared/components/IconComponent";
import deleteIcon from "./../../../../assets/images/delete-alert.png";
import addIcon from "./../../../../assets/images/add.svg";
import RefundDetailsCard from "./RefundDetailsCard";
import PurchaseSettlementsCard from "./PurchaseSettlementsCard";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { UserModel } from "../../../../models/User/user.model";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import MenuComponent from "../../../../shared/components/MenuComponent";
import AssetDetails from "./AssetDetails";
import RemoveImeiModal from "./RemoveImeiModal";
import { StateProps } from "../../../../models/Meta/meta.model";

const PurchasesView: FC = (props) => {
  const { fetchPurchase, loading, purchase } = PurchaseService();
  const { purchaseId } = useParams();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = stateValue?.page;
  const fromPage = stateValue?.from;
  const filterOptions = stateValue?.filters;
  const [createRefundModalVisible, setCreateRefundModalVisible] = useState(false);
  const [showRemoveImeiModal, setShowRemoveImeiModal] = useState(false);
  const user = LocalStorage.getItem('USER') as UserModel
  const isAdminManager = user?.role !== BadgeTypes.VIEWER

  const openCreateRefundModal = () => setCreateRefundModalVisible(true);
  const closeCreateRefundModal = () => setCreateRefundModalVisible(false);
  const openRemoveImeiModal = () => setShowRemoveImeiModal(true);
  const closeRemoveImeiModal = () => setShowRemoveImeiModal(false);
  const refreshHandler = () => purchaseId && fetchPurchase(purchaseId);

  const showDeleteImei = !!purchase?.mobilePurchaseDetails?.imeiNumber;
  const title = <span className="delete-imei">Remove IMEI number</span>;

  useEffect(() => {
    refreshHandler();
  }, [purchaseId]);

  const refundButton = (
    <h4 onClick={openCreateRefundModal} className="refund-button">
      {" "}
      <span>
        {/* TODO change icon */}
        {/* <img className="refund-icon" src={refundIcon} /> */}
      </span>{" "}
      Create Refund
    </h4>
  );

  let backRoute;
  
  switch (fromPage){
    case 'payment-logs':
      backRoute = `${AppRoutes.PAYMENTS}#payment-logs`;
      break;
    case 'outstanding-payments':
      backRoute = `${AppRoutes.PAYMENTS}#outstanding-payments`;
      break;
    case 'overdue-payments':
      backRoute = `${AppRoutes.PAYMENTS}#overdue-payments`;
      break;
    case 'partner-pending':
      backRoute = `${AppRoutes.PARTNER_FEE_SETTLEMENTS}#pending`;
      break;
    case 'pending-payment-collection':
      backRoute = `${AppRoutes.SETTLEMENTS_PAYMENT_COLLECTION}#pending`;
      break;
    case 'purchases':
      backRoute = `${AppRoutes.PURCHASES}`;
      break;
    case 'pending-settlements':
    case 'pending-settlements-returns':
      backRoute = `${AppRoutes.SETTLEMENTS_VENDOR}#pending`;
      break;
    default:
  }

  const showRefund = !purchase?.refundAvailed;
  const isMobileVendor = purchase?.vendor?.isMobileVendor;

  const refundObject = {
    title: "Create refund",
    clickHandler: openCreateRefundModal,
    icon: <img className="refund-icon" src={refundIcon} />,
  };

  const IMEIObject = {
    title: title,
    clickHandler: openRemoveImeiModal,
    icon: showDeleteImei && <img className="refund-icon" src={deleteIcon} />,
  };
  
  let menuItems = [];
  
  if (showRefund) {
    menuItems.push(refundObject);
  }

  if (!!isMobileVendor && showDeleteImei) {
    menuItems.push(IMEIObject);
  }

  return (
    <div className="purchases-view__container">
    <PageView
      pageNumber={pageNumber}
      filterOptions={filterOptions}
      backRoute={backRoute}
      type="back-only"
      title={{
        name: purchase?.purchaseId ?? "",
        span: moment(purchase?.createdAt).format("DD MMM YYYY"),
      }}
      showPurchaseMenu={
        (!loading && isAdminManager && menuItems.length > 0 ?
          <MenuComponent
          dropDownPlacement="topCenter"
          dropdownClassName="customer-purchase-menu"
          menu={menuItems}
        /> : null)  as JSX.Element}
    >
      <Row className="purchases-view" gutter={[24, 24]}>
        <Col span={9}>
          <PurchaseCustomerDetailsCard purchase={purchase} loading={loading} />
        </Col>
        <Col span={15}>
          <PurchaseSummaryCard purchase={purchase} loading={loading} />
        </Col>
        {purchase?.returnSummary?.returnItems?.length ? (
          <Col span={24}>
            <PurchaseReturnSummaryCard purchase={purchase} loading={loading} />
          </Col>
        ) : (
          ""
        )}
      </Row>
      {loading ? (
        <Fragment />
      ) : (
        purchase?.refundAvailed && <RefundDetailsCard purchase={purchase} />
      )}
      {
       purchase?.mobilePurchaseDetails?.imeiNumber && <AssetDetails imeiNumber={purchase?.mobilePurchaseDetails?.imeiNumber} />
      }
      {purchase?.outstandingPayments &&
        purchase?.outstandingPayments.length > 0 && (
          <Fragment>
            {purchase?.outstandingPayments?.filter(
              (payment) =>
                payment.isOverDue &&
                payment.minimumDue &&
                !(payment.minimumDue === 0)
            ).length > 0 && <PurchaseOverduePaymentsCard purchase={purchase} handleRefresh={refreshHandler} />}

            {purchase?.outstandingPayments?.filter(
              (payment) =>
                !payment.isOverDue &&
                payment.minimumDue &&
                !(payment.minimumDue === 0)
            ).length > 0 && (
              <PurchaseOutstandingPaymentsCard purchase={purchase} />
            )}
          </Fragment>
        )}
        {
          !!purchase?.paymentLogs?.length && <PurchasePaymentLogsCard purchase={purchase} loading={loading} />
        }
        {
          !!purchase?.settlements?.length && <PurchaseSettlementsCard purchase={purchase} loading={loading} />
        }
      <CreateRefundForm
        purchase={purchase}
        visible={createRefundModalVisible}
        closeHandler={closeCreateRefundModal}
        refreshHandler={refreshHandler}
      />
      <RemoveImeiModal visible={showRemoveImeiModal} closeHandler={closeRemoveImeiModal} refreshHandler={refreshHandler} />
    </PageView>
    </div>
  );
};

export default PurchasesView;
