import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import { CustomerModel } from "../../../models/Customer/customer.model"
import { tableSorter } from "../../../shared/utils/tableSorter"
import { retryCount } from "../../../enums/constants"
import { SortColumnTypes } from "../../../shared/types/SortColumn.type"

export const tableColumns: ColumnsType<CustomerModel> = [
    {
      title: "Customer Name",
      dataIndex: "fullName",
      key: SortColumnTypes.CUSTOMER_NAME,
      sorter: true,
      render: (text) => text,
      width: 200,
      ellipsis: true,
    },
    {
      title: "National ID",
      dataIndex: "nationalId",
      key: "nationalId",
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (text, record) => `${record?.countryCode?.isdCode ?? ""} ${text}`
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
      ellipsis: true,
    },
    {
      title: "Purchasing Status",
      dataIndex: "plan",
      key: "plan",
      width: 250,
      sorter: true,
    },
    {
      title: "Retry Count",
      dataIndex: "retryCount",
      key: "retryCount",
      render: (_value: string, record: CustomerModel) => `${record.onboardingDetail?.retriesLeft ?? 0} left of ${retryCount}`
    },
    {
      title: "Onboarding Start Date",
      dataIndex: "onboardingStartedAt",
      key: SortColumnTypes.ONBOARDING_START_DATE,
      render: (_value: string, record: CustomerModel) => record.onboardingDetail?.onboardingStartedAt && moment(record.onboardingDetail?.onboardingStartedAt)?.format("DD MMM YYYY"),
      sorter: true,
    },
  ]