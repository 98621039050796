import React, { FC, Fragment, useState } from "react";
import "./fileViewControllers.scss";
import { useNavigate } from "react-router-dom";
import { CustomerDocumentModel } from "../../../../models/Customer/customer.model";
import CustomerService from "../../../../services/CustomerService/customer.service";
import ModalComponent from "../../ModalComponent";
import ButtonComponent from "../../ButtonComponent";
import { LocalStorage } from "../../../utils/localStorageHelpers";
import { UserModel } from "../../../../models/User/user.model";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import FileViewControllerModal from "./FileViewControllerModal";
import { Row, Col } from "antd";
import { DocumentTypes } from "../../../../enums/documentTypes";
import { RiskModelCommentDefinition } from "../../../../definitions/riskModelCommentDefinition";

interface FileViewControllersProps {
  documentId: number;
  refreshList?: () => void;
  attachmentStatus?: string;
  attachmentType: DocumentTypes;
}

const FileViewControllers: FC<FileViewControllersProps> = (
  props
) => {
  const { documentId, attachmentStatus, attachmentType } = props;
  const navigate = useNavigate()
  const [isRejectAlertShown, setIsRejectAlertShown] = useState(false);
  const [isApproveAlertShown, setIsApproveAlertShown] = useState(false);
  const { updateCustomerDocument, loading } = CustomerService();
  const user = LocalStorage.getItem('USER') as UserModel

  const handleUpdate = (data: CustomerDocumentModel) => {
    updateCustomerDocument(
      { ...data, id: documentId, creditScoreBreakdown: {} },
      () => {
        setIsApproveAlertShown(false);
        setIsRejectAlertShown(false);
        navigate(-1)
      }
    )
  }

  const showRiskModelCommentField = attachmentType === DocumentTypes.BANK_STATEMENT || attachmentType === DocumentTypes.SPORTS_CLUB_MEMBERSHIP || attachmentType === DocumentTypes.VEHICLE_CARD || attachmentType === DocumentTypes.PAYSLIP || attachmentType === DocumentTypes.EMPLOYMENT_LETTER || attachmentType === DocumentTypes.UTILITY_BILL || attachmentType === DocumentTypes.PROPERTY_CONTRACT

  return (
    <Fragment>
      <FileViewControllerModal
        alert={{ title: "Are you sure you want to reject the document?" }}
        visible={isRejectAlertShown}
        closeHandler={() => setIsRejectAlertShown(false)}
        handleUpdate={handleUpdate}
        attachmentStatus={BadgeTypes.REJECTED}
        successProps={{
          title: "Reject",
          clickHandler: () => handleUpdate({ attachmentStatus: BadgeTypes.REJECTED }),
          loading: loading
        }}
        cancelProps={{
          title: "Close",
          clickHandler: () => setIsRejectAlertShown(false),
        }}
        fieldLabel="Rejection reason *"
        fieldLabel1={showRiskModelCommentField ? RiskModelCommentDefinition[attachmentType] : ""}
      />
      <FileViewControllerModal
        alert={{ title: "Are you sure you want to approve the document ?", buttonType: "affirmative" }}
        visible={isApproveAlertShown}
        attachmentStatus={BadgeTypes.APPROVED}
        closeHandler={() => setIsApproveAlertShown(false)}
        handleUpdate={handleUpdate}
        successProps={{
          title: "Approve",
          clickHandler: () => handleUpdate({ attachmentStatus: BadgeTypes.APPROVED }),
          loading: loading
        }}
        cancelProps={{
          title: "Close",
          clickHandler: () => setIsApproveAlertShown(false),
        }}
        fieldLabel="Comment (optional)"
        fieldLabel1={showRiskModelCommentField ? RiskModelCommentDefinition[attachmentType] : ""}
      />
      {user?.role !== BadgeTypes.VIEWER && (
        <div className="approve-documents-controllers">
          <Row align="middle" className="approve-customers-controllers-row" gutter={10}>
          {attachmentType !== DocumentTypes.CUSTOMER_SIGNATURE && (
            <>
              <Col span={11} sm={11} xs={11} md={11} lg={11}>
                {(attachmentStatus === BadgeTypes.PENDING_APPROVAL || attachmentStatus === BadgeTypes.PENDING_VERIFICATION) && (
                  <ButtonComponent
                    size="medium"
                    additionalTypes="negative"
                    onClick={() => setIsRejectAlertShown(true)}
                  >
                    Reject
                  </ButtonComponent>
                )}
              </Col>
              <Col span={11} sm={11} xs={11} md={11} lg={11}>
                {(attachmentStatus === BadgeTypes.REJECTED || attachmentStatus === BadgeTypes.PENDING_APPROVAL || attachmentStatus === BadgeTypes.PENDING_VERIFICATION) && (
                  <ButtonComponent
                    size="medium"
                    additionalTypes="affirmative"
                    onClick={() => {
                      setIsApproveAlertShown(true);
                    }}
                  >
                    Approve
                  </ButtonComponent>
                )}
              </Col>
            </>
            )}
          </Row>
        </div>
      )}
    </Fragment>
  );
};

export default FileViewControllers;
