import React, { FC, useState } from "react";
import TableComponent from "../../../../../shared/components/TableComponent";
import "./purchasePaymentLogsCard.scss";
import faker from "faker";
import { currencyString } from "../../..";
import { PurchaseModel, PurchasePaymentLogModel } from "../../../../../models/Purchase/purchase.model";
import { PaymentModeTypes } from "../../../../../enums/paymentModeTypes";
import { PaymentModeTypesDefinition } from "../../../../../definitions/paymentModeTypesDefinition";
import moment from "moment";
import { SortColumnTypes } from "../../../../../shared/types/SortColumn.type";
import { Col, Divider, Row, Tooltip } from "antd";
import pastPaymentsImage from "./../../../../../assets/images/past-payments-title.png"
import { formatDate } from "../../../../../shared/utils/formatDate";
import { Installment } from "../../../../../models/Installment/installment.model";
import TooltipComponent from "../../../../../shared/components/TooltipComponent";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import BadgeComponent from "../../../../../shared/components/BadgeComponent";
import { getOrdinal } from "../../../../../shared/utils/getOrdinal";

interface PurchasePaymentLogsCardProps {
  purchase: PurchaseModel | undefined;
  loading: boolean;
}

const PurchasePaymentLogsCard: FC<PurchasePaymentLogsCardProps> = (props) => {
  const { purchase, loading } = props;

  return (
    <div className="purchase-payment-logs-card purchase-card-table">
      <Row className="purchase-card-title" align="middle" gutter={12}>
        <Col>
          <img src={pastPaymentsImage} alt="" />
        </Col>
        <Col>
          <span className="text">Past Payments</span>
        </Col>
      </Row>
      <TableComponent
        columns={[
          {
            title: "External Payment ID",
            dataIndex: "externalPaymentId",
            key: SortColumnTypes.EXTERNAL_PAYMENT_ID,
            render: (text, record) => text ?? "-",
            width: "15%"
          },
          {
            title: "Payment Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => formatDate(text),
            width: "10%"
          },
          {
            title: "Payment Mode",
            dataIndex: "paymentMode",
            key: "paymentMode",
            render: (text : PaymentModeTypes) => PaymentModeTypesDefinition[text],
            width: "15%"
          },
          {
            title: "Payment ID",
            dataIndex: "transactionId",
            key: "transactionId",
            render: (text, record) => record?.transactionType === "return" ? "-" : text,
            width: "15%"
          },
          {
            title: "Installement",
            dataIndex: "installment",
            key: "installment",
            width: "10%",
            render: (text, record: PurchasePaymentLogModel) => {
              const installments = record?.installments || [];
            
              return (
                <div className="installment">
                  {record?.transactionType === 'down_payment' 
                    ? "Down payment" 
                    : installments.map((installment, index) => (
                      <React.Fragment key={installment?.installmentNo}>
                        <span>{installment?.installmentNo}</span>
                        {index < installments.length - 1 && ', '}
                      </React.Fragment>
                    ))
                  }
                </div>
              );
            }
          },
          {
            title: "Installment Amount",
            dataIndex: "amount",
            key: "amount",
            width: "15%",
            render: (text, record: PurchasePaymentLogModel) => {
              const totalAmount = record.installments?.reduce((total, installment) => {
                return total + (installment?.installmentAmount ?? 0);
              }, 0);
            
              const totalLateFee = record?.installments?.reduce((total, installment) => {
                return total + (installment?.lateFee ?? 0);
              }, 0);
            
              const totalInstallmentAmount = (totalAmount ?? 0) + (totalLateFee ?? 0);
              const tooltipElement =
              <div className="container">
              {record?.installments?.map((installment, index) => (
                <div key={index} className="content">
                  <div className="label">{getOrdinal(installment?.installmentNo!)} installment</div>
                  <div className="value">{currencyString} {installment?.installmentAmount}</div>
                </div>
              ))}
              {totalLateFee ? (totalLateFee > 0 && 
              <div className="content">
                <div>Late payment</div>
                <div>{currencyString}{totalLateFee}</div>
              </div>) : <></>}
              <div className="content">
                <div>Installment amount</div>
                <div>{currencyString}{totalInstallmentAmount}</div>
              </div>
            </div>
            const data = <div className="tooltip">{currencyString}{totalInstallmentAmount} <TooltipComponent content={tooltipElement} /></div>
             return  record?.transactionType === 'down_payment' ? <div className="tooltip">-</div> : data

            },
          },
          {
            title: "Payment Amount",
            dataIndex: "amount",
            key: "amount",
            render: (text, record: PurchasePaymentLogModel) => {
                let paymentAmountClass = ''
                if(record?.paymentStatus === 'failure') {
                    paymentAmountClass = ''
                }
                else if(record?.isAmountNegative) {
                    paymentAmountClass = 'payment-loss';
                } else {
                    paymentAmountClass = 'payment-profit';
                }
                return (
                    <span className={paymentAmountClass} >
                      {record?.isAmountNegative ? "-" : "+"}&nbsp; {currencyString}
                      {text}
                    </span>
                )
              },
              width: "10%"
          },
          {
              title: "Payment Status",
              dataIndex: "paymentStatus",
              key: "payment_status",
              render: (text, record: PurchasePaymentLogModel) => (
                <><BadgeComponent type={text === BadgeTypes.RETURN ? BadgeTypes.RETURNED : text} /> 
                {record?.paymentStatus === 'failure' && <TooltipComponent content={record?.failureReason ?? ''} />}</>
              ),
              width: "10%"
          }
        ]}
        data={purchase?.paymentLogs}
        loading={loading}
      />
    </div>
  );
};

export default PurchasePaymentLogsCard;
