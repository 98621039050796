import { PaymentModeTypes } from "../enums/paymentModeTypes";

export const PaymentModeTypesDefinition = {
    [PaymentModeTypes.CASH]: "Cash",
    [PaymentModeTypes.CREDIT_CARD]: "Credit Card",
    [PaymentModeTypes.CREDIT_AND_DEBIT_CARD]: "Credit and Debit Card",
    [PaymentModeTypes.MOBILE_WALLET]: "Mobile Wallet",
    [PaymentModeTypes.PREPAID_CARD]: "Prepaid Card",
    [PaymentModeTypes.AUTOPAY]: "Credit and Debit Card (Autopay)",
    [PaymentModeTypes.INSTAPAY]: "Instapay"
}