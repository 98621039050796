import React, { FC, useEffect, useState } from "react";
import FileViewer from "../FileViewer";
import ModalComponent from "../ModalComponent";
import "./fileviewmodal.scss"

interface FileViewModalProps {
    visible: boolean;
    closeHandler: () => void;
    data: {
        fileUrl: string,
        fileType: string
    };
}

const FileViewModal: FC<FileViewModalProps> = (props) => {
    const { visible, closeHandler, data } = props;
    const [file, setFile] = useState({
        uri:"",
        fileType:""
    })
    useEffect(() => {
        setFile({  uri: JSON.stringify(data?.fileUrl) , fileType: data?.fileType})
    }, [data])
    
    return (
        <ModalComponent
            type="no-controllers"
            visible={visible}
            closeHandler={closeHandler}
            className="file-view-modal"
            destroyOnClose={true}
        >
            <FileViewer 
                fileType={file.fileType?.toLowerCase()} 
                uri={file.uri && JSON.parse(file.uri)}
                isPasswordProtected={false}
                fileError={false}
                documentData={new ArrayBuffer(0)}
            />
        </ModalComponent>
    );
};

export default FileViewModal;
