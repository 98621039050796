import { useEffect, useRef, useState } from "react";
import ActionCable from "actioncable";
import { LocalStorage } from "./localStorageHelpers";
import Notification from "../components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { NotificationModel } from "../../models/notification.model";
import { InAppNotificationTypes } from "../../enums/inAppNotificationTypes";
import { deserialize, serialize } from 'serializr';
import { EventModel } from "../../models/Meta/meta.model";

let cableInstance: ActionCable.Cable | null = null;

interface ViewingEventInput {
  customerId: number;
  pageName: string;
}

export const useWebsocket = (userId?: number) => {
  const [notificationChannel, setNotificationChannel] = useState<ActionCable.Channel | null>(null);
  const hasSubscribed = useRef(false);

  useEffect(() => {
    if (!userId || hasSubscribed.current) return;

    const accessToken = LocalStorage.getItem("ACCESS_TOKEN");
    if (!accessToken) return;

    if (!cableInstance) {
      cableInstance = ActionCable.createConsumer(
        `${process.env.REACT_APP_CABLE_URL}?access_token=${accessToken}`
      );
    }

    const adminCable = cableInstance.subscriptions.create(
      {
        channel: "AdminNotificationChannel",
        room: `admin_notification_${userId}`,
      },
      {
        received: (data: NotificationModel) => {
          const _data = deserialize(NotificationModel, data)
          if (_data.notificationType === InAppNotificationTypes.PAGE_LOCKED) {
            window.dispatchEvent(new CustomEvent("admin-viewing-alert", { 
              detail: { _data } 
            }));
          } else if(_data.notificationType === InAppNotificationTypes.PURCHASE_STATUS) {
            Notification({
              message: "New notification",
              type: NotificationTypes.OPEN,
              description: "RISE"
            });
          }
        },
      }
    );

    setNotificationChannel(adminCable);
    hasSubscribed.current = true;
  }, [userId]);

  const sendViewingEvent = (event: "enter_page" | "leave_page", input: ViewingEventInput) => {
    if (!notificationChannel) {
      console.warn("Notification channel is not initialized yet!");
      return;
    }
    const _input = serialize(EventModel, input)
    notificationChannel.perform(event, _input);
  };

  return { sendViewingEvent };
};