import React, { FC, useState } from 'react'
import ModalComponent from '../ModalComponent'
import { Cropper } from 'react-cropper'
import "cropperjs/dist/cropper.css";
import ButtonComponent from '../ButtonComponent';
import { UploadChangeParam, RcFile } from 'antd/lib/upload';
import { getBase64 } from '../../utils/getBase64';

interface CropImageComponentProps { 
    visible: boolean;
    closeHandler: () => void;
    src: string;
    fileInfo: UploadChangeParam;
    onUpload: (file: File, fileUrl: string) => void;
}

const CropImageComponent: FC<CropImageComponentProps> = (props) => {

    const { visible, closeHandler, src, fileInfo, onUpload } = props
    const [cropper, setCropper] = useState<Cropper>();

    const handleChange = () => {
        if (fileInfo.file.status !== "uploading" && fileInfo.file.originFileObj) {
            getBase64(fileInfo.file.originFileObj, (fileUrl: string) => {
                if(fileInfo.file.originFileObj) onUpload(fileInfo.file.originFileObj, fileUrl);
                closeHandler();
            });
        }
    };

    const getCropData = async () => {
        const info = fileInfo?.file?.originFileObj
        if (cropper && info) {
          const base64Data = cropper.getCroppedCanvas().toDataURL();
        
          const blob = await fetch(base64Data).then((res) => res.blob());
          
          const croppedFile: RcFile = new File([blob], info?.name, {
            type: info?.type,
            lastModified: new Date().getTime(),
          }) as RcFile;
      
          croppedFile.uid = info?.uid;
          onUpload(croppedFile, base64Data);
      
          closeHandler();
        }
      };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ModalComponent
            type="no-controllers"
            visible={visible}
            closeHandler={closeHandler}
            className="file-view-modal"
            destroyOnClose={true}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Cropper
                className="cropper"
                src={src!}
                minCropBoxHeight={100}
                cropBoxResizable
                aspectRatio={1}
                minCropBoxWidth={100}
                guides={false}
                checkOrientation={false}
                onInitialized={(instance) => {
                setCropper(instance);
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <ButtonComponent onClick={getCropData} type="primary" className="crop-button">
                Crop Image
                </ButtonComponent>
                <ButtonComponent onClick={closeHandler} type="default" className="crop-button">
                Cancel
                </ButtonComponent>
            </div>
            </div>
        </ModalComponent>
    </div>
    )
}

export default CropImageComponent