import React, { FC, ReactElement } from 'react'
import { Tooltip } from 'antd'
import "./TooltipComponent.scss"
import { getInfoIcon } from '../../utils/getInfoIcon';

interface TooltipComponentProps {
    content: string | ReactElement;
    iconType?: "accept" | "reject";
 }

const TooltipComponent: FC<TooltipComponentProps> = (props) => {

    const { content, iconType } = props

    return (
        <Tooltip className='tooltip-component' title={content}>
            <img className="info-icon" src={getInfoIcon(iconType)} alt="" />
        </Tooltip>
    )
}

export default TooltipComponent