import { Dropdown, Menu, Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import React, { FC, ReactNode } from "react";
import IconComponent from "../IconComponent";
import { handleBeforeImageUpload, handleValidationError } from "../../utils/imageValidation";
import "./menuComponent.scss";

interface MenuComponentProps {
  menu: {
    title: ReactNode;
    subtitle?: ReactNode;
    icon?: ReactNode;
    clickHandler?: () => void;
    upload?: {
      accept: string;
      handleChange?: (info: UploadChangeParam) => void;
    };
  }[];
  dropdownClassName?: string;
  overlayClassName?: string;
  iconColor?: "primary" | "secondary";
  iconName?: string;
  type?: string;
  dropDownPlacement?: "topCenter" | "topLeft" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight" | "top" | "bottom" | undefined
}

interface UploadComponentProps {
  file: RcFile;
  fileUrl: string;
}

const MenuComponent: FC<MenuComponentProps> = (props) => {
  const {
    menu,
    dropdownClassName,
    overlayClassName,
    iconColor = "primary",
    children,
    iconName = "icon-ellipsis",
    type = ""
  } = props;

  const handleChange = (
    info: UploadChangeParam,
    menu: MenuComponentProps["menu"][0]
  ): any => {
    if (menu?.upload?.handleChange && info.file.status !== "uploading" && info.file.originFileObj) {
        menu?.upload?.handleChange(info);
    }
  };

  const handleBeforeUpload = (file: File) => {
    const acceptedTypes = menu?.flatMap(menu => menu?.upload?.accept || []).join(",").split(", ");
    const validationData = handleBeforeImageUpload(file, acceptedTypes, type)
    validationData?.then((result) => {
      handleValidationError(result, type)
    })
    return validationData
  };

  return (
    <Dropdown
      placement="topCenter"
      getPopupContainer={(trigger:HTMLElement) => trigger?.parentNode as HTMLElement}
      trigger={["hover"]}
      className={`menu-component ${iconColor} ${dropdownClassName} `}
      overlay={
        <Menu className={`menu-component-overlay ${overlayClassName}`}>
          {menu?.map((value, index) => {
            return value?.upload ? (
              <Upload
                beforeUpload={handleBeforeUpload}
                accept={value?.upload?.accept}
                showUploadList={false}
                onChange={(info) => {
                  handleChange(info, value);
                }}
              >
                <Menu.Item icon={value?.icon}>{value?.title}</Menu.Item>
              </Upload>
            ) : (
              <Menu.Item icon={value?.icon} onClick={value?.clickHandler}>
                <div>{value?.title}</div>
                <div>{value?.subtitle}</div>
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <div>{children ?? <IconComponent name={iconName === "icon-down" ? "icon-down" : "icon-ellipsis"} />}</div>
    </Dropdown>
  );
};

export default MenuComponent;
