import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import { axiosInstance, axiosInstanceV2 } from "../../interceptor/axiosInstance";
import {
  VendorStorePromotionCreationModel,
  VendorStorePromotionModel,
} from "../../models/Vendor/StorePromotion/storePromotion.model";
import { VendorPurchaseConfigModel } from "../../models/Vendor/PurchaseConfig/purchaseConfig.model";
import { Vendor, VendorAttachment, VendorListModel, VendorModel } from "../../models/Vendor/vendor.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import {
  TerminalIdModel,
  VendorInstoreCreationModel,
  VendorInstoreModel,
} from "../../models/Vendor/Instore/instore.model";
import { VendorSettlementModel } from "../../models/Vendor/Settlement/settlement.model";
import { PaginationModel } from "../../models/pagination.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";

const VendorService = () => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState<VendorListModel[]>([]);
  const [unverifiedVendors, setUnverifiedVendors] = useState<VendorListModel[]>(
    []
  );
  const [unapprovedInstores, setUnapprovedInstores] = useState<
    VendorInstoreModel[]
  >([]);
  const [vendor, setVendor] = useState<Vendor>();
  const [vendorPurchaseConfig, setVendorPurchaseConfig] = useState<
    VendorPurchaseConfigModel[]
  >([]);
  const [vendorStorePromotions, setVendorStorePromotions] = useState<
    VendorStorePromotionModel[]
  >([]);
  const [vendorInstores, setVendorInstores] = useState<VendorInstoreModel[]>(
    []
  );
  const [vendorSettlements, setVendorSettlements] = useState<
    VendorSettlementModel[]
  >([]);

  const [vendorInstore, setVendorInstore] = useState<VendorInstoreModel>();

  const [vendorDocument, setVendorDocument] = useState<VendorAttachment>();

  const [paginations, setPaginations] = useState<PaginationModel>()

  const fetchVendorList = (params?: SortFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    setVendors([])
    return axiosInstance
      .get(ApiRoutes.VENDOR_LIST, { params: _params })
      .then((response) => {
        const vendors = deserialize(
          VendorListModel,
          response?.data?.vendors
        ) as VendorListModel[];
        const _paginations = deserialize(PaginationModel,response.data["meta"]);
				setPaginations(_paginations)
        setVendors(vendors);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createVendor = (vendor: FormData, onSuccess: () => void) => {
    setLoading(true);
    return axiosInstance
      .post(ApiRoutes.VENDOR_LIST, vendor)
      .then((response) => {
        const vendor = deserialize(VendorModel, response?.data?.vendor);
        setVendors((prev) => [...prev, vendor]);
        onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVendor = (
    vendorId: number|string,
    vendor: FormData,
    onSuccess: () => void,
    onError?: Function,
    onFinal?: () => void,
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_DETAILS, {
      vendorId: vendorId?.toString(),
    });

    return axiosInstance
      .put(API_URL, vendor)
      .then((response) => {
        const vendor = deserialize(VendorModel, response?.data?.vendor);
        setVendors((prev) => [...prev, vendor]);
        onSuccess();
      })
      .catch((error) => {
        setError(error);
        onError && onError()
      })
      .finally(() => {
        setLoading(false);
        onFinal && onFinal()
      });
  };

  const fetchVendor = (vendorId: string, loader: boolean = true) => {
    loader && setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_DETAILS, { vendorId });
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const vendor = deserialize(Vendor, response?.data?.vendor);
        setVendor(vendor);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        loader && setLoading(false);
      });
  };
  const fetchVendorPurchaseConfigList = (vendorId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_PURCHASE_CONFIG_LIST, {
      vendorId,
    });
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          VendorPurchaseConfigModel,
          response?.data?.["vendor_purchase_configs"]
        ) as VendorPurchaseConfigModel[];
        setVendorPurchaseConfig(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVendorPurchaseConfig = (
    data: VendorPurchaseConfigModel,
    onSuccess: () => void
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_PURCHASE_CONFIG_DETAILS, {
      vendorPurchaseConfigId: data?.id?.toString(),
    });
    const payload = {...data};
    delete payload["id"];
    delete payload["deallScoreRange"];
    delete payload["internalScore"];
    delete payload["vendorId"];

    const requestPayload = {
      vendors_purchase_config: serialize(VendorPurchaseConfigModel, data),
    };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(
          VendorPurchaseConfigModel,
          response?.data?.["vendors_purchase_config"]
        );
        setVendorPurchaseConfig((prev) =>
          prev?.map((config) => (config?.id == data?.id ? data : config))
        );
        onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchStorePromotionList = (vendorId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_STORE_PROMOTION_LIST, {
      vendorId,
    });
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          VendorStorePromotionModel,
          response?.data?.["vendor_attachments"]
        ) as VendorStorePromotionModel[];
        setVendorStorePromotions(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createVendorStorePromotion = (
    vendorId: string,
    data: VendorStorePromotionCreationModel,
    callback: (vendorStorePromotion?: VendorStorePromotionModel) => void
  ) => {
    const requestPayload = {
      vendor_attachment: serialize(VendorStorePromotionCreationModel, data),
    };
    const API_URL = generatePath(ApiRoutes.VENDOR_STORE_PROMOTION_LIST, {
      vendorId,
    });
    return axiosInstance
      .post(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(
          VendorStorePromotionModel,
          response?.data?.["vendor_attachment"]
        );
        setVendorStorePromotions((prev) => [...prev, data]);
        callback(response?.data?.vendor_promotions);
      })
      .catch((error) => {
        setError(error);
        callback();
      })
      .finally(callback);
  };

  const deleteVendorStorePromotion = (
    vendorStorePromotionId: number,
    callback: () => void
  ) => {
    const API_URL = generatePath(ApiRoutes.VENDOR_STORE_PROMOTION_DETAILS, {
      vendorStorePromotionId: vendorStorePromotionId?.toString(),
    });
    return axiosInstance
      .delete(API_URL)
      .then(() => {
        setVendorStorePromotions((prev) =>
          prev?.filter((data) => data?.id !== vendorStorePromotionId)
        );
        callback();
      })
      .catch((error) => {
        setError(error);
        callback();
      })
      .finally(callback);
  };

  const fetchVendorInstoreList = (
    params?: SortFilterModel,
    vendorId?: string
  ) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE_LIST, {
      vendorId,
    });
    return axiosInstance
      .get(API_URL, { params: _params })
      .then((response) => {
        const data = deserialize(
          VendorInstoreModel,
          response?.data?.instores
        ) as VendorInstoreModel[];
        setVendorInstores(data);
        const _paginations = deserialize(PaginationModel,response.data["meta"]);
				setPaginations(_paginations)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchVendorInstore = (
    instoreId: string
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE_DETAILS, {vendorInstoreId: instoreId});
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          VendorInstoreModel,
          response?.data?.instore
        );
        setVendorInstore(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createVendorInstore = (
    vendorId: string,
    data: VendorInstoreCreationModel,
    onSuccess?: () => void
  ) => {
    setLoading(true);

    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE_LIST, {
      vendorId,
    });
    const requestPayload = {
      instore: serialize(VendorInstoreCreationModel, data),
    };
    return axiosInstance
      .post(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(VendorInstoreModel, response?.data?.instore);
        setVendorInstore(data);
        onSuccess && onSuccess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVendorInstore = (
    vendorInstoreId: number | string,
    data: VendorInstoreCreationModel,
    onSuccess?: () => void,
    onError?: Function
  ) => {
    setLoading(true);

    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE_DETAILS, {
      vendorInstoreId: vendorInstoreId?.toString(),
    });
    const requestPayload = {
      instore: serialize(VendorInstoreCreationModel, data),
    };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(VendorInstoreModel, response?.data?.instore);
        setVendorInstore(data);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        setError(error);
        onError && onError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVendorInstoreStatus = (
    vendorInstoreId: number,
    data: VendorInstoreCreationModel,
    onSuccess: () => void,
    onError?: Function
  ) => {
    setLoading(true);

    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE_UPDATE_STATUS, {
      vendorInstoreId: vendorInstoreId?.toString(),
    });
    const requestPayload = {
      instore: serialize(VendorInstoreCreationModel, data),
    };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(VendorInstoreModel, response?.data?.instore);
        setVendorInstores((prev) =>
          prev?.map((instore) => (instore?.id == data?.id ? data : instore))
        );
        onSuccess();
      })
      .catch((error) => {
        setError(error);
        onError && onError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteVendorInstore = (vendorInstoreId: number) => {
    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE_DETAILS, {
      vendorInstoreId: vendorInstoreId?.toString(),
    });
    return axiosInstance.delete(API_URL);
  };

  const fetchVendorSettlementList = (params?:SortFilterModel, vendorId?: string, ) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    const API_URL = generatePath(ApiRoutes.VENDOR_SETTLEMENT_LIST, {
      vendorId,
    });
    return axiosInstance
      .get(API_URL, {params: _params})
      .then((response) => {
        const data = deserialize(
          VendorSettlementModel,
          response?.data?.vendor_settlements
        ) as VendorSettlementModel[];
        setVendorSettlements(data);
        const _paginations = deserialize(PaginationModel,response.data["meta"]);
				setPaginations(_paginations)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchUnverifiedVendorList = (params?: SortFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    setUnverifiedVendors([])
    return axiosInstance
      .get(ApiRoutes.UNVERIFIED_VENDOR_LIST, { params: _params })
      .then((response) => {
        const data = deserialize(
          VendorListModel,
          response?.data?.vendors
        ) as VendorListModel[];
        setUnverifiedVendors(data);
        const _paginations = deserialize(PaginationModel,response.data["meta"]);
				setPaginations(_paginations)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchUnapprovedInstoreList = (params?: SortFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    setUnapprovedInstores([]);
    return axiosInstance
      .get(ApiRoutes.UNAPPROVED_VENDOR_INSTORE_LIST, { params: _params })
      .then((response) => {
        const data = deserialize(
          VendorInstoreModel,
          response?.data?.instores
        ) as VendorInstoreModel[];
        setUnapprovedInstores(data);
        const _paginations = deserialize(PaginationModel,response.data["meta"]);
				setPaginations(_paginations)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateRisePointTerminal = (
    data: TerminalIdModel,
    onSuccess?: () => void
    ) => {
    setLoading(true)
    const requestPayload = {
      rise_pos: serialize(TerminalIdModel, data),
    };
    return axiosInstanceV2
      .post(ApiRoutes.UPDATE_POS, requestPayload)
      .then((response) => {
        const data = deserialize(VendorInstoreModel, response?.data?.instore);
        setVendorInstore(data);
        onSuccess && onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const fetchVendorDocument = (documentId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VIEW_VENDOR_DOCUMENT, {
      vendorAttachmentId: documentId,
    });
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const document = deserialize( VendorAttachment, response?.data?.vendor_attachment);
        setVendorDocument(document);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addFawryTerminal = (
    data: TerminalIdModel,
    onSuccess: () => void,
    onError?: Function
  ) => {
    setLoading(true);

    const API_URL = ApiRoutes.FAWRY_POS_DEVICES_INFO;
    const requestPayload = {
      fawry_pos: serialize(TerminalIdModel, data),
    };
    return axiosInstance
      .post(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(VendorInstoreModel, response?.data?.instore);
        setVendorInstores((prev) =>
          prev?.map((instore) => (instore?.id === data?.id ? data : instore))
        );
        onSuccess();
      })
      .catch((error) => {
        setError(error);
        onError && onError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteFawryTerminal = (fawryPosId: string, onSuccess: () => void) => {
    const API_URL = generatePath(ApiRoutes.DELETE_FAWRY_POS_DEVICES, {
      fawryPosId: fawryPosId?.toString(),
    });
    return axiosInstance.delete(API_URL)
          .then((response) => {
            const data = deserialize(VendorInstoreModel, response?.data?.instore);
            setVendorInstores((prev) =>
              prev?.map((instore) => (instore?.id === data?.id ? data : instore))
            );
            onSuccess();
          })
          .catch((error) => {
            setError(error);
          })
          .finally(() => {
            setLoading(false);
          });
  };

  return {
    loading,
    error,
    vendors,
    vendorPurchaseConfig,
    vendor,
    vendorStorePromotions,
    vendorInstores,
    vendorSettlements,
    unverifiedVendors,
    unapprovedInstores,
    fetchVendorList,
    createVendor,
    fetchVendor,
    updateVendor,
    fetchUnverifiedVendorList,
    fetchUnapprovedInstoreList,
    fetchVendorPurchaseConfigList,
    updateVendorPurchaseConfig,
    fetchStorePromotionList,
    createVendorStorePromotion,
    deleteVendorStorePromotion,
    fetchVendorInstoreList,
    createVendorInstore,
    updateVendorInstore,
    updateVendorInstoreStatus,
    deleteVendorInstore,
    fetchVendorSettlementList,
    paginations,
    fetchVendorInstore,
    vendorInstore,
    updateRisePointTerminal,
    fetchVendorDocument,
    vendorDocument,
    addFawryTerminal, 
    deleteFawryTerminal
  };
};

export default VendorService;
