import React from "react";
import {  Outlet } from "react-router-dom";
import Navbar from "../../shared/components/Navbar";
import MobileNavbar from "../../shared/components/Mobilenavbar";
import "./home.scss";
import useWindowWidth from "../../shared/utils/useWindowWidth";
import { mobileScreenWidth } from "../../enums/constants";

export const currencyString = <span>E£ &nbsp;</span>;

const Home = (props: any) => {

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= mobileScreenWidth;
  
  return (
    <div className="home">
      {isMobile ? <MobileNavbar/> : <Navbar />}
      <div className="home-right__container">
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
