import React, { FC, useEffect } from 'react'
import "./customerreferralrewards.scss"
import { Col, Divider, Row } from 'antd'
import tick from "../../../../../../assets/images/tick.png"
import ellipses from "../../../../../../assets/images/ellipses.png"
import accountCreation from "../../../../../../assets/images/account_creation.png"
import coins from "../../../../../../assets/images/coins.png"
import referralReward from "../../../../../../assets/images/referral_reward.png"
import rewardRedeemed from "../../../../../../assets/images/reward_redeemed.png"
import PurchasingStatusService from '../../../../../../services/PurchasingStatusService/purchasingStatus.service'
import { currencyString } from '../../../..'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { maxReferral, maxEligibleReward } from '../../../../../../enums/constants'
import { RewardsHistory } from '../../../../../../models/RewardsHistory/rewardsHistory.model'
import EmptyStateComponent from '../../../../../../shared/components/EmptyStateComponent'

interface TitleDescriptionMapping {
    [title: string]: string;
}

interface CustomerReferralRewardsProps {
    isPhoneUser?: boolean;
}

const CustomerReferralRewards: FC<CustomerReferralRewardsProps> = ({ isPhoneUser }) => {

    const { fetchReferralRewardMetrics, referralRewardMetrics } = PurchasingStatusService()
    const { customerId } = useParams();

    const hasReachedMaxPayments = (referralRewardMetrics?.totalFriendsReferred ?? 0) > 5

    const titleDescriptionMapping: TitleDescriptionMapping = {
        'Referral reward earned': 'On successful referral of',
        'Referral reward redeemed': 'Reward redeemed as discount during the purchase',
        'Account creation using referral code': 'Creating an account using referral code',
      };

    const getDescriptionByTitle = (title: string) => {
        return titleDescriptionMapping[title] || 'No description available';
    };

    const renderProgress = (referred: number) => {
        const progressItems = [];
    
        for (let i = 1; i <= maxReferral; i++) {
          if (i <= referred) {
            progressItems.push(<img className="icon-style-referred" src={tick}></img>);
          } else {
            progressItems.push(<img className="icon-style-referral-left" src={ellipses}></img>);
          }
        }
    
        return progressItems;
    };

    useEffect(() => {
        customerId && fetchReferralRewardMetrics(customerId)
    }, [])
    

    return (
        <div className="customerreferralrewards">
            {isPhoneUser ? <EmptyStateComponent header='Not applicable' description='Referral & rewards is not applicable for mobile purchasing status users' title=''/> : 
            <Row>
                <Col span={6} xs={6}>
                    <div className='referral_cash'>
                        <Row>
                            <Col span={16}>
                                <div className='label'>Referral Cash earned</div>
                                <div className='value'>
                                    {currencyString}{referralRewardMetrics?.referralCashEarned} <span className='value-2'>/ {maxEligibleReward}</span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <img className="icon-style-coins" src={coins} alt="coins"></img>
                            </Col>
                        </Row>
                    </div>
                    <div className='friends_referred'>
                        <div className='label'>Friends referred</div>
                        <div className='value'>
                            {hasReachedMaxPayments ? 5 : referralRewardMetrics?.totalFriendsReferred} 
                            <span className='value-2'>/ {maxReferral}</span>
                            <span>{renderProgress(hasReachedMaxPayments ? 5 : (referralRewardMetrics?.totalFriendsReferred ?? 0))}</span>

                        </div>
                        <Divider />
                        <div className='referral-container'>
                            <div className='referral-item'>
                                <span className='referral_label'>Accepted invite</span>
                                <span className='referral_value'>{referralRewardMetrics?.acceptedInvite}</span>
                            </div>
                            <div className='referral-item'>
                                <span className='referral_label'>Made a purchase</span>
                                <span className='referral_value'>{referralRewardMetrics?.noOfFriendsMadePurchase}</span>
                            </div>
                            <div className='referral-item'>
                                <span className='referral_label'>Made a payment</span>
                                <span className='referral_value'>{referralRewardMetrics?.noOfFriendsMadePayment}</span>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12} xs={12}>
                    <div className='rewards-container'>
                        <div className='label'>Rewards history</div>
                        {referralRewardMetrics?.rewardsHistories?.map((rewards: RewardsHistory) => (
                            <div className='rewards-item-container'>
                                <Row className='rewards-item'>
                                    <Col span={2}><img className="icon-style-reward-redeemed" src={rewards?.title === 'Referral reward earned' ? rewardRedeemed : rewards?.title === 'Referral reward redeemed' ? referralReward : accountCreation} alt='reward'></img></Col>
                                    <Col span={16}>
                                        <div className='title'>{rewards?.title}</div>
                                        <div className='subtitle'>{rewards?.title && getDescriptionByTitle(rewards?.title)}</div>
                                    </Col>
                                    <Col span={5}>
                                        <div className={`value ${rewards?.isInbound ? 'inbound' : 'outbound'}`}>{rewards?.isInbound ? '+' : '-'} {currencyString}{rewards?.amount}</div>
                                        <div className='value-date'>{moment(rewards?.createdAt)?.format("DD MMM")}</div>
                                    </Col>
                                </Row>
                            </div>  
                        ))}
                    </div>
                </Col>
            </Row>
            }
        </div>
    )
}

export default CustomerReferralRewards