import { DatePicker } from "antd";
import { ErrorMessage, Field } from "formik";
import moment, { Moment } from "moment";
import React, { FC, Fragment } from "react";
import Error from "../Error";
import IconComponent from "../IconComponent";
import "./datePickerField.scss";

interface DatePickerFieldProps {
  name: string;
  value: any;
  label: string;
  onChange: (date: moment.Moment | null, dateString: string) => void;
  className?: string;
  placeholder: string;
  format?: string;
  dateRange?: Moment;
  required?: boolean;
}

const DatePickerField: FC<DatePickerFieldProps> = (props) => {
  const { name, value, label, placeholder, format, onChange, className, dateRange, required } = props;

  const disabledDate = (current: Moment | null) => {
    if (!current) return false;

    return ((dateRange && (current < dateRange)) || current.isAfter(moment()));
  };

  return (
    <Fragment>
      <Field name={name}>
        {({ field, form: { touched, errors }, meta }: any) => (
          <div
            className={`date-picker-field ${meta?.error && meta.touched && "error"
              } ${className}`}
          >
            {label ? <label>{label}{required ? "*" : "" }</label> : ""}
            <DatePicker
              disabledDate={disabledDate}
              value={value && moment(value)}
              format={format}
              placeholder={placeholder}
              suffixIcon={<IconComponent name="icon-calender" />}
              onChange={onChange}
            />
          </div>
        )}
      </Field>
      <ErrorMessage name={name}>
        {(message: string) => <Error message={message} />}
      </ErrorMessage>
    </Fragment>

  );
};

export default DatePickerField;
