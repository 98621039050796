import { Row, Col, Divider, DatePicker } from 'antd'
import { Formik, Form, FieldArray, FormikValues, ErrorMessage } from 'formik'
import React, { FC, useEffect, useRef, useState } from 'react'
import CheckboxComponent from '../../../../../shared/components/CheckboxComponent'
import DropdownField from '../../../../../shared/components/DropdownField'
import InputField from '../../../../../shared/components/InputField'
import ModalComponent from '../../../../../shared/components/ModalComponent'
import UploadComponent from '../../../../../shared/components/UploadComponent'
import { hideBrowserSuggestions } from '../../../../../shared/utils/hideBrowserSuggestions'
import { PromotionTypes } from '../../../../../enums/promotionTypes'
import { PromotionTypesDefinition } from '../../../../../definitions/promotionTypesDefinition'
import S3AttachmentService from '../../../../../services/S3UploadService/s3upload.service'
import infoIcon from "../../../../../assets/images/info-icon-alert.svg"
import "./promotionform.scss"
import { Promotion } from '../../../../../models/Promotion/promotion.model'
import { promotionFormValidation } from './promotionValidationSchema'
import moment, { Moment } from 'moment'
import Error from "../../../../../shared/components/Error";
import ConfigurationService from '../../../../../services/ConfigurationService/configuration.service'
import { parseIsoDate } from '../../../../../shared/utils/formatDate'
import { RangePickerValueType } from '../../../../../shared/types/rangePicker.type'
import { generateDateString } from '../../../../../shared/utils/generateDateString'
import { useParams } from 'react-router-dom'
import examplePromo from '../../../../../assets/images/example-promotion.webp'
import phoneIcon from "../../../../../assets/images/phone-icon.svg"
import bagIcon from "../../../../../assets/images/bag-icon.svg"
import PromotionAudienceComponent from './PromotionAudienceComponent'

interface PromotionFormProps {
    data?: Promotion;
    visible: boolean;
    closeHandler: () => void;
    successHandler: (values?: any) => void;
}

const { RangePicker } = DatePicker;

const PromotionForm: FC<PromotionFormProps> = ({  data, visible, closeHandler, successHandler }) => {
    const { promotionId } = useParams();
    const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([moment(parseIsoDate(data?.startDate)) ?? null, moment(parseIsoDate(data?.endDate)) ?? null]);
    const { createPromotion, updatePromotion } = ConfigurationService();
    const feeData: boolean = !!data?.promoConfig?.feePercent?.all || !!data?.promoConfig?.feePercent?.two;
    const installmentData = [{installment:2, percentage: '0'}, {installment:3, percentage: '0'}, {installment:4, percentage: '0'}, {installment:5, percentage: '0'}]
    const processingFeeForInstallments = feeData ? Object.entries(data?.promoConfig?.feePercent!)?.map(([key, value], index) => ({ installment: index + 2, percentage: value })).filter(installments => installments.percentage != undefined) : installmentData;

    const [initialValues, setInitialValues] = useState<Promotion>(new Promotion());
    const [showImage, setShowImage] = useState(false);
    const [type, setType] = useState('');
    const [isFeeSame, setIsFeeSame] = useState(false);
    const formRef = useRef<any>();
    const {
        uploadAttachment,
        loading: attachmentLoading,
      } = S3AttachmentService();

    const resetFormAndClose = () => {
      formRef?.current?.resetForm({ values: {} });
      closeHandler();
    };

    const onSubmit = (values: FormikValues) => {
      const feePercent = values?.isProcessingFeeSame ? {all: values?.discountedProcessingFee} : values?.promoConfig?.installmentPercentage?.reduce((acc: { [x: string]: number }, fee: { installment: number; percentage: number }) => {
        const key = ['two', 'three', 'four', 'five'][fee.installment - 2];
        acc[key] = Number(fee.percentage) ?? undefined;
        return acc;
      }, {} as { [key: string]: number });

      let customers: string[] = [];
      let purchaseType: string[] = [];

      if(values?.phoneCredit) customers.push('phone_credit');
      if(values?.purchaseTypeOther) customers.push('credit');
      if(values?.phone) customers.push('phone');
      if(values?.purchaseTypePhone) purchaseType.push('phone');
      if(values?.purchaseTypeOther) purchaseType.push('others');
      
      let updatedValue = {
        title: values?.title,
        startDate: values?.startDate,
        endDate: values?.endDate,
        discountType: values?.discountType,
        promoId: values?.promoId,
        status: "pending",
        promoConfig: {
          feePercent: feePercent,
          purchaseType: purchaseType,
          customers: customers,
          purchasePercent: values?.purchasePercent
        },
        images: {
          explainerBannerArId: values?.images?.explainerBannerArId,
          explainerBannerEnId: values?.images?.explainerBannerEnId,
          landingBannerArId: values?.images?.landingBannerArId,
          landingBannerEnId: values?.images?.landingBannerEnId,
          smallBannerArId: values?.images?.smallBannerArId,
          smallBannerEnId: values?.images?.smallBannerEnId
        }
      };
      let payload: Promotion = { ...updatedValue };
      promotionId ? updatePromotion(promotionId, payload, successHandler) : createPromotion(payload, successHandler);
    }

    useEffect(() => {
      promotionId ? setDateRange([moment(parseIsoDate(data?.startDate)) ?? null, moment(parseIsoDate(data?.endDate)) ?? null]) : setDateRange([null, null]);
      if (promotionId){
        setInitialValues({
          title: data?.title,
          startDate: data?.startDate,
          endDate: data?.endDate,
          discountType: data?.discountType,
          promoConfig: {
            installmentPercentage: processingFeeForInstallments,
            feePercent: {
              two: data?.promoConfig?.feePercent?.two,
              three: data?.promoConfig?.feePercent?.three,
              four: data?.promoConfig?.feePercent?.four,
              five: data?.promoConfig?.feePercent?.five,
              all: data?.promoConfig?.feePercent?.all
            },
            purchaseType: data?.promoConfig?.purchaseType,
            customers: data?.promoConfig?.customers,
          },
          purchasePercent: data?.purchasePercent,
          discountedProcessingFee: data?.promoConfig?.feePercent?.all,
          images: {
            landingBannerAr: data?.images?.landingBannerAr,
            landingBannerEn: data?.images?.landingBannerEn,
            smallBannerAr: data?.images?.smallBannerAr,
            smallBannerEn: data?.images?.smallBannerEn,
            explainerBannerAr: data?.images?.explainerBannerAr,
            explainerBannerEn: data?.images?.explainerBannerEn,
            landingBannerArId: data?.images?.landingBannerArId ?? data?.images?.landingBannerAr?.id,
            landingBannerEnId: data?.images?.landingBannerEnId ?? data?.images?.landingBannerEn?.id,
            smallBannerArId: data?.images?.smallBannerArId ?? data?.images?.smallBannerAr?.id,
            smallBannerEnId: data?.images?.smallBannerEnId ?? data?.images?.smallBannerEn?.id,
            explainerBannerArId: data?.images?.explainerBannerArId ?? data?.images?.explainerBannerAr?.id,
            explainerBannerEnId: data?.images?.explainerBannerEnId ?? data?.images?.explainerBannerEn?.id,
          },
          phone: !!data?.promoConfig?.customers?.includes("phone"),
          phoneCredit: !!data?.promoConfig?.customers?.includes("phone_credit"),
          credit: !!data?.promoConfig?.customers?.includes("credit"),
          purchaseTypePhone: !!data?.promoConfig?.purchaseType?.includes("phone"),
          purchaseTypeOther: !!data?.promoConfig?.purchaseType?.includes("others"),
          isProcessingFeeSame: Number(data?.promoConfig?.feePercent?.all) >= 0,
        });
      } else{
        setInitialValues({...new Promotion(),           
          promoConfig: {
            installmentPercentage: processingFeeForInstallments,
            feePercent: {
              two: '0',
              three: '0',
              four: '0',
              five: '0',
              all: '0'
            },
        },
        purchasePercent: '0',
        discountedProcessingFee: '0',
        phone: false,
        credit: false,
        phoneCredit: false,
        purchaseTypeOther: false,
        purchaseTypePhone: false
      })
      }
    }, [data, visible])

    return (
        <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        innerRef={formRef}
        validationSchema={promotionFormValidation(type, isFeeSame)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <>
            <Form onScroll={hideBrowserSuggestions}>
              <ModalComponent
                visible={visible}
                closeHandler={resetFormAndClose}
                type="larger"
                successProps={{
                  title: data ? "Update" : "Create",
                  clickHandler: handleSubmit,
                  loading: attachmentLoading,
                  successDisabled:   (values.purchaseTypePhone && !(values.phone || values.phoneCredit)) || 
                  (!values.purchaseTypePhone && !values.purchaseTypeOther)
                }}
                cancelProps={{
                  title: "Discard",
                  clickHandler: resetFormAndClose,
                  additionaltypes: 'rise-secondary'
                }}
                className="promotion-form-modal"
                destroyOnClose={true}
              >
                <div className="promotion-form">
                <div className='audience-header'>New promotion</div>
                  <Row gutter={[16,10]}>
                    <Col span={12}>
                      <InputField
                        label="Promotion name"
                        placeholder="Enter"
                        name="title"
                      />
                    </Col>
                    <Col span={12}>
                      <div className="input-field">
                          <label>Promotion Date</label>
                          <RangePicker
                            disabledDate={(date) => date.isBefore(moment().startOf('day'))}
                            value={dateRange}
                            className={`picker-field ${errors.startDate ? 'error' : ''}`}
                            onChange={(values) => {
                              if (values?.length) {
                                setDateRange(values)
                                const dataString = generateDateString(
                                  values as RangePickerValueType
                                );
                                setFieldValue(
                                  "startDate",
                                  dataString?.split(" - ")[0]
                                );
                                setFieldValue(
                                  "endDate",
                                  dataString?.split(" - ")[1]
                                );
                              }
                            }}
                            format={'DD/MM/YYYY'} />
                            {errors.startDate &&
                            <ErrorMessage name={'startDate'}>
                                {(message: string) =>
                                    <Error message={message} />
                                }
                            </ErrorMessage>
                            }
                      </div>
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        label="Promotion type"
                        placeholder="Select"
                        name="discountType"
                        value={values?.discountType}
                        options={[
                          {
                              value: PromotionTypes.PROCESSING_FEE_PERCENTAGE,
                              label: PromotionTypesDefinition[PromotionTypes.PROCESSING_FEE_PERCENTAGE],
                          },
                          {
                              value: PromotionTypes.PURCHASE_DISCOUNT_PERCENTAGE,
                              label: PromotionTypesDefinition[PromotionTypes.PURCHASE_DISCOUNT_PERCENTAGE],
                          }
                          ]}
                        onChange={(value) =>{
                          setType(value);
                          setFieldValue("discountType", value);
                          if(value === 'fee_percentage') {setFieldValue("purchasePercent", 0)} 
                          if(value === 'purchase_percentage') { setFieldValue("discountedProcessingFee", 0)}
                          if(value === 'purchase_percentage') { [0,1,2,3].map(installment => setFieldValue(`promoConfig.installmentPercentage.${installment}.percentage`, 0))}
                        }
                        }
                      />
                    </Col>
                    <Col span={11}>
                      {values?.discountType === 'purchase_percentage' && 
                      <InputField
                        name="purchasePercent"
                        label="Purchase discount %"
                        placeholder="Enter"
                        type="text"
                      />}
                    </Col>
                    {(values?.isProcessingFeeSame && values?.discountType === 'fee_percentage') &&                      
                      <Col span={(values?.isProcessingFeeSame && values?.discountType === 'fee_percentage') ? 12 : 0}>
                        <InputField
                          name="discountedProcessingFee"
                          label="Discounted processing fee %"
                          placeholder="Enter"
                          type="text"
                        />
                      </Col>
                    }
                    <Col span={12} />
                    {values?.discountType === 'fee_percentage' &&
                    <Col span={24}>
                      <CheckboxComponent 
                        value={values?.isProcessingFeeSame!} 
                        title="Same processing fee for all number of installments" 
                        onChecked={(value) => {
                          setIsFeeSame(value?.target?.checked);
                          setFieldValue('isProcessingFeeSame', value?.target?.checked);
                        }
                        } 
                      />
                    </Col>}
                    {(!values?.isProcessingFeeSame && values?.discountType === 'fee_percentage') &&
                      <div className='installment-container'>
                        <Row>
                          <Col span={5}><label>Installment</label></Col>
                          <Col span={13}><label>Discounted Processing fee %</label></Col>
                        </Row>
                        <FieldArray
                          name="promoConfig.installmentPercentage"
                          render={() => (
                            <div className="promo-config-container">
                              {values?.promoConfig?.installmentPercentage?.map((installment, index) => (
                                <Row className="input-row" key={index} gutter={[4,4]}>
                                  <Col span={5}>
                                    <InputField
                                      name={`promoConfig.installmentPercentage.${index}.installment`}
                                      placeholder="Installment"
                                      disabled
                                    />
                                  </Col>
                                  <Col span={14}>
                                    <InputField
                                      name={`promoConfig.installmentPercentage.${index}.percentage`}
                                      placeholder="Enter"
                                    />
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          )}
                        />
                      </div>
                    }
                  </Row>
                  <Divider />
                  <div className='audience-header'>Promotion audience</div>
                  <div className='subtitle'>Purchase type</div>
                  <Row>
                  <Col span={9}><PromotionAudienceComponent title='Phone' iconName={phoneIcon} setFieldValue={setFieldValue} fieldName="purchaseTypePhone"  /></Col>
                  <Col span={9}><PromotionAudienceComponent title='Other' iconName={bagIcon} setFieldValue={setFieldValue} fieldName="purchaseTypeOther" /></Col>
                  </Row>
                  {!(values.purchaseTypePhone || values.purchaseTypeOther) && (
                    <Error message="Promotion audience is required" />
                  )}

                  {values.purchaseTypePhone && !(values.phone || values.phoneCredit) && (
                    <Error message="Select at least one customer for phone purchase" />
                  )}
                  <Divider />
                  <div className='asset-container'>
                  <h4>Promotion assets</h4>
                  <div className='promotion-header' style={{ position: 'relative' }}>
                    <img src={infoIcon} alt="info-icon" className='sample-text' /> 
                    <span>Get promotion assets from Rise design team</span> 
                    <span 
                        className='view-example' 
                        onMouseEnter={() => setShowImage(true)} 
                        onMouseLeave={() => setShowImage(false)}
                    >
                        View example
                    </span>
                    <img 
                        src={examplePromo} 
                        alt="Promotion Example" 
                        className={`promotion-example-image ${showImage ? 'show' : 'hide'}`} 
                    />
                  </div>
                  <div className='title'>Landing page banner</div>
                  <div className='subtitle'>Upload size (w*h) : 1080 * 1500 px, File format : png file</div>
                  <div className='banner-container'>
                    <div className='asset-1'>
                      <div className='banner'>
                      <UploadComponent
                        fileId={
                        values?.images?.landingBannerEnId || ''
                        }
                        fileType={values?.images?.landingBannerEn?.fileType || ''}
                        fileName={values?.images?.landingBannerEn?.filename || ''}
                        accept={"image/png"}
                        fileUrl={values?.images?.landingBannerEn?.s3Url || ''}
                        title={
                        <>
                            <div>Upload image</div>
                        </>
                        }
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("images.landingBannerEn.s3Url", fileUrl);
                          setFieldValue("images.landingBannerEn.filename", file?.name);
                          const _attachmentId = await uploadAttachment(file);
                          setFieldValue("images.landingBannerEnId", _attachmentId);
                        }}
                        onRemove={() => {
                          setFieldValue("images.landingBannerEn.s3Url", undefined);
                          setFieldValue("images.landingBannerEnId", undefined);
                        }}
                        type="banner-preview"
                        additionalType='landing'
                        label='English asset'
                        language='en'
                        error={(errors as unknown as Promotion)?.images?.landingBannerEnId}
                        />
                      </div>
                      </div>
                    <div className='asset-2'>
                      <div className='banner'>
                      <UploadComponent
                        fileId={
                        values?.images?.landingBannerAr?.id || ''
                        }
                        fileType={values?.images?.landingBannerAr?.fileType || ''}
                        fileName=''
                        accept={"image/png"}
                        fileUrl={values?.images?.landingBannerAr?.s3Url || ''}
                        type="banner-preview"
                        additionalType='landing'
                        label='الأصول العربية'
                        language='ar'
                        title={
                        <>
                            <div>تحميل الصورة</div>
                        </>
                        }
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("images.landingBannerAr.s3Url", fileUrl);
                          setFieldValue("images.landingBannerAr.filename", file?.name);
                          const _attachmentId = await uploadAttachment(file);
                          setFieldValue("images.landingBannerArId", _attachmentId);
                        }}
                        onRemove={() => {
                          setFieldValue("images.landingBannerAr.s3Url", undefined);
                          setFieldValue("images.landingBannerArId", undefined);
                        }}
                        error={(errors as unknown as Promotion)?.images?.landingBannerArId}
                    />
                      </div>
                    </div>
                  </div>
                  <div className='title'>Explainer page banner</div>
                  <div className='subtitle'>Upload size (w*h) : 1092 * 1008 px, File format : png file</div>
                  <div className='banner-container'>
                    <div className='asset-1'>
                      <div className='banner explainer'>
                      <UploadComponent
                        fileId={
                        values?.images?.explainerBannerEn?.id || ''
                        }
                        fileType={values?.images?.explainerBannerEn?.fileType || ''}
                        fileName=''
                        accept={"image/png"}
                        fileUrl={values?.images?.explainerBannerEn?.s3Url || ''}
                        type="banner-preview"
                        additionalType='explainer'
                        label='English asset'
                        language='en'
                        title={
                        <>
                            <div>Upload image</div>
                        </>
                        }
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("images.explainerBannerEn.s3Url", fileUrl);
                          const _attachmentId = await uploadAttachment(file);
                          setFieldValue("images.explainerBannerEnId", _attachmentId);
                        }}
                        onRemove={() => {
                          setFieldValue("images.explainerBannerEn.s3Url", undefined);
                          setFieldValue("images.explainerBannerEnId", undefined);
                        }}
                        error={(errors as unknown as Promotion)?.images?.explainerBannerEnId}
                    />
                      </div>
                    </div>
                    <div className='asset-2'>
                      <div className='banner explainer'>
                      <UploadComponent
                        fileId={
                        values?.images?.explainerBannerAr?.id || ''
                        }
                        fileType={values?.images?.explainerBannerAr?.fileType || ''}
                        fileName=''
                        accept={"image/png"}
                        fileUrl={values?.images?.explainerBannerAr?.s3Url || ''}
                        type="banner-preview"
                        additionalType='explainer'
                        label='الأصول العربية'
                        language='ar'
                        title={
                        <>
                            <div>تحميل الصورة</div>
                        </>
                        }
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("images.explainerBannerAr.s3Url", fileUrl);
                          const _attachmentId = await uploadAttachment(file);
                          setFieldValue("images.explainerBannerArId", _attachmentId);
                        }}
                        onRemove={() => {
                          setFieldValue("images.explainerBannerAr.s3Url", undefined);
                          setFieldValue("images.explainerBannerArId", undefined);
                        }}
                        error={(errors as unknown as Promotion)?.images?.explainerBannerArId}
                    />
                      </div>
                    </div>
                  </div>
                  <div className='title'>Small banner</div>
                  <div className='subtitle'>Upload size (w*h) : 1092 * 308 px, File format : png file</div>
                  <div className='banner-container'>
                    <div className='asset-1'>
                      <div className='banner small'>
                      <UploadComponent
                        fileId={
                        values?.images?.smallBannerEn?.id || ''
                        }
                        fileType={values?.images?.smallBannerEn?.fileType || ''}
                        fileName=''
                        accept={"image/png"}
                        fileUrl={values?.images?.smallBannerEn?.s3Url || ''}
                        type="banner-preview"
                        additionalType='small'
                        label='English asset'
                        language='en'
                        title={
                        <>
                            <div>Upload image</div>
                        </>
                        }
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("images.smallBannerEn.s3Url", fileUrl);
                          const _attachmentId = await uploadAttachment(file);
                          setFieldValue("images.smallBannerEnId", _attachmentId);
                        }}
                        onRemove={() => {
                          setFieldValue("images.smallBannerEn.s3Url", undefined);
                          setFieldValue("images.smallBannerEnId", undefined);
                        }}
                        error={(errors as unknown as Promotion)?.images?.smallBannerEnId}
                    />
                      </div>
                    </div>
                    <div className='asset-2'>
                      <div className='banner small'>
                      <UploadComponent
                        fileId={
                        values?.images?.smallBannerAr?.id || ''
                        }
                        fileType={values?.images?.smallBannerAr?.fileType || ''}
                        fileName=''
                        accept={"image/png"}
                        fileUrl={values?.images?.smallBannerAr?.s3Url || ''}
                        type="banner-preview"
                        additionalType='small'
                        label='الأصول العربية'
                        language='ar'
                        title={
                        <>
                            <div>تحميل الصورة</div>
                        </>
                        }
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("images.smallBannerAr.s3Url", fileUrl);
                          const _attachmentId = await uploadAttachment(file);
                          setFieldValue("images.smallBannerArId", _attachmentId);
                        }}
                        onRemove={() => {
                          setFieldValue("images.smallBannerAr.s3Url", undefined);
                          setFieldValue("images.smallBannerArId", undefined);
                        }}
                        error={(errors as unknown as Promotion)?.images?.smallBannerArId}
                    />
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </ModalComponent>
            </Form>
            </>
          );
        }}
      </Formik>
    )
}

export default PromotionForm