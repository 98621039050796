import React, {FC} from 'react'
import "./CustomerContactsStatistics.scss"
import {Col, Row} from "antd";

interface CustomerContactsStatisticsProps {
    data?: Record<string, number>
}

const CustomerContactsStatistics: FC<CustomerContactsStatisticsProps> = ({data}) => {
    let stats = {
        'Contacts Approved': data?.Approved ?? 0,
        'Contacts with late payments': data?.['Related Contacts With Late Payment'] ?? 0,
        'Contacts with default payments': data?.['Related Contacts With Overdue'] ?? 0,
    };

    return (
        <div>
            <p className="customer-stat-title">Contact data analysis</p>
            <Row gutter={1} className="customer-stat-container">
                <Col md={7} xs={24} className="customer-stat-row">
                    Contacts Approved
                    <p className="stat-number">{stats['Contacts Approved']} <span className="green-circle"></span></p>
                </Col>
                <Col md={7} xs={24} className="customer-stat-row">
                    Contacts with late payments
                    <p className="stat-number">{stats['Contacts with late payments']} <span className="orange-circle"></span></p>
                </Col>
                <Col md={7} xs={24} className="customer-stat-row">
                    Contacts with default payments
                    <p className="stat-number">{stats['Contacts with default payments']} <span className="red-circle"></span></p>
                </Col>
            </Row>
        </div>
    )
}

export default CustomerContactsStatistics