import {serializable, alias, object, primitive} from 'serializr';

class KycConfigurations { 
	@serializable(alias('kyc_verification', primitive()))
	kycVerification?: string;
}
export class InstallmentProcessingFeeList {
	@serializable(alias("processing_fee", primitive()))
	processingFee?: number;
  
	@serializable(alias("number_of_installments", primitive()))
	numberOfInstallments?: number;
  }
class ConfigJson { 
	@serializable(alias('threshold_credit_score_v2', primitive()))
	thresholdCreditScoreV2?: number;

	@serializable(alias('threshold_credit_score_v3', primitive()))
	thresholdCreditScoreV3?: number;

	@serializable(alias('phone_threshold_credit_score_v2', primitive()))
	phoneThresholdCreditScoreV2?: number;

	@serializable(alias('phone_threshold_credit_score_v3', primitive()))
	phoneThresholdCreditScoreV3?: number;

	@serializable(alias('threshold_credit_score_v4', primitive()))
	thresholdCreditScoreV4?: number;

	@serializable(alias('phone_threshold_credit_score_v4', primitive()))
	phoneThresholdCreditScoreV4?: number;

	@serializable(alias('max_down_payment_percent', primitive()))
	maxDownPaymentPercent?: number;

	@serializable(alias('min_down_payment_percent', primitive()))
	minDownPaymentPercent?: number;

	@serializable(alias('installments', object(InstallmentProcessingFeeList)))
	installments?: InstallmentProcessingFeeList;
}

export class RiseConfig { 
	@serializable(alias('config_json', object(ConfigJson)))
	configJson?: ConfigJson;
}

export  class CreditScoreConfig { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('kyc_configurations', object(KycConfigurations)))
	kycConfigurations?: KycConfigurations;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('configuration_identifier', primitive()))
	configurationIdentifier?: string;

	@serializable(alias('identifier_type', primitive()))
	identifierType?: string;

	@serializable(alias('config_json', object(ConfigJson)))
	configJson?: ConfigJson;

	@serializable(alias('config_class_name', primitive()))
	configClassName?: string;

}