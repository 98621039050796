import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import "./styles/_main.scss";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<BrowserRouter><App /></BrowserRouter>);

serviceWorker.unregister();
