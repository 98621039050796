import * as Yup from "yup";

export const promotionFormValidation = (type: string, isSame: boolean) => {
  let schema = Yup.object().shape({
    title: Yup.string().required('Promotion name is required'),
    discountType: Yup.string().required('Promotion type is required'),
    startDate: Yup.string().nullable().required('Date range is required'),
    purchasePercent: Yup.number()
      .transform((value, originalValue) => {
        const parsedValue = Number(originalValue);
        return isNaN(parsedValue) ? undefined : parsedValue;
      })
      .when('discountType', {
        is: 'purchase_percentage',
        then: Yup.number()
          .required('Purchase discount % is required')
          .typeError('Must be a valid number')
          .min(0, 'Must be at least 0')
          .nullable(),
        otherwise: Yup.number().nullable(),
      }),
    discountedProcessingFee: Yup.number()
      .when(['discountType', 'isProcessingFeeSame'], {
        is: (discountType, isProcessingFeeSame) => discountType === 'fee_percentage' && isProcessingFeeSame,
        then: Yup.number().required('Discounted processing fee % is required').typeError('Must be a valid number').nullable().min(0, 'Must be at least 0'),
        otherwise: Yup.number().nullable(),
      }),
    promoConfig: Yup.object().shape({
      installmentPercentage: Yup.array().nullable(),
    }),
    phone: Yup.boolean(),
    basic: Yup.boolean(),
    silver: Yup.boolean(),
    gold: Yup.boolean(),
    images: Yup.object().shape({
      landingBannerEnId: Yup.string().required('Landing banner is required'),
      landingBannerArId: Yup.string().required('Landing banner is required'),
      explainerBannerEnId: Yup.string().required('Explainer banner is required'),
      explainerBannerArId: Yup.string().required('Explainer banner is required'),
      smallBannerEnId: Yup.string().required('Small banner is required'),
      smallBannerArId: Yup.string().required('Small banner is required')
    })
  });

  if (type === 'fee_percentage' && !isSame) {
    schema = schema.shape({
      promoConfig: Yup.object().shape({
        installmentPercentage: Yup.array().of(
          Yup.object().shape({
            percentage: Yup.number()
              .typeError('Must be a valid number')
              .required('Percentage is required')
              .min(0, 'Percentage must be at least 0')
              .max(100, 'Percentage must be at most 100')
              .nullable()
          })
        ).required('Installment percentages are required'),
      }),
    });
  }

  return schema;
};
