import * as Yup from  "yup";

export const downpaymentFormValidation = (
  type: 'Minimum' | 'Maximum',
  maxValue?: number,
  minValue?: number
) =>
  Yup.object().shape({
    downpayment: Yup.number()
      .min(
        type === 'Maximum' && minValue !== undefined ? minValue : 0,
        type === 'Maximum'
          ? `Maximum down payment % cannot be less than minimum (${minValue}%)`
          : 'Down payment % must be at least 0'
      )
      .max(
        type === 'Minimum' && maxValue !== undefined ? maxValue : 100,
        type === 'Minimum'
          ? `Minimum down payment % cannot exceed maximum (${maxValue}%)`
          : 'Down payment % must be at most 100'
      )
      .typeError('Down payment % must be a number')
      .required('Down payment % is required.')
  });
