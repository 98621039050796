export enum BadgeTypes {
    ACTIVE = "active",
    APPROVED = "approved",
    INACTIVE = "inactive",
    COMPLETED = "completed",
    REFUND_COMPLETED = "refund_completed",
    PURCHASED = "purchased",
    PENDING = "pending",
    SUCCESS = "success",
    INITIATED = "initiated",
    PARTIAL_RETURN = "partial_return",
    PENDING_APPROVAL = "waiting_for_approval",
    PENDING_SETTLEMENT = "pending_settlement",
    RETURNED = "returned",
    RETURN_CANCELLED = "return_cancelled",
    OVERDUE = "overdue",
    OUTSTANDING = "outstanding",
    OUTSTANDING_PAYMENT = "outstanding_payment",
    SETTLED = "settled",
    REJECTED = "rejected",
    ADMIN= "admin",
    INTERNAL_USER = "internal_user",
    MANAGER = "manager",
    VIEWER = "viewer",
    FAILED = "failed",
    KYC_PENDING = "verification_in_progress",
    KYC_APPROVED = "kyc_approved",
    KYC_FAILED = "kyc_failed",
    RETURN = "return",
    WAITLISTED = "waitlisted",
    FAILED_VERIFICATION = "failed_verification",
    MANUAL_VERIFICATION = "manual_verification",
    PENDING_SETTLEMENTS = "pending_settlements",
    FAWRY = "fawry",
    PAID = "paid",
    FAILURE = "failure",
    PENDING_VERIFICATION = "pending_verification",
    REAPPLY = "reapply",
    USER_CANCELLED = "user_cancelled"
}