import React, { FC, Fragment, useEffect } from 'react'
import { Spin } from 'antd';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

interface FileViewerProps {
  uri: string,
  fileType?: string,
  documentData: ArrayBuffer | null,
  isPasswordProtected: boolean,
  fileError: boolean
}

const FileViewer: FC<FileViewerProps> = ({ uri, fileType, documentData, isPasswordProtected, fileError }) => {
  const base64String = documentData 
    ? `data:application/${fileType};base64,${btoa(Array.from(new Uint8Array(documentData)).map(byte => String.fromCharCode(byte)).join(''))}`
    : '';

  return (
    <Fragment>
      {documentData && !isPasswordProtected ? (
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          style={{
            minHeight: 500,
            minWidth: 350,
            marginLeft: -140
          }}
          config={{
            header: {
              disableFileName: true,
            },
          }}
          documents={[
            {
              uri: base64String,
              fileType,
            },
          ]}
        />
      ) : !uri ?
        <div className="spin-container">
          <Spin />
        </div> :
        (
          <div>{isPasswordProtected ? 'The document is password protected.' : fileError ? "Error loading the document" : 'Loading document...'}</div>
        )}
    </Fragment>
  )
}

export default FileViewer