import React, { FC, ReactElement } from 'react'
import { CustomerModel } from '../../../models/Customer/customer.model'
import { displayDash } from '../../utils/displayDash';
import { Divider } from "antd"
import { DragOutlined } from '@ant-design/icons'

interface CustomerdetailsProps { 
    customer: CustomerModel;
    renderDivider: ReactElement;
    isMobile: boolean;
    detailsExpanded: () => void;
    isApprovedUser: boolean;
}

const Customerdetails: FC<CustomerdetailsProps> = ({customer, renderDivider, isMobile, detailsExpanded, isApprovedUser}) => {

return (
<>
  {!isMobile && <div className="subheader">Customer details</div>}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">National ID</p>
    <p className="kyc-value">{displayDash(customer?.nationalIdNumber)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Name (Customer provided name)</p>
    <p className="kyc-value">{displayDash(customer?.fullName)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Name (Captured from ID)</p>
    <p className="kyc-value">{displayDash(customer?.nameFromId)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">DOB</p>
    <p className="kyc-value">{displayDash(customer?.dateOfBirth)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Address (Captured from ID)</p>
    <p className="kyc-value">{displayDash(customer?.address)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">City (Captured from ID)</p>
    <p className="kyc-value">{displayDash(customer?.city?.label)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Region (Captured from ID)</p>
    <p className="kyc-value">{displayDash(customer?.region?.label)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Neighbourhood (from ID) </p>
    <p className="kyc-value">{displayDash(customer?.neighbourhood?.label)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Neighborhood (from questionnaire) </p>
    <p className="kyc-value">{displayDash(customer?.neighbourhoodFromQuestionnaire?.label)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Job Title</p>
    <p className="kyc-value">{displayDash(customer?.jobTitle)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Professional Group</p>
    <p className="kyc-value">{displayDash(customer?.professionalGroup?.label)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Marital Status</p>
    <p className="kyc-value">{displayDash(customer?.maritalStatus)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Religion</p>
    <p className="kyc-value">{displayDash(customer?.religion)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Sex</p>
    <p className="kyc-value">{displayDash(customer?.gender)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">iscore</p>
    <p className="kyc-value">{displayDash(customer?.iscore)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">OS</p>
    <p className="kyc-value">{displayDash(customer?.os)}</p>
  </div>
  {renderDivider}
  <div className="approve-customer-third-party-details-card-item">
    <p className="approve-customer-third-party-details-card-item-title">Acquisition channel</p>
    <p className="kyc-value">{displayDash(customer?.acquisitionChannel)}</p>
  </div>
  <div className="approve-customer-third-party-details-card-item">
      <p className="approve-customer-third-party-details-card-item-title">Comment</p>
      <p className="kyc-value">
        {displayDash(customer?.onboardingDetail?.adminComments)}
      </p>
  </div>
  {!customer?.active &&
    <div className="approve-customer-third-party-details-card-item">
      <p className="approve-customer-third-party-details-card-item-title">Deactivation reason</p>
      <p className="kyc-value">{displayDash(customer?.deactivationReason)}</p>
    </div>
  }
  {
    !isMobile && <DragOutlined title="expand" className="expand-details-icon" onClick={detailsExpanded}/>
  }
  <Divider />
  </>
    )
}

export default Customerdetails