import { Select } from "antd";
import { ErrorMessage, Field } from "formik";
import React, { FC, Fragment } from "react";
import { MetaModel, NeighbourhoodModel } from "../../../models/Meta/meta.model";
import Error from "../Error";
import IconComponent from "../IconComponent";
import "./dropdownField.scss";

interface DropdownFieldProps {
  name: string;
  value: any;
  label: string;
  onChange: (value: any, record: any) => void;
  className?: string;
  placeholder: string;
  options: MetaModel[] | NeighbourhoodModel[];
  disabled?: boolean;
  mode?: string;
  maxTagCount?: number | "responsive" | undefined;
  optionFilterProp?: string;
  allowClear?: boolean;
  defaultValue?: string;
  required?: boolean;
  loading?: boolean;
}

interface SelectOption {
  label: string;
  value: string;
}

const filterOption = (input: string, option?: SelectOption): boolean => {
  if (!option) return false;
  const inputLower = input?.toLowerCase();
  const optionText = option?.label?.toLowerCase();
  return optionText?.includes(inputLower);
};

const dropdownRender = (menu: React.ReactElement) => (
  <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
    {menu}
  </div>
);

const DropdownField: FC<DropdownFieldProps> = (props) => {
  const { name, value, label, loading, onChange, className, placeholder, options, disabled, defaultValue, required, ...rest } = props;

  return (
    <Fragment>
      <Field name={name}>
        {({ field, form: { touched, errors, setTouched }, meta }: any) => (
          <div
            className={`dropdown-field ${meta?.error && meta.touched && "error"
              } ${className}`}
          >
            {label ? <label>{label}{required ? "*" : "" }</label> : ""}
            <Select
              {...field}
              {...rest}
              disabled={disabled}
              loading={loading}
              notFoundContent={loading ? "Loading" : 'No data'}
              placeholder={placeholder}
              onChange={(value, record) => {
                onChange(value, record)
              }}
              data-testid={name}
              defaultActiveFirstOption
              value={(!loading && value) ? value : defaultValue}
              showArrow
              options={options}
              suffixIcon={<IconComponent name="icon-down" />}
              onBlur={() => setTouched({ ...touched, [name]: true })}
              getPopupContainer={trigger => trigger.parentElement as HTMLElement}
              dropdownRender={dropdownRender}
              placement="bottomLeft"
              showSearch
              filterOption={filterOption}
            />
          </div>
        )}
      </Field>
      <ErrorMessage name={name}>
        {(message: string) =>
          <Error
            testId={`input-error-${name}`}
            message={message}
          />
        }
      </ErrorMessage>
    </Fragment>
  );
};

export default DropdownField;
