import React, { FC, useEffect, useState } from 'react'
import { VendorDownpaymentStatusModel } from '../../../../models/Configuration/configuration.model'
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import CardSkeletonComponent from '../../../../shared/components/CardSkeletonComponent'
import EmptyStateComponent from '../../../../shared/components/EmptyStateComponent'
import SwitchComponent from '../../../../shared/components/SwitchComponent'
import TableComponent from '../../../../shared/components/TableComponent'

interface VendordownpaymentstatusProps { }

const VendorDownpaymentStatus: FC<VendordownpaymentstatusProps> = () => {

    const { getVendorsDownpaymentStatus, vendorDownpaymentStatus, loading, updateDownpaymentStatus, paginations } = ConfigurationService();
    const [page, setPage] = useState(1);

    const handleRefresh = (page: number) => getVendorsDownpaymentStatus({page});

    useEffect(() => {
        handleRefresh(page);
    }, [])
    
    const handlePageChange = (page:number) => {
        setPage(page)
        handleRefresh(page)
    }

    return (
        <div className="vendordownpaymentstatus">
        {loading ? <CardSkeletonComponent /> : vendorDownpaymentStatus?.length > 0 ? <TableComponent
            loading={loading}
            isSettlements
                data={vendorDownpaymentStatus}
                paginationMeta={paginations}
                handlePageChange={handlePageChange}
                pageNumber={page}
                columns={[
                {
                    dataIndex: "name",
                    key: "name",
                    title: "Vendor",
                    width: "12%",
                },
                {
                    dataIndex: "downPaymentEnabled",
                    key: "downPaymentEnabled",
                    title: "Down payment status",
                    render: (text, record: VendorDownpaymentStatusModel) => <SwitchComponent
                        label=""
                        value={record?.downPaymentEnabled}
                        checkedTitle={"Down payment applicable"}
                        unCheckedTitle={"Down payment not applicable"}
                        onSwitch={(value) => {
                            updateDownpaymentStatus(value, Number(record?.id), () => handleRefresh(page))
                        }}
                    />,
                    width: "30%"

                }
                ]}
            />  : <EmptyStateComponent header='Vendor down payment status not found' description='' title=''/>}
        </div>
    )
}

export default VendorDownpaymentStatus