import React, { FC, ReactElement, useEffect, useState } from "react";
import {Col, Collapse, Divider, Row, Tabs} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import { DocumentTypes } from "../../../../../enums/documentTypes";
import CustomerService from "../../../../../services/CustomerService/customer.service";
import S3AttachmentService from "../../../../../services/S3UploadService/s3upload.service";
import DocumentsCard from "../../../../../shared/components/DocumentsCard";
import PageView from "../../../../../shared/components/PageView";
import ApproveCustomerThirdPartyDetailsCard from "./ApproveCustomerThirdPartyDetailsCard";
import "./approveCustomersView.scss";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import { CustomerModel } from "../../../../../models/Customer/customer.model";
import CustomerQuestionnaire from "../../CustomerList/CustomerListView/CustomerQuestionnaire";
import { UploadChangeParam } from "antd/lib/upload";
import CustomerDetailsCard from "../../CustomerList/CustomerListView/CustomerDetailsCard";
import { checkDocApprovalStatus } from "../../../../../shared/utils/checkDocApprovalStatus";
import CustomerAdditionalInfo from "../../CustomerList/CustomerListView/CustomerAdditionalInfo";
import useLocationState from "../../../../../shared/utils/useLocationState";
import { mobileScreenWidth } from "../../../../../enums/constants";
import MetaService from "../../../../../services/MetaService/meta.service";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import rollingEye from "../../../../../assets/lotties/rolling-eyes.json"
import LottiePlayerComponent from "../../../../../shared/components/LottiePlayerComponent";
import { AuthContext } from "../../../../../context/AuthContext";
import { useWebsocket } from "../../../../../shared/utils/useWebsocket";
import { NotificationModel } from "../../../../../models/notification.model";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";

const { TabPane } = Tabs;
const { Panel } = Collapse;

interface ApproveCustomersViewProps { }

const ApproveCustomersView: FC<ApproveCustomersViewProps> = () => {
  const {user} = AuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { sendViewingEvent } = useWebsocket(user?.id); 
  const { pageNumber, filterOptions } = useLocationState();
  const {PROFILE_PIC,NATIONAL_ID_BACK,NATIONAL_ID_FRONT,BANK_STATEMENT,EMPLOYMENT_LETTER, SELFIE_PIC, PAYSLIP, SPORTS_CLUB_MEMBERSHIP, VEHICLE_CARD, CREDIT_CARD_STATEMENT, PAYSLIP_BANK_STATEMENT, EMPLOYMENT_LETTER_MEDICAL_CARD, STUDENT_CARD, MEDICAL_CARD, CUSTOMER_SIGNATURE} = DocumentTypes
  const { fetchCustomerDetails, loading, customer, updateVerificationDocument, resetRetryCount, fetchCustomerContactsStatistics, customerContactStat } = CustomerService();
  const {  getRiseConfig, riseConfigMeta } = MetaService()
  const { uploadAttachment, uploadingBankStatement, uploadingEmploymentLetter, uploadingNationalIdBack, uploadingNationalIdFront, uploadingProfile, uploadingSelfie, uploadingPayslip, uploadingSportsClubMembership, uploadingVehicleCard, uploadingCreditCardStatement, uploadingEmploymentLetterMedicalCard, uploadingPayslipBankstatement, uploadingStudentCard, uploadingMedicalCard } = S3AttachmentService()
  const { customerId } = useParams();
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [content, setContent] = useState<string>();
  const isPendingApprovalScreen = location.pathname.split('/').includes('approve-customers');

  const handleRefresh = () => {
    getRiseConfig();
    if (customerId) {
      fetchCustomerDetails(customerId);
      fetchCustomerContactsStatistics(customerId);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const checkKycStatus = async () => {
      if (customer?.kycStatus === BadgeTypes.KYC_PENDING && customerId) {
        await fetchCustomerDetails(customerId);
        if (isMounted) {
          setTimeout(checkKycStatus, 30000);
        }
      }
    };

    checkKycStatus();

    return () => {
      isMounted = false; // Prevent running after unmount
    };
  }, [customer?.kycStatus]);

  const _updateVerificationDocument = async (file:any,updateAttachmentId:string, attachmentType: typeof PROFILE_PIC|typeof NATIONAL_ID_FRONT|typeof NATIONAL_ID_BACK|typeof EMPLOYMENT_LETTER|typeof BANK_STATEMENT|typeof SELFIE_PIC|typeof VEHICLE_CARD|typeof PAYSLIP|typeof SPORTS_CLUB_MEMBERSHIP|typeof CREDIT_CARD_STATEMENT|typeof PAYSLIP_BANK_STATEMENT|typeof EMPLOYMENT_LETTER_MEDICAL_CARD|typeof MEDICAL_CARD|typeof STUDENT_CARD) => {
    const attachmentId = await uploadAttachment(file?.file?.originFileObj, attachmentType)
    updateVerificationDocument(customerId ?? "", updateAttachmentId, { attachmentType, attachmentId }, () => {
      handleRefresh()
    })
  }

  // Changed for au10tix earlier user to be failed_verification
  const isPendingCustomer = customer?.status === BadgeTypes.PENDING_APPROVAL 
  const isRejected = customer?.status === BadgeTypes.REJECTED
  const pathName = `${location?.pathname?.split('/')[2]}`
  const backRouteURL = `${AppRoutes.CUSTOMERS}${pathName === "rejected-customers" ? "#rejected-customers" : (pathName === 'incomplete-onboarding-customers') ? "#incomplete-onboarding-customers" : "#approve-customers"}`

  const isDocVerificationIncomplete = customer && checkDocApprovalStatus(customer)
  const isNeighborhoodDetailsMissing = !customer?.neighbourhood;

  const isMobile = window.innerWidth < mobileScreenWidth

  const documentsCard =  <DocumentsCard
  detailsExpanded={detailsExpanded}
  isUpload
  customer={customer}
  data={[
    {
      label: "CUSTOMER PHOTO",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.profilePic?.[0]?.id??"",DocumentTypes.PROFILE_PIC),
      isUpload: true,
      accept: "image/png, image/jpg, image/jpeg",
      uploading: uploadingProfile,
      document: customer?.profilePic
    },
    {
      label: "SELFIE PIC",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.selfiePic?.[0]?.id??"",DocumentTypes.SELFIE_PIC),
      isUpload: true,
      accept: "image/png, image/jpg, image/jpeg",
      uploading: uploadingSelfie,
      document: customer?.selfiePic
    },
    {
      label: "NATIONAL ID - FRONT",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.nationalIdFront?.[0]?.id??"",DocumentTypes.NATIONAL_ID_FRONT),
      isUpload: true,
      accept:"image/png, image/jpg, image/jpeg, application/pdf",
      uploading: uploadingNationalIdFront,
      document: customer?.nationalIdFront
    },
    {
      label: "NATIONAL ID - BACK",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.nationalIdBack?.[0]?.id??"",DocumentTypes.NATIONAL_ID_BACK),
      isUpload: true,
      accept:"image/png, image/jpg, image/jpeg, application/pdf",
      uploading: uploadingNationalIdBack,
      document: customer?.nationalIdBack
    },
    {
      label: "BANK STATEMENT",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.bankStatement?.[0]?.id??"",DocumentTypes.BANK_STATEMENT),
      isUpload: true,
      accept:"image/png, image/jpg, image/jpeg, application/pdf",
      uploading: uploadingBankStatement,
      document: customer?.bankStatement
    },
    {
      label: "EMPLOYMENT LETTER",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.employmentLetter?.[0]?.id??"",DocumentTypes.EMPLOYMENT_LETTER),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingEmploymentLetter,
      document: customer?.employmentLetter
    },
    {
      label: "VEHICLE CARD",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.vehicleCard?.[0]?.id??"",DocumentTypes.VEHICLE_CARD),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingVehicleCard,
      document: customer?.vehicleCard
    },
    {
      label: "SPORTS CLUB MEMBERSHIP",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.sportsClubMembership?.[0]?.id??"",DocumentTypes.SPORTS_CLUB_MEMBERSHIP),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingSportsClubMembership,
      document: customer?.sportsClubMembership
    },
    {
      label: "PAY SLIP",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.paySlip?.[0]?.id??"",DocumentTypes.PAYSLIP),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingPayslip,
      document: customer?.paySlip
    },
    {
      label: "CREDIT CARD STATEMENT",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.creditCardStatement?.[0]?.id??"",DocumentTypes.CREDIT_CARD_STATEMENT),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingCreditCardStatement,
      document: customer?.creditCardStatement
    },
    {
      label: "PAY SLIP / BANK STATEMENT",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.paySlipBankStatement?.[0]?.id??"",DocumentTypes.PAYSLIP_BANK_STATEMENT),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingPayslipBankstatement,
      document: customer?.paySlipBankStatement
    },
    {
      label: "EMPLOYMENT LETTER / MEDICAL CARD",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.employmentLetterMedicalCard?.[0]?.id??"",DocumentTypes.EMPLOYMENT_LETTER_MEDICAL_CARD),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingEmploymentLetterMedicalCard,
      document: customer?.employmentLetterMedicalCard
    },
    {
      label: "MEDICAL CARD",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.medicalCard?.[0]?.id??"",DocumentTypes.MEDICAL_CARD),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingMedicalCard,
      document: customer?.medicalCard
    },
    {
      label: "STUDENT CARD",
      handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.studentCard?.[0]?.id??"",DocumentTypes.STUDENT_CARD),
      isUpload: true,
      accept: "application/pdf",
      uploading: uploadingStudentCard,
      document: customer?.studentCard
    },
    {
      label: "CUSTOMER SIGNATURE",
      isUpload: true,
      accept: "image/png, image/jpg, image/jpeg, application/pdf",
      document: customer?.esign
    },
  ]}
  loading={loading}
/>

useEffect(() => {
  handleRefresh();
}, []);

useEffect(() => {  
  if (isPendingApprovalScreen && user?.id && customer?.id) {
    
    sendViewingEvent("enter_page", { 
      customerId: customer?.id, 
      pageName: 'approve-customers' 
    });

    const handleAdminViewingAlert = (event: CustomEvent) => {
      const { detail } = event as CustomEvent<{ _data: NotificationModel }>;
      setContent(detail?._data?.body);
      setOpenAlertModal(true);
    };

    window.addEventListener("admin-viewing-alert", handleAdminViewingAlert as EventListener);

    return () => {
      sendViewingEvent("leave_page", {
        customerId: customer?.id!, 
        pageName: 'approve-customers' 
      });
      
      window.removeEventListener("admin-viewing-alert", handleAdminViewingAlert as EventListener);
    };
  }
}, [isPendingApprovalScreen, user?.id, customer?.id]);

const alertHeaderElement: ReactElement = (
  <div>
    <div className="alert-background"></div>
    <LottiePlayerComponent data={rollingEye} height={56.776} width={94} className='' />
    <div className="admin-name">{content}</div>
  </div>
);

const successProps =  {title: "Go back",
  loading: loading,
  clickHandler: () => {
    navigate({ pathname: AppRoutes.CUSTOMERS, hash: "approve-customers" });
  }
}

const cancelProps = {
  title: !isMobile ? "View customer" : "",
  clickHandler: () => {
    setOpenAlertModal(false);
  }
}

  return (
    <div>
      <ModalComponent
        type={!isMobile ? "medium" : "no-controller-medium"}
        visible={openAlertModal && content !== ''}
        className="alert-admins"
        closeHandler={() => setOpenAlertModal(false)}
        successProps={!isMobile ? successProps : {}}
        cancelProps={!isMobile ? cancelProps : {}}
        title={alertHeaderElement}
        description="Another admin is currently reviewing this customer. Please check back later or select a different customer."
      >
        <div className={`ctas ${!isMobile && 'hide-cta'}`}>
        <ButtonComponent
        type={"primary"}
        htmlType={"submit"}
        size="fixed-v4"
        onClick={() => {
          navigate({ pathname: AppRoutes.CUSTOMERS, hash: "approve-customers" })
        }}
        loading={loading}
      >
        Go back
      </ButtonComponent>
        <ButtonComponent
        type="default"
        additionalTypes="secondary"
        size="fixed-v4"
        onClick={() => {
          setOpenAlertModal(false);
        }}
      >
        View customer
      </ButtonComponent>
        </div>
      </ModalComponent>
    <PageView className="pending-approval-view" type="back-only" backRoute={backRouteURL} title={{ name: customer?.nameFromId ?? "" }} customer={customer as CustomerModel} handleRefresh={handleRefresh} pageNumber={pageNumber} filterOptions={filterOptions}>
      <div className="approve-customers-view">
        <Row gutter={24}>
          <Col xs={24} sm={24} lg={6}>
            <CustomerDetailsCard riseConfigMeta={riseConfigMeta} customer={customer} detailsExpanded={detailsExpanded} isFetching={loading} refreshList={handleRefresh} view={true} docVerificationIncomplete={isDocVerificationIncomplete} isNeighborhoodDetailsMissing={isNeighborhoodDetailsMissing} />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            {/* Need to be tested and removed */}
            {/* {customer?.status === BadgeTypes.WAITLISTED ? 
            <CustomerInfoCard customer={customer} isFetching={loading} refreshList={handleRefresh}/> : */}
            <ApproveCustomerThirdPartyDetailsCard
              loading={loading}
              customer={customer}
              handleRefresh={handleRefresh}
              detailsExpanded={detailsExpanded}
              setDetailsExpanded={setDetailsExpanded}
            />
            {/* } */}
          </Col>
          {!isMobile &&
          <Col  xs={24} sm={24} lg={6}>
            {documentsCard}
          </Col>}
        </Row>
        {!isMobile &&
        <Tabs className="customer-list-view-tabs">
          <TabPane tab="Questionnaire" key={"questionnaire"}>
            {!!customer?.questionnaire?.length && <CustomerQuestionnaire data={customer?.questionnaire} />}
          </TabPane>
          <TabPane tab="Additional Information" key={"additional-information"}>
            <CustomerAdditionalInfo/>
          </TabPane>
        </Tabs>}
        {isMobile &&
          <Collapse
            accordion={false}
            expandIconPosition="right"
            className="accordion-panel-container"
            defaultActiveKey={['documents', 'questionnaire', 'contact-data', 'sms-data']}
          >
              <Panel header="Documents" key="documents" className="accordion-panel">
                {documentsCard}
              </Panel>
              <Panel header="Questionnaire" key="questionnaire" className="accordion-panel">
                <CustomerQuestionnaire data={customer?.questionnaire} />
              </Panel>
              <Panel header="Contact data analysis" key="contact-data" className="accordion-panel">
                <CustomerAdditionalInfo isMobileContactView />
              </Panel>
              <Panel header="SMS data analysis" key="sms-data" className="accordion-panel">
                <CustomerAdditionalInfo isMobileSMSView />
              </Panel>
          </Collapse>
          }
      </div>
    </PageView>
    </div>
  );
};

export default ApproveCustomersView;
