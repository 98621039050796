import { Row, Col } from 'antd';
import { Formik, Form } from 'formik';
import { FC, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { VendorStorePromotionModel } from '../../../../../models/Vendor/StorePromotion/storePromotion.model';
import S3AttachmentService from '../../../../../services/S3UploadService/s3upload.service';
import VendorService from '../../../../../services/VendorService/vendor.service';
import ModalComponent from '../../../../../shared/components/ModalComponent';
import UploadComponent from '../../../../../shared/components/UploadComponent';
import { promotionFormValidation } from './promotionFormValidation';
import "./vendorstorepromotionform.scss"

interface VendorStorePromotionFormProps { 
    visible: boolean;
    closeHandler: () => void;
    handleRefresh: () => void;
}

const VendorStorePromotionForm: FC<VendorStorePromotionFormProps> = ({ visible, closeHandler, handleRefresh }) => {
    const formRef = useRef<any>();
    const [initialValues, setInitialValues] = useState(new VendorStorePromotionModel())
    const [isUploading, setIsUploading] = useState(false);
    const [enAttachmentId, setEnAttachmentId] = useState<number>();
    const [arAttachmentId, setArAttachmentId] = useState<number>();

    const { vendorId } = useParams();
    const { createVendorStorePromotion } = VendorService()
    const { uploadAttachment, loading } = S3AttachmentService();

    const resetFormAndClose = () => {
      formRef?.current?.resetForm({ values: {} });
      closeHandler();
    };


    return (
      <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={() => {}}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={promotionFormValidation}
      >
        {({ setFieldValue, values, errors }) => (
          <ModalComponent
              type="alert-large-2"
              visible={visible}
              closeHandler={resetFormAndClose}
              successProps={{
                title: "Create",
                successDisabled: loading,
                clickHandler: async () => {
                  const errors = await formRef.current?.validateForm();
                  if (Object.keys(errors).length === 0) {
                    if (!!vendorId && !!enAttachmentId && !!arAttachmentId) {
                      setIsUploading(true);
                      createVendorStorePromotion(vendorId, { attachment: enAttachmentId, attachmentAr: arAttachmentId }, () => {
                        setIsUploading(false);
                        handleRefresh();
                      });
                      setArAttachmentId(undefined);
                      setEnAttachmentId(undefined);
                      resetFormAndClose();
                    }
                  }
                }
              }}
              cancelProps={{
                title: "Discard",
                clickHandler: resetFormAndClose,
              }}
              title={"New store promotion"}
          >
              <Form>
                  <Row>
                    <Col span={24}>
                      <div className='poster-header'>Upload size (w*h) : 335 * 200 px</div>
                      <div className='poster-header'>File format : png, jpg, jpeg file</div>
                    </Col>
                    <Col span={24}>
                    <div className='posters'>
                    <UploadComponent
                      fileId={
                        values?.attachment?.id?.toString() || ''
                      }
                      fileType={values?.attachment?.fileType || ''}
                      fileName={values?.attachment?.filename || ''}
                      accept={"image/png"}
                      fileUrl={values?.attachment?.s3Url || ''}
                      title={
                      <>
                          <div>Upload image</div>
                      </>
                      }
                      onUpload={async (file, fileUrl) => {
                        setFieldValue("attachment.filename", file?.name);
                        setFieldValue("attachment.s3Url", fileUrl);
                        const attachment = await uploadAttachment(file);
                        setFieldValue('enAttachmentId', attachment);
                        setEnAttachmentId(attachment)
                      }}
                      type="poster"
                      additionalType='poster'
                      label='English asset'
                      language='en'
                      error={(errors as unknown as VendorStorePromotionModel)?.enAttachmentId}
                    />
                    </div>
                    </Col>
                    <Col span={24}>
                    <div className='posters'>
                    <UploadComponent
                    fileId={
                      values?.attachmentAr?.id?.toString() || ''
                    }
                    fileType={values?.attachmentAr?.fileType || ''}
                    fileName={values?.attachmentAr?.filename || ''}
                    accept={"image/png"}
                    fileUrl={values?.attachmentAr?.s3Url || ''}
                      title={
                      <>
                          <div>تحميل الصورة</div>
                      </>
                      }
                      onUpload={async (file, fileUrl) => {
                        setFieldValue("attachment.filename", file?.name);
                        setFieldValue("attachmentAr.s3Url", fileUrl);
                        const attachmentAr = await uploadAttachment(file);
                        setFieldValue('arAttachmentId', attachmentAr);
                        setArAttachmentId(attachmentAr)
                      }}
                      type="poster"
                      additionalType='poster'
                      label='الأصول العربية'
                      language='ar'
                      error={(errors as unknown as VendorStorePromotionModel)?.arAttachmentId}
                    />
                    </div>
                    </Col>
                  </Row>                            
              </Form>
          </ModalComponent>
        )}
      </Formik>
  )
}

export default VendorStorePromotionForm