import { CitiesModel } from './../Meta/meta.model';
import { serializable, alias, list, primitive, object } from "serializr";
import { RegionsModel } from "../Meta/meta.model";

export class ConfigurationListModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("customer_processing_fee", primitive()))
  customerProcessingFee?: number;

  @serializable(alias("deall_score_range", list(primitive())))
  deallScoreRange?: number;

  @serializable(alias("installments", primitive()))
  installments?: number;

  @serializable(alias("purchase_power", primitive()))
  purchasePower?: number;
}
export class ConfigurationProcessingFeeModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("processing_fee", primitive()))
  processingFee?: number;

  @serializable(alias("late_fee", primitive()))
  lateFee?: number;

}

export class ConfigurationNeighbourhoodModel {
	@serializable(alias('id', primitive()))
	value?: number;
  
	@serializable(alias('zone_keyword', primitive()))
	label?: string;
	
	@serializable(alias('zone_rank', primitive()))
	zoneRank?: string;
	
	@serializable(alias('zone_score', primitive()))
	zoneScore?: number;
  
	@serializable(alias('name_in_en', primitive()))
	nameInEn?: string;
  
	@serializable(alias('approved', primitive()))
	approved?: boolean;
  
  }

  export class VendorDownpaymentStatusModel {
    @serializable(alias("id", primitive()))
    id?: number;
  
    @serializable(alias("name", primitive()))
    name?: string;
  
    @serializable(alias("down_payment_enabled", primitive()))
    downPaymentEnabled?: boolean;
  }

