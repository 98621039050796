import * as Yup from  "yup";
import { REGEX } from "../../../../enums/Regex";

export const configurationSchema = Yup.object().shape({
    customerProcessingFee: Yup.number()
        .typeError("Processing fee should be a number")
        .min(0)
        .max(100)
        .required('Processing fee is required.'),
    purchasePower: Yup.number()
        .typeError("Purchasing power should be a number")
        .required('Purchasing power is required.'),
    installments: Yup.number()
        .typeError("Installments should be a number")
        .positive()
        .min(1)
        .max(10, "Maximum installments is 10")
        .required('Installments is required.'),
  })

  export const processingFeeSchema = Yup.object().shape({
    processingFee: Yup.number()
        .typeError("Processing fee should be a number")
        .min(0)
        .max(100)
        .required('Processing fee is required.'),
  })

  export const vendorPurchaseConfigurationSchema = Yup.object().shape({
    purchasePower: Yup.number()
        .typeError("Purchasing power should be a number")
        .required('Purchasing power is required.'),
    installments: Yup.number()
        .typeError("Installments should be a number")
        .positive()
        .min(1)
        .max(10, "Maximum installments is 10")
        .required('Installments is required.'),
  })