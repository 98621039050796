export enum SortColumnTypes {
    TOTAL_AMOUNT = "total_amount",
    PURCHASE_STATUS = "purchase_status",
    CUSTOMER_PAYMENT_STATUS = "customer_payment_status",
    PURCHASE_ID = "purchase_id",
    PURCHASE_DATE = "purchase_date",
    VENDOR_NAME = "vendor_name",
    VENDOR_ID = "vendor_id",
    VENDOR_TYPE = "vendor_type",
    VENDOR_STATUS = "vendor_status",
    PAYMENT_DATE = "payment_date",
    PAYMENT_AMOUNT = "payment_amount",
    DEALL_SCORE = "deall_score",
    PLAN = "plan",
    MAX_PURCHASE_POWER = "max_purchase_power",
    PROCESSING_FEE = "processing_fee",
    CUSTOMER_STATUS = "customer_status",
    ACTIVE = "active",
    CUSTOMER_NAME = "customer_name",
    USER_ID = "user_id",
    USER_NAME = "user_name",
    USER_ROLE = "user_role",
    USER_STATUS = "user_status",
    ONBOARDING_START_DATE = "onboarding_start_date",
    SUBMITTED_ON = "submitted_on",
    ASCEND = "ascend",
    DESCEND = "descend",
    SETTLED_AT = "settled_at",
    NUMBER_OF_ORDERS = "no_of_orders",
    CREATED_AT = "created_at",
    MINIMUM_DUE = "min_amount",
    DUE_DATE = "due_date",
    SETTLEMENT_NO_OF_ORDERS = "settlement_no_of_orders",
    VENDOR_NO_OF_ORDERS = "vendor_no_of_orders",
    SETTLEMENT_AMOUNT = "settlement_amount",
    EXTERNAL_PAYMENT_ID = "external_payment_id",
    INSTORE_NAME = "instore_name",
    INSTORE_NO_OF_ORDERS = "instore_no_of_orders",
    VENDOR_SETTLEMENT_NO_OF_ORDERS = "vendor_settlement_no_of_orders",
    VENDOR_SETTLEMENT_SETTLED_AT = "vendor_settlement_settled_at",
    PARTNER_NAME = "partner_name",
    PARTNER_TRANSACTION_DATE = "partner_transaction_date",
    PARTNER_TRANSACTION_AMOUNT = "partner_transaction_amount",
    PARTNER_FEES = "partner_fees",
    PARTNER_VAT = "partner_vat",
    PARTNER_TOTAL_FEES = "partner_total_fees",
    PAST_TRANSACTION_DATE = "past_transaction_date",
    PAST_TRANSACTION_AMOUNT = "past_transaction_amount",
    VAT = "vat",
    VENDOR_PROCESSING_FEE = "vendor_processing_fee",
    VENDOR_SETTLEMENT_AMOUNT = "vendor_settlement_amount",
    ONBOARDED_DATE = "onboarded_date",
    PENDING_PARTNER_NAME = "pending_partner_fees_partner_name",
    PENDING_PARTNER_TRANSACTION_DATE = "pending_partner_fees_transaction_date",
    PENDING_PARTNER_TRANSACTION_AMOUNT = "pending_partner_fees_transaction_amount",
    PENDING_PARTNER_PROCESSING_FEE = "pending_partner_fees_partner_processing_fee",
    PENDING_PARTNER_VAT = "pending_partner_fees_partner_processing_fee_vat",
    PENDING_PARTNER_SETTLEMENT_AMOUNT = "pending_partner_fees_settlement_amount",
    PAST_PARTNER_NAME = "past_partner_fees_partner_name",
    PAST_PARTNER_SETTLED_DATE = "past_partner_fees_settled_date",
    PAST_PARTNER_ORDERS = "past_partner_fees_no_of_orders",
    PAST_PARTNER_TOTAL_AMOUNT = "past_partner_fees_total_amount",
    PENDING_PAYMENT_PARTNER_NAME = "pending_payment_collection_partner_name",
    PENDING_PAYMENT_CREATED_DATE = "pending_payment_collection_created_at",
    PENDING_PAYMENT_AMOUNT = "pending_payment_collection_amount",
    PENDING_PAYMENT_PROCESSING_FEE = "pending_payment_collection_partner_processing_fee",
    PENDING_PAYMENT_VAT = "pending_payment_collection_partner_processing_fee_vat",
    PENDING_PAYMENT_COLLECTION_AMOUNT = "pending_payment_collection_collection_amount",
    PAST_PAYMENT_PARTNER_NAME = "past_payment_collection_partner_name",
    PAST_PAYMENT_SETTLED_DATE = "past_payment_collection_settled_date",
    PAST_PAYMENT_ORDERS = "past_payment_collection_no_of_orders",
    PAST_PAYMENT_COLLECTION_AMOUNT = "past_payment_collection_collection_amount",
    PENDING_VENDOR_SETTLEMENTS_VENDOR_NAME = "pending_vendor_settlements_vendor_name",
    PENDING_VENDOR_SETTLEMENTS_PURCHASE_DATE = "pending_vendor_settlements_purchase_date",
    PENDING_VENDOR_SETTLEMENTS_PURCHASE_AMOUNT = "pending_vendor_settlements_purchase_amount",
    PENDING_VENDOR_SETTLEMENTS_VENDOR_FEE = "pending_vendor_settlements_vendor_fee",
    PENDING_VENDOR_SETTLEMENTS_VENDOR_FEE_VAT = "pending_vendor_settlements_vendor_fee_vat",
    PENDING_VENDOR_SETTLEMENTS_VENDOR_SETTLEMENT_AMOUNT = "pending_vendor_settlements_vendor_settlement_amount",
    PENDING_VENDOR_SETTLEMENTS_REFUND_VENDOR_SETTLEMENT_AMOUNT = "pending_vendor_settlements_refund_vendor_settlement_amount",
    PAST_VENDOR_SETTLEMENTS_VENDOR_ID = "past_vendor_settlements_vendor_id",
    PAST_VENDOR_SETTLEMENTS_VENDOR_NAME = "past_vendor_settlements_vendor_name",
    PAST_VENDOR_SETTLEMENTS_SETTLED_DATE = "past_vendor_settlements_settled_date",
    PAST_VENDOR_SETTLEMENTS_NO_OF_ORDERS = "past_vendor_settlements_no_of_orders",
    PAST_VENDOR_SETTLEMENTS_VENDOR_SETTLEMENT_AMOUNT = "past_vendor_settlements_vendor_settlement_amount",
    PENDING_VENDOR_SETTLEMENTS_REFUND_AMOUNT = "pending_vendor_settlements_refund_amount",
    PENDING_VENDOR_SETTLEMENTS_REFUND_RECEIPT_ID = "pending_vendor_settlements_refund_receipt_id",
    PENDING_VENDOR_SETTLEMENTS_VENDOR_RETURNED_DATE = "pending_vendor_settlements_returned_date",
    CUSTOMER_DOCUMENT_ATTACHMENT_STATUS = "attachment_status"
}