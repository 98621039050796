import React, { FC, useEffect, useState } from "react";
import BadgeComponent from "../../../shared/components/BadgeComponent";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../shared/components/TableComponent";
import "./purchases.scss";
import { BadgeTypes } from "../../../enums/badgeTypes";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { currencyString } from "..";
import { Form, Formik } from "formik";
import { Col, DatePicker, Row } from "antd";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import DatePickerField from "../../../shared/components/DatePickerField";
import PurchaseService from "../../../services/PurchaseService/purchase.service";
import NoPurchasesImg from "../../../assets/empty/purchases.svg";
import {
  PurchaseListModel,
} from "../../../models/Purchase/purchase.model";
import moment, { Moment } from "moment";
import { BadgeTypesDefinition } from "../../../definitions/badgeTypesDefinition";
import { removeUndefined } from "../../../shared/utils/removeUndefined";
import { VendorTypes } from "../../../enums/vendorTypes";
import { VendorTypesDefinition } from "../../../definitions/vendorTypesDefinition";
import { SortFilterModel } from "../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../shared/types/SortColumn.type";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import useWindowWidth from "../../../shared/utils/useWindowWidth";
import Viewondesktop from "../../../shared/components/Viewondesktop";
import { calculatePurchaseTotal } from "../../../shared/utils/calculatePurchaseTotal";
import { RangePickerField } from "../Payments/PaymentLogs";
import { objectHasKeys } from "../../../shared/utils/objectHasKeys";
import { StateProps } from "../../../models/Meta/meta.model";
import { mobileScreenWidth } from "../../../enums/constants";
import { clearFilters } from "../../../shared/utils/clearFilters";
import { parseDate } from "../../../shared/utils/formatDate";
import { RangePickerValueType } from "../../../shared/types/rangePicker.type";
import { generateDateString } from "../../../shared/utils/generateDateString";
interface PurchasesProps {}

const { RangePicker } = DatePicker;

const Purchases: FC<PurchasesProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  let pageNumber = stateValue?.pageNumber;
  let filterOptions = stateValue?.filterOptions;
  const [isFilterShown, setIsFilterShown] = useState(false);
  const { loading, fetchPurchases, purchases, paginations } = PurchaseService();
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const [page, setPage] = useState(pageNumber ?? 1);
  const startDate = parseDate(filterOptions?.startDate)
  const endDate = parseDate(filterOptions?.endDate)
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([startDate ?? null, endDate ?? null]);
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const showTest = LocalStorage.getItem("IS_TEST")
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= mobileScreenWidth;

  const handleFilterSubmit = (values: SortFilterModel) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  const filterInitialValues = filterOptions ?? new SortFilterModel();

  const handleFilterClose = () => {
    setIsFilterShown(false);
  };
  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              clearFilters({
                resetForm,
                setPage,
                handleFilterClose,
                setFilters,
                setDateRange,
                filterOptions,
                filters,
                location,
              })
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputField
                    name="customerName"
                    placeholder="Enter"
                    label="Customer Name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="nationalId"
                    placeholder="Enter"
                    label="National ID"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="vendorName"
                    placeholder="Enter"
                    label="Vendor Name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="vendorId"
                    placeholder="Enter"
                    label="Vendor ID"
                  />
                </Col>
                <Col span={12}>
                  <div className="input-field">
                    <label>Purchase Date</label>
                    <RangePicker
                      disabledDate={(date) => date.isAfter(moment())}
                      value={dateRange}
                      onChange={(values) => {
                        if (values?.length) {
                          setDateRange(values)
                          const dataString = generateDateString(
                            values as RangePickerValueType
                          );
                          setFieldValue(
                            "startDate",
                            dataString?.split(" - ")[0]
                          );
                          setFieldValue(
                            "endDate",
                            dataString?.split(" - ")[1]
                          );
                        }
                      }}
                      format={"DD/MM/YYYY"}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <DropdownField
                    options={[
                      {
                        value: BadgeTypes.FAWRY,
                        label: BadgeTypesDefinition[BadgeTypes.FAWRY],
                      }
                    ]}
                    label="POS Type"
                    name="posType"
                    placeholder="Enter"
                    onChange={(value) =>
                      setFieldValue("posType", value)
                    }
                    value={values?.posType}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    options={[
                      {
                        value: BadgeTypes.OUTSTANDING_PAYMENT,
                        label: BadgeTypesDefinition[BadgeTypes.OUTSTANDING],
                      },
                      {
                        value: BadgeTypes.COMPLETED,
                        label: BadgeTypesDefinition[BadgeTypes.COMPLETED],
                      },
                      {
                        value: BadgeTypes.OVERDUE,
                        label: BadgeTypesDefinition[BadgeTypes.OVERDUE],
                      },
                    ]}
                    label="Customer Payment Status"
                    name="customerPaymentStatus"
                    placeholder="Enter"
                    onChange={(value) =>
                      setFieldValue("customerPaymentStatus", value)
                    }
                    value={values?.customerPaymentStatus}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    label="Purchase Status"
                    options={[
                      {
                        value: BadgeTypes.COMPLETED,
                        label: BadgeTypesDefinition[BadgeTypes.COMPLETED],
                      },
                      {
                        value: BadgeTypes.FAILED,
                        label: BadgeTypesDefinition[BadgeTypes.FAILED],
                      },
                      {
                        value: BadgeTypes.PARTIAL_RETURN,
                        label: BadgeTypesDefinition[BadgeTypes.PARTIAL_RETURN],
                      },
                      {
                        value: BadgeTypes.RETURN,
                        label: BadgeTypesDefinition[BadgeTypes.RETURNED],
                      },
                    ]}
                    name="purchaseStatus"
                    placeholder="Enter"
                    onChange={(value) => setFieldValue("purchaseStatus", value)}
                    value={values?.purchaseStatus}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="imeiNumber"
                    placeholder="Enter"
                    label="IMEI Number"
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  const handlePageChange = (page:number) => {
    location.state = null;
    setPage(page)
    fetchPurchases({page, ...filters, ...sortOptions, showTest})
  }

  useEffect(() => {
    if(objectHasKeys(filters) || location.pathname === '/purchases'){
      const updatedFilters = objectHasKeys(filters) 
      ? { page, ...filters, ...sortOptions, showTest  }
      : { page, ...sortOptions, search, ...filters, showTest  };

      fetchPurchases(updatedFilters);
    }
  }, [location.pathname, filters]);

  return (
    <PageView
      title={{
        name: "Purchases",
        icon: <IconComponent name="icon-purchases" />,
      }}
    >
      {isMobile ? <Viewondesktop/> :
      <div className="purchases">
        <TableComponent
        pageNumber={pageNumber}
        paginationMeta={paginations}
        handlePageChange={handlePageChange}
        onChange={fetchPurchases}
        onSort={(data)=>{
         setSortOptions(data)
        }}
        filtersSorts={{page, ...filters, ...sortOptions, search, showTest}}
        onRow={(record)=>
          navigate(
            {pathname: generatePath(AppRoutes.PURCHASES_VIEW, {
              purchaseId: record?.id,
            })}, {state: {page, from: 'purchases', filters}}
          )
        }
          search={{
            placeholder: "Search purchase by pos purchase id or customer phone number",
            onSearch: (value) => {
              setSearch(value)
              handleFilterSubmit({ search: value });
            },
          }}
          filter={filter}
          loading={loading}
          columns={[
            {
              title: "Purchase ID",
              dataIndex: "purchaseId",
              key: SortColumnTypes.PURCHASE_ID,
              sorter: true,
              render: (text) => text,
            },
            {
              title: "POS Purchase ID",
              dataIndex: "customerReferenceNumber",
              key: "customerReferenceNumber"
            },
            {
              title: "Purchase Date",
              dataIndex: "createdAt",
              key: SortColumnTypes.PURCHASE_DATE,
              render: (text) => moment(text)?.format("DD MMM YYYY"),
              sorter: true,
            },
            {
              title: "Purchase Time",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (text) => moment(text)?.local().format("HH:mm")
            },
            {
              title: "Customer",
              dataIndex: "customerName",
              key: "customerName",
            },
            {
              title: "Vendor",
              dataIndex: "vendor",
              key: SortColumnTypes.VENDOR_NAME,
              render: (record) => (
                <span className="vendor__name">
                  <img src={record?.logo?.s3Url} />
                  {record?.name}
                </span>
              ),
              sorter: true,
            },
            {
              title: "Mode",
              dataIndex: "vendor",
              key: "vendor",
              render: (record: PurchaseListModel["vendor"]) =>
                record?.mode === VendorTypes.ONLINE ? VendorTypesDefinition[VendorTypes.ONLINE] : record?.instore?.name,
            },
            {
              title: "POS Type",
              dataIndex: "posType",
              key: "posType",
            },
            {
              title: "Customer Fee %",
              dataIndex: "processingFee",
              render: (text) => (
                <span className="vendor__name">
                  {text ?? 0} %
                </span>
              ),
              sorter: true,
            },
            {
              title: "Purchase Total",
              dataIndex: "totalAmount",
              key: SortColumnTypes.TOTAL_AMOUNT,
              render: (text, record: PurchaseListModel) => (
                <span className="vendor__name">
                  {currencyString}
                  {calculatePurchaseTotal(text, Number(record?.customerProcessingFee), Number(record?.promotionDiscount), Number(record?.cashDiscount), Number(record?.totalLateFee))}
                </span>
              ),
            },
            {
              title: "Customer Payment",
              dataIndex: "customerPaymentStatus",
              key: SortColumnTypes.CUSTOMER_PAYMENT_STATUS,
              render: (text) => <BadgeComponent type={text} />,
              sorter: true,
            },
            {
              title: "Purchase Status",
              dataIndex: "purchaseStatus",
              key: SortColumnTypes.PURCHASE_STATUS,
              render: (text) => <BadgeComponent type={text === BadgeTypes.RETURN ? BadgeTypes.RETURNED : text} />,
              sorter: true,
            },
          ]}
          data={purchases}
          empty={{
            img: NoPurchasesImg,
            text: "No purchases",
          }}
        />
      </div>
      }
    </PageView>
  );
};

export default Purchases;
